import { StyleSheet, Dimensions, Platform } from 'react-native';
import Colors from '../constants/Colors';
import * as Device from 'expo-device';
import { withGlobalContext } from '../GlobalContext';

const GLOBAL = require('../constants/Globals');

const TimelineHeadStyle = StyleSheet.create({
  topBarContainer: {
    // paddingTop: 4,
    paddingBottom: 16,
    zIndex: 3,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    // backgroundColor: 'red',
    paddingHorizontal: 20,
    elevation: 0,
  },
  topBarContainerSmall: {
    // marginTop: 6,
    height: 55,
    paddingBottom: 0,
    paddingHorizontal: 14,
  },
  topBarContainerNotch: {
    height: 55,
    paddingBottom: 10,
    paddingLeft: 20,
    // paddingTop: 47,
    // paddingBottom: 16,
  },
  topBarSpace: {
    flex: 1,
    flexDirection: 'row',
  },
  topBarSpaceFixed: {
    width: 40,
    flex: null,
  },
  topBarItem: {
    alignSelf: 'center',
    backgroundColor: 'white',
    // height: 37,
    paddingHorizontal: 0,
    borderRadius: 20,
    borderColor: '#aaa',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    shadowOpacity: 0.15,
    shadowRadius: 4,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 1,
  },
  container: {
    flex: 1,
    paddingTop: Platform.OS === 'web' ? 20 : 0,
  },

  mainContainer: {
    flex: 1,
    paddingTop: 5,
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  buttonText: {
    fontSize: 15,
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.lightBlue,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
    paddingHorizontal: 17,
  },
  logoContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  logo: {
    width: 138,
    height: 30,
    marginLeft: -5,
    marginBottom: 0,
  },
  logoSmall: {
    width: 140 * 0.8,
    height: 34 * 0.8,
    marginLeft: 3,
  },
  logoSquare: {
    marginLeft: 6,
    width: 28,
    height: 28,
  },
});

export default TimelineHeadStyle;
