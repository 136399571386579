import React, { createContext, useEffect, useState } from 'react';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  parse,
  set,
  setMilliseconds,
  setSeconds,
} from 'date-fns';

export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT = 'yyyy-MM-dd';

export interface DateTimeRange {
  fromString: string;
  toString: string;
  from: Date;
  to: Date;
}

export type DateTimeContextState = {
  selectedDateTimeRange: DateTimeRange;
  selectedDateString: string;
  selectedDayShortString: string;
  isLive: boolean;
  switchLiveMode: (on: boolean) => void;
  setDate: (date: string) => void;
  setTimeRange: (fromString: string, toString: string) => void;
};

const contextDefaultValues: DateTimeContextState = {
  selectedDateTimeRange: {
    fromString: '1970-01-01T12:00:00',
    toString: '1970-01-01T20:00:00',
    from: set(new Date(), { seconds: 0, minutes: 0, milliseconds: 0 }),
    to: set(new Date(), { seconds: 0, minutes: 0, milliseconds: 0 }),
  },
  isLive: false,
  selectedDayShortString: 'mon',
  selectedDateString: '1970-01-01',
  setDate: () => {},
  switchLiveMode: () => {},
  setTimeRange: () => {},
};

export const DateTimeContext = createContext<DateTimeContextState>(
  contextDefaultValues,
);

const DateTimeProvider: React.FC = ({ children }) => {
  const [selectedDateTimeRange, setSelectedDateTimeRange] = useState(
    contextDefaultValues.selectedDateTimeRange,
  );

  const [selectedDateString, setSelectedDateString] = useState(
    contextDefaultValues.selectedDateString,
  );

  const [selectedDayShortString, setSelectedDayShortString] = useState(
    contextDefaultValues.selectedDayShortString,
  );

  const [isLive, setIsLive] = useState(contextDefaultValues.isLive);

  const updateDayString = () => {
    const dayString = format(selectedDateTimeRange.from, 'eee');
    const dayStringLowercase = dayString.toLowerCase();
    setSelectedDayShortString(dayStringLowercase);
  };

  useEffect(() => {
    const from = set(new Date(), { seconds: 0, minutes: 0, milliseconds: 0 });
    const to = addHours(from, 2);
    updateValues(from, to);
  }, []);

  const checkIfLive = () => {
    const now = new Date();
    const diffMinutesFromNow = Math.abs(
      differenceInMinutes(now, selectedDateTimeRange.from),
    );

    if (
      diffMinutesFromNow < 10 &&
      getHours(selectedDateTimeRange.to) === 23 &&
      getMinutes(selectedDateTimeRange.to) === 59
    ) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  };

  useEffect(() => {
    updateDayString();
    checkIfLive();
  }, [selectedDateTimeRange]);

  const switchLiveMode = (on: boolean) => {
    const from = new Date();
    const to = on ? set(from, { hours: 23, minutes: 59 }) : addHours(from, 2);
    updateValues(from, to);
  };

  const setDate = (dateString: string) => {
    const newDate = parse(dateString, DATE_FORMAT, new Date());
    const year = getYear(newDate);
    const month = getMonth(newDate);
    const date = getDate(newDate);

    const diffMinutes = differenceInMinutes(
      selectedDateTimeRange.to,
      selectedDateTimeRange.from,
    );

    const from = set(selectedDateTimeRange.from, { year, month, date });
    const to = addMinutes(from, diffMinutes);
    updateValues(from, to);
  };

  const setTimeRange = (fromString: string, toString: string) => {
    if (isLive) {
      return;
    }
    let from = parse(fromString, DATETIME_FORMAT, new Date());
    from = setMilliseconds(from, 0);
    from = setSeconds(from, 0);
    let to = parse(toString, DATETIME_FORMAT, new Date());
    to = setMilliseconds(to, 0);
    to = setSeconds(to, 0);
    updateValues(from, to);
  };

  const updateValues = (from: Date, to: Date) => {
    const fromString = format(from, DATETIME_FORMAT);
    const toString = format(to, DATETIME_FORMAT);
    const newDateString = format(from, DATE_FORMAT);

    const newDateTimeRange: DateTimeRange = { from, to, fromString, toString };
    setSelectedDateTimeRange(newDateTimeRange);
    setSelectedDateString(newDateString);
  };

  const value = React.useMemo(
    () => ({
      selectedDateTimeRange,
      selectedDateString,
      selectedDayShortString,
      isLive,
      switchLiveMode,
      setDate,
      setTimeRange,
    }),
    [selectedDateTimeRange, selectedDateString, selectedDayShortString, isLive],
  );

  return (
    <DateTimeContext.Provider value={value}>
      {children}
    </DateTimeContext.Provider>
  );
};

export default DateTimeProvider;

export const withDateTimeContext = (ChildComponent) => (props) => (
  <DateTimeContext.Consumer>
    {(context) => <ChildComponent {...props} dateTimeContext={context} />}
  </DateTimeContext.Consumer>
);
