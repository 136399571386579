import React, { useContext, useEffect, useState } from 'react';
import { Image, Platform, Text, View } from 'react-native';
import Animated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { State, TapGestureHandler } from 'react-native-gesture-handler';
import { ActivityContext } from '../contex/ActivityContext';
import NavigationService from '../services/NavigationService';

interface LogoNotificationProps {
  showIconOnly?: boolean;
  small?: boolean;
}

const LogoNotification: React.FC<LogoNotificationProps> = ({
  showIconOnly,
  small,
}) => {
  const iconHeight = small ? 26 : 30;
  const brandNameHeight = small ? 19 : 21;
  const brandNameWidth = small ? 90 : 98;
  const [count, setCount] = useState(0);

  const { unseenNewReservations, numberOfPendingReservations } = useContext(
    ActivityContext,
  );

  useEffect(() => {
    const newCount = unseenNewReservations + numberOfPendingReservations;
    setCount(newCount);
  }, [unseenNewReservations, numberOfPendingReservations]);

  const y = useSharedValue(0);
  useEffect(() => {
    if (count > 0) {
      y.value = 0;
      y.value = withRepeat(
        withSequence(
          withTiming(1, {
            duration: 800,
            easing: Easing.bezier(0.5, 1, 0.89, 1),
          }),
          withTiming(0, {
            duration: 800,
            easing: Easing.bezier(0.11, 0, 0.5, 0),
          }),
        ),
        10000,
      );
    } else {
      cancelAnimation(y);
      y.value = withTiming(0, {
        duration: 800,
        easing: Easing.bezier(0.11, 0, 0.5, 0),
      });
    }
  }, [count]);

  const animatedIconStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: y.value * -5 }],
    };
  });

  const animatedShadowStyle = useAnimatedStyle(() => {
    return {
      shadowRadius: y.value * 4,
    };
  });

  // const navigator = withNavigation()

  const handleTap = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      NavigationService.navigate('activity');
    }
  };

  return (
    <TapGestureHandler onHandlerStateChange={handleTap}>
      <View
        style={{
          flexDirection: 'row',
          height: iconHeight,
          alignItems: 'center',
        }}
      >
        <Animated.View
          style={[
            {
              width: iconHeight,
              height: 18,
              opacity: count > 0 ? 1 : 0,
              borderRadius: 100,
              position: 'absolute',
              bottom: -8,
              alignItems: 'center',
            },
          ]}
        >
          <Animated.View
            style={[
              {
                backgroundColor: 'rgba(255,255,255,0.2)',
                shadowColor: 'rgb(91,91,91)',
                width: 20,
                height: 20,
                borderRadius: 100,
                shadowOpacity: 0.7,
                shadowOffset: {
                  width: 0,
                  height: 0,
                },
                opacity: 0.8,
                transform: [
                  {
                    scaleY: 0.35,
                  },
                ],
              },
              Platform.OS !== 'web' && animatedShadowStyle,
            ]}
          />
        </Animated.View>
        <Animated.View
          style={[
            {
              width: iconHeight,
              height: iconHeight,
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'white',
              shadowColor: 'rgba(23,55,82,0.2)',
              shadowOpacity: count > 0 && Platform.OS !== 'web' ? 1 : 0,
              shadowRadius: Platform.OS === 'web' ? 0 : 4,
              shadowOffset: {
                width: 0,
                height: 0,
              },
            },
            Platform.OS !== 'web' && animatedIconStyle,
          ]}
        >
          <Image
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: iconHeight,
              height: iconHeight,
              opacity: count > 0 ? 1 : 0,
            }}
            source={require('../assets/images/reetab-logo-icon-filled-small.png')}
            resizeMode="contain"
          />
          <Image
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: iconHeight,
              height: iconHeight,
              opacity: count > 0 ? 0 : 1,
            }}
            source={require('../assets/images/reetab-logo-icon-small.png')}
            resizeMode="contain"
          />
          {count > 0 && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                // backgroundColor: 'rgba(0,0,0,0.2)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View style={{ flex: 9, justifyContent: 'center' }}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 10,
                    fontWeight: '700',
                    shadowOpacity: Platform.OS === 'web' ? 0 : 0.4,
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowRadius: Platform.OS === 'web' ? 0 : 1,
                  }}
                >
                  {count > 99 ? `99` : `${count}`}
                </Text>
              </View>
              <View style={{ flex: 3 }} />
            </View>
          )}
        </Animated.View>
        {!showIconOnly && (
          <View>
            <Image
              style={{ width: brandNameWidth, height: brandNameHeight }}
              source={require('../assets/images/reetab-logo-black-text-only.png')}
              resizeMode="contain"
            />
          </View>
        )}
      </View>
    </TapGestureHandler>
  );
};
export default LogoNotification;
