import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';

const Button = ({
  onPress,
  color,
  text,
  active,
  loading,
  style,
  textColor,
  loadingComponent,
  icon,
  disabled,
  showShadow,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      opacity={active ? 1 : 0.3}
      disabled={!active || disabled}
      style={[
        {
          flexDirection: 'row',
          backgroundColor: color,
          flex: 1,
          // flexGrow: 1,
          paddingVertical: 8,
          paddingHorizontal: 26,
          // borderRadius: 4,
          borderRadius: 12,
          alignSelf: 'flex-start',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: active ? 1 : 0.3,
        },
        showShadow && {
          shadowOpacity: 0.3,
          shadowOffset: {
            width: 2,
            height: 2,
          },
          shadowRadius: 3,
          shadowColor: '#3f434b',
          elevation: 3,
        },
        style,
      ]}
    >
      {icon}
      {loading && loadingComponent}
      <Text
        style={{
          color: textColor || Colors.white,
          fontSize: 14,
          fontWeight: '500',
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default Button;
