import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { BottomTabBar, createBottomTabNavigator } from 'react-navigation-tabs';

import {
  createStackNavigator,
  TransitionPresets,
} from 'react-navigation-stack';

import TabBarIcon from '../components/TabBarIcon';
import Timeline from '../screens/Timeline/Timeline';
import Spaces from '../screens/Spaces/Spaces';
import SetupNavigatorStack from './SetupNavigatorStack';
import SettingsStack from './SettingsStack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ActivityScreen from '../screens/ActivityScreen';

const config = Platform.select({
  // web: { headerShown: false },
  default: {
    defaultNavigationOptions: {
      ...TransitionPresets.ModalTransition,
      headerStyle: {
        shadowRadius: 0,
        borderBottomWidth: 0,
        height: null,
        shadowOffset: {
          height: 0,
        },
      },
    },
  },
  web: {
    defaultNavigationOptions: {
      ...TransitionPresets.ModalTransition,
      headerStyle: {
        shadowRadius: 0,
        borderBottomWidth: 0,
        height: 20,
        shadowOffset: {
          height: 10,
        },
      },
    },
  },
});

const TimelineStack = createStackNavigator(
  {
    timeline: Timeline,
  },

  config,
);

TimelineStack.navigationOptions = {
  tabBarLabel: 'Timeline',
  tabBarIcon: ({ focused, tintColor }) => (
    <TabBarIcon
      focused={focused}
      name={'menu-outline'}
      color={tintColor}
      size={Platform.OS === 'web' ? 32 : 26}
    />
  ),
};

TimelineStack.path = '';

const SpacesStack = createStackNavigator(
  {
    spaces: Spaces,
  },
  {
    headerMode: 'none',
    ...config,
  },
  // config,
);

SpacesStack.navigationOptions = {
  tabBarLabel: 'Spaces',
  tabBarIcon: ({ focused, tintColor }) => (
    <TabBarIcon
      focused={focused}
      name={'apps'}
      size={Platform.OS === 'web' ? 24 : 19}
      color={tintColor}
    />
  ),
};

SpacesStack.path = '';

const TabBarComponent = ({ navigation, ...props }) => {
  const insets = useSafeAreaInsets();
  const portrait =
    useWindowDimensions().width < useWindowDimensions().height ? 1 : 0;
  const web = Platform.OS === 'web' ? 1 : 0;

  const safeAresI = {
    top: 'never',
    bottom: 'never',
    left: 'never',
    right: 'never',
  };

  return (
    <BottomTabBar
      {...props}
      navigation={navigation}
      safeAreaInset={safeAresI}
      showLabel
      adaptive
      tabStyle={{
        alignItems: 'center',
        height: 42 + portrait * 8 + web * 10,
      }}
      style={{
        backgroundColor: '#ffffff', // TabBar background
        borderTopColor: '#fff',
        shadowColor: '#000',
        shadowOffset: { width: 8, height: 8 },
        shadowOpacity: Platform.OS === 'web' ? 0.6 : 0.2,
        shadowRadius: 12,
        elevation: 8,
        height: 46 + insets.bottom + portrait * 8 + web * 10,
      }}
    />
  );
};

const ActivityStack = createStackNavigator(
  {
    activity: ActivityScreen,
  },
  {
    headerMode: 'none',
    headerShown: false,
    defaultNavigationOptions: {
      animationEnabled: false,
    },
  },
);
ActivityStack.navigationOptions = {
  tabBarLabel: 'Activity',
  tabBarIcon: ({ focused, tintColor }) => (
    <TabBarIcon
      focused={focused}
      name="notifications"
      size={Platform.OS === 'web' ? 26 : 21}
      color={tintColor}
      showBadge={true}
    />
  ),
};

ActivityStack.path = 'activity';

const tabNavigator = createBottomTabNavigator(
  {
    TimelineStack,
    SpacesStack,
    ActivityStack,
    SetupNavigatorStack,
    SettingsStack,
  },
  {
    lazy: true,
    tabBarComponent: props => <TabBarComponent {...props} />,
    tabBarOptions: {
      keyboardHidesTabBar: false,
      // activeTintColor: 'rgba(29,38,43,0.67)',
    },
  },
);
tabNavigator.path = 'app';

export default tabNavigator;
