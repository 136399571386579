import { Text, View } from 'react-native';
import React from 'react';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';

const DynamicContainer = ({
  children,
  vertical,
  title,
  buttonComponent,
  animatedHeight,
  reanimatedContainerHeight,
}) => {
  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = Math.min(
        animatedHeight.value,
        reanimatedContainerHeight.value,
      );
    },
    onActive: (event, ctx) => {
      animatedHeight.value = ctx.start - event.translationY;
    },
    onEnd: (event, ctx) => {
      let newValue = ctx.start - event.translationY;
      if (animatedHeight.value < 120) {
        newValue = 60;
      } else if (animatedHeight.value > reanimatedContainerHeight.value - 60) {
        newValue = reanimatedContainerHeight.value;
      }
      animatedHeight.value = withSpring(newValue, {
        damping: 30,
        stiffness: 200,
      });
    },
  });

  const animatedStyleContainer = useAnimatedStyle(() => {
    const height = interpolate(
      animatedHeight.value,
      [
        0,
        60,
        reanimatedContainerHeight.value,
        reanimatedContainerHeight.value + 150,
      ],
      [
        0,
        60,
        reanimatedContainerHeight.value,
        reanimatedContainerHeight.value + 50,
      ],
      Extrapolate.CLAMP,
    );

    return {
      height,
      // transform: [
      //   {
      //     translateY: animatedHeight.value,
      //   },
      // ],
    };
  }, [reanimatedContainerHeight]);

  return (
    <Animated.View
      style={[
        {
          backgroundColor: Colors.lightBlueBackground,
          flex: 1,
          borderLeftWidth: vertical ? 0 : 1,
          // borderWidth: vertical ? 1 : undefined,
          borderColor: '#e2e5ea',
          borderTopLeftRadius: vertical ? 12 : 0,
          borderTopRightRadius: vertical ? 12 : 0,
        },
        Style.shadowSpacesMenu,
        animatedStyleContainer,
      ]}
    >
      {vertical && (
        <View
          style={{
            position: 'absolute',
            top: 8,
            left: 0,
            right: 0,
            alignItems: 'center',
          }}
        >
          <View
            style={{
              width: 38,
              height: 5,
              backgroundColor: '#d8dde1',
              borderRadius: 30,
            }}
          />
        </View>
      )}
      <PanGestureHandler onGestureEvent={gestureHandler}>
        <Animated.View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 60,
            paddingLeft: 20,
            paddingRight: 0,
            borderColor: Colors.lighterer,
            overflow: 'hidden',
          }}
        >
          <View
            style={{
              height: '100%',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                color: Colors.dark,
                fontSize: 17,
              }}
              numberOfLines={1}
              selectable={false}
            >
              {title}
            </Text>
          </View>
          {buttonComponent}
        </Animated.View>
      </PanGestureHandler>
      {children}
    </Animated.View>
  );
};

export default DynamicContainer;
