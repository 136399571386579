import GLOBAL from '../constants/Globals';
import deviceStorage from './DeviceStorage';
import { Alert } from 'react-native';

const LayoutServices = {
  async getRooms2(jwtToken, restaurantId) {
    const url = GLOBAL.API_URL + '/tables/rooms?restaurant_id=' + restaurantId;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        // responseJson.ok = response.ok;
        return responseJson;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getTableModels2(jwtToken, restaurantId) {
    const url = GLOBAL.API_URL + '/tables/models?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        // responseJson.ok = response.ok;
        return responseJson;
      } else {
        return [];
      }
    } catch (error) {
      return false;
    }
  },

  async createTableModel(data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/tables/models`;
    const auth = `Bearer ${jwtToken}`;

    const collection = data;
    collection.restaurant_id = parseInt(restaurantId);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;

      const responseJson = await response.json();
      responseCollection.message = responseJson.message;

      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async updateRoomLayout(data) {
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/tables/rooms/layout`;
    const auth = `Bearer ${jwtToken}`;

    const collection = data;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;

      const responseJson = await response.json();
      responseCollection.message = responseJson.message;

      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async AssignTableToModel(data, jwtToken) {
    const url = `${GLOBAL.API_URL}/tables/models/assign`;
    const auth = `Bearer ${jwtToken}`;

    const collection = data;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;

      const responseJson = await response.json();
      responseCollection.message = responseJson.message;

      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async addNewRoom(data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = GLOBAL.API_URL + '/tables/rooms';
    const auth = 'Bearer ' + jwtToken;

    const collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.name = data.name;
    collection.dimensions = data.dimensions;
    collection.floor = data.floor;
    collection.outside = data.outside;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async deleteRoom(roomId) {
    const jwtToken = await deviceStorage.getJWT();

    const url = GLOBAL.API_URL + '/tables/rooms/' + roomId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
};

export default LayoutServices;
