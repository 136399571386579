import GLOBAL from '../constants/Globals';
import { Platform } from 'react-native';

function DataURIToBlob2(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

function DataURIToBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const ManagementServices = {
  async restaurantSignup(data) {
    const url = `${GLOBAL.API_URL}/auth/new_restaurant`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data),
      });
      return response;
      // if (response.ok) {
      //   return true;
      // }
      // return await response.json();
    } catch (error) {
      throw Error(error);
    }
  },

  async photoUpload(photo) {
    const url = `${GLOBAL.API_URL}/auth/photo_upload`;
    const data = new FormData();

    // const getFilename = photo.uri.split('/');
    // const imageName = getFilename[getFilename.length - 1];

    if (Platform.OS === 'web') {
      const file = DataURIToBlob(photo.uri);
      data.append('image', file, 'image.jpg');
    } else {
      data.append('image', {
        name: 'slika.png',
        type: 'image',
        uri: photo.uri,
      });
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        // headers: new Headers({
        //   Accept: 'application/json',
        //   'Content-Type': 'multipart/form-data',
        // }),
        body: data,
      });

      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.image_name;
      }
      return null;
    } catch (error) {
      throw Error(error);
    }
  },
};

export default ManagementServices;
