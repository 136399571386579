import React, { createRef } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Image,
  PanResponder,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import QuickAddReservation from './modals/QuickAddReservation';

import Colors from '../../constants/Colors';
import deviceStorage from '../../services/DeviceStorage';
import TimelineHeadStyle from '../../styles/TimelineHeadStyle';

import DateSelectItem from '../../components/timelineComponents/DateSelectItem';
import DrawTables from '../../components/timelineComponents/DrawTables';
import DrawGrid from '../../components/timelineComponents/DrawGrid';
import DrawHours from '../../components/timelineComponents/DrawHours';
import { withGlobalContext } from '../../GlobalContext';
import Buttons from '../../styles/Buttons';
import ReservationOverviewModal from './modals/ReservationOverviewModal';
import SelectRestaurantModal from './modals/SelectRestaurantModal';
import OptionsModal from './modals/OptionsModal';
import HeaderButton from '../../components/HeaderButton';
import DrawReservation from '../../components/timelineComponents/DrawReservation';
import ConfirmEditsModal from './modals/ConfirmEditsModal';
import * as Haptics from 'expo-haptics';
import { ImpactFeedbackStyle, NotificationFeedbackType } from 'expo-haptics';
import { withReservationsContext } from '../../contex/ReservationsContext';
import { DATE_FORMAT, withDateTimeContext } from '../../contex/DateTimeContext';
import { withOpenHoursContext } from '../../contex/OpenHoursContext';
import { addMinutes, format, set } from 'date-fns';
import CurrentTimeIndicator from '../../components/timelineComponents/CurrentTimeIndicator';
import { NavigationEvents } from 'react-navigation';
import LogoNotification from '../../components/LogoNotification';

const TABLE_COLUMN_WIDTH = 90;
const TABLE_COLUMN_WIDTH_SMALL = 76;

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hourLength: 132,
      rowHeight: 48,
      hideCancelled: false,
      scrolling: false,
      scrollingMomentum: false,
      isReservationDetailsVisible: false,
      isSelectRestaurantVisible: false,
      isOptionsModalVisible: false,
      selectedReservation: {},
      time: moment(),
      isBoxActive: false,
      isQuickAddVisible: false,
      isConfirmEditsModalVisible: false,
      quickAddParameters: null,
      tableColumnWidth: 90,
      isNarrow: false,
      isPhone: false,
      editingReservations: false,
      reservationEdits: [],
    };

    this.pan = new Animated.ValueXY({ x: 0, y: 0 });
    this.helperCirclePan = new Animated.ValueXY({ x: 0, y: 0 });
    this.boxOpacity = new Animated.Value(0);
    this.helperCircleOpacity = new Animated.Value(0);
    this.boxScale = new Animated.Value(0);
    this.buttonOpacity = new Animated.Value(1);
    this.offsets = { x: 0, y: 0 };

    this.viewMarker = createRef();
    this.newReservationButtonRef = createRef();
    this.timelineHeaderScroll = createRef();
    this.timelineScroll = createRef();

    this._bootstrapAsync();

    this._panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (e, gestureState) => {
        return true;
      },
      onStartShouldSetPanResponder: (e, gestureState) => {
        return true;
      },
      onPanResponderGrant: (e, gestureState) => {
        const { buttonOpacity } = this;

        this.showNewReservationBox(e);
        Animated.timing(buttonOpacity, {
          toValue: 0,
          duration: 40,
          useNativeDriver: true,
        }).start();

        this.viewMarker.current.measure((x, y, width, height, pageX, pageY) => {
          this.offsets = { x: pageX, y: pageY };
        });
      },
      onPanResponderTerminationRequest: (e, gestureState) => {
        const { boxOpacity, buttonOpacity, boxScale } = this;
        Animated.timing(boxOpacity, {
          toValue: 0,
          duration: 600,
          useNativeDriver: true,
        }).start();
        Animated.timing(boxScale, {
          toValue: 0,
          duration: 600,
          useNativeDriver: true,
        }).start();
        Animated.timing(buttonOpacity, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          delay: 1400,
        }).start();
        return true;
      },
      onPanResponderTerminate: (e, gestureState) => {
        // Another component has become the responder, so this gesture
        setTimeout(() => {
          this.setState({ isBoxActive: false });
        }, 100);
      },
      onPanResponderEnd: (e, gestureState) => {
        const { helperCirclePan, helperCircleOpacity } = this;
        const { showAlert } = this.props.global;
        const dist = gestureState.dx ** 2 + gestureState.dy ** 2;
        if (dist < 500) {
          if (Platform.OS !== 'web') {
            Haptics.notificationAsync(NotificationFeedbackType.Error).then();
          }
          Animated.timing(helperCircleOpacity, {
            toValue: 0.3,
            duration: 700,
            delay: 900,
            useNativeDriver: true,
          }).start(() => {
            Animated.timing(helperCircleOpacity, {
              toValue: 1,
              duration: 150,
              delay: 500,
              useNativeDriver: true,
            }).start(() => {
              Animated.timing(helperCircleOpacity, {
                toValue: 0.3,
                duration: 150,
                delay: 3050,
                useNativeDriver: true,
              }).start(() => {
                Animated.timing(helperCircleOpacity, {
                  toValue: 0,
                  duration: 1000,
                  delay: 500,
                  useNativeDriver: true,
                }).start();
              });
            });
          });

          const x = gestureState.x0;
          const y = gestureState.y0;

          helperCirclePan.setValue({
            x: x - 30,
            y: y + 70,
          });

          Animated.timing(
            helperCirclePan, // Auto-multiplexed
            {
              toValue: {
                x: x - 20,
                y: y - 20,
              },
              duration: 1300,
              delay: 1000,
              easing: Easing.bezier(0.33, 1, 0.68, 1),
              useNativeDriver: true,
            },
          ).start(() =>
            Animated.timing(
              helperCirclePan, // Auto-multiplexed
              {
                toValue: {
                  x: x - 120,
                  y: y + 200,
                },
                duration: 2300,
                delay: 400,
                easing: Easing.bezier(0.48, 0.05, 0.47, 0.93),
                useNativeDriver: true,
              },
            ).start(),
          );

          showAlert(
            // 'You can add new reservation by dragging\n  + Reservation button to timeline.',
            'To add  a reservation, drag the + button to the timeline.',
            'info',
          );
        }
      },
      onShouldBlockNativeResponder: (e, gestureState) => {
        return true;
      },

      onPanResponderMove: (evt, gestureState) => {
        const { boxOpacity, pan } = this;
        pan.setValue({
          x: gestureState.moveX,
          y: gestureState.moveY,
        });

        if (gestureState.dy < 60 && Math.abs(gestureState.dx) > 30) {
          Animated.timing(boxOpacity, {
            toValue: 0.5,
            duration: 30,
            useNativeDriver: true,
          }).start();
        } else {
          Animated.timing(boxOpacity, {
            toValue: 1,
            duration: 30,
            useNativeDriver: true,
          }).start();
        }
      },
      onPanResponderRelease: (evt, gestureState) => {
        setTimeout(() => {
          this.setState({ isBoxActive: false });
        }, 50);
        const { offsets } = this;

        const { boxOpacity, pan, buttonOpacity, boxScale } = this;

        const { hourLength, rowHeight } = this.state;
        const timelinePosition = {
          x: gestureState.moveX - offsets.x + hourLength * 0.3,
          y: gestureState.moveY - offsets.y + rowHeight * 0.4,
        };
        const timeUnit = hourLength / 4;
        const adjustToIntervalX =
          timelinePosition.x % timeUnit > timeUnit / 2
            ? timeUnit - (timelinePosition.x % timeUnit)
            : -timelinePosition.x % timeUnit;
        const adjustToIntervalY =
          timelinePosition.y % rowHeight > rowHeight / 2
            ? rowHeight - (timelinePosition.y % rowHeight)
            : -timelinePosition.y % rowHeight;

        const adjustedTimelinePosition = {
          x: timelinePosition.x + adjustToIntervalX - hourLength * 1.5,
          y: timelinePosition.y + adjustToIntervalY - rowHeight,
        };

        if (
          adjustedTimelinePosition.y >= 0 &&
          Math.abs(gestureState.vx) + Math.abs(gestureState.vy) < 1 &&
          gestureState.dy > 50
        ) {
          if (Platform.OS !== 'web') {
            Haptics.impactAsync(ImpactFeedbackStyle.Light).then();
          }
          setTimeout(() => {
            this.showQuickAddModal(adjustedTimelinePosition);
          }, 350);
          const extraPaddingWebY = Platform.OS === 'web' ? -5 : 0;
          Animated.spring(
            pan, // Auto-multiplexed
            {
              toValue: {
                x: gestureState.moveX + adjustToIntervalX,
                y: gestureState.moveY + adjustToIntervalY + extraPaddingWebY,
              },
              speed: 20,
              useNativeDriver: true,
            },
          ).start();
          Animated.timing(buttonOpacity, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true,
            delay: 1500,
          }).start();
        } else {
          Animated.timing(buttonOpacity, {
            toValue: 1,
            duration: 350,
            useNativeDriver: true,
            delay: 600,
          }).start();
          Animated.timing(boxOpacity, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
            delay: 0,
          }).start();
          Animated.timing(boxScale, {
            toValue: 0,
            duration: 30,
            useNativeDriver: true,
            delay: 500,
          }).start();
        }
      },
    });
  }

  _bootstrapAsync = async () => {
    const userToken = await AsyncStorage.getItem('jwtToken');
    if (!userToken) {
      const { logout } = this.props.global;
      logout();
    }
  };

  updateDimensions = ({ window, screen }) => {
    this.setState({
      isNarrow: window.width < 550,
      isPhone: window.width < 550 || window.height < 550,
    });
  };

  componentDidMount = async () => {
    const { restaurantId } = this.props.global;

    Dimensions.addEventListener('change', this.updateDimensions);

    if (restaurantId) {
      this.interval = setInterval(() => this.periodicTimeUpdate(), 1000);
    }
    const isPhone =
      Dimensions.get('window').width < 550 ||
      Dimensions.get('window').height < 550;
    if (isPhone) {
      this.updateHourLength(50);
      this.updateRowHeight(44);
    }

    this.setState({
      isNarrow: Dimensions.get('window').width < 550,
      isPhone,
      // isPhone: false,
    });
    this.scrollToCurrentTime();
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      scrolling,
      scrollingMomentum,
      isOptionsModalVisible,
      isQuickAddVisible,
      isBoxActive,
    } = this.state;

    if (!isBoxActive && nextState.isBoxActive) {
      return false;
    }
    if (isBoxActive) {
      return false;
    }
    if (
      isOptionsModalVisible !== nextState.isOptionsModalVisible ||
      isQuickAddVisible !== nextState.isQuickAddVisible
    ) {
      return true;
    }
    return !(
      nextState.scrolling ||
      nextState.scrollingMomentum ||
      scrolling ||
      scrollingMomentum
    );
  }

  componentWillUnmount = () => {
    Dimensions.removeEventListener('change', this.updateDimensions);
    clearInterval(this.interval);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { restaurants } = this.props.global;
    // const aaa = this.props.activity;
    // console.log(aaa);

    if (prevProps.global.restaurants === null && restaurants) {
      if (restaurants.length === 1) {
        this.saveRestaurantId(restaurants[0].id);
      } else if (restaurants.length > 1) {
        this.setState({
          isSelectRestaurantVisible: true,
        });
      }
    }
    // if (
    //   prevProps.global.selectedDate !== selectedDate &&
    //   selectedDate.isSame(new Date(), 'date')
    // ) {
    //   this.scrollToCurrentTime();
    // }
  };

  showNewReservationBox = event => {
    const { boxOpacity, boxScale, pan } = this;
    boxOpacity.setValue(0);
    boxScale.setValue(0);
    Animated.timing(boxOpacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
      delay: 0,
    }).start();

    Animated.timing(boxScale, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
      // speed: 6,
    }).start();
    pan.setValue({
      x: event.nativeEvent.pageX,
      y: event.nativeEvent.pageY,
    });
    // pan.setOffset({
    //   x: -this.state.hourLength * 0.75,
    //   y: -this.state.rowHeight / 2,
    // });
    // pan.setOffset({
    //   x: -this.state.hourLength * 1.2,
    //   y: -this.state.rowHeight * 0.6,
    // });

    this.setState({
      isBoxActive: true,
    });
  };

  periodicTimeUpdate = () => {
    const { time } = this.state;
    const currentTime = moment(new Date());
    // if (parseInt(currentTime.format('ss')) % 10 === 0) {
    //   this.getReservations(true, false);
    // }
    if (time.format('HH:mm') !== currentTime.format('HH:mm')) {
      this.setState({ time: currentTime });
    }
  };

  getStartDateTimeFromOffset = offsetX => {
    const { openHoursContext, dateTimeContext } = this.props;
    const { hourLength } = this.state;
    const { selectedDateTimeRange } = dateTimeContext;
    const { openHoursDayOnSelectedDate } = openHoursContext;
    const { firstHour } = openHoursDayOnSelectedDate;
    const { from } = selectedDateTimeRange;

    const timelineStartDate = set(from, { minutes: 0, hours: firstHour });
    const minutesFromStart = (offsetX / hourLength) * 60;
    return addMinutes(timelineStartDate, minutesFromStart);
  };

  getTableFromOffset = offsetY => {
    const { global } = this.props;
    const { tables } = global;
    const { rowHeight } = this.state;
    const tableIndex = offsetY / rowHeight;
    const table = tables?.[tableIndex];
    return table;
  };

  showQuickAddModal = position => {
    // const { firstHour } = this.state;
    // const startDateTime = selectedDate
    //   .clone()
    //   .set({ hours: firstHour, minutes: 0 })
    //   .set({ second: 0, millisecond: 0 });
    // const minutesFromStart = (position.x / this.state.hourLength) * 60;
    //
    // startDateTime.add(minutesFromStart, 'minutes');
    const startDateTime = this.getStartDateTimeFromOffset(position.x);
    const table = this.getTableFromOffset(position.y);
    if (table) {
      const quickAddParameters = {
        persons: 2,
        table,
        from: startDateTime,
        durationMinutes: 120,
      };

      this.setState({
        isQuickAddVisible: true,
        quickAddParameters,
      });
    } else {
      this.hideQuickAddModal(false);
    }
  };

  hideQuickAddModal = reload => {
    this.setState({ isQuickAddVisible: false });
    const { boxOpacity, boxScale } = this;
    const { refreshReservations } = this.props.reservationsContext;
    if (reload) {
      refreshReservations(true);
    }

    // getReservations(selectedDate?.format('YYYY-MM-DD'), reload);

    Animated.timing(boxOpacity, {
      toValue: 0,
      duration: reload ? 200 : 200,
      useNativeDriver: true,
    }).start();
    Animated.timing(boxScale, {
      toValue: 0,
      duration: reload ? 200 : 200,
      useNativeDriver: true,
    }).start();
    // this.getReservations(reload, !reload);
  };

  hideConfirmEditsModal = () => {
    this.setState({ isConfirmEditsModalVisible: false });
  };

  scrollToCurrentTime = () => {
    const { tableColumnWidth } = this.state;

    const { openHoursContext, dateTimeContext } = this.props;
    const { openHoursDayOnSelectedDate } = openHoursContext;
    const { firstHour } = openHoursDayOnSelectedDate;
    const { selectedDateTimeRange } = dateTimeContext;

    const currentDate = moment(new Date());
    const hourStart = moment(new Date().setHours(firstHour, 0));
    const offsetTime = currentDate.diff(hourStart, 'minutes');
    const screenWidth = Dimensions.get('window').width;
    const { hourLength } = this.state;
    const offsetMargin =
      (offsetTime * hourLength) / 60 - screenWidth / 2 + tableColumnWidth;
    if (
      format(selectedDateTimeRange.from, DATE_FORMAT) ===
      format(new Date(), DATE_FORMAT)
    ) {
      setTimeout(() => {
        this.timelineScroll?.current?.scrollTo({
          x: offsetMargin,
          animated: true,
        });
      }, 400);
    }
  };

  changeSelectedDate = date => {
    const { dateTimeContext } = this.props;
    const { setDate } = dateTimeContext;
    setDate(date);
  };

  showReservationDetailsModal = reservationObject => {
    this.setState({
      selectedReservation: reservationObject,
      isReservationDetailsVisible: true,
    });
  };

  hideReservationDetailsModal = () => {
    this.setState({ isReservationDetailsVisible: false });
  };

  hideSelectRestaurantsModal = () => {
    this.setState({ isSelectRestaurantVisible: false });
  };

  hideOptionsModal = () => {
    this.setState({ isOptionsModalVisible: false });
  };

  saveRestaurantId = id => {
    const idString = id.toString();
    deviceStorage.saveKey('restaurantId', idString).then();
    this.props.global.changeRestaurantId(id);
  };

  setTablesColumnWidth = size => {
    this.setState({
      tableColumnWidth: size,
    });
  };

  updateHourLength = value => {
    this.setState({ hourLength: value });
    if (value < 85) {
      this.setTablesColumnWidth(TABLE_COLUMN_WIDTH_SMALL);
    } else {
      this.setTablesColumnWidth(TABLE_COLUMN_WIDTH);
    }
  };

  updateRowHeight = value => {
    this.setState({ rowHeight: value });
  };

  hideCancelledSwitch = () => {
    const { hideCancelled } = this.state;
    this.setState({
      hideCancelled: !hideCancelled,
    });
  };

  isPhoneAndPortrait = () => {
    const { height, width } = Dimensions.get('window');
    const { isNarrow } = this.state;
    return isNarrow && height > width;
  };

  setEditingReservations = state => {
    this.setState({
      editingReservations: state,
    });
  };

  onReservationEdit = (reservation, dateFrom, dateTo, updatedTables) => {
    const { reservationEdits } = this.state;
    /*
    reservationEdits = [
      {
        reservationId: 10,
        reservation: [reservationObject]
        updatedDateFrom: 2021-10-10T12:00:00
        updatedDateTo: 2021-10-10T14:00:00
        updatedTables: [
          {
            prevTable: [table],
            updatedTable: [table]
          }
        ]
      }
    ]
    */

    const existingEditIndex = reservationEdits.findIndex(
      res => res.reservationId === reservation.id,
    );
    if (existingEditIndex === -1) {
      const edit = {
        reservationId: reservation.id,
        reservation,
        updatedDateFrom: dateFrom,
        updatedDateTo: dateTo,
        updatedTables,
      };
      this.setState({
        reservationEdits: [...reservationEdits, edit],
      });
    } else {
      const existingEdit = reservationEdits[existingEditIndex];
      existingEdit.updatedDateFrom = dateFrom || existingEdit.updatedDateFrom;
      existingEdit.updatedDateTo = dateTo || existingEdit.updatedDateTo;
      existingEdit.updatedTables = updatedTables || existingEdit.updatedTables;
      const updatedReservationEdits = Object.assign([...reservationEdits], {
        [existingEditIndex]: existingEdit,
      });
      this.setState({
        reservationEdits: updatedReservationEdits,
      });
    }
  };

  onDoneEditing = () => {
    const { reservationEdits } = this.state;
    if (reservationEdits.length > 0) {
      this.setState({ isConfirmEditsModalVisible: true });
    } else {
      this.onCancelEditing();
    }
  };

  onCancelEditing = () => {
    this.setEditingReservations(false);
    this.setState({ isConfirmEditsModalVisible: false });
    this.setState({ reservationEdits: [] });
  };

  render() {
    const {
      selectedReservation,
      isReservationDetailsVisible,
      isSelectRestaurantVisible,
      isOptionsModalVisible,
      hideCancelled,
      hourLength,
      rowHeight,
      quickAddParameters,
      isQuickAddVisible,
      isConfirmEditsModalVisible,
      tableColumnWidth,
      isNarrow,
      isPhone,
      editingReservations,
      reservationEdits,
    } = this.state;

    const {
      pan,
      helperCirclePan,
      boxOpacity,
      helperCircleOpacity,
      boxScale,
    } = this;

    const {
      tables,
      jwtToken,
      restaurantId,
      restaurants,
      showAlert,
    } = this.props.global;

    const { reservations } = this.props.reservationsContext;
    const { openHoursDayOnSelectedDate } = this.props.openHoursContext;
    const {
      selectedDateString,
      selectedDateTimeRange,
    } = this.props.dateTimeContext;
    const { firstHour, lastHour } = openHoursDayOnSelectedDate;

    const panStyle = {
      transform: pan.getTranslateTransform(),
    };

    const onScreenFocus = () => {
      const { updateOpenHours, updateTables } = this.props.global;
      updateOpenHours();
      updateTables();
    };

    return (
      <SafeAreaView style={TimelineHeadStyle.mainContainer} edges={['top']}>
        <NavigationEvents onDidFocus={onScreenFocus} />
        <View
          style={[
            TimelineHeadStyle.container,
            {
              flex: 2,
            },
          ]}
        >
          <View
            style={[
              TimelineHeadStyle.topBarContainer,
              isPhone && TimelineHeadStyle.topBarContainerSmall,
              this.isPhoneAndPortrait() &&
                TimelineHeadStyle.topBarContainerNotch,
            ]}
          >
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                // this.isPhoneAndPortrait() && TimelineHeadStyle.topBarSpaceFixed,
                { justifyContent: 'flex-start' },
              ]}
            >
              {editingReservations ? (
                <HeaderButton
                  small={this.isPhoneAndPortrait()}
                  label={'Cancel editing'}
                  icon={'close-circle'}
                  backgroundColor={'white'}
                  color={Colors.light}
                  iconSize={24}
                  onPress={() => this.onCancelEditing()}
                />
              ) : (
                <View style={TimelineHeadStyle.logoContainer}>
                  <LogoNotification
                    showIconOnly={isNarrow}
                    small={isPhone && !isNarrow}
                  />
                  {/*<Image*/}
                  {/*  style={[*/}
                  {/*    TimelineHeadStyle.logo,*/}
                  {/*    isPhone && TimelineHeadStyle.logoSmall,*/}
                  {/*    isNarrow && TimelineHeadStyle.logoSquare,*/}
                  {/*  ]}*/}
                  {/*  source={*/}
                  {/*    this.isPhoneAndPortrait()*/}
                  {/*      ? require('../../assets/images/reetab_logo.png')*/}
                  {/*      : require('../../assets/images/reetab.png')*/}
                  {/*  }*/}
                  {/*  resizeMode={'contain'}*/}
                  {/*/>*/}
                </View>
              )}
            </View>
            <DateSelectItem
              selectedDate={selectedDateString}
              onDateChangeCallback={this.changeSelectedDate}
              size={isPhone || isNarrow ? 'small' : 'big'}
            />
            <View
              style={[
                TimelineHeadStyle.topBarSpace,
                {
                  justifyContent: 'flex-end',
                },
              ]}
            >
              {editingReservations ? (
                <HeaderButton
                  small={this.isPhoneAndPortrait()}
                  label={'Done editing'}
                  icon={'checkmark'}
                  backgroundColor={Colors.lightBlue}
                  onPress={() => this.onDoneEditing()}
                  iconSize={22}
                  shadow
                />
              ) : (
                <Animated.View
                  ref={this.newReservationButtonRef}
                  onLayout={({ nativeEvent }) => {
                    if (this.newReservationButtonRef?.current) {
                      this.newReservationButtonRef.current.measure(() => {});
                    }
                  }}
                  style={{
                    opacity: this.buttonOpacity,
                  }}
                  {...this._panResponder.panHandlers}
                >
                  <HeaderButton
                    small={this.isPhoneAndPortrait()}
                    label={'Reservation'}
                    icon={'add'}
                    iconSize={24}
                    shadow
                  />
                </Animated.View>
              )}
            </View>
          </View>

          <View style={{ flex: 1 }}>
            <View
              style={{
                height: 30,
                flexDirection: 'row',
                borderColor: '#ccc',
                borderBottomWidth: 0.4,
                zIndex: 1,
              }}
            >
              <TouchableOpacity
                onPress={() => this.setState({ isOptionsModalVisible: true })}
                style={{
                  backgroundColor: 'white',
                  width: tableColumnWidth,
                  alignItems: 'center',
                  justifyContent: 'center',
                  // marginHorizontal: 12,
                  flexDirection: 'row',
                }}
                hitSlop={{ top: 30, bottom: 15, left: 0, right: 0 }}
              >
                <Ionicons
                  name="options"
                  size={18}
                  color={Colors.superCoolBlue}
                />
                {/*<Text*/}
                {/*  style={{*/}
                {/*    marginLeft:*/}
                {/*      tableColumnWidth === TABLE_COLUMN_WIDTH_SMALL ? 2 : 4,*/}
                {/*    color: Colors.lightBlue,*/}
                {/*    fontWeight: '700',*/}
                {/*    textTransform: 'capitalize',*/}
                {/*    fontSize: 13,*/}
                {/*  }}*/}
                {/*>*/}
                {/*  options*/}
                {/*</Text>*/}
                {tableColumnWidth !== TABLE_COLUMN_WIDTH_SMALL &&
                  Platform.OS !== 'android' && (
                    <Text
                      style={{
                        fontWeight: '600',
                        marginLeft: 4,
                        fontSize: 13,
                        color: Colors.lightBlue,
                      }}
                    >
                      Options
                    </Text>
                  )}
              </TouchableOpacity>
              <View
                style={{
                  flex: 12,
                  zIndex: 4,
                  marginBottom: -2000,
                }}
                pointerEvents="none"
              >
                <ScrollView
                  // scrollEventThrottle={16}
                  horizontal
                  ref={this.timelineHeaderScroll}
                  scrollEnabled={false}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{
                    flexGrow: 1,
                    height: 30,
                    zIndex: 5,
                    overflow: 'visible',
                    elevation: 50,
                  }}
                >
                  <DrawHours
                    rowLength={hourLength}
                    startTime={firstHour}
                    endTime={lastHour}
                  />
                  <CurrentTimeIndicator
                    position="top"
                    firstHour={firstHour}
                    hourLength={hourLength}
                    selectedDate={selectedDateTimeRange.from}
                    color={'rgba(48,209,88,1)'}
                    lineColor={'rgba(48,209,88,0.7)'}
                  />
                </ScrollView>
              </View>
            </View>

            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{ flexGrow: 1 }}
              bounces={false}
              snapToInterval={rowHeight}
              showsVerticalScrollIndicator
              decelerationRate="fast"
              onMomentumScrollBegin={() =>
                this.setState({ scrollingMomentum: true })
              }
              onScrollBeginDrag={() => this.setState({ scrolling: true })}
              onMomentumScrollEnd={() =>
                this.setState({ scrollingMomentum: false })
              }
              onScrollEndDrag={() => this.setState({ scrolling: false })}
              StickyHeaderComponent={
                <View
                  style={{
                    width: 30,
                    height: 30,
                    backgroundColor: 'red',
                    zIndex: 80,
                  }}
                />
              }
            >
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <View
                  style={{
                    backgroundColor: '#f8f8f8',
                  }}
                >
                  <DrawTables
                    rowHeight={rowHeight}
                    tables={tables}
                    width={tableColumnWidth}
                  />
                </View>

                <View style={{ flex: 1 }}>
                  <ScrollView
                    ref={this.timelineScroll}
                    scrollEventThrottle={16}
                    onScroll={e =>
                      this.timelineHeaderScroll.current.scrollTo({
                        x: e.nativeEvent.contentOffset.x,
                        animated: false,
                      })
                    }
                    showsHorizontalScrollIndicator
                    horizontal
                    onMomentumScrollBegin={() =>
                      this.setState({ scrollingMomentum: true })
                    }
                    onScrollBeginDrag={() => this.setState({ scrolling: true })}
                    onMomentumScrollEnd={() =>
                      this.setState({ scrollingMomentum: false })
                    }
                    onScrollEndDrag={() => this.setState({ scrolling: false })}
                    bounces={false}
                    contentContainerStyle={{
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <View
                      ref={this.viewMarker}
                      onLayout={({ nativeEvent }) => {
                        if (this.viewMarker?.current) {
                          this.viewMarker?.current?.measure(() => {});
                        }
                      }}
                    />
                    <DrawGrid
                      rowHeight={rowHeight}
                      rowLength={hourLength}
                      rows={tables ? tables.length : 0}
                      columns={
                        lastHour - firstHour >= 0
                          ? lastHour - firstHour
                          : lastHour + 24 - firstHour
                      }
                    />
                    {reservations.map(reservation => (
                      <DrawReservation
                        key={`${reservation.id}${firstHour}${lastHour}${reservation.dateTimeFrom}${reservation.dateTimeTo}`}
                        reservation={reservation}
                        tables={tables}
                        firstHour={firstHour}
                        lastHour={lastHour}
                        hourLength={hourLength}
                        rowHeight={rowHeight}
                        showReservationDetailsModal={
                          this.showReservationDetailsModal
                        }
                        hideCancelled={hideCancelled}
                        editingReservations={editingReservations}
                        setEditingReservations={this.setEditingReservations}
                        onReservationEdit={(dateFrom, dateTo, editedTables) =>
                          this.onReservationEdit(
                            reservation,
                            dateFrom,
                            dateTo,
                            editedTables,
                          )
                        }
                      />
                    ))}
                  </ScrollView>
                </View>
              </View>
            </ScrollView>
          </View>

          <ReservationOverviewModal
            isVisible={isReservationDetailsVisible}
            onBackDropPress={this.hideReservationDetailsModal}
            onSwipeComplete={this.hideReservationDetailsModal}
            selectedReservation={selectedReservation}
            fullScreen={isNarrow}
          />

          <SelectRestaurantModal
            isVisible={isSelectRestaurantVisible}
            restaurants={restaurants || []}
            closeModal={this.hideSelectRestaurantsModal}
            saveId={this.saveRestaurantId}
          />

          <OptionsModal
            hideModal={this.hideOptionsModal}
            isVisible={isOptionsModalVisible}
            hourLength={hourLength}
            rowHeight={rowHeight}
            updateHourLength={this.updateHourLength}
            updateRowHeight={this.updateRowHeight}
            hideCancelledSwitch={this.hideCancelledSwitch}
            hideCancelled={hideCancelled}
            portrait={this.isPhoneAndPortrait()}
          />
        </View>
        <Animated.View
          style={[
            {
              position: 'absolute',
              opacity: boxOpacity,
              width: hourLength * 1.5,
              height: rowHeight,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 20,
              transform: [
                { translateX: -this.state.hourLength * 1.2 },
                { translateY: -this.state.rowHeight * 0.6 },
                ...panStyle.transform,
              ],
            },
          ]}
          pointerEvents="none"
        >
          <Animated.View
            pointerEvents="none"
            style={[
              {
                marginTop: 2,
                margin: 1.5,
                width: hourLength * 1.5,
                height: rowHeight,
                backgroundColor: Colors.someGreen,
                flexDirection: 'row',
                paddingHorizontal: 12,
                alignItems: 'center',
                borderRadius: 3,
                transform: [
                  {
                    scale: boxScale.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.3, 1],
                    }),
                  },
                ],
              },
            ]}
          >
            <Text
              selectable={false}
              style={[
                Buttons.buttonIcon,
                { marginRight: this.isPhoneAndPortrait() ? 0 : 0 },
              ]}
            >
              <Ionicons name="add" size={22} />
            </Text>
            <Text
              style={[Buttons.buttonText, {}]}
              selectable={false}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {hourLength < 80 ? '' : ' Reservation '}
            </Text>
          </Animated.View>
        </Animated.View>
        <QuickAddReservation
          isVisible={isQuickAddVisible}
          hideModal={this.hideQuickAddModal}
          from={quickAddParameters?.from || new Date()}
          duration={quickAddParameters?.durationMinutes}
          persons={quickAddParameters?.persons}
          table={quickAddParameters?.table}
          jwtToken={jwtToken}
          restaurantId={restaurantId}
          showAlert={showAlert}
        />
        <ConfirmEditsModal
          isVisible={isConfirmEditsModalVisible}
          hideModal={this.hideConfirmEditsModal}
          edits={reservationEdits}
          onCancel={this.onCancelEditing}
        />

        <Animated.View
          pointerEvents={'none'}
          style={[
            {
              position: 'absolute',
              opacity: helperCircleOpacity,
              transform: helperCirclePan.getTranslateTransform(),
              height: 40,
              width: 40,
              backgroundColor: 'rgba(68,169,241,0.85)',
              borderRadius: 100,
              zIndex: 200,
            },
          ]}
        />
      </SafeAreaView>
    );
  }
}

const Timeline2 = withOpenHoursContext(
  withDateTimeContext(withReservationsContext(withGlobalContext(Timeline))),
);

Timeline2.navigationOptions = {
  headerShown: false,
};
export default Timeline2;
