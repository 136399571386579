import { Text, View } from 'react-native';
import React from 'react';
import Colors from '../../constants/Colors';

const DrawTables = ({ tables, rowHeight, width }) => {
  const tablesView = [];

  let tablesArray = [];
  if (tables && tables.length > 0) {
    tablesArray = tables;
  } else {
    for (let i = 0; i < 20; i++) tablesArray.push({});
  }
  for (let i = 0; i < tablesArray.length; i += 1) {
    let seatCountColor = '';
    if (tablesArray[i].chairs < 3) {
      seatCountColor = 'rgba(62,213,115,0.85)';
    } else if (tablesArray[i].chairs < 5) {
      seatCountColor = 'rgba(55,166,240,0.85)';
    } else {
      seatCountColor = 'rgba(69,122,238,0.85)';
    }

    tablesView.push(
      <View
        style={{
          height: rowHeight,
          width,
          backgroundColor: '#f6f6f7',
          shadowOpacity: 0.04,
          shadowOffset: {
            width: -0,
            height: 0,
          },
          shadowRadius: 4,
          alignItems: 'center',
          justifyContent: 'space-between',
          borderColor: Colors.lighterer,
          borderBottomWidth: 1,
          flexDirection: 'row',
          paddingRight: 12,
          paddingLeft: 6,
          paddingVertical: 4,
        }}
        key={`${i}+${rowHeight}+${width}`}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontSize: 19,
              fontWeight: '400',
              color: Colors.dark,
              marginRight: 6,
              textAlign: 'center',
              // letterSpacing: 1.5,
            }}
            adjustsFontSizeToFit
            selectable={false}
            numberOfLines={1}
          >
            <Text
              style={{
                color: Colors.lighter2,
                // letterSpacing: 1.5,
                fontWeight: '400',
              }}
            >
              {tablesArray[i].group_prefix}
            </Text>
            {tablesArray[i].table_number}
          </Text>
        </View>

        {tablesArray[i].chairs && (
          <View
            style={{
              backgroundColor: seatCountColor,
              borderRadius: 7,
              height: 19,
              marginVertical: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: 'white',
                fontSize: 12,
                alignSelf: 'center',
                paddingHorizontal: 7,
              }}
              selectable={false}
              adjustsFontSizeToFit
            >
              {tablesArray[i].chairs}
            </Text>
          </View>
        )}
      </View>,
    );
  }
  return tablesView;
};

export default DrawTables;
