import React from 'react';
import {
  Dimensions,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
// import CheckBox from 'react-native-check-box';
import Colors from '../../constants/Colors';
import { withGlobalContext } from '../../GlobalContext';
import styles from '../../styles/LoginStyle';
import ManagementServices from '../../services/ManagementServices';
import Globals from '../../constants/Globals';
import OpenHoursStyle from '../../styles/OpenHoursStyle';
import OpenHoursDaySelect from '../../components/OpenHoursDaySelect';

const GLOBAL = require('../../constants/Globals');

const STEPS = [
  {
    id: 1,
    name: 'Sign Up',
    label: 'sign up',
  },
  {
    id: 2,
    name: 'Info and Contact',
    label: 'basic info',
  },
  {
    id: 3,
    name: 'Open Hours',
    label: 'open hours',
  },
  {
    id: 4,
    name: 'Where Are You Located?',
    label: 'address',
  },
  {
    id: 5,
    name: 'Success!',
    label: 'Completed',
  },
];

const LAST_STEP_ID = 5;
const SUBMIT_STEP_ID = 4;

class RegisterScreen extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      days: {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: { closed: true },
        sun: { closed: true },
      },
      timeToEdit: { currentTime: '20:00' },
      currentStepId: 1,
      maxStepId: 1,
      isDateTimePickerVisible: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      restaurantName: '',
      companyName: '',
      website: '',
      phone: '',
      description: '',
      street: '',
      city: '',
      zip: '',
      country: '',
      loading: false,
    };
  }

  updateValue = (text, field) => {
    if (field === 'email') {
      this.setState({
        email: text,
      });
    } else if (field === 'password') {
      this.setState({
        password: text,
      });
    } else if (field === 'firstName') {
      this.setState({
        firstName: text,
      });
    } else if (field === 'lastName') {
      this.setState({
        lastName: text,
      });
    } else if (field === 'restaurantName') {
      this.setState({
        restaurantName: text,
      });
    } else if (field === 'companyName') {
      this.setState({
        companyName: text,
      });
    } else if (field === 'website') {
      this.setState({
        website: text,
      });
    } else if (field === 'phone') {
      this.setState({
        phone: text,
      });
    } else if (field === 'description') {
      this.setState({
        description: text,
      });
    } else if (field === 'street') {
      this.setState({
        street: text,
      });
    } else if (field === 'city') {
      this.setState({
        city: text,
      });
    } else if (field === 'zip') {
      this.setState({
        zip: text,
      });
    } else if (field === 'country') {
      this.setState({
        country: text,
      });
    }
  };

  RenderSteps = props => {
    const { currentStepId, steps, goToStep } = props;
    return steps.map(step => {
      const lineColor = step.id < currentStepId ? Colors.pastelRed : '#e7e7e7';
      const circleColor =
        step.id <= currentStepId ? Colors.pastelRed : '#e7e7e7';
      const stepNumberColor = step.id <= currentStepId ? 'white' : '#999';
      const stepLabelColor = step.id <= currentStepId ? '#333' : '#ccc';

      const icon = null;
      return (
        <View
          style={{
            flex: step.id !== LAST_STEP_ID ? 1 : null,
            alignItems: 'flex-start',
            marginRight: step.id === LAST_STEP_ID ? -26 : 0,
          }}
          key={step.id}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: step.id !== LAST_STEP_ID ? '100%' : undefined,
            }}
          >
            <TouchableOpacity
              onPress={() => goToStep(step.id)}
              style={{
                zIndex: 20,
                height: 28,
                width: 28,
                borderRadius: 16,
                backgroundColor: circleColor,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 11,
                  color: stepNumberColor,
                  fontWeight: '700',
                }}
              >
                {step.id}
              </Text>
            </TouchableOpacity>
            {step.id !== LAST_STEP_ID ? (
              <View
                style={{
                  height: 5,
                  marginHorizontal: -3,
                  flex: 1,
                  backgroundColor: lineColor,
                }}
              />
            ) : null}
          </View>
          <View
            style={{
              width: 80,
              marginTop: 10,
              marginLeft: -25,
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 9,
                fontWeight: '600',
                color: stepLabelColor,
              }}
            >
              {step.label}
            </Text>
          </View>
        </View>
      );
    });
  };

  async signup() {
    Keyboard.dismiss();

    this.setState({
      loading: true,
    });

    const {
      firstName,
      lastName,
      email,
      password,
      restaurantName,
      companyName,
      website,
      phone,
      description,
      street,
      city,
      zip,
      country,
      days,
    } = this.state;

    const { global } = this.props;
    const { showSnackbarMessage } = global;

    const address = {
      street,
      city,
      zip,
      country,
    };

    const data = {
      first_name: firstName,
      last_name: lastName,
      restaurant_name: restaurantName,
      company_name: companyName,
      email,
      password,
      address,
      website_url: website,
      phone,
      description,
      open_hours: days,
    };

    if (!this.verifyStep(LAST_STEP_ID)) {
      showSnackbarMessage('Please complete all required fields.', 0);
      this.setState({
        loading: false,
      });
      return;
    }

    try {
      const response = await ManagementServices.restaurantSignup(data);
      if (response.ok) {
        showSnackbarMessage('Success! Please verify your email address', 0);
        this.setStep('next');
      } else {
        const result = await response.json();
        const message = result.message || 'Something went wrong';
        showSnackbarMessage(message, 0);
      }
    } catch (error) {
      showSnackbarMessage('Something went wrong', 0);
    }
    this.setState({
      loading: false,
    });
  }

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  verifyStep = step => {
    const { email, password, restaurantName } = this.state;
    const { street, zip, city, country } = this.state;
    const {
      companyName,
      website,
      phone,
      description,
      firstName,
      lastName,
    } = this.state;
    switch (step) {
      case 1:
        if (!this.validateEmail(email)) return false;
        if (password.length < 8) return false;
        if (restaurantName.length < 4) return false;
        break;
      case 2:
        if (companyName.length < 4) return false;
        if (firstName.length < 2) return false;
        if (lastName.length < 2) return false;
        break;
      case 4:
        if (street.length < 4) return false;
        if (zip.length < 3) return false;
        if (city.length < 2) return false;
        if (country.length < 2) return false;
        break;

      default:
    }
    return true;
  };

  goToStep = step => {
    const { global } = this.props;
    const { maxStepId, currentStepId } = this.state;
    const { showSnackbarMessage } = global;

    if (currentStepId === LAST_STEP_ID) {
      return;
    }
    if (step > maxStepId) {
      showSnackbarMessage('Please complete all required fields.', 0);
    } else {
      this.setState({
        currentStepId: step,
      });
    }
  };

  setStep = step => {
    const { global } = this.props;
    const { showSnackbarMessage } = global;

    const { currentStepId } = this.state;
    let newStep = step === 'next' ? currentStepId + 1 : currentStepId - 1;
    if (step === 'next' && !this.verifyStep(currentStepId)) {
      showSnackbarMessage('Please complete all required fields.', 0);
    } else {
      newStep = Math.max(newStep, 1);
      newStep = Math.min(newStep, 5);
      this.setState({
        maxStepId: Math.max(newStep, currentStepId),
        currentStepId: newStep,
      });
    }
  };

  AddressStep = () => {
    const { street, zip, city, country } = this.state;

    return (
      <View>
        <Text style={styles2.textInputTitle}>STREET ADDRESS</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Street address"
          onChangeText={text => this.updateValue(text, 'street')}
          value={street}
        />
        <Text style={styles2.textInputTitle}>ZIP</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Zip"
          onChangeText={text => this.updateValue(text, 'zip')}
          value={zip}
        />
        <Text style={styles2.textInputTitle}>CITY</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="City"
          onChangeText={text => this.updateValue(text, 'city')}
          value={city}
        />
        <Text style={styles2.textInputTitle}>COUNTRY</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Country"
          onChangeText={text => this.updateValue(text, 'country')}
          value={country}
        />
      </View>
    );
  };

  InfoStep = () => {
    const {
      companyName,
      website,
      phone,
      description,
      firstName,
      lastName,
    } = this.state;
    return (
      <View>
        <Text style={styles2.textInputTitle}>FIRST NAME</Text>
        <TextInput
          style={styles2.textInput}
          placeholder="Enter your first name"
          onChangeText={text => this.updateValue(text, 'firstName')}
          value={firstName}
        />
        <Text style={styles2.textInputTitle}>LAST NAME</Text>
        <TextInput
          style={styles2.textInput}
          placeholder="Enter your last name"
          onChangeText={text => this.updateValue(text, 'lastName')}
          value={lastName}
        />
        <Text style={styles2.textInputTitle}>COMPANY NAME</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Company name"
          onChangeText={text => this.updateValue(text, 'companyName')}
          value={companyName}
        />
        <Text style={styles2.textInputTitle}>WEBSITE URL</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Restaurant's website url"
          onChangeText={text => this.updateValue(text, 'website')}
          value={website}
        />
        <Text style={styles2.textInputTitle}>PHONE</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Phone number"
          onChangeText={text => this.updateValue(text, 'phone')}
          value={phone}
        />
        {/*<Text style={styles2.textInputTitle}>DESCRIPTION</Text>*/}
        {/*<TextInput*/}
        {/*  style={[styles2.textInput, { minHeight: 80, maxHeight: 120 }]}*/}
        {/*  autoCapitalize="none"*/}
        {/*  placeholder="Describe the restaurant"*/}
        {/*  multiline*/}
        {/*  numberOfLines={4}*/}
        {/*  onChangeText={text => this.updateValue(text, 'description')}*/}
        {/*  value={description}*/}
        {/*/>*/}
      </View>
    );
  };

  dayUpdate = props => {
    const { day, openTime, closeTime, closed } = props;
    const newState = { ...this.state };
    const { days } = newState;
    days[day].open_time = openTime;
    days[day].close_time = closeTime;
    days[day].closed = closed;
    this.setState({ days });
  };

  OpenHoursStep = () => {
    const { dayUpdate } = this;
    const { days } = this.state;
    return (
      <View>
        {Globals.DAYS.map(day => {
          return (
            <View key={day.name} style={OpenHoursStyle.fullRow}>
              <Text style={OpenHoursStyle.dayText}>{day.name}</Text>
              <OpenHoursDaySelect
                key={0}
                selectedOpenTime={days[day.shortName]?.open_time}
                selectedCloseTime={days[day.shortName]?.close_time}
                selectedClosed={days[day.shortName]?.closed}
                onChange={value =>
                  dayUpdate({
                    day: day.shortName,
                    holidays: false,
                    ...value,
                  })
                }
              />
            </View>
          );
        })}
      </View>
    );
  };

  SuccessStep = () => {
    const { days, email } = this.state;
    return (
      <View style={{ alignItems: 'center', flex: 1 }}>
        <Text style={{ lineHeight: 24, alignSelf: 'flex-start' }}>
          We have sent an email with a confirmation link to{' '}
          <Text style={{ fontWeight: 'bold' }}>{email}</Text>. In order to
          complete the sign-up process, please click the confirmation link.
        </Text>
        <Image
          style={{ width: 260, height: 260, marginTop: 25 }}
          source={require('../../assets/images/undraw_Mail_sent_re_0ofv.png')}
          resizeMode={'contain'}
        />
      </View>
    );
  };

  FirstStep = () => {
    const { email, password, restaurantName, firstName, lastName } = this.state;

    return (
      <View>
        <Text style={styles2.textInputTitle}>RESTAURANT NAME</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Restaurant name"
          onChangeText={text => this.updateValue(text, 'restaurantName')}
          value={restaurantName}
        />

        <Text style={styles2.textInputTitle}>EMAIL</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Email"
          keyboardType="email-address"
          autoCompleteType="email"
          onChangeText={text => this.updateValue(text, 'email')}
          value={email}
        />
        <Text style={styles2.textInputTitle}>PASSWORD</Text>
        <TextInput
          style={styles2.textInput}
          autoCapitalize="none"
          placeholder="Password"
          textContentType="newPassword"
          secureTextEntry
          onChangeText={text => this.updateValue(text, 'password')}
          value={password}
        />
      </View>
    );
  };

  render() {
    const { currentStepId, loading } = this.state;
    const title = STEPS[currentStepId - 1].name;
    // return <View />;
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{ flex: 1 }}
        keyboardVerticalOffset={-100}
      >
        <SafeAreaView
          style={[
            styles.container,
            { justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          {Platform.OS === 'web' && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 60,
                marginTop: -100,
              }}
            >
              <Image
                style={{
                  width: 420,
                  maxWidth: '85%',
                  height: 45,
                }}
                source={require('../../assets/images/reetab_business.png')}
                resizeMode={'contain'}
              />
            </View>
          )}

          <TouchableWithoutFeedback
            onPress={() => (Platform.OS === 'web' ? null : Keyboard.dismiss())}
          >
            <View style={styles2.floater}>
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 30,
                  // paddingTop: 10,
                  marginHorizontal: 10,
                }}
              >
                <this.RenderSteps
                  steps={STEPS}
                  currentStepId={currentStepId}
                  goToStep={this.goToStep}
                />
              </View>
              <Text
                style={{
                  fontSize: 23,
                  fontWeight: '500',
                  color: Colors.darkest,
                  marginBottom: 30,
                  marginTop: 0,
                }}
              >
                {title}
              </Text>
              <View style={{ flex: 1 }}>
                {currentStepId === 1 ? <this.FirstStep /> : null}
                {currentStepId === 2 ? <this.InfoStep /> : null}
                {currentStepId === 3 ? <this.OpenHoursStep /> : null}
                {currentStepId === 4 ? <this.AddressStep /> : null}
                {currentStepId === LAST_STEP_ID ? <this.SuccessStep /> : null}
              </View>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                {currentStepId === 1 && (
                  <TouchableOpacity
                    style={{ paddingRight: 16, paddingVertical: 8 }}
                    onPress={() => this.props.navigation.navigate('login')}
                  >
                    <Text
                      style={[styles2.buttonFont, { color: Colors.darkBlue }]}
                    >
                      Back to login
                    </Text>
                  </TouchableOpacity>
                )}
                {currentStepId !== LAST_STEP_ID && currentStepId > 1 && (
                  <TouchableOpacity
                    style={{
                      paddingRight: 16,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={() => this.setStep('prev')}
                  >
                    <Text
                      style={[styles2.buttonFont, { color: Colors.darker }]}
                    >
                      Back
                    </Text>
                  </TouchableOpacity>
                )}
                {currentStepId === SUBMIT_STEP_ID && <View />}

                <View />
                {currentStepId < SUBMIT_STEP_ID && (
                  <TouchableOpacity
                    style={styles2.button}
                    onPress={() => this.setStep('next')}
                  >
                    <Text style={styles2.buttonFont}>Continue</Text>
                  </TouchableOpacity>
                )}
                {currentStepId === SUBMIT_STEP_ID && (
                  <TouchableOpacity
                    style={[styles2.button, loading && { opacity: 0.5 }]}
                    disabled={loading}
                    onPress={() => this.signup()}
                  >
                    <Text style={styles2.buttonFont}>
                      {!loading ? 'Submit' : 'submitting...'}
                    </Text>
                  </TouchableOpacity>
                )}
                {currentStepId === LAST_STEP_ID && (
                  <TouchableOpacity
                    style={styles2.button}
                    onPress={() => this.props.navigation.navigate('login')}
                  >
                    <Text style={styles2.buttonFont}>Back To Login</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
}

const styles2 = StyleSheet.create({
  floater: {
    backgroundColor: 'white',
    // width: 600,
    width: 480,
    maxWidth: '96%',
    height: 620,
    // height: 560,
    borderRadius: 12,
    paddingHorizontal:
      Dimensions.get('window').height < 550 ||
      Dimensions.get('window').width < 550
        ? 20
        : 30,
    paddingTop: 30,
    paddingBottom: 18,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: {
      height: 0,
      width: 0,
    },
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 25,
    backgroundColor: 'rgba(255,69,58,0.93)',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOpacity: 0.15,
    shadowRadius: 6,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 2,
  },
  buttonFont: {
    color: 'rgba(255,255,255,0.92)',
    fontSize: 14,
    fontWeight: '500',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  textInput: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.lighterer,
    // height: 40,
    // width: 400,
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginBottom: 8,
  },
  textInputTitle: { fontSize: 11, fontWeight: '600', marginBottom: 5 },
});

const RegisterScreen2 = withGlobalContext(RegisterScreen);
RegisterScreen2.navigationOptions = {
  headerShown: false,
};
export default RegisterScreen2;
