import { format, parse } from 'date-fns';
import { Table, transformTables } from './tablesTransformer';
import { Customer, transformCustomer } from './customerTransformer';
import { DATE_FORMAT, DATETIME_FORMAT } from '../contex/DateTimeContext';

export enum ReservationTypes {
  UNDEFINED = -1,
  ONLINE = 0,
  CREATED_BY_RESTAURANT = 1,
  MULTI_TABLE = 2,
  PENDING = 3,
  WALK_IN = 4,
  CANCELLED = 5,
}

export interface Message {
  author: string;
  text: string;
  timestamp: string;
}

export interface ReservationRequest {
  confirmed: boolean;
  rejected: boolean;
  rejectionReason?: string | null;
}

export interface Reservation {
  id: number;
  createdByNonRegisteredCustomer: boolean;
  createdByRestaurant: boolean;
  walkIn: boolean;
  multiTable: boolean;
  cancelled: boolean;
  createdOnDate: string;
  duration: string;
  formattedDate: string;
  persons: number;
  dateString: string;
  dateTimeFromISOString: string;
  dateTimeToISOString: string;
  dateTimeFrom: Date;
  dateTimeTo: Date;
  timeFrom: string;
  timeTo: string;
  tables: Table[];
  customer: Customer;
  messages: Message[];
  pendingConfirmation: boolean;
  request?: ReservationRequest;
  reservationType: ReservationTypes;
}

export const transformReservationRequest = (
  reservationRequestApiData: any,
): ReservationRequest => {
  return {
    confirmed: reservationRequestApiData.confirmed,
    rejected: reservationRequestApiData.rejected,
    rejectionReason: reservationRequestApiData.rejection_reason,
  };
};

export const transformMessage = (messageApiData: any): Message => {
  return {
    author: messageApiData.author,
    text: messageApiData.text,
    timestamp: messageApiData.timestamp,
  };
};

export const transformMessages = (messages: any): Message[] => {
  if (messages) {
    return messages.map((message: any) => {
      return transformMessage(message);
    });
  }
  return [];
};
export const transformReservation = (reservationApiData: any): Reservation => {
  const dateFrom = parse(
    reservationApiData.reservation_date,
    DATETIME_FORMAT,
    new Date(),
  );
  const dateTo = parse(
    reservationApiData.reservation_date_end,
    DATETIME_FORMAT,
    new Date(),
  );
  const timeFrom = format(dateFrom, 'HH:mm');
  const timeTo = format(dateTo, 'HH:mm');
  const formattedDate = format(dateFrom, 'EEEE, dd. MMM. yyyy');

  const isMultiTable = reservationApiData?.tables?.length > 1;

  const reservation: Reservation = {
    id: reservationApiData.id,
    dateTimeFromISOString: reservationApiData.reservation_date,
    dateTimeToISOString: reservationApiData.reservation_date_end,
    dateString: format(dateFrom, DATE_FORMAT),
    dateTimeFrom: dateFrom,
    dateTimeTo: dateTo,
    createdOnDate: reservationApiData.date_create,
    cancelled: reservationApiData.cancelled,
    persons: reservationApiData.persons,
    createdByNonRegisteredCustomer:
      reservationApiData.created_by_non_registered_customer,
    createdByRestaurant: reservationApiData.created_by_restaurant,
    walkIn: reservationApiData.walk_in,
    multiTable: isMultiTable,
    timeFrom,
    timeTo,
    duration: reservationApiData.duration,
    formattedDate,
    tables: transformTables(reservationApiData.tables),
    customer: transformCustomer(reservationApiData.customer),
    messages: transformMessages(reservationApiData.messages),
    pendingConfirmation: reservationApiData.pending_confirmation,
    request: transformReservationRequest(reservationApiData.request),
    reservationType: ReservationTypes.ONLINE,
  };

  if (reservation.createdByRestaurant)
    reservation.reservationType = ReservationTypes.CREATED_BY_RESTAURANT;

  if (reservation.multiTable)
    reservation.reservationType = ReservationTypes.MULTI_TABLE;

  if (reservation.pendingConfirmation)
    reservation.reservationType = ReservationTypes.PENDING;

  if (reservation.walkIn)
    reservation.reservationType = ReservationTypes.WALK_IN;

  if (reservation.cancelled)
    reservation.reservationType = ReservationTypes.CANCELLED;

  return reservation;
};

export const transformReservations = (reservations: any): Reservation[] => {
  if (reservations) {
    return reservations.map((reservation: any) => {
      return transformReservation(reservation);
    });
  }
  return [];
};
