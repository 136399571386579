import React from 'react';
import {
  Alert,
  Keyboard,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import CountIncreaser from '../../../../components/CountIncreaser';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import TableServices from '../../../../services/TablesServices';

import deviceStorage from '../../../../services/DeviceStorage';
import { withGlobalContext } from '../../../../GlobalContext';
import Collapsible from '../../../../components/Collapsible';

class UpdateTableModal extends React.Component {
  constructor(props) {
    const { navigation } = props;

    const table = navigation.getParam('table', 'NO-ID');

    super(props);
    this.state = {
      tableId: table.id,
      tableNumber: table.table_number,
      selectedGroupName: table.table_group_name,
      selectedGroupId: table.table_group_id,
      chairs: table.chairs,
      isVisible: true,
      groupSelectCollapsed: true,
    };
    this.loadGroups = TableServices.getTableGroups.bind(this);
  }

  componentDidMount() {
    const { jwtToken, restaurantId } = this.props.global;
    // this.loadGroups(jwtToken, restaurantId, 0).catch(err => console.log(err));
  }

  downArrow = () => {
    const name = 'ios-arrow-down';
    return (
      <Ionicons
        name={name}
        size={20}
        color={Colors.lightBlue}
        style={{
          marginTop: Platform.OS === 'ios' ? 5 : 0,
          marginLeft: 12,
        }}
      />
    );
  };

  closeModal = force => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  changeSelectedGroup = (id, name) => {
    this.setState({
      selectedGroupId: id,
      selectedGroupName: name,
      groupSelectCollapsed: true,
    });
  };

  renderGroupOptions = () => {
    const { tableGroups } = this.props.global;

    if (!tableGroups) {
      return <View />;
    }
    const sets = tableGroups.map((set, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.changeSelectedGroup(set.id, set.name)}
          key={index}
          style={ModalStyle.selectOptionRow}
        >
          <Text style={{ fontSize: 17 }}>{set.name}</Text>
          <Text style={ModalStyle.selectText}>Select</Text>
        </TouchableOpacity>
      );
    });
    return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
  };

  switchCollapse = () => {
    Keyboard.dismiss();

    this.setState({
      groupSelectCollapsed: !this.state.groupSelectCollapsed,
    });
  };

  async updateTable() {
    Keyboard.dismiss();

    const { tableNumber, selectedGroupId, chairs, tableId } = this.state;

    const { jwtToken, restaurantId } = this.props.global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = selectedGroupId;
    data.chairs = chairs;

    const result = await TableServices.updateTable(
      jwtToken,
      restaurantId,
      data,
      tableId,
    );
    if (result.ok) {
      this.props.global.updateTableGroups();

      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  updateChairsCount = value => {
    Keyboard.dismiss();
    this.setState({ chairs: value });
  };

  updateTableNumber = value => {
    const number = parseInt(value);
    this.setState({ tableNumber: number });
  };

  async removeTable() {
    const { tableId } = this.state;
    const { jwtToken } = this.props.global;

    const result = await TableServices.removeTable(jwtToken, tableId);
    if (result.ok) {
      this.props.global.updateTableGroups();
      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  removeDialog = () => {
    Alert.alert('Confirm Delete', `Delete table?`, [
      {
        text: 'Cancel',
        // style: 'cancel',
      },
      {
        text: 'Delete',
        style: 'destructive',
        onPress: () => this.removeTable(),
      },
    ]);
  };

  render() {
    const {
      groupSelectCollapsed,
      selectedGroupName,
      tableNumber,
      chairs,
      isVisible,
    } = this.state;
    const tableNumberString = tableNumber ? tableNumber.toString() : '';
    const { switchCollapse, closeModal, updateTableNumber } = this;

    const chairsOptions = [];
    for (let i = 0; i < 50; i += 1) {
      chairsOptions[i] = {
        label: i + 1,
        key: i + 1,
        value: i + 1,
      };
    }

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
          <View style={ModalStyle.modalContainer}>
            <View style={ModalStyle.headerBar}>
              <View style={ModalStyle.headerNavigation} />
              <Text style={ModalStyle.headerTitle}>Edit Table</Text>
              <TouchableOpacity
                style={ModalStyle.headerNavigation}
                onPress={() => closeModal()}
              >
                <Ionicons name="md-close" size={29} color="#ccc" />
              </TouchableOpacity>
            </View>
            <View style={{ marginHorizontal: 36 }}>
              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Table Number</Text>
                  <View style={ModalStyle.touchableSelect}>
                    <TextInput
                      keyboardType="numeric"
                      style={[
                        ModalStyle.fontRight,
                        ModalStyle.inputRight,
                        { color: '#222', minWidth: 100 },
                      ]}
                      onChangeText={value => updateTableNumber(value)}
                      placeholder="Enter table number"
                      value={tableNumberString}
                      autoCorrect={false}
                    />
                  </View>
                </View>
              </View>

              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Group</Text>
                  <TouchableOpacity
                    style={ModalStyle.touchableSelect}
                    onPress={() => switchCollapse()}
                  >
                    <Text style={ModalStyle.fontRight}>
                      {selectedGroupName}
                    </Text>
                    <this.downArrow />
                  </TouchableOpacity>
                </View>
                <Collapsible
                  collapsed={groupSelectCollapsed}
                  duration={500}
                  collapsedHeight={0}
                >
                  <ScrollView style={{ maxHeight: 250 }}>
                    <this.renderGroupOptions />
                  </ScrollView>
                </Collapsible>
              </View>

              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Chairs</Text>
                  <View
                    style={[ModalStyle.touchableSelect, { paddingRight: 0 }]}
                  >
                    <CountIncreaser
                      initialIndex={chairs > 0 ? chairs - 1 : 0}
                      optionsArray={chairsOptions}
                      updateValueFunc={value => this.updateChairsCount(value)}
                    />
                  </View>
                </View>
              </View>
              <View style={Buttons.container}>
                <TouchableOpacity
                  style={[Buttons.big]}
                  onPress={() => this.updateTable()}
                >
                  <Text style={Buttons.text}>Save Changes</Text>
                </TouchableOpacity>
                <View style={{ width: 10 }} />
                {/*<TouchableOpacity*/}
                {/*  style={[Buttons.big, Buttons.red]}*/}
                {/*  onPress={() => this.updateTable()}*/}
                {/*>*/}
                {/*  <Ionicons*/}
                {/*    name="ios-trash"*/}
                {/*    size={27}*/}
                {/*    color={Colors.white}*/}
                {/*    style={{*/}
                {/*      height: 27,*/}
                {/*      width: 16,*/}
                {/*      alignSelf: 'center',*/}
                {/*      marginHorizontal: 0,*/}
                {/*    }}*/}
                {/*  />*/}
                {/*  <Text style={Buttons.text}>Delete</Text>*/}

                {/*</TouchableOpacity>*/}
              </View>
            </View>

            <TouchableOpacity
              style={ModalStyle.bottomButton}
              onPress={() => this.removeDialog()}
            >
              <Ionicons
                name="close"
                size={28}
                color={Colors.dangerRed}
                style={{
                  height: 28,
                  width: 28,
                }}
              />
              <Text style={ModalStyle.deleteText}>Delete this table</Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

export default withGlobalContext(UpdateTableModal);
