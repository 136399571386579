export interface Customer {
  fullName?: string;
  phone?: string;
}

export const transformCustomer = (tableApiData: any): Customer => {
  return {
    fullName: tableApiData.fullname,
    phone: tableApiData.phone,
  };
};
