import React from 'react';
import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import CountIncreaser from '../../../../components/CountIncreaser';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import TableServices from '../../../../services/TablesServices';
import { withGlobalContext } from '../../../../GlobalContext';
import Collapsible from '../../../../components/Collapsible';

const RenderGroupOptions = props => {
  const { groups, onSelect } = props;

  if (!groups) {
    return <View />;
  }
  const sets = groups.map((set, index) => {
    return (
      <TouchableOpacity
        onPress={() => onSelect(set.id)}
        key={index}
        style={ModalStyle.selectOptionRow}
      >
        <Text style={{ fontSize: 17 }}>{set.name}</Text>
        <Text style={ModalStyle.selectText}>Select</Text>
      </TouchableOpacity>
    );
  });
  return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
};

class NewTableModal extends React.Component {
  constructor(props) {
    const { navigation } = props;
    const groupId = navigation.getParam('groupId', null);
    super(props);
    this.state = {
      groupSelectCollapsed: true,
      selectedGroupName: 'loading...',
      selectedGroupId: groupId,
      tableNumber: null,
      chairs: 4,
      isVisible: true,
    };
  }

  // componentDidMount() {
  //   const { jwtToken, restaurantId } = this.state;
  //   this.loadGroups(jwtToken, restaurantId, 0).catch(err => console.log(err));
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { selectedGroupId } = this.state;
  //   const { tableGroups } = this.props.global;
  //
  //   if (
  //     prevState.tableGroups !== this.state.tableGroups &&
  //     tableGroups &&
  //     tableGroups.length > 0
  //   ) {
  //     this.changeSelectedGroup(selectedGroupId);
  //   }
  // }

  componentDidMount() {
    const { tableGroups } = this.props.global;
    const { selectedGroupId } = this.state;

    if (tableGroups && selectedGroupId) {
      this.changeSelectedGroup(selectedGroupId);
    }
  }

  downArrow = () => {
    const name = 'chevron-down';
    return (
      <Ionicons
        name={name}
        size={20}
        color={Colors.lightBlue}
        style={{
          width: 24,
          height: 20,
          marginLeft: 12,
          textAlign: 'center',
        }}
      />
    );
  };

  closeModal = () => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  changeSelectedGroup = id => {
    const { tableGroups } = this.props.global;
    const groupName = tableGroups.find(x => x.id === id).name;
    this.setState({
      selectedGroupId: id,
      selectedGroupName: groupName,
      groupSelectCollapsed: true,
    });
  };

  switchCollapse = () => {
    Keyboard.dismiss();

    const { groupSelectCollapsed } = this.state;
    this.setState({
      groupSelectCollapsed: !groupSelectCollapsed,
    });
  };

  async addNewTable() {
    Keyboard.dismiss();

    const { tableNumber, selectedGroupId, chairs } = this.state;

    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = this.props.global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = selectedGroupId;
    data.chairs = chairs;

    const result = await TableServices.addNewTable(
      jwtToken,
      restaurantId,
      data,
    );
    if (result.ok) {
      // this.closeModal();
      showSnackbarMessage(`Table ${tableNumber} was added.`);
      updateTableGroups();
      updateTables();
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  updateChairsCount = value => {
    Keyboard.dismiss();
    this.setState({ chairs: value });
  };

  updateTableNumber = value => {
    const number = parseInt(value);
    this.setState({ tableNumber: number });
  };

  render() {
    // const { isVisible } = this.props;
    const { isVisible } = this.state;
    const { groupSelectCollapsed, selectedGroupName, tableNumber } = this.state;
    const tableNumberString = tableNumber ? tableNumber.toString() : '';
    const { switchCollapse, closeModal, updateTableNumber } = this;

    const chairsOptions = [];
    for (let i = 0; i < 50; i += 1) {
      chairsOptions[i] = {
        label: i + 1,
        key: i + 1,
        value: i + 1,
      };
    }

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        // coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <KeyboardAvoidingView behavior={'padding'}>
          <View style={ModalStyle.modalContainer}>
            <View style={ModalStyle.headerBar}>
              <View style={ModalStyle.headerNavigation} />
              <Text style={ModalStyle.headerTitle}>New Table</Text>
              <TouchableOpacity
                style={ModalStyle.headerNavigation}
                onPress={() => closeModal()}
              >
                <Ionicons name="md-close" size={29} color="#ccc" />
              </TouchableOpacity>
            </View>
            <View style={{ marginHorizontal: 36 }}>
              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Table Number</Text>
                  <View style={ModalStyle.touchableSelect}>
                    <TextInput
                      keyboardType="numeric"
                      style={[
                        ModalStyle.fontRight,
                        ModalStyle.inputRight,
                        { color: '#222' },
                      ]}
                      placeholderTextColor={Colors.placeholder}
                      onChangeText={value => updateTableNumber(value)}
                      placeholder="ex. 42"
                      value={tableNumberString}
                      autoCorrect={false}
                    />
                    {/*<TextInput*/}
                    {/*  keyboardType="numeric"*/}
                    {/*  style={[*/}
                    {/*    ModalStyle.fontRight,*/}
                    {/*    ModalStyle.inputRight,*/}
                    {/*    { color: '#222', minWidth: 100 },*/}
                    {/*  ]}*/}
                    {/*  placeholderTextColor={Colors.placeholder}*/}
                    {/*  onChangeText={value => updateTableNumber(value)}*/}
                    {/*  placeholder="Enter table number"*/}
                    {/*  value={tableNumberString}*/}
                    {/*  autoCorrect={false}*/}
                    {/*/>*/}
                  </View>
                </View>
              </View>

              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Group</Text>
                  <TouchableOpacity
                    style={ModalStyle.touchableSelect}
                    onPress={() => switchCollapse()}
                  >
                    <Text style={ModalStyle.fontRight}>
                      {selectedGroupName}
                    </Text>
                    <this.downArrow />
                  </TouchableOpacity>
                </View>
                <Collapsible
                  collapsed={groupSelectCollapsed}
                  duration={500}
                  collapsedHeight={0}
                >
                  <ScrollView style={{ maxHeight: 250 }}>
                    {/*<this.renderGroupOptions />*/}
                    <RenderGroupOptions
                      groups={this.props.global.tableGroups}
                      onSelect={this.changeSelectedGroup}
                    />
                  </ScrollView>
                </Collapsible>
              </View>

              <View style={ModalStyle.itemSelect}>
                <View style={ModalStyle.itemSelectText}>
                  <Text style={ModalStyle.fontLeft}>Chairs</Text>
                  <View
                    style={[ModalStyle.touchableSelect, { paddingRight: 0 }]}
                  >
                    <CountIncreaser
                      initialIndex={3}
                      optionsArray={chairsOptions}
                      updateValueFunc={value => this.updateChairsCount(value)}
                      circleBorder={0}
                      valueWidth={50}
                      iconSize={24}
                    />
                  </View>
                </View>
              </View>
              <View style={Buttons.container}>
                <TouchableOpacity
                  style={[Buttons.big, Buttons.green]}
                  onPress={() => this.addNewTable()}
                >
                  <Text style={Buttons.text}>Add New Table</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}

export default withGlobalContext(NewTableModal);
