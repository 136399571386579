import React, { useEffect, useState } from 'react';
import Animated, {
  cancelAnimation,
  Easing,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import {
  LongPressGestureHandler,
  PanGestureHandler,
  State,
  TapGestureHandler,
} from 'react-native-gesture-handler';
import { Platform, StyleSheet, View } from 'react-native';
import * as Haptics from 'expo-haptics';
import { ImpactFeedbackStyle } from 'expo-haptics';

const MovableReservationContainer = ({
  rowHeight,
  onPress,
  children,
  numberOfTables,
  numberOfHours,
  minuteWidth,
  editingReservations,
  setEditingReservations,
  tableIndex,
  x,
  width,
  durationMinutes,
  minutesFromFirstHour,
  onTimeChange,
  onTableChange,
}) => {
  const [verticalOffset, setVerticalOffset] = useState(tableIndex * rowHeight);

  const [isStartedByThisComponent, setIsStartedByThisComponent] = useState(
    false,
  );

  useEffect(() => {
    setVerticalOffset(tableIndex * rowHeight);
  }, [tableIndex, rowHeight]);

  const animateToValue = (value, delay = 0) => {
    return withDelay(
      delay,
      withSpring(value, {
        damping: 30,
        stiffness: 200,
      }),
    );
  };

  const y = useSharedValue(0);

  const resetToDefaults = () => {
    x.value = animateToValue(0);
    y.value = animateToValue(0);
    width.value = animateToValue(0);
  };

  const isPressedIn = useSharedValue(0);
  const zIndex = useSharedValue(0);
  const expanderOpacity = useSharedValue(0);

  const onStartEditing = () => {
    zIndex.value = 2;
    // cancelAnimation(isPressedIn);
    isPressedIn.value = withTiming(0.8, {
      duration: 200,
    });
    setIsStartedByThisComponent(true);
    // isPressedIn.value = animateToValue(0.8);
  };

  const onPressIn = () => {
    zIndex.value = 2;
    cancelAnimation(isPressedIn);

    if (!editingReservations) {
      // isPressedIn.value = withSequence(
      //   withTiming(-2, {
      //     duration: 100,
      //     easing: Easing.out(Easing.bezier(0.5, 1, 0.89, 1)),
      //   }),
      //   withSpring(0.8, {
      //     damping: 14,
      //     mass: 1,
      //     stiffness: 200,
      //   }),
      // );
      isPressedIn.value = withTiming(-2, {
        duration: 500,
        easing: Easing.out(Easing.bezier(0.5, 1, 0.89, 1)),
      });
    } else {
      isPressedIn.value = withTiming(0.8, {
        duration: 100,
      });
    }
  };
  const onPressOut = (delay = 0) => {
    // cancelAnimation(isPressedIn);
    zIndex.value = withDelay(
      400,
      withTiming(1, {
        duration: 100,
      }),
    );
    isPressedIn.value = animateToValue(0, delay);
  };

  useEffect(() => {
    if (editingReservations && !isStartedByThisComponent) {
      showExpander();
    } else if (!editingReservations) {
      hideExpander();
      resetToDefaults();
      setIsStartedByThisComponent(false);
    }
  }, [editingReservations]);

  const showExpander = () => {
    expanderOpacity.value = withDelay(
      100,
      withTiming(1, {
        duration: 250,
      }),
    );
  };
  const hideExpander = () => {
    expanderOpacity.value = withTiming(0, {
      duration: 150,
    });
  };

  const handleLongPress = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      if (!editingReservations) {
        onStartEditing();
        vibrate({ type: 'medium' });
        setEditingReservations(true);
      }
    }
    if (nativeEvent.state === State.END) {
      if (editingReservations && isStartedByThisComponent) {
        showExpander();
        setIsStartedByThisComponent(false);
      }
      onPressOut();
    }
  };

  const minMaxXdtValue = () => {
    const snapXWidth = minuteWidth * 15;
    let snapX = Math.round(x.value / snapXWidth) * snapXWidth;

    const newAbsoluteX = minutesFromFirstHour * minuteWidth + x.value;
    if (newAbsoluteX < 0) {
      snapX = -(minutesFromFirstHour * minuteWidth);
    } else if (
      newAbsoluteX + durationMinutes * minuteWidth + width.value >
      numberOfHours * minuteWidth * 60
    ) {
      snapX =
        numberOfHours * minuteWidth * 60 -
        (minutesFromFirstHour + durationMinutes) * minuteWidth -
        width.value;
    }
    return snapX;
  };

  const minMaxWidthdtValue = () => {
    const snapXWidth = minuteWidth * 15;
    let snapXLength = Math.round(width.value / snapXWidth) * snapXWidth;
    const totalWidth = durationMinutes * minuteWidth + snapXLength;
    const totalDurationMinutes = totalWidth / minuteWidth;
    if (totalDurationMinutes < 30) {
      snapXLength = minuteWidth * 30 - durationMinutes * minuteWidth;
    }

    return snapXLength;
  };

  const minMaxYdtValue = () => {
    const snapY = Math.round(y.value / rowHeight) * rowHeight;
    const absoluteIndex = (verticalOffset + snapY) / rowHeight;
    if (absoluteIndex < 0) {
      return -verticalOffset;
    } else if (absoluteIndex > numberOfTables - 1) {
      return (numberOfTables - 1) * rowHeight - verticalOffset;
    }
    return snapY;
  };

  const minutesDtFromX = value => {
    return Math.round(value / minuteWidth);
  };

  const tablesDtFromY = value => {
    return Math.round(value / rowHeight);
  };

  const vibrate = ({ timeout, type, iosOnly }) => {
    if (Platform.OS !== 'web' && (!iosOnly || Platform.OS === 'ios')) {
      setTimeout(() => {
        if (type === 'medium') {
          Haptics.impactAsync(ImpactFeedbackStyle.Heavy).then();
        } else {
          // Haptics.selectionAsync().then();
          Haptics.impactAsync(ImpactFeedbackStyle.Light).then();
        }
      }, timeout);
    }
  };

  const handlePanHandlerState = ({ nativeEvent }) => {
    if (nativeEvent.state === State.END) {
      if (editingReservations) {
        onPressOut();
        const xDt = minMaxXdtValue();
        const yDt = minMaxYdtValue();

        const minutesDt = minutesDtFromX(xDt);
        const tablesDt = tablesDtFromY(yDt);
        const durationMinutesDt = minutesDtFromX(width.value);

        x.value = animateToValue(xDt);
        y.value = animateToValue(yDt);
        onTimeChange(minutesDt, durationMinutesDt);
        onTableChange(tablesDt);
        vibrate({ timeout: 0 });
      }
    }
  };

  const handlePanHandlerLengthState = ({ nativeEvent }) => {
    if (nativeEvent.state === State.END) {
      if (editingReservations) {
        showExpander();
        const widthDt = minMaxWidthdtValue();
        const minutesDt = minutesDtFromX(x.value);
        const durationMinutesDt = minutesDtFromX(widthDt);

        width.value = animateToValue(widthDt);
        onTimeChange(minutesDt, durationMinutesDt);
        // vibrate({ timeout: 50 });
      }
    }
  };

  const handleTapGesture = ({ nativeEvent }) => {
    if (nativeEvent.state === State.BEGAN) {
      onPressIn();
    } else if (nativeEvent.state === State.ACTIVE) {
      onPress();
      onPressOut(50);
    } else if (nativeEvent.state === State.FAILED && !editingReservations) {
      onPressOut();
    }
  };

  const gestureHandler = useAnimatedGestureHandler(
    {
      onStart: (event, ctx) => {
        ctx.startX = x.value;
        ctx.startY = y.value;
        ctx.canceledPressedInAnimation = false;
      },
      onActive: (event, ctx) => {
        if (!editingReservations) {
          if (
            !ctx.canceledPressedInAnimation &&
            event.translationX ** 2 + event.translationY ** 2 > 25 * 25
          ) {
            cancelAnimation(isPressedIn);
            isPressedIn.value = 0;
          }
        } else if (ctx.set) {
          if (ctx.set === 'x') {
            x.value = ctx.startX + event.translationX;
          } else if (ctx.set === 'y') {
            y.value = ctx.startY + event.translationY;
          }
        } else if (
          event.translationX ** 2 > 6 ** 2 ||
          event.translationY ** 2 > 6 ** 2
        ) {
          if (event.translationX ** 2 > event.translationY ** 2) {
            ctx.set = 'x';
            y.value = withSpring(ctx.startY, {
              damping: 30,
              stiffness: 200,
            });
          } else {
            ctx.set = 'y';
            x.value = withSpring(ctx.startX, {
              damping: 30,
              stiffness: 200,
            });
          }
        } else {
          x.value = ctx.startX + event.translationX;
          y.value = ctx.startY + event.translationY;
        }
      },
      onEnd: (event, ctx) => {
        ctx.set = undefined;
        ctx.startX = undefined;
        ctx.startY = undefined;
      },
    },
    [editingReservations],
  );

  const gestureHandlerLength = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startXDt = width.value;
      ctx.prevMax = -minuteWidth * 7;
    },
    onActive: (event, ctx) => {
      if (event.translationX > ctx.prevMax + minuteWidth * 15) {
        ctx.prevMax = event.translationX;
        runOnJS(vibrate)({ timeout: 0, iosOnly: true });
      } else if (event.translationX < ctx.prevMax - minuteWidth * 15) {
        ctx.prevMax = event.translationX;
        runOnJS(vibrate)({ timeout: 0, iosOnly: true });
      }
      width.value = ctx.startXDt + event.translationX;
    },
    onEnd: (event, ctx) => {
      ctx.startXDt = undefined;
      // width.value = ctx.startXDt + event.translationX;
    },
  });

  const derivedWidth = useDerivedValue(() => {
    return durationMinutes * minuteWidth + width.value - 1;
  }, [durationMinutes, minuteWidth]);

  const derivedTranslateX = useDerivedValue(() => {
    return x.value + minutesFromFirstHour * minuteWidth;
  }, [minutesFromFirstHour, minuteWidth]);

  const derivedTranslateY = useDerivedValue(() => {
    return y.value + verticalOffset;
  }, [verticalOffset, minuteWidth]);

  const animatedStyleContainer = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: derivedTranslateX.value,
        },
        { translateY: derivedTranslateY.value },
        {
          scale: 1 + isPressedIn.value * 0.04,
        },
      ],
    };
  });

  const animatedStyleContainerNotWeb =
    Platform.OS !== 'web' &&
    useAnimatedStyle(() => {
      return {
        // paddingHorizontal: isPressedIn.value * 3,
        // paddingVertical: isPressedIn.value * 3,
        shadowOpacity: isPressedIn.value * 0.2,
        shadowRadius: isPressedIn.value * 4,
        zIndex: zIndex.value,
      };
    });

  const animatedExpanderStyle = useAnimatedStyle(() => {
    return {
      width: expanderOpacity.value * 15,
      opacity: expanderOpacity.value,
    };
  });
  const animatedInnerContainerStyle = useAnimatedStyle(() => {
    return {
      paddingRight: expanderOpacity.value * 12,
      width: derivedWidth.value,
    };
  });
  const panRef = React.useRef(null);
  const tapRef = React.useRef(null);

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          left: 0,
          top: 0,
          height: rowHeight - 2,
          margin: 1,
          shadowOffset: {
            height: 2,
            width: 2,
          },
          // shadowRadius: 8,
          shadowOpacity: 0,
        },
        animatedStyleContainer,
        animatedStyleContainerNotWeb,
      ]}
    >
      <LongPressGestureHandler
        onHandlerStateChange={handleLongPress}
        simultaneousHandlers={[panRef, tapRef]}
        minDurationMs={500}
        maxDist={20}
      >
        <Animated.View
          style={[
            {
              flex: 1,
              borderRadius: 4,
              backgroundColor: 'white',
            },
            animatedInnerContainerStyle,
          ]}
        >
          <TapGestureHandler
            onHandlerStateChange={handleTapGesture}
            simultaneousHandlers={panRef}
            maxDurationMs={500}
            ref={tapRef}
            maxDist={0}
            // enabled={false}
          >
            <Animated.View style={{ flex: 1 }}>
              <PanGestureHandler
                onGestureEvent={gestureHandler}
                onHandlerStateChange={handlePanHandlerState}
                minDist={editingReservations ? 0 : 30}
                ref={panRef}
                simultaneousHandlers={[tapRef]}
              >
                <Animated.View style={{ flex: 1 }}>{children}</Animated.View>
              </PanGestureHandler>
            </Animated.View>
          </TapGestureHandler>
          <PanGestureHandler
            enabled={editingReservations}
            onGestureEvent={gestureHandlerLength}
            onHandlerStateChange={handlePanHandlerLengthState}
          >
            <Animated.View
              style={[
                {
                  position: 'absolute',
                  right: 0,
                  paddingRight: 0,
                  width: 30,
                  alignItems: 'flex-end',
                  height: rowHeight - 1,
                  top: 0,
                },
                Platform.OS !== 'web' && {
                  right: -15,
                  paddingRight: 15,
                  width: 45,
                },
              ]}
            >
              <Animated.View
                style={[styles.widthExpander, animatedExpanderStyle]}
              >
                <View style={styles.widthExpanderMark} />
                <View style={styles.widthExpanderMark} />
              </Animated.View>
            </Animated.View>
          </PanGestureHandler>
        </Animated.View>
      </LongPressGestureHandler>
    </Animated.View>
  );
};

export default MovableReservationContainer;

const styles = StyleSheet.create({
  widthExpander: {
    backgroundColor: 'rgb(245,245,245)',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    shadowOpacity: 0.2,
    elevation: 3,
    shadowRadius: 4,
    shadowOffset: {
      width: 1,
      height: 1,
    },
    flex: 1,
    // width: 10,
  },
  widthExpanderMark: {
    width: 1,
    backgroundColor: '#c9cfdc',
    borderRadius: 10,
    marginHorizontal: 1,
    shadowOpacity: 0,
    // shadowRadius: 1,
    // shadowOffset: {
    //   width: 1,
    //   height: 1,
    // },
    height: 15,
  },
});
