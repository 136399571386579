import {
  ActivityIndicator,
  Platform,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import {
  CountSelectorDurationMinutes,
  CountSelectorInt,
} from './CountSelector';
import Colors from '../constants/Colors';
import Button from './Button';
import AreYouSureWrapper from './AreYouSureWrapper';

export interface RowInputProps extends TextInputProps {
  label?: string;
  placeholder?: string;
  value: string;
  disabled?: boolean;
  onChangeText: (text: string) => void;
  inlineLabel?: string;
  multiline?: boolean;
  small?: boolean;
  autoCorrect?: boolean;
  inputWidth?: number;
  width?: number;
  containerStyle?: ViewStyle;
}

export interface DimensionsInputProps {
  labelFirst?: string;
  labelSecond?: string;
  valueFirst: number;
  valueSecond: number;
  onChangeValueFirst?: (text: string) => void;
  onChangeValueSecond?: (text: string) => void;
  onChangeAny?: () => void;
  float?: boolean;
  unit?: string;
}

export const DimensionsInput: React.FC<DimensionsInputProps> = ({
  labelFirst,
  labelSecond,
  valueFirst,
  valueSecond,
  onChangeValueFirst,
  onChangeValueSecond,
  onChangeAny = () => null,
  float,
  unit,
}) => {
  const [valueFirstString, setValueFirstString] = useState(String(valueFirst));
  const [valueSecondString, setValueSecondString] = useState(
    String(valueSecond),
  );

  useEffect(() => {
    const newString = valueFirst > 0 ? `${valueFirst}` : '';
    setValueFirstString(newString);
  }, [valueFirst]);

  useEffect(() => {
    const newString = valueSecond > 0 ? `${valueSecond}` : '';
    setValueSecondString(newString);
  }, [valueSecond]);

  const onChangeTextAction = (text, setValueString, setValueNumber) => {
    if (float) {
      setValueString(text.replace(',', '.'));
      const number = parseFloat(text) || 0;
      setValueNumber(number);
    } else {
      const number = parseInt(text, 10) || 0;
      setValueNumber(number);
    }
    onChangeAny();
  };
  const onChangeTextFirst = (text) => {
    onChangeTextAction(text, setValueFirstString, onChangeValueFirst);
  };

  const onChangeTextSecond = (text) => {
    onChangeTextAction(text, setValueSecondString, onChangeValueSecond);
  };

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <RowInput
        label={labelFirst}
        value={valueFirstString}
        inlineLabel={unit}
        onChangeText={onChangeTextFirst}
        small
        // inputWidth={50}
        maxLength={5}
        keyboardType="number-pad"
        selectTextOnFocus
        containerStyle={{
          flex: 1,
        }}
      />
      <Ionicons
        name="close"
        size={18}
        style={{
          paddingTop: 8,
          color: Colors.light,
          marginHorizontal: 6,
        }}
      />
      <RowInput
        label={labelSecond}
        value={valueSecondString}
        inlineLabel={unit}
        onChangeText={onChangeTextSecond}
        small
        // inputWidth={50}
        maxLength={5}
        keyboardType="number-pad"
        selectTextOnFocus
        containerStyle={{
          flex: 1,
        }}
      />
    </View>
  );
};

export const RowInput: React.FC<RowInputProps> = ({
  label,
  placeholder,
  value,
  disabled,
  onChangeText,
  inlineLabel,
  multiline,
  small,
  autoCapitalize,
  autoCorrect = false,
  inputWidth,
  selectTextOnFocus,
  onEndEditing = () => null,
  onBlur = () => null,
  onFocus = () => null,
  keyboardType,
  maxLength,
  containerStyle,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  let height;
  if (small) {
    height = 40;
  }
  if (multiline) {
    height = 90;
  }

  const onEndEditingExtra = (e) => {
    onEndEditing(e);
  };

  const onBlurExtra = (e) => {
    onBlur(e);
    setIsFocused(false);
  };

  const onFocusExtra = (e) => {
    onFocus(e);
    setIsFocused(true);
  };

  const inputRef = useRef(null);

  return (
    <FormRow
      label={label}
      isFocused={isFocused}
      disabled={disabled}
      height={height}
      containerStyle={containerStyle}
      fullWidth={!inputWidth}
    >
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          // alignItems: 'center',
        }}
      >
        <View style={{ flex: inputWidth ? 0 : 1 }}>
          <TextInput
            ref={inputRef}
            placeholder={placeholder}
            multiline={multiline}
            editable={!disabled}
            value={`${value || ''}`}
            onChangeText={onChangeText}
            onFocus={onFocusExtra}
            onBlur={onBlurExtra}
            placeholderTextColor={Colors.lighter}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            onEndEditing={onEndEditingExtra}
            selectTextOnFocus={selectTextOnFocus}
            keyboardType={keyboardType}
            maxLength={maxLength}
            style={{
              paddingHorizontal: small ? 12 : 16,
              paddingRight: inlineLabel ? 0 : undefined,
              paddingTop: multiline ? 10 : 0,
              color: disabled ? '#6b7d94' : '#2d3c4f',
              fontWeight: '500',
              fontSize: 15,
              height: '100%',
              borderRadius: 8,
              textAlignVertical: multiline ? 'top' : undefined,
              width: inputWidth || '100%',
              // flex: 1,
            }}
          />
        </View>
        {inlineLabel && (
          <TouchableWithoutFeedback onPress={() => inputRef.current.focus()}>
            <View
              style={{
                height: '100%',
                justifyContent: 'center',
                // alignItems: 'center',
                // backgroundColor: 'blue',
              }}
            >
              <Text
                style={{
                  paddingRight: small ? 12 : 16,
                  fontSize: 15,
                  color: '#8b939a',
                  fontWeight: '500',
                }}
              >
                {inlineLabel}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    </FormRow>
  );
};

export const RowDropdown = ({ label, value, options, containerStyle }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormRow
      label={label}
      isFocused={isFocused}
      width={null}
      containerStyle={containerStyle}
    >
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 16,
          paddingRight: 12,
          height: '100%',
        }}
        onPress={() => setIsFocused((prevState) => !prevState)}
      >
        <Text
          style={{
            color: '#485b73',
            fontWeight: '500',
            fontSize: 15,
          }}
        >
          {value}
        </Text>
        <Text
          style={{
            color: '#90a1b5',
            fontWeight: '500',
            fontSize: 15,
            marginTop: 3,
          }}
        >
          <Ionicons name="chevron-down" size={18} />
        </Text>
      </TouchableOpacity>
    </FormRow>
  );
};

export const RowInputStepper = ({
  label,
  value,
  maxValue = 100,
  onValueUpdate,
  small = false,
  duration = false,
  containerStyle,
  labelArray,
}) => (
  // const defaultDim = small ? 42 : 52;

  <FormRow
    label={label}
    width={160}
    height={small ? 42 : 52}
    containerStyle={containerStyle}
  >
    {!duration && (
      <CountSelectorInt
        min={0}
        max={maxValue}
        value={value}
        onUpdateValue={onValueUpdate}
        labelArray={labelArray}
      />
    )}
    {duration && (
      <CountSelectorDurationMinutes
        interval={15}
        min={30}
        max={500}
        value={value}
        onUpdateValue={onValueUpdate}
      />
    )}
  </FormRow>
);

export const FormSelectorItem = ({
  children,
  selected,
  onSelect = () => null,
  width = 100,
  height = 100,
}) => (
  <TouchableOpacity
    style={{
      // flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height,
      width,
      borderRadius: 18,
      marginRight: 6,
      borderWidth: selected ? 1.5 : 1,
      borderColor: selected ? '#88bfff' : '#e0e7ee',
      backgroundColor: 'white',
      // backgroundColor: selected ? '#eff1f3' : 'white',
    }}
    onPress={onSelect}
  >
    {children}
  </TouchableOpacity>
);

export const RowHorizontalSelector = ({
  label,
  children,
  height,
  containerStyle,
}) => (
  <FormRowRaw label={label} style={containerStyle}>
    <ScrollView
      horizontal
      style={{
        paddingVertical: 3,
        height,
      }}
    >
      {children}
    </ScrollView>
  </FormRowRaw>
);

export const FormRowStyledContainer = ({
  children,
  isFocused,
  disabled,
  height,
  width,
  fullWidth = true,
}) => (
  <View
    style={{
      borderRadius: 8,
      borderWidth: isFocused ? 1.5 : 1,
      borderColor: isFocused ? '#88bfff' : '#e0e7ee',
      height,
      backgroundColor: disabled ? '#eff1f3' : 'white',
      width,
      alignSelf: fullWidth ? 'stretch' : 'flex-start',
    }}
  >
    {children}
  </View>
);

export interface FormRowProps {
  label: string;
  children?: React.ReactNode;
  isFocused?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
  containerStyle?: ViewStyle;
  fullWidth?: boolean;
}

export const FormRow: React.FC<FormRowProps> = ({
  label,
  children,
  isFocused = false,
  disabled = false,
  width = undefined,
  height = 52,
  containerStyle,
  fullWidth = true,
}) => (
  <FormRowRaw label={label} style={containerStyle}>
    <FormRowStyledContainer
      isFocused={isFocused}
      disabled={disabled}
      width={width}
      height={height}
      fullWidth={fullWidth}
    >
      {children}
    </FormRowStyledContainer>
  </FormRowRaw>
);

export const FormRowSwitch = ({
  label,
  value,
  onValueChange,
  containerStyle,
  inlineText,
}) => (
  <FormRowRaw label={label} style={containerStyle}>
    <View
      style={{
        paddingLeft: 2,
        paddingTop: 0,
        alignItems: 'center',
        height: 40,
        flexDirection: 'row',
      }}
    >
      <Switch
        onValueChange={onValueChange}
        value={value}
        trackColor={{
          true: Colors.superCoolBlue,
          false: '#767577',
        }}
        thumbColor="#ffffff"
        activeThumbColor="#ffffff"
      />
      {inlineText && (
        <Text
          style={{
            paddingHorizontal: 8,
            paddingRight: 40,
            color: Colors.dark,
            fontWeight: '500',
            fontSize: 13,
          }}
        >
          {inlineText}
        </Text>
      )}
    </View>
  </FormRowRaw>
);

export const FormRowStaticText = ({
  label,
  value,
  width = undefined,
  containerStyle,
  textAlignRight,
  textStyle,
}) => (
  <FormRowRaw label={label} style={containerStyle}>
    <View
      style={{
        paddingHorizontal: 2,
        width,
      }}
    >
      <Text
        style={[
          {
            color: '#3c4a5b',
            fontWeight: '600',
            fontSize: 15,
            textAlign: textAlignRight ? 'right' : 'left',
          },
          textStyle,
        ]}
      >
        {value}
      </Text>
    </View>
  </FormRowRaw>
);

export interface FormRowRawProps {
  label: string;
  children?: React.ReactNode;
  style?: ViewStyle;
}

export const FormRowRaw: React.FC<FormRowRawProps> = ({
  label,
  children,
  style,
}) => (
  <View
    style={[
      {
        backgroundColor: undefined,
        marginBottom: 7,
      },
      style,
    ]}
  >
    <View
      style={{
        marginBottom: Platform.OS === 'android' ? 1 : 2,
      }}
    >
      <Text
        style={{
          textTransform: 'uppercase',
          color: '#717f8f',
          fontSize: 12,
          fontWeight: '500',
          paddingLeft: 2,
        }}
      >
        {label}
      </Text>
    </View>
    {children}
  </View>
);

export enum FormButtonsType {
  DEFAULT = 0,
  CREATE = 1,
  DELETE = 2,
  CANCEL = 3,
  ACCEPT = 4,
}

export enum FormButtonSize {
  SMALL = 40,
  MEDIUM = 44,
  LARGE = 46,
}

export enum FormButtonIconType {
  TRASH = 0,
  CHECK = 1,
  CANCEL = 2,
}

export interface FormButtonProps {
  label?: string;
  icon?: FormButtonIconType;
  loading?: boolean;
  disabled?: boolean;
  disableOnPress?: boolean;
  onPress?: () => void;
  type?: FormButtonsType;
  size?: FormButtonSize;
  secondary?: boolean;
}

export const FormButtom = ({
  label,
  loading = false,
  disabled = false,
  onPress = () => null,
  disableOnPress = false,
  icon,
  type = FormButtonsType.DEFAULT,
  size = FormButtonSize.MEDIUM,
  secondary = false,
}: FormButtonProps) => {
  let color: string | null = Colors.white;
  let backGroundColor: string | null = Colors.darkBlue;
  let showShadow = true;

  switch (type) {
    case FormButtonsType.DELETE:
      color = Colors.dangerRed;
      backGroundColor = undefined;
      showShadow = false;
      break;
    case FormButtonsType.CREATE:
      backGroundColor = Colors.lightGreen;
      break;
    case FormButtonsType.ACCEPT:
      backGroundColor = Colors.lightGreen;
      break;
    case FormButtonsType.CANCEL:
      backGroundColor = Colors.lighter2;
      break;
    default:
      break;
  }

  let iconComponent;
  switch (icon) {
    case FormButtonIconType.TRASH:
      iconComponent = (
        <Ionicons
          name="trash-bin-outline"
          size={20}
          color={secondary ? backGroundColor : color}
          style={{
            marginRight: 10,
          }}
        />
      );
      break;
    case FormButtonIconType.CANCEL:
      iconComponent = (
        <Ionicons
          name="close"
          size={20}
          color={secondary ? backGroundColor : color}
          style={{
            marginRight: 6,
          }}
        />
      );
      break;
    case FormButtonIconType.CHECK:
      iconComponent = (
        <Ionicons
          name="checkmark"
          size={20}
          color={secondary ? backGroundColor : color}
          style={{
            marginRight: 6,
          }}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Button
      color={secondary ? 'white' : backGroundColor}
      disabled={disableOnPress}
      textColor={secondary ? backGroundColor : color}
      text={loading ? '' : label}
      icon={iconComponent}
      active={!(disabled || loading)}
      loading={loading}
      // showShadow={showShadow}
      loadingComponent={
        loading && (
          <ActivityIndicator
            animating
            size="small"
            style={{
              opacity: 1,
              paddingHorizontal: 8,
            }}
            color="#ffffff"
          />
        )
      }
      style={{
        // marginLeft: 8,
        paddingHorizontal: 12,
        height: size,
        borderWidth: secondary ? 1 : 0,
        borderColor: backGroundColor,
      }}
      onPress={onPress}
    />
  );
};

export interface FormButtonWithConfirmationProps extends FormButtonProps {
  onConfirm: () => void;
  title: string;
  confirmText: string;
  cancelText: string;
  message: string;
}

export const FormButtonWithConfirmation: React.FC<FormButtonWithConfirmationProps> =
  ({
    icon,
    label,
    loading,
    disabled,
    type = FormButtonsType.DEFAULT,
    onConfirm = () => null,
    title,
    confirmText,
    cancelText,
    message,
    size,
    secondary,
  }) => (
    <AreYouSureWrapper
      style={{
        // height: 43,
        // flex: 1,
        // backgroundColor: 'red',
        // justifyContent: 'center',
        // alignItems: 'center',
        // textAlign: 'center',
        flexGrow: 1,
        // flexDirection: 'row',
      }}
      containerStyle={{
        flexDirection: 'row',
        flexGrow: 1,
      }}
      onConfirm={() => onConfirm()}
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
      message={message}
    >
      <FormButtom
        size={size}
        disableOnPress
        label={label}
        disabled={disabled}
        type={type}
        loading={loading}
        icon={icon}
        secondary={secondary}
      />
    </AreYouSureWrapper>
  );

export interface FormButtonsGroupProps {
  children: React.ReactNode;
  marginTop?: number;
  marginBottom?: number;
}

export const FormButtonsGroup: React.FC<FormButtonsGroupProps> = ({
  children,
  marginTop = 0,
  marginBottom = 0,
}) => (
  <View
    style={[
      {
        flexDirection: 'row',
        marginTop,
        marginBottom,
        // alignItems: 'stretch',
        // justifyContent: 'center',
      },
    ]}
  >
    {children}
  </View>
);
