import React from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import OpenHoursServices from '../../../../services/OpenHoursServices';
import TableServices from '../../../../services/TablesServices';
import { withGlobalContext } from '../../../../GlobalContext';
import Collapsible from '../../../../components/Collapsible';

const RenderOpenHoursOptions = ({ openHours, changeSelectedOpenHours }) => {
  if (!openHours) {
    return <View />;
  }
  const sets = openHours.map((set, index) => {
    return (
      <TouchableOpacity
        onPress={() => changeSelectedOpenHours(set.id, set.name)}
        key={index}
        style={ModalStyle.selectOptionRow}
      >
        <Text style={{ fontSize: 17 }}>{set.name}</Text>
        <Text style={ModalStyle.selectText}>Select</Text>
      </TouchableOpacity>
    );
  });
  return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
};

class NewGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: null,
      isOutside: false,
      openHoursSelectCollapsed: true,
      selectedOpenHoursName: 'Select',
      selectedOpenHoursId: null,
      isVisible: true,
    };
  }

  componentDidMount() {
    const { selectedOpenHoursId } = this.state;
    const { openHours } = this.props.global;

    if (!selectedOpenHoursId && openHours.length > 0) {
      this.changeSelectedOpenHours(openHours[0].id, openHours[0].name);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOpenHoursId } = this.state;
    const { openHours } = this.props.global;

    if (!selectedOpenHoursId && openHours.length > 0) {
      this.changeSelectedOpenHours(openHours[0].id, openHours[0].name);
    }
  }

  downArrow = () => {
    const name = 'chevron-down';
    return (
      <Ionicons
        name={name}
        size={20}
        color={Colors.lightBlue}
        style={{ marginTop: Platform.OS === 'ios' ? 5 : 0, marginLeft: 12 }}
      />
    );
  };

  closeModal = force => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  changeSelectedOpenHours = (id, name) => {
    this.setState({
      selectedOpenHoursId: id,
      selectedOpenHoursName: name,
      openHoursSelectCollapsed: true,
    });
  };

  isOutsideSwitch = () => {
    const { isOutside } = this.state;
    this.setState({
      isOutside: !isOutside,
    });
  };

  switchCollapse = () => {
    const { openHoursSelectCollapsed } = this.state;
    this.setState({
      openHoursSelectCollapsed: !openHoursSelectCollapsed,
    });
  };

  async createNewGroup() {
    const { groupName, isOutside, selectedOpenHoursId } = this.state;

    const { jwtToken, restaurantId } = this.props.global;

    const data = {};
    data.name = groupName;
    data.outside = isOutside;
    data.open_hours_set_id = selectedOpenHoursId;

    const result = await TableServices.createTableGroup(
      jwtToken,
      restaurantId,
      data,
    );
    if (result.ok) {
      this.props.global.updateTableGroups();
      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  render() {
    const {
      groupName,
      isOutside,
      openHoursSelectCollapsed,
      selectedOpenHoursName,
      isVisible,
    } = this.state;
    const {
      isOutsideSwitch,
      switchCollapse,
      closeModal,
      changeSelectedOpenHours,
    } = this;
    const { openHours } = this.props.global;

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <View style={ModalStyle.modalContainer}>
          <View style={ModalStyle.headerBar}>
            <View style={ModalStyle.headerNavigation} />
            <Text style={ModalStyle.headerTitle}>New Group</Text>
            <TouchableOpacity
              style={ModalStyle.headerNavigation}
              onPress={() => closeModal()}
            >
              <Ionicons name="md-close" size={29} color="#ccc" />
            </TouchableOpacity>
          </View>
          <View style={{ marginHorizontal: 36 }}>
            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Name</Text>
                <View style={ModalStyle.touchableSelect}>
                  <TextInput
                    style={[
                      ModalStyle.fontRight,
                      ModalStyle.inputRight,
                      { color: '#222' },
                    ]}
                    placeholderTextColor={Colors.placeholder}
                    onChangeText={text => this.setState({ groupName: text })}
                    placeholder="Enter name"
                    value={groupName}
                    autoCorrect={false}
                  />
                </View>
              </View>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Open Hours</Text>
                <TouchableOpacity
                  style={ModalStyle.touchableSelect}
                  onPress={() => switchCollapse()}
                >
                  <Text style={ModalStyle.fontRight}>
                    {selectedOpenHoursName}
                  </Text>
                  <this.downArrow />
                </TouchableOpacity>
              </View>
              <Collapsible
                collapsed={openHoursSelectCollapsed}
                duration={500}
                collapsedHeight={0}
              >
                <ScrollView style={{ maxHeight: 250 }}>
                  <RenderOpenHoursOptions
                    openHours={openHours}
                    changeSelectedOpenHours={changeSelectedOpenHours}
                  />
                </ScrollView>
              </Collapsible>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Outside</Text>
                <View style={ModalStyle.touchableSelect}>
                  <Switch
                    onValueChange={isOutsideSwitch}
                    value={isOutside}
                    // trackColor={{ true: Colors.lightBlue }}
                  />
                </View>
              </View>
            </View>

            <View style={Buttons.container}>
              <TouchableOpacity
                style={[Buttons.big, Buttons.green]}
                onPress={() => this.createNewGroup()}
              >
                <Text style={Buttons.text}>Create New Group</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

export default withGlobalContext(NewGroupModal);
