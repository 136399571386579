import React from 'react';
import {
  Modal,
  Platform,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';

import { Calendar } from 'react-native-calendars';
import Colors from '../../../constants/Colors';
import { format } from 'date-fns';

const Arrow = ({ direction }) => {
  let name = 'chevron-forward';
  if (direction === 'left') {
    name = 'chevron-back';
  }
  return (
    <View
      style={{
        height: 45,
        width: 45,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.lightererer,
          justifyContent: 'center',
          alignItems: 'center',
          height: 36,
          width: 36,
          borderRadius: 8,
        }}
      >
        <Ionicons name={name} size={20} color={'rgb(24,57,100)'} />
      </View>
    </View>
  );
};

const CalendarModal = ({
  isVisible,
  onBackDropPress,
  selectedDate,
  changeSelectedDate,
  size,
}) => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const dim = useWindowDimensions();

  const mark = {};
  const dayWidth = dim.height < 500 ? 40 : 44;
  const dayMarginVertical = dim.height < 500 ? 0 : 0;
  const dayMarginHorizontal = 20;
  const calendarWidth = dayWidth * 7 + dayMarginHorizontal * 6;
  const calendarHeight = (dayWidth + 2 * dayMarginVertical) * 6 + 105;
  mark[selectedDate] = { selected: true };
  mark[today] = {
    selected: today === selectedDate,
    marked: true,
    dotColor: today === selectedDate ? 'white' : 'red',
  };

  return (
    <Modal
      visible={isVisible}
      animationType={'fade'}
      transparent={true}
      onRequestClose={() => onBackDropPress()}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
      // isVisible={isVisible}
      // backdropOpacity={0.5}
      // animationIn="fadeIn"
      // animationInTiming={150}
      // animationOut="fadeOut"
      // animationOutTiming={400}
      // onBackdropPress={onBackDropPress}
      // coverScreen
      statusBarTranslucent
      // transparent
      // useNativeDriver={Platform.OS === 'android'}
      // useNativeDriver
      // useNativeDriverForBackdrop
      // deviceHeight={Dimensions.get('screen').height}
      // deviceWidth={Dimensions.get('screen').width}
      // style={{ margin: 0 }}

      // useNativeDriver={Platform.OS === 'android'}
    >
      <TouchableWithoutFeedback onPress={() => onBackDropPress()}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            padding: 12,
          }}
        >
          <View
            style={{
              width: dim.width < calendarWidth ? '100%' : calendarWidth,
              height: calendarHeight,
              backgroundColor: 'white',
              paddingHorizontal: 12,
              borderRadius: 12,
              overflow: 'hidden',
              alignSelf: 'center',
            }}
          >
            <Calendar
              renderArrow={direction => <Arrow direction={direction} />}
              hideArrows={false}
              horizontal
              pagingEnabled
              // calendarWidth={calendarWidth}
              pastScrollRange={12}
              futureScrollRange={12}
              scrollEnabled={Platform.OS !== 'web'}
              calendarHeight={calendarHeight}
              current={selectedDate}
              onDayPress={day => {
                changeSelectedDate(day.dateString);
              }}
              onDayLongPress={day => {
                changeSelectedDate(day.dateString);
              }}
              markedDates={mark}
              monthFormat="MMMM yyyy"
              firstDay={1}
              onPressArrowLeft={substractMonth => substractMonth()}
              onPressArrowRight={addMonth => addMonth()}
              // hideExtraDays
              // enableSwipeMonths
              // markedDates={{
              //   '2021-09-16': {
              //     selected: true,
              //     marked: true,
              //     selectedColor: 'blue',
              //   }
              //     `${selectedDate.format('YYYY-MM-DD')}`: {marked: true},
              //   // '2021-09-18': {marked: true, dotColor: 'red', activeOpacity: 0},
              //   '2021-09-19': {disabled: true, disableTouchEvent: true},
              // }}
              theme={{
                textDayFontSize: 18,
                textMonthFontSize: 18,
                textDayHeaderFontSize: 16,
                textDayStyle: {},
                'stylesheet.calendar.header': {
                  header: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 12,
                    marginBottom: 4,
                    alignItems: 'center',
                  },
                  monthText: {
                    fontSize: 18,
                    color: '#0a3159',
                  },
                  arrow: {
                    padding: 0,
                  },
                  week: {
                    marginTop: 10,
                    marginBottom: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dayHeader: {
                    width: dayWidth,
                    textAlign: 'center',
                    fontSize: 13,
                    fontWeight: Platform.OS === 'ios' ? '600' : '700',
                    textTransform: 'uppercase',
                    color: '#a5aeb9',
                  },
                },
                'stylesheet.calendar.main': {
                  container: {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                  dayContainer: {
                    width: dayWidth,
                    height: dayWidth,
                    marginVertical: dayMarginVertical,
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  emptyDayContainer: {
                    width: dayWidth,
                    height: dayWidth,
                  },
                  week: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                },
                'stylesheet.day.basic': {
                  container: {
                    justifyContent: 'space-between',
                    alignSelf: 'stretch',
                    alignItems: 'flex-start',
                  },
                  base: {
                    width: size === 'small' ? dayWidth - 6 : dayWidth - 3,
                    height: size === 'small' ? dayWidth - 6 : dayWidth - 3,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: Colors.lightererer,
                  },
                  selected: {
                    backgroundColor: Colors.lightBlue,
                    borderRadius: 10,
                  },
                  text: {
                    marginTop: Platform.OS === 'android' ? 4 : 6,
                    color: Colors.dark,
                    fontSize: 18,
                  },
                  selectedText: {
                    fontWeight: '500',
                    color: 'white',
                  },
                  todayText: {
                    color: '#fc3f3f',
                    fontWeight: '500',
                  },
                },
              }}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default CalendarModal;
