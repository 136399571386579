import GLOBAL from '../constants/Globals';

import deviceStorage from './DeviceStorage';

const ReservationServices = {
  async getReservations(jwtToken_deprecated, restaurantId_deprecated, date) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/reservations?restaurant_id=${restaurantId}&date=${date}`;
    const auth = `Bearer ${jwtToken}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      this.setState({
        reservations: responseJson,
        loadingReservations: false,
      });
    } catch (error) {
      this.setState({
        reservations: null,
        loadingReservations: false,
      });
      console.log(error);
    }
  },

  async getUpcomingReservations() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/reservations/upcoming?restaurant_id=${restaurantId}`;
    const auth = `Bearer ${jwtToken}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return false;
      console.log(error);
    }
  },

  async getReservationLogs() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/logs?restaurant_id=${restaurantId}&list_unseen_only`;
    const auth = `Bearer ${jwtToken}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      throw error;
      // console.log(error);
    }
  },

  async getReservationLastLog() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/logs/last?restaurant_id=${restaurantId}&list_unseen_only`;
    const auth = `Bearer ${jwtToken}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        throw Error('error');
        // throw Error(response.statusText);
      }
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      throw error;
      // console.log(error);
    }
  },

  async markLogsAsSeen() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/logs/mark_all_as_seen`;
    const auth = `Bearer ${jwtToken}`;
    const collection = { restaurant_id: parseInt(restaurantId, 10) };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
        timeout: 5,
      });
      if (response.ok) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getReservations2(jwtToken, restaurantId, date) {
    const url = `${GLOBAL.API_URL}/reservations?restaurant_id=${restaurantId}&date=${date}`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        throw new Error('Error');
      }
      const responseJson = await response.json();
      responseJson.ok = response.ok;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async getAvailableTables(jwtToken_deprecated, data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = `${
      GLOBAL.API_URL
    }/reservations/available_tables?restaurant_id=${
      data.restaurantId
    }&date_from=${encodeURIComponent(
      data.date_from,
    )}&date_to=${encodeURIComponent(data.date_to)}&persons=${encodeURIComponent(
      data.persons,
    )}`;
    const auth = `Bearer ${jwtToken}`;
    // console.log(data, url);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (!response.ok) {
        const responseJson = await response.json();
        console.log(responseJson);
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      console.log('done');
      this.setState({
        availableTables: responseJson,
        loadingAvailableTables: false,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async submitReservation(jwtToken_deprecated, data, walkIn = false) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url = `${GLOBAL.API_URL}/reservations`;
    if (walkIn) {
      url = `${GLOBAL.API_URL}/reservations/walk_in`;
    }
    const auth = `Bearer ${jwtToken}`;
    const collection = data;
    console.log(JSON.stringify(collection));

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
        timeout: 5,
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      const responseJson = await response.json();
      console.log(responseJson);
      responseCollection.message = responseJson.message;
      return responseCollection;
    } catch (error) {
      console.log(error);
    }
  },

  async patchReservations(reservations) {
    const jwtToken = await deviceStorage.getJWT();

    const url = `${GLOBAL.API_URL}/reservations`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(reservations),
        timeout: 5,
      });
      if (response.ok) {
        return true;
      }
      const responseJson = await response.json();
      throw Error(responseJson);
    } catch (error) {
      throw Error(error);
    }
  },

  async cancelReservation(reservationId) {
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/reservations/${reservationId}`;
    const auth = `Bearer ${jwtToken}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async putReservationRequest(reservationId, collection) {
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/reservations/${reservationId}/request`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
        timeout: 5,
      });
      const responseJson = await response.json();
      console.log(responseJson);

      if (response.ok) {
        return true;
      }
      // throw Error(responseJson);
    } catch (error) {
      throw Error(error);
    }
  },
  async acceptReservationRequest(reservationId) {
    const collection = { confirmed: true, rejected: false };
    return this.putReservationRequest(reservationId, collection);
  },
  async rejectReservationRequest(reservationId, reasonMessage = '') {
    const collection = {
      confirmed: false,
      rejected: true,
      rejection_reason: reasonMessage,
    };
    return this.putReservationRequest(reservationId, collection);
  },
};

export default ReservationServices;
