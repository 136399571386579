import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import SettingsServices from '../services/SettingsServices';

export default function ImageUpload({
  defaultUrl,
  circle,
  size,
  showEdit,
  picker = true,
  onSuccessfulUpload = () => {},
}) {
  const [imageUrl, setImageUrl] = useState(undefined);
  const [libraryPermissions, setLibraryPermissions] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const {
          status,
        } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          // alert('Sorry, we need camera roll permissions to make this work!');
        }
      } else {
      }
    })();
  }, []);

  useEffect(() => {
    if (defaultUrl !== undefined) {
      setImageUrl(defaultUrl);
    }
  }, [defaultUrl]);

  const pickImage = () => {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 1,
    }).then(result => {
      if (result && !result.cancelled) {
        SettingsServices.photoUpload(result).then(res => {
          if (res) {
            onSuccessfulUpload(res);
          }
        });
      }
    });
  };

  return (
    <View style={styles.imageRow}>
      <TouchableOpacity
        onPress={pickImage}
        disabled={!picker}
        style={{
          height: size !== undefined ? size : 80,
          width: size !== undefined ? size : 80,
          borderRadius: circle === true ? 150 : 4,
          overflow: 'hidden',
        }}
      >
        {imageUrl ? (
          <Image style={styles.miniImage} source={{ uri: imageUrl }} />
        ) : (
          <View style={[styles.miniImage, styles.addImage]}>
            <Ionicons size={28} name="camera" color={'#888'} />
          </View>
        )}
      </TouchableOpacity>
      {showEdit === true && imageUrl !== null && (
        <View
          pointerEvents={'none'}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: circle === true ? 120 : 4,
            height: size !== undefined ? size : 80,
            width: size !== undefined ? size : 80,
            flexDirection: 'column-reverse',
            overflow: 'hidden',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'rgba(0,0,0,0.3)',
              width: size !== undefined ? size : 80,
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: 'white',
                paddingBottom: 4,
                paddingTop: 2,
                fontSize: 12,
                fontWeight: '500',
              }}
            >
              Remove
            </Text>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  miniImage: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  imageRow: {
    margin: 4,
    // overflow: 'hidden',
  },
  addImage: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
