import React, { createContext, useContext, useEffect, useState } from 'react';
import ReservationServices from '../services/ReservationsServices';
import { GlobalContext } from '../GlobalContext';
import {
  Reservation,
  transformReservations,
} from '../transofrmations/reservationsTransformer';
import useInterval from '../hooks/useInterval';
import {
  Log,
  transformLog,
  transformLogs,
} from '../transofrmations/logsTransformer';

export type ActivityContextState = {
  upcomingReservations: Reservation[];
  logs: Log[];
  unseenNewReservations: number;
  numberOfPendingReservations: number;
  // lastLogTimestamp: string;
  refreshing: boolean;
  updatedDate: Date;
  refreshUpcomingReservationsAsync: () => Promise<boolean>;
  refreshUpcomingReservations: () => void;
  markAllLogsAsSeen: () => void;
};

const contextDefaultValues: ActivityContextState = {
  upcomingReservations: [],
  unseenNewReservations: 0,
  numberOfPendingReservations: 0,
  logs: [],
  // lastLogTimestamp: '',
  refreshing: false,
  updatedDate: new Date(),
  refreshUpcomingReservationsAsync: () => new Promise(() => true),
  refreshUpcomingReservations: () => {},
  markAllLogsAsSeen: () => {},
};

export const ActivityContext =
  createContext<ActivityContextState>(contextDefaultValues);

const ActivityProvider: React.FC = ({ children }) => {
  const [logsRaw, setLogsRaw] = useState<[]>([]);
  const [lastLogTimestamp, setLastLogTimestamp] = useState(
    contextDefaultValues.lastLogTimestamp,
  );
  const [unseenNewReservations, setUnseenNewReservations] = useState<number>(
    contextDefaultValues.unseenNewReservations,
  );
  const [numberOfPendingReservations, setNumberOfPendingReservations] =
    useState<number>(contextDefaultValues.unseenNewReservations);
  const [logs, setLogs] = useState<Log[]>([]);
  const [lastLog, setLastLog] = useState<Log | undefined>();
  const [refreshing, setRefreshing] = useState(contextDefaultValues.refreshing);
  const [updatedDate, setUpdatedDate] = useState(
    contextDefaultValues.updatedDate,
  );
  const [upcomingReservations, setUpcomingReservations] = useState<
    Reservation[]
  >(contextDefaultValues.upcomingReservations);

  const global = useContext(GlobalContext);
  const { jwtToken, restaurantId } = global;

  const refreshUpcomingReservationsAsync = async () => {
    if (jwtToken && restaurantId) {
      // setRefreshing(true);
      try {
        ReservationServices.getUpcomingReservations().then((res) => {
          if (res) {
            setUpdatedDate(new Date());
            const transformed = transformReservations(res);
            setUpcomingReservations(transformed);
            // setUpcomingReservationsRaw(res);
            return new Promise<boolean>((resolve, reject) => {
              return resolve(true);
            });
          }
        });
      } catch (error) {
        return new Promise<boolean>((resolve, reject) => {
          return reject('not ok');
        });
      }
      // setRefreshing(false);
    } else {
      return new Promise<boolean>((resolve, reject) => {
        return reject('not ok');
      });
    }
  };

  const refreshUpcomingReservations = () => {
    refreshUpcomingReservationsAsync()
      .then()
      .catch(() => null);
  };

  const refreshLogs = () => {
    if (jwtToken && restaurantId) {
      ReservationServices.getReservationLogs()
        .then((res) => {
          if (res) {
            setLogsRaw(res);
          } else {
            console.log('error');
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const getLastLog = () => {
    if (jwtToken && restaurantId) {
      try {
        ReservationServices.getReservationLastLog().then((res) => {
          if (res) {
            const transformedLog = transformLog(res);
            setLastLog(transformedLog);
          } else {
            console.log('error');
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (lastLog && lastLogTimestamp !== lastLog.timestampString) {
      setLastLogTimestamp(lastLog.timestampString);
    }
  }, [lastLog]);

  const markAllLogsAsSeen = () => {
    if (jwtToken && restaurantId) {
      try {
        ReservationServices.markLogsAsSeen().then((res) => {
          if (res) refreshLogs();
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    refreshUpcomingReservations();
    refreshLogs();
  }, [lastLogTimestamp]);

  useEffect(() => {
    const unseenCount = logs.filter((c) => {
      return c.actionType === 'create' && c.actionBy === 'guest' && !c.seen;
    }).length;
    setUnseenNewReservations(unseenCount);
  }, [logs]);

  useEffect(() => {
    const pendingReservationsCount = upcomingReservations.filter(
      (c: Reservation) => c.pendingConfirmation,
    ).length;
    setNumberOfPendingReservations(pendingReservationsCount);
  }, [upcomingReservations]);

  useEffect(() => {
    setLogs(transformLogs(logsRaw));
  }, [logsRaw]);

  useEffect(() => {
    refreshUpcomingReservations();
    refreshLogs();
  }, [jwtToken, restaurantId]);

  useInterval(() => {
    getLastLog();
    // refreshUpcomingReservations();
  }, 1 * 1000);

  const value = React.useMemo(
    () => ({
      upcomingReservations,
      unseenNewReservations,
      logs,
      refreshing,
      updatedDate,
      numberOfPendingReservations,
      refreshUpcomingReservations,
      refreshUpcomingReservationsAsync,
      markAllLogsAsSeen,
    }),
    [
      upcomingReservations,
      unseenNewReservations,
      numberOfPendingReservations,
      logs,
      updatedDate,
      refreshing,
    ],
  );

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;

export const withActivityContext = (ChildComponent) => (props) =>
  (
    <ActivityContext.Consumer>
      {(context) => <ChildComponent {...props} activityContext={context} />}
    </ActivityContext.Consumer>
  );
