import { Reservation } from './reservationsTransformer';

export interface Room {
  id?: number;
  name?: string;
  floor?: number;
  outside?: boolean;
  dimensions?: RoomDimensions;
  tables?: TableInRoom[];
}

export interface Chairs {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  count?: number;
}

export interface RoomDimensions {
  width?: number;
  height?: number;
}

export interface TableDimensions {
  length?: number;
  width?: number;
  round?: boolean;
}

export interface TablePosition {
  x?: number;
  y?: number;
  angle?: number;
}

export interface TableStatus {
  occupied?: boolean;
  active?: boolean;
  activeReservation?: Reservation;
  remainingDurationPercent?: number;
  nextReservationTime?: string;
  nextReservation?: Reservation;
}

export interface TableInRoom {
  id?: number;
  label?: string;
  chairs?: Chairs;
  dimensions?: TableDimensions;
  position?: TablePosition;
  tableStatus?: TableStatus;
}

export const transformTableInRoom = (roomTableApiData: any): TableInRoom => {
  const label = `${roomTableApiData.group_prefix || ''}${
    roomTableApiData.table_number
  }`;
  return {
    id: roomTableApiData.table_id,
    label: label,
    chairs: roomTableApiData.chairs,
    dimensions: transformTableDimensions(roomTableApiData.dimensions),
    position: transformTablePosition(roomTableApiData.position),
  };
};

export const transformRoomDimensions = (
  roomDimensionsApiData: any,
): RoomDimensions => {
  return {
    width: roomDimensionsApiData.width,
    height: roomDimensionsApiData.height,
  };
};

export const transformTableDimensions = (
  tableDimensionsApiData: any,
): TableDimensions => {
  return {
    length: tableDimensionsApiData.length,
    width: tableDimensionsApiData.width,
    round: tableDimensionsApiData.round,
  };
};

export const transformTableChairs = (tableChairsApiData: any): Chairs => {
  return {
    top: tableChairsApiData.top,
    bottom: tableChairsApiData.bottom,
    left: tableChairsApiData.left,
    right: tableChairsApiData.right,
    count: tableChairsApiData.count,
  };
};

export const transformTablePosition = (
  tablePositionApiData: any,
): TablePosition => {
  return {
    x: tablePositionApiData.x,
    y: tablePositionApiData.y,
    angle: tablePositionApiData.angle,
  };
};

export const transformTablesInRoom = (roomTables: any): Room[] => {
  if (roomTables) {
    return roomTables.map((roomTable: any) => {
      return transformTableInRoom(roomTable);
    });
  }
  return [];
};

export const transformRooms = (rooms: any): Room[] => {
  if (rooms) {
    return rooms.map((room: any) => {
      return transformRoom(room);
    });
  }
  return [];
};

export const transformRoom = (roomApiData: any): Room => {
  return {
    id: roomApiData.id,
    floor: roomApiData.floor,
    name: roomApiData.name,
    outside: roomApiData.outside,
    tables: transformTablesInRoom(roomApiData.tables),
    dimensions: transformRoomDimensions(roomApiData.dimensions),
  };
};
