import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import LayoutServices from '../../../services/LayoutServices';
import {
  DimensionsInput,
  FormButtom,
  FormButtonsGroup,
  FormButtonsType,
  FormRowSwitch,
  RowInput,
  RowInputStepper,
} from '../../../components/Form';

const NewSpaceModal = props => {
  const { visible, close } = props;
  const global = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(8);
  const [isOutside, setIsOutside] = useState(false);
  const [floor, setFloor] = useState(0);

  const floorLabels = ['Ground', '1st', '2nd', '3rd'];
  for (let i = 4; i < 100; i += 1) {
    floorLabels[i] = `${i}th`;
  }

  const createRoom = () => {
    const { updateRooms, showSnackbarMessage, showAlert } = global;
    const widthInCm = Math.floor(width * 100);
    const heightInCm = Math.floor(height * 100);
    const data = {
      name,
      dimensions: { width: widthInCm, height: heightInCm },
      outside: isOutside,
      floor,
    };

    LayoutServices.addNewRoom(data).then(result => {
      if (result.ok) {
        showSnackbarMessage(`Space ${name} was created.`);
        updateRooms();
        close();
      } else {
        showAlert(`${result.message}`, 'error');
      }
    });
  };

  return (
    <ModalWrapper
      onClose={close}
      title="New Space"
      isVisible={visible}
      backgroundColor={'#f6f7f9'}
    >
      <RowInput
        label={'Name'}
        value={name}
        onChangeText={value => setName(value)}
        placeholder="Name new space"
        small
      />
      <DimensionsInput
        labelFirst={'Length'}
        labelSecond={'Width'}
        valueFirst={width}
        valueSecond={height}
        onChangeValueFirst={value => setWidth(value)}
        onChangeValueSecond={value => setHeight(value)}
        float
        unit="m"
      />
      <RowInputStepper
        value={floor}
        maxValue={50}
        onValueUpdate={setFloor}
        label={'Floor'}
        labelArray={floorLabels}
        small
      />
      <FormRowSwitch
        label={'Outside'}
        onValueChange={setIsOutside}
        value={isOutside}
      />

      <FormButtonsGroup marginTop={12} marginBottom={20}>
        <FormButtom
          disabled={!name || !width || !height}
          onPress={() => createRoom()}
          label={'Create New Space'}
          type={FormButtonsType.CREATE}
        />
      </FormButtonsGroup>
    </ModalWrapper>
  );
};
export default NewSpaceModal;
