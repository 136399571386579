import { Platform } from 'react-native';
import { decode as atob } from 'base-64';
import GLOBAL from '../constants/Globals';

import deviceStorage from './DeviceStorage';

function DataURIToBlob(dataurl: any) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const SettingsServices = {
  async getInfo(jwtToken, restaurantId) {
    const url = `${GLOBAL.API_URL}/settings/info?restaurant_id=${restaurantId}`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      const responseJson = await response.json();
      responseJson.ok = response.ok;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async updateInfo(data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/settings/info`;
    const auth = `Bearer ${jwtToken}`;

    const collection = {};
    collection.restaurant_id = parseInt(restaurantId);

    collection.name = data.restaurantName;
    collection.company = data.restaurantCompany;
    collection.description = data.restaurantDescription;

    collection.website_url = data.restaurantUrl;
    collection.phone = data.phoneNumber;

    collection.address = data.address;

    console.log(collection);
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async getReservationsSettings(jwtToken, restaurantId) {
    const url = `${GLOBAL.API_URL}/settings/reservations?restaurant_id=${restaurantId}`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      const responseJson = await response.json();
      responseJson.ok = response.ok;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async updateReservationsSettings(data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/settings/reservations`;
    const auth = `Bearer ${jwtToken}`;

    const collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.accept_reservations = data.acceptReservations;
    collection.min_minutes_before = data.minMinutesBefore;
    collection.max_days_in_advance = data.daysInAdvance;
    collection.default_duration_minutes = data.reservationDuration;
    collection.gap_minutes = data.gap;

    collection.reservation_policy = data.reservationPolicy;
    collection.availability_check_party_size_limit =
      data.availabilityCheckPartySizeLimit;
    collection.larger_party_size_message = data.largerPartySizeMessage;

    collection.require_confirmation = data.requireConfirmation;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        console.log(responseJson);
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
  async getPhotos(jwtToken, restaurantId) {
    const url = `${GLOBAL.API_URL}/settings/photos?restaurant_id=${restaurantId}`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  },

  async photoUpload(photo) {
    const jwtToken = await deviceStorage.getJWT();
    const auth = `Bearer ${jwtToken}`;
    const url = `${GLOBAL.API_URL}/settings/photos/upload`;
    const data = new FormData();

    if (Platform.OS === 'web') {
      const file = DataURIToBlob(photo.uri);
      data.append('image', file, 'xxx');
    } else {
      const fileType = photo.uri.substring(photo.uri.lastIndexOf('.') + 1);
      data.append('image', {
        uri: photo.uri,
        name: `photo.${fileType}`,
        type: `image/${fileType}`,
      });
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.image_name;
      }
      return null;
    } catch (error) {
      throw Error(error);
    }
  },
  async addPhoto(name) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/settings/photos`;
    const auth = `Bearer ${jwtToken}`;

    const collection = {
      image_name: name,
      restaurant_id: parseInt(restaurantId, 10),
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
  async removePhoto(photoId) {
    const jwtToken = await deviceStorage.getJWT();
    const url = `${GLOBAL.API_URL}/settings/photos/${photoId}`;
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        console.log(responseJson);
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
};

export default SettingsServices;
