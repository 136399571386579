import { StyleSheet, Dimensions, Platform } from 'react-native';
import Colors from '../constants/Colors';

const GLOBAL = require('../constants/Globals');

const TablesViewStyle = StyleSheet.create({
  rowContainer: {
    height: 55,
    marginVertical: 6,
    paddingLeft: 20,
    // marginHorizontal: 10,
    backgroundColor: 'white',
    borderRadius: 6,
    borderColor: Colors.light,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableRowContainer: {
    shadowOpacity: 0.25,
    shadowColor: Platform.OS === 'web' ? '#8b96a2' : '#b4bcc5',
    elevation: 2,

    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 4,
    borderRadius: 14,
    paddingHorizontal: 20,
    // marginRight: 12,
    marginBottom: 8,
    height: 50,
    // paddingHorizontal: 6,
    backgroundColor: '#ffffff',
    // borderBottomWidth: 0.5,
    // borderColor: Colors.lighter,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pureContainer: {
    // height: 55,
    margin: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  groupRowContainer: {
    // flex: 1,
    // minHeight: 55,
    // flexDirection: "row",
    margin: 6,
    paddingHorizontal: 5,
    paddingVertical: 4,
    // marginHorizontal: 20,
    backgroundColor: 'white',
    // backgroundColor: 'yellow',
    borderRadius: 8,
    borderColor: Colors.light,
    justifyContent: 'center',
  },
  openHoursContainer: {
    width: 500,
    maxWidth: '95%',
    // height: null,
    margin: 6,
    padding: 20,
    paddingTop: 5,
    // backgroundColor: 'red',
    backgroundColor: 'white',
    borderRadius: 6,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  openHoursHeader: {
    // flex: 1,
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.lighterer,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 0,
    alignItems: 'center',
    backgroundColor: null,
  },
  column: {
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    color: Colors.dark,
  },
  columnFirst: {
    // justifyContent: 'flex-start',
    flex: 0.6,
    // width: 40,
    // backgroundColor: Colors.lighterer,
    // padding: 8,
    // borderRadius: 4,
  },
  columnLast: {
    flex: null,
    width: 50,
    // justifyContent: 'center',
    justifyContent: 'flex-end',
  },
  rowText: {
    fontSize: 14,
    color: Colors.light,
    fontWeight: '700',
  },
  rowTextDark: {
    fontSize: 14,
    color: Colors.lighter2,
    fontWeight: '600',
    // textTransform: 'uppercase',
  },
  rowTextLight: {
    fontSize: 14,
    color: Colors.lighter2,
    fontWeight: '600',
    // textTransform: 'uppercase',
  },
  openHoursRowText: {
    fontSize: 18,
    color: Colors.darker,
  },
  bold: {
    fontWeight: '500',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.lightest,
    // justifyContent: "center",
    alignItems: 'center',
  },
  innerContainer: {
    // flexBasis: 800,
    flex: 1,
    // flexGrow: 1,
    // flexBasis: 800,
    // flexDirection: 'row',
    // backgroundColor:"red",
    // paddingHorizontal: '3%',
  },
  innerContainerRooms: {
    // width: 1000,
    // maxWidth: '80%',
    // flexBasis: 300,
    // width: 500,
    // backgroundColor:"red",
    // flexWrap:'wrap',
    // height: 300,
    // flexGrow: 1,
    // flex:1
    // flexShrink: 0.3,
    // flexGrow: 1,
    // width: '100%',
    // paddingHorizontal: '3%',
  },
  innerContainerModels: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 12,
  },
  hoursInnerContainer: {
    // width: 700,
    flex: 1,
  },
  topBarContainer: {
    // height: 96,
    // marginTop: 30,
    width: '100%',
    justifyContent: 'center',
    // alignItems:"center",
    // paddingVertical: 15,
    position: 'absolute',
    zIndex: 10,
    // backgroundColor:"blue"
  },
  menuBarContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor:"red",
    marginHorizontal: 30,
  },
  subHeader: {
    paddingBottom: 12,
    // paddingLeft: 8,
    height: 52,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'flex-end',
    alignItems: 'center',
    // marginBottom: 15,
  },
  subHeaderTitleFont: { fontSize: 19, fontWeight: '500' },
  subHeaderSubtitleFont: { fontSize: 17, fontWeight: '500' },
  moduleContainer: {
    backgroundColor: Colors.lightBackground,
    borderRadius: 12,
    padding: 8,
    paddingHorizontal: 12,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Colors.lightererer,
    shadowOpacity: 0.0,
    shadowRadius: 2,
    shadowOffset: {
      height: 2,
      width: 2,
    },
  },
  moduleRow: { flexDirection: 'row', justifyContent: 'space-between' },
  moduleTitleBold: { color: Colors.dark, fontSize: 12, fontWeight: '600' },
  moduleSubTitle: {
    color: Colors.light,
    fontSize: 12,
    marginBottom: 5,
    paddingLeft: 0,
    fontWeight: '400',
  },
  moduleMainInfoFont: {
    textAlign: 'right',
    fontSize: 20,
    fontWeight: '500',
    color: Colors.dark,
  },
  moduleButtonCenter: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
    color: Colors.darker,
  },
  openHoursDayContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 6,
    paddingHorizontal: 0,
  },
  openHoursDayContainerSelected: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    backgroundColor: Colors.lighterer,
    borderRadius: 6,
    marginHorizontal: -8,
    paddingHorizontal: 8,
  },
  editButton: {
    // backgroundColor: Colors.lightBlue,
    paddingHorizontal: 16,
    // paddingVertical: 6,
    height: 32,
    borderRadius: 10,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    shadowOpacity: 0.25,
    shadowOffset: {
      height: 1,
      width: -1,
    },
    shadowRadius: 3,
    elevation: 4,
    shadowColor: '#455d79',
    // borderWidth: 1,
    // borderColor: Colors.darkBlue,
    borderColor: 'white',
    // backgroundColor: 'white',
    backgroundColor: Colors.lightBlue,
  },
  editButtonFont: { fontWeight: '500', fontSize: 13, color: Colors.white },
});

export default TablesViewStyle;
