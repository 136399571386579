import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import {
  PanGestureHandler,
  State,
  TapGestureHandler,
} from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import AreYouSureWrapper from './AreYouSureWrapper';
import { ReservationTypes } from '../transofrmations/reservationsTransformer';

const expandedWidth = 12 + 72 * 2;

const MenuItem = ({ text, icon, onPress, requireConfirmation }: any) => {
  const opacity = useSharedValue('rgba(0, 0, 0, 0.30)');

  const animatePressIn = () => {
    opacity.value = withTiming('rgba(0, 0, 0, 0.60)', { duration: 300 });
  };

  const animatePressOut = () => {
    opacity.value = withDelay(
      1000,
      withTiming('rgba(0, 0, 0, 0.30)', { duration: 500 }),
    );
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: opacity.value,
    };
  });

  return (
    <AreYouSureWrapper
      style={{
        flex: 1,
      }}
      onConfirm={onPress}
      title={'Cancel reservation'}
      confirmText={'Yes'}
      cancelText={'No'}
      message={'Do you want to cancel this reservation?'}
      disabled={!requireConfirmation}
    >
      <TapGestureHandler
        onHandlerStateChange={({ nativeEvent }) => {
          if (nativeEvent.state === State.BEGAN) {
            animatePressIn();
          }
          if (
            nativeEvent.state === State.END ||
            nativeEvent.state === State.FAILED
          ) {
            animatePressOut();
          }
          if (nativeEvent.state === State.ACTIVE && !requireConfirmation) {
            onPress();
          }
        }}
      >
        <Animated.View
          style={[
            {
              marginHorizontal: 4,
              alignItems: 'center',
              justifyContent: text ? 'flex-end' : 'center',
              paddingBottom: 6,
              paddingTop: 8,
              borderRadius: 3,
              height: 50,
            },
            animatedStyle,
          ]}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: '600',
            }}
          >
            {icon}
          </Text>
          {text && (
            <Text
              style={{
                marginTop: 4,
                fontSize: 11,
                color: 'white',
                fontWeight: '600',
              }}
            >
              {text}
            </Text>
          )}
        </Animated.View>
      </TapGestureHandler>
    </AreYouSureWrapper>
  );
};

interface SwipeItemMenuInterface {
  type?: ReservationTypes;
  children: any;
  // onPressEdit?: () => void;
  onActiveCallback?: () => void;
  onPressPrimary?: () => void;
  onPressMove?: () => void;
  onPressCancel?: () => void;
  onPressDetails?: () => void;
  itemId?: string;
  activeItemId?: string;
}

const SwipeItemMenu: React.FC<SwipeItemMenuInterface> = ({
  type,
  children,
  itemId,
  activeItemId,
  onActiveCallback = () => null,
  // onPressEdit = () => null,
  // onPressMove = () => null,
  onPressCancel = () => null,
  onPressPrimary = () => null,
  onPressDetails = () => null,
}) => {
  const x = useSharedValue(0);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = x.value;
    },
    onActive: (event, ctx) => {
      x.value = ctx.start + event.translationX;
    },
    onEnd: (event, ctx) => {
      let newValue = 0;
      if (event.translationX > 30) {
        newValue = expandedWidth;
      }

      x.value = withSpring(newValue, {
        damping: 30,
        stiffness: 300,
      });
    },
  });

  useEffect(() => {
    if (activeItemId !== itemId && x.value > 30) {
      x.value = withSpring(0, {
        damping: 30,
        stiffness: 60,
      });
    }
  }, [activeItemId]);

  const onGestureHandlerStateChange = ({ nativeEvent }) => {
    if (nativeEvent.state === State.BEGAN) {
      onActiveCallback();
    }
    // console.log('end', x.value);
  };

  const animatedStyleMenuContainer = useAnimatedStyle(() => {
    const width = interpolate(
      x.value,
      [expandedWidth - 30, expandedWidth, expandedWidth + 30],
      [expandedWidth - 18, expandedWidth, expandedWidth + 30],
      {
        extrapolateLeft: Extrapolate.CLAMP,
        extrapolateRight: Extrapolate.EXTEND,
      },
    );
    return {
      width,
    };
  });

  const animatedStyleContainer = useAnimatedStyle(() => {
    const shadowOpacity = interpolate(
      x.value,
      [5, 50],
      [0, 1],
      Extrapolate.CLAMP,
    );

    const translateX = interpolate(
      x.value,
      [-200, 0, expandedWidth],
      [-8, 5, expandedWidth + 5],
      {
        extrapolateLeft: Extrapolate.CLAMP,
        extrapolateRight: Extrapolate.EXTEND,
      },
    );
    return {
      shadowOpacity,
      transform: [
        {
          translateX,
        },
      ],
    };
  });

  const onItemPress = callback => {
    callback();
    x.value = withDelay(
      0,
      withSequence(
        withTiming(expandedWidth + 12, { duration: 300 }),
        withTiming(0, {
          duration: 500,
          easing: Easing.bezier(0.76, 0, 0.24, 1),
        }),
      ),
    );
  };

  const childrenTapHandleStateChange = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      onChildrenTap();
    } else if (nativeEvent.state === State.BEGAN) {
      onChildrenPressIn();
    } else if (
      nativeEvent.state === State.END ||
      nativeEvent.state === State.FAILED
    ) {
      if (x.value < 30) {
        x.value = withTiming(0, {
          duration: 60,
        });
      }
    }
  };

  const onChildrenTap = () => {
    if (x.value >= 30) {
      x.value = withTiming(0, {
        duration: 300,
      });
    } else {
      onPressPrimary();
    }
  };

  const onChildrenPressIn = () => {
    if (x.value < 30) {
      x.value = withTiming(5, {
        duration: 120,
      });
    }
  };

  return (
    <PanGestureHandler
      onGestureEvent={gestureHandler}
      onHandlerStateChange={onGestureHandlerStateChange}
      activeOffsetX={[-10, 10]}
    >
      <Animated.View
        style={[
          styles.container,
          type === ReservationTypes.MULTI_TABLE && styles.itemMultiTable,
          type === ReservationTypes.CANCELLED && styles.itemCancelled,
          type === ReservationTypes.CREATED_BY_RESTAURANT &&
            styles.itemByRestaurant,
          type === ReservationTypes.PENDING && styles.itemPending,
          type === ReservationTypes.WALK_IN && styles.itemWalkIn,
          type === ReservationTypes.ONLINE && styles.itemOnline,
        ]}
      >
        <View
          style={{
            backgroundColor: '#ebecf3',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: 8,
          }}
        />
        <Animated.View style={[styles.menuContainer]}>
          <Animated.View
            style={[styles.menuInnerContainer, animatedStyleMenuContainer]}
          >
            <MenuItem
              text={'Details'}
              icon={<Ionicons name={'book'} size={17} />}
              onPress={() => onItemPress(() => setTimeout(onPressDetails, 500))}
            />
            {/*<MenuItem*/}
            {/*  text={'Edit'}*/}
            {/*  icon={<Ionicons name={'pencil'} size={17} />}*/}
            {/*  onPress={() => onItemPress(onPressEdit)}*/}
            {/*/>*/}
            {/*<MenuItem*/}
            {/*  text={'Move'}*/}
            {/*  icon={<Ionicons name={'move'} size={17} />}*/}
            {/*  onPress={() => onItemPress(onPressMove)}*/}
            {/*/>*/}
            <MenuItem
              text={'Cancel'}
              icon={<Ionicons name={'trash-bin'} size={17} />}
              onPress={() => onItemPress(onPressCancel)}
              requireConfirmation
            />
          </Animated.View>
        </Animated.View>
        <Animated.View
          style={[styles.childrenContainer, animatedStyleContainer]}
        >
          <TapGestureHandler
            onHandlerStateChange={childrenTapHandleStateChange}
          >
            <Animated.View>{children}</Animated.View>
          </TapGestureHandler>
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
};

export default SwipeItemMenu;
const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: '#ebecf3',
    width: '100%',
  },
  childrenContainer: {
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    overflow: 'visible',
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
  },
  menuInnerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
    paddingLeft: 5 + 6,
    paddingRight: 0 + 6,
  },
  itemByRestaurant: {
    backgroundColor: 'rgba(86,196,95,0.8)',
  },
  itemOnline: {
    backgroundColor: 'rgba(243,84,84,0.8)',
  },
  itemPending: {
    backgroundColor: 'rgb(255,180,118)',
  },
  itemWalkIn: {
    backgroundColor: 'rgba(248,155,218,0.9)',
    // backgroundColor: 'rgba(109,196,193,0.8)',
  },
  itemCancelled: {
    backgroundColor: '#c3c8cb',
  },
  itemMultiTable: {
    backgroundColor: 'rgba(82,155,229,0.7)',
  },
});
