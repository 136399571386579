import { getHours, parse } from 'date-fns';

export interface OpenHoursDays {
  mon: OpenHoursDay;
  tue: OpenHoursDay;
  wed: OpenHoursDay;
  thu: OpenHoursDay;
  fri: OpenHoursDay;
  sat: OpenHoursDay;
  sun: OpenHoursDay;
}

export interface OpenHoursDay {
  openTime: string;
  openTimeHolidays: string;
  closeTime: string;
  closeTimeHolidays: string;
  closed: boolean;
  closedHolidays: boolean;
  firstHour: number;
  lastHour: number;
}

export interface OpenHours {
  id: number;
  name: string;
  days?: OpenHoursDays;
}

export const transformDays = (daysApiData: any): OpenHoursDays => {
  return {
    mon: transformDay(daysApiData.mon),
    tue: transformDay(daysApiData.tue),
    wed: transformDay(daysApiData.wed),
    thu: transformDay(daysApiData.thu),
    fri: transformDay(daysApiData.fri),
    sat: transformDay(daysApiData.sat),
    sun: transformDay(daysApiData.sun),
  };
};

export const transformDay = (dayApiData: any): OpenHoursDay => {
  const openTime = dayApiData.open_time;
  const closeTime = dayApiData.close_time;
  const closed = dayApiData.closed;
  const firstHour = closed ? 0 : getHours(parse(openTime, 'HH:mm', new Date()));
  const lastHour = closed
    ? 23
    : getHours(parse(closeTime, 'HH:mm', new Date()));
  return {
    openTime: openTime,
    closeTime: closeTime,
    closed: closed,
    openTimeHolidays: dayApiData.open_time_holidays,
    closeTimeHolidays: dayApiData.close_time_holidays,
    closedHolidays: dayApiData.closed_holidays,
    firstHour,
    lastHour,
  };
};

export const transformOpenHoursSet = (openHoursApiData: any): OpenHours => {
  return {
    id: openHoursApiData.id,
    name: openHoursApiData.name,
    days: transformDays(openHoursApiData.days),
  };
};
