import React, { useEffect, useState } from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import Animated, {
  interpolateColor,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { addMinutes, format, getHours } from 'date-fns';
import { DATETIME_FORMAT } from '../contex/DateTimeContext';
import { Ionicons } from '@expo/vector-icons';
import TimeRangeIndicator from './TimeRangeIndicator';

interface TimelineHourSectionProps {
  hour?: number;
  height: number;
  width: number;
  firstTransparent?: boolean;
}

const TimelineHourSection: React.FC<TimelineHourSectionProps> = ({
  hour,
  height,
  width,
  firstTransparent,
}) => {
  return (
    <View
      style={{
        width: width,
        height,
        justifyContent: 'space-between',
      }}
    >
      <View style={styles.timeStringContainer}>
        <Text style={styles.timeString}>
          {hour !== undefined ? `${hour}:00` : ''}
        </Text>
      </View>
      <View style={styles.tickContainer}>
        {firstTransparent && <View style={styles.tickTransparent} />}
        {!firstTransparent && <View style={styles.tickFirst} />}
        <View style={styles.tick} />
        <View style={styles.tick} />
        <View style={styles.tick} />
        <View style={styles.tickTransparent} />
      </View>
    </View>
  );
};

const RangeDurationSlider = ({ height, offset, onChangeCallback }) => {
  const moveGestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = offset.value;
    },
    onActive: (event, ctx) => {
      offset.value = ctx.start + event.translationX;
    },
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: offset.value,
        },
      ],
    };
  });
  return (
    <PanGestureHandler
      onGestureEvent={moveGestureHandler}
      onHandlerStateChange={({ nativeEvent }) => {
        if (nativeEvent.state === State.END) {
          onChangeCallback();
        }
      }}
    >
      <Animated.View
        style={[
          {
            height,
          },
          styles.durationSliderContainer,
          animatedStyle,
        ]}
      >
        <TimeRangeIndicator />
      </Animated.View>
    </PanGestureHandler>
  );
};

const RangeMiddleSlider = ({
  height,
  offsetFrom,
  offsetTo,
  onChangeCallback,
}) => {
  const moveGestureHandler = useAnimatedGestureHandler({
    onStart: (event, ctx) => {
      ctx.startFrom = offsetFrom.value;
      ctx.startTo = offsetTo.value;
      ctx.absoluteStart = event.absoluteX;
    },
    onActive: (event, ctx) => {
      offsetFrom.value = ctx.startFrom + event.absoluteX - ctx.absoluteStart;
      offsetTo.value = ctx.startTo + event.absoluteX - ctx.absoluteStart;
    },
    onEnd: (event, ctx) => {},
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: 1,
      left: 0,
      transform: [
        {
          translateX:
            offsetFrom.value + (offsetTo.value - offsetFrom.value) / 2, // center between from to
        },
        { scaleX: offsetTo.value - offsetFrom.value },
      ],
    };
  }, [offsetFrom]);
  return (
    <PanGestureHandler
      onGestureEvent={moveGestureHandler}
      onHandlerStateChange={({ nativeEvent }) => {
        if (nativeEvent.state === State.END) {
          onChangeCallback();
        }
      }}
    >
      <Animated.View
        style={[
          {
            height,
          },
          styles.middleSection,
          animatedStyle,
        ]}
      />
    </PanGestureHandler>
  );
};

interface RangeComponentProps {
  height: number;
  minuteWidth: number;
  durationIntervalInMinutes: number;
  rangeHours: number;
  minDurationInMinutes: number;
  style: StyleProp<any>;
  onChangeCallback: (
    minutesFromStart: number,
    durationInMinutes: number,
  ) => void;
  hours?: number[];
}

const RangeComponent: React.FC<RangeComponentProps> = ({
  height,
  minuteWidth,
  durationIntervalInMinutes,
  rangeHours,
  minDurationInMinutes,
  style,
  onChangeCallback,
  hours,
}) => {
  const sliderFromOffset = useSharedValue(0);
  const sliderToOffset = useSharedValue(minuteWidth * 120);
  const snapWidth = minuteWidth * durationIntervalInMinutes;

  useEffect(() => {
    sliderFromOffset.value = 0;
    sliderToOffset.value = minuteWidth * 120;
    onChangeCallback(0, 120);
  }, [hours]);

  const onPositionChange = () => {
    const start = adjustToInterval(sliderFromOffset);
    const end = adjustToInterval(
      sliderToOffset,
      start + minDurationInMinutes * minuteWidth,
      rangeHours * minuteWidth * 60,
    );

    const startMinutes = Math.round(start / minuteWidth);
    const endMinutes = Math.round(end / minuteWidth);

    const durationMinutes = endMinutes - startMinutes;
    onChangeCallback(startMinutes, durationMinutes);
  };

  const adjustToInterval = (
    sharedValue: Animated.SharedValue<number>,
    minValue = 0,
    maxValue = Infinity,
  ) => {
    let adjustedValue = Math.round(sharedValue.value / snapWidth) * snapWidth;
    if (adjustedValue < minValue) {
      adjustedValue = minValue;
    } else if (adjustedValue > maxValue) {
      adjustedValue = maxValue;
    }

    sharedValue.value = adjustedValue;
    return adjustedValue;
  };

  return (
    <Animated.View style={[{ position: 'absolute' }, style]}>
      <RangeMiddleSlider
        height={height}
        offsetFrom={sliderFromOffset}
        offsetTo={sliderToOffset}
        onChangeCallback={onPositionChange}
      />
      <RangeDurationSlider
        height={height}
        offset={sliderFromOffset}
        onChangeCallback={onPositionChange}
      />
      <RangeDurationSlider
        height={height}
        offset={sliderToOffset}
        onChangeCallback={onPositionChange}
      />
    </Animated.View>
  );
};

const ScrollControlButton = ({ iconName, onPress, onEnd, height }) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: 'rgba(255, 255, 255, 1)',
        height: height,
        position: 'absolute',
        right: onEnd ? 0 : undefined,
        left: !onEnd ? 0 : undefined,
        width: 44,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={onPress}
    >
      <Ionicons name={iconName} size={23} color="rgba(9,125,255,1)" />
    </TouchableOpacity>
  );
};

interface RangeScrollViewProps {
  height: number;
  minuteWidth: number;
  showControls?: boolean;
  disableScroll?: boolean;
  isCollapsed?: boolean;
  hours?: number[];
}

const RangeScrollView: React.FC<RangeScrollViewProps> = ({
  children,
  height,
  minuteWidth,
  showControls,
  disableScroll,
  isCollapsed,
  hours,
}) => {
  const x = useSharedValue(0);
  const heightAnimatedValue = useSharedValue(height);

  useEffect(() => {
    if (isCollapsed) {
      heightAnimatedValue.value = withTiming(0, { duration: 500 });
    } else {
      heightAnimatedValue.value = withTiming(height, { duration: 500 });
    }
  }, [isCollapsed]);

  const animatedStyleContainer = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: x.value,
        },
      ],
    };
  });

  const animatedStyleHeight = useAnimatedStyle(() => {
    return {
      height: heightAnimatedValue.value,
      // backgroundColor: 'rgba(0, 0, 0, 0.08)',
      backgroundColor: interpolateColor(
        heightAnimatedValue.value,
        [0, height - height / 5, height],
        [
          'rgba(230,233,235, 0.4)',
          'rgba(230,233,235,0.4)',
          'rgba(230,233,235,0.0)',
        ],
      ),
    };
  }, [height]);

  useEffect(() => {
    x.value = withTiming(0, { duration: 300 });
  }, [hours]);

  const scrollGestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.start = x.value;
    },
    onActive: (event, ctx) => {
      x.value = ctx.start + event.translationX;
    },
    onEnd: (event, ctx) => {
      if (x.value > 0) {
        // x.value = withSpring(0);
        x.value = withTiming(0, { duration: 250 });
      }
    },
  });

  const scrollDt = minutes => {
    x.value = x.value + minutes * minuteWidth;
  };

  return (
    <PanGestureHandler
      onGestureEvent={scrollGestureHandler}
      enabled={!disableScroll}
    >
      <Animated.View
        style={[
          {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            overflow: 'hidden',
          },
          animatedStyleHeight,
        ]}
      >
        <Animated.View style={[{ flexDirection: 'row' }]}>
          <Animated.View
            style={[{ flexDirection: 'row' }, animatedStyleContainer]}
          >
            {children}
          </Animated.View>
          {showControls && (
            <ScrollControlButton
              iconName={'chevron-back'}
              onPress={() => scrollDt(30)}
              onEnd={false}
              height={height}
            />
          )}
          {showControls && (
            <ScrollControlButton
              iconName={'chevron-forward'}
              onPress={() => scrollDt(-30)}
              onEnd
              height={height}
            />
          )}
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
};

interface TimeRangeSelectorV2Props {
  rangeFrom: Date;
  rangeTo: Date;
  hourWidth: number;
  height: number;
  durationIntervalInMinutes?: number;
  minDurationInMinutes?: number;
  onIntervalUpdateCallBack?: (dateTimeFrom: string, dateTimeTo: string) => void;
  showControls?: boolean;
  disableScroll?: boolean;
  isCollapsed?: boolean;
}

const TimeRangeSelectorV2: React.FC<TimeRangeSelectorV2Props> = ({
  rangeFrom,
  rangeTo,
  hourWidth,
  height,
  durationIntervalInMinutes = 15,
  minDurationInMinutes = 60,
  onIntervalUpdateCallBack = () => null,
  showControls = false,
  disableScroll = false,
  isCollapsed = false,
}) => {
  const [hours, setHours] = useState([]);

  useEffect(() => {
    const firstHour = getHours(rangeFrom);
    const lastHour = getHours(rangeTo);
    const len = (lastHour - firstHour + 24) % 24;
    const newHours = Array.from({ length: len }, (v, k) => k + firstHour);
    if (
      newHours.length > 0 &&
      hours.length > 0 &&
      newHours[0] === hours[0] &&
      newHours.length === hours.length
    ) {
      // same hours
    } else {
      setHours(newHours);
    }
  }, [rangeFrom, rangeTo]);

  const onRangeChange = (minutesFromStart, durationInMinutes) => {
    const selectedRangeFrom = addMinutes(rangeFrom, minutesFromStart);
    const selectedRangeTo = addMinutes(
      rangeFrom,
      minutesFromStart + durationInMinutes,
    );
    onIntervalUpdateCallBack(
      format(selectedRangeFrom, DATETIME_FORMAT),
      format(selectedRangeTo, DATETIME_FORMAT),
    );
  };

  return (
    <RangeScrollView
      height={height}
      minuteWidth={hourWidth / 60}
      showControls={showControls}
      disableScroll={disableScroll}
      isCollapsed={isCollapsed}
      hours={hours}
    >
      <TimelineHourSection height={height} width={hourWidth} firstTransparent />
      {hours.map((hour, key) => {
        return (
          <TimelineHourSection
            key={`${hour} ${key}`}
            hour={hour}
            height={height}
            width={hourWidth}
          />
        );
      })}
      <RangeComponent
        height={height}
        minuteWidth={hourWidth / 60}
        durationIntervalInMinutes={durationIntervalInMinutes}
        style={{ left: hourWidth }}
        rangeHours={hours.length}
        minDurationInMinutes={minDurationInMinutes}
        onChangeCallback={onRangeChange}
        hours={hours}
      />
    </RangeScrollView>
  );
};

const styles = StyleSheet.create({
  timeStringContainer: {
    width: 40,
    // backgroundColor: 'red',
    marginLeft: -20,
    flex: 1,
    paddingTop: 5,
    // paddingTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeString: {
    fontSize: 14,
    fontWeight: '500',
    color: '#2d3a44',
  },
  tickContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  tickTransparent: {
    width: 1,
    backgroundColor: 'transparent',
  },
  tick: {
    flexDirection: 'column',
    width: 1,
    height: 5,
    backgroundColor: '#d0d6de',
  },
  tickFirst: {
    height: 8,
    width: 1,
    // backgroundColor: '#989ea8',
    backgroundColor: '#6f7981',
  },
  middleSection: {
    backgroundColor: 'rgba(9,125,255,0.10)',
    position: 'absolute',
    top: 0,
  },
  durationSliderContainer: {
    width: 44,
    position: 'absolute',
    left: -22,
    top: 0,
  },
});

export default TimeRangeSelectorV2;
