import React, { useEffect, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../constants/Colors';
import { Picker } from '@react-native-community/picker';
import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import OpenHoursStyle from '../styles/OpenHoursStyle';

const closedText = () => {
  return (
    <View style={{ flex: 1, paddingRight: 12 }}>
      <Text style={OpenHoursStyle.closedText}>Closed</Text>
    </View>
  );
};

const OpenHoursDaySelectWeb = props => {
  const {
    selectedOpenTime,
    selectedCloseTime,
    selectedClosed,
    onChange = () => {},
  } = props;
  const [openTime, setOpenTime] = useState(selectedOpenTime);
  const [closeTime, setCloseTime] = useState(selectedCloseTime);
  const [open, setOpen] = useState(!selectedClosed);

  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hours = i < 10 ? `0${i}` : i;
      const minutes = j == 0 ? '00' : j;
      timeOptions.push(`${hours}:${minutes}`);
    }
  }

  const pickerOptions = timeOptions.map(time => {
    return <Picker.Item key={time} label={time} value={time} />;
  });

  const onChangePush = () => {
    const time = {
      openTime: openTime,
      closeTime: closeTime,
      closed: !open,
    };
    onChange(time);
  };
  const updateOpenTime = time => {
    setOpenTime(time);
  };
  const updateCloseTime = time => {
    setCloseTime(time);
  };
  const handleOpenSwitch = () => {
    setOpen(!open);
  };

  useEffect(() => {
    onChangePush();
  }, [openTime, closeTime, open]);

  const fromToTime = () => (
    <View style={{ flexDirection: 'row', marginRight: 12 }}>
      <Picker
        mode="dropdown"
        style={OpenHoursStyle.webTimePicker}
        selectedValue={openTime}
        onValueChange={time => updateOpenTime(time)}
      >
        {pickerOptions}
      </Picker>
      <Text style={OpenHoursStyle.time}>-</Text>
      <Picker
        mode="dropdown"
        style={OpenHoursStyle.webTimePicker}
        selectedValue={closeTime}
        onValueChange={time => updateCloseTime(time)}
      >
        {pickerOptions}
      </Picker>
    </View>
  );

  return (
    <View>
      <View style={OpenHoursStyle.row}>
        {open ? fromToTime() : closedText()}
        <Switch
          thumbColor={'white'}
          activeThumbColor={'white'}
          trackColor={{ false: Colors.lightererer, true: Colors.lightGreen }}
          onValueChange={() => handleOpenSwitch()}
          value={open}
        />
      </View>
    </View>
  );
};

const OpenHoursDaySelectMobile = props => {
  const {
    selectedOpenTime,
    selectedCloseTime,
    selectedClosed,
    onChange = () => {},
  } = props;

  const openTimeDate = `2000-01-01T${selectedOpenTime || '00:00'}:00`;
  const closeTimeDate = `2000-01-01T${selectedCloseTime || '00:00'}:00`;

  const [openTime, setOpenTime] = useState(new Date(openTimeDate));
  const [closeTime, setCloseTime] = useState(new Date(closeTimeDate));

  const [showOpenTime, setShowOpenTime] = useState(false);
  const [showCloseTime, setShowCloseTime] = useState(false);

  const [open, setOpen] = useState(!selectedClosed);

  const onChangePush = () => {
    const time = {
      openTime: moment(openTime).format('HH:mm'),
      closeTime: moment(closeTime).format('HH:mm'),
      closed: !open,
    };
    onChange(time);
  };

  const handleConfirmOpenTime = date => {
    setOpenTime(date);
    setShowOpenTime(false);
  };

  const handleConfirmCloseTime = date => {
    setCloseTime(date);
    setShowCloseTime(false);
  };

  const handleOpenSwitch = () => {
    setOpen(!open);
  };

  useEffect(() => {
    onChangePush();
  }, [openTime, closeTime, open]);

  const fromToTime = () => {
    return (
      <View style={OpenHoursStyle.fromToTimeContainer}>
        <TouchableOpacity onPress={() => setShowOpenTime(true)}>
          <Text style={OpenHoursStyle.time}>
            {moment(openTime).format('HH:mm')}
          </Text>
        </TouchableOpacity>
        <Text style={[OpenHoursStyle.time, OpenHoursStyle.timeLine]}> - </Text>
        <TouchableOpacity onPress={() => setShowCloseTime(true)}>
          <Text style={OpenHoursStyle.time}>
            {moment(closeTime).format('HH:mm')}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View>
      <View style={OpenHoursStyle.row}>
        {open ? fromToTime() : closedText()}
        <Switch
          style={{ marginLeft: 12 }}
          onValueChange={() => handleOpenSwitch()}
          value={open}
        />
      </View>
      <DateTimePicker
        isVisible={showOpenTime}
        onConfirm={handleConfirmOpenTime}
        onCancel={() => setShowOpenTime(false)}
        mode="time"
        date={openTime}
        minuteInterval={15}
        // width={320}
      />
      <DateTimePicker
        isVisible={showCloseTime}
        onConfirm={handleConfirmCloseTime}
        onCancel={() => setShowCloseTime(false)}
        mode="time"
        date={closeTime}
        minuteInterval={15}
        // width={320}
      />
    </View>
  );
};

const OpenHoursDaySelect = props => {
  if (Platform.OS === 'web') {
    return <OpenHoursDaySelectWeb {...props} />;
  } else {
    return <OpenHoursDaySelectMobile {...props} />;
  }
};

export default OpenHoursDaySelect;
