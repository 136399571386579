import React, { useContext } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import moment from 'moment';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Portal } from 'react-native-paper';
import Modal from 'react-native-modal';
import Colors from '../../../constants/Colors';
import { GlobalContext } from '../../../GlobalContext';
// import SafeAreaView from 'react-native-safe-area-view';
import AreYouSureWrapper from '../../../components/AreYouSureWrapper';
import ReservationServices from '../../../services/ReservationsServices';
import { Reservation } from '../../../transofrmations/reservationsTransformer';
import { ReservationsContext } from '../../../contex/ReservationsContext';
import {
  FormButtom,
  FormButtonIconType,
  FormButtonsGroup,
  FormButtonSize,
  FormButtonsType,
  FormButtonWithConfirmation,
} from '../../../components/Form';

interface InfoCrossedProps {
  value?: string | string[];
  crossedValue?: string;
  italic?: boolean;
  valueColor?: string;
}

export const InfoCrossed: React.FC<InfoCrossedProps> = ({
  value,
  crossedValue,
  italic,
  valueColor,
}) => {
  return (
    <Text
      style={[
        { color: '#161721', fontSize: 16, fontWeight: '500' },
        italic && { fontStyle: 'italic' },
        valueColor && { color: valueColor },
      ]}
    >
      <Text
        style={{
          // textDecorationLine: 'line-through',
          color: '#afb2bb',
          fontStyle: 'italic',
          fontWeight: '500',
        }}
      >
        {crossedValue}
      </Text>
      {crossedValue ? '  ' : ''}
      {crossedValue ? (
        <Ionicons name="arrow-forward" size={16} color="#555" />
      ) : (
        ''
      )}
      {crossedValue ? '  ' : ''}
      {value}
    </Text>
  );
};

interface InfoItemProps {
  label?: string;
  value?: string | string[];
  italic?: boolean;
  valueColor?: string;
  crossedValue?: string;
  children?: any;
}

export const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  italic,
  valueColor = '#2d3c4f',
  crossedValue,
  children,
}) => {
  return (
    <View style={{ marginBottom: 10 }}>
      <Text
        style={{
          fontWeight: '700',
          fontSize: 11,
          textTransform: 'uppercase',
          marginBottom: 2,
          color: '#8a949f',
        }}
      >
        {label}
      </Text>
      <View>
        {children}
        {!!value && !children && (
          <InfoCrossed
            value={value}
            crossedValue={crossedValue}
            italic={italic}
            valueColor={valueColor}
          />
        )}
        {!value && !children && (
          <InfoCrossed value="none" italic={italic} valueColor="#dbdcde" />
        )}
      </View>
    </View>
  );
};

interface ReservationOverviewModalProps {
  isVisible: boolean;
  onBackDropPress: () => void;
  selectedReservation: Reservation;
}

const ReservationOverviewModal = ({
  isVisible,
  onBackDropPress,
  selectedReservation,
}: ReservationOverviewModalProps) => {
  const global = useContext(GlobalContext);
  const { deviceType, showSnackbarMessage, showAlert } = global;

  const { refreshReservationsAsync } = useContext(ReservationsContext);
  const windowWidth = useWindowDimensions().width;

  const fullScreen = deviceType === 'phone' || windowWidth < 500;

  let modalWidth: number | string = Platform.OS === 'web' ? 420 : 360;
  if (fullScreen) {
    modalWidth = '100%';
  }

  if (!selectedReservation) {
    return <View />;
  }
  const tables = selectedReservation?.tables?.map((table) => {
    return `${table.label}`;
  });

  const cancelReservation = () => {
    ReservationServices.cancelReservation(selectedReservation.id).then(
      (res) => {
        if (res.ok) {
          refreshReservationsAsync().then(() => {
            onBackDropPress();
            showSnackbarMessage('Reservation was cancelled.');
          });
        } else {
          showSnackbarMessage('Something went wrong.');
        }
      },
    );
  };

  const acceptReservationRequest = () => {
    ReservationServices.acceptReservationRequest(selectedReservation.id).then(
      (res) => {
        if (res) {
          refreshReservationsAsync().then(() => {
            onBackDropPress();
            showAlert('Reservation was accepted!', 'success');
          });
        } else {
          showAlert('Something went wrong.', 'error');
        }
      },
    );
  };

  const rejectReservationRequest = () => {
    ReservationServices.rejectReservationRequest(selectedReservation.id).then(
      (res) => {
        if (res) {
          refreshReservationsAsync().then(() => {
            onBackDropPress();
            showAlert('Reservation was rejected!', 'info');
          });
        } else {
          showAlert('Something went wrong.', 'error');
        }
      },
    );
  };

  let status = selectedReservation.cancelled ? 'canceled' : 'active';
  let statusColor = selectedReservation.cancelled
    ? Colors.dangerRed
    : Colors.confirmGreen;

  if (selectedReservation.pendingConfirmation) {
    status = 'pending';
    statusColor = Colors.orange;
  }

  return (
    <Modal
      hideModalContentWhileAnimating
      isVisible={isVisible}
      deviceHeight={Dimensions.get('screen').height}
      deviceWidth={Dimensions.get('screen').width}
      backdropOpacity={0.4}
      animationIn={Platform.OS === 'web' ? 'fadeIn' : 'fadeInRight'}
      animationInTiming={200}
      animationOut={Platform.OS === 'web' ? 'fadeOut' : 'fadeOutRightBig'}
      onBackButtonPress={() => onBackDropPress()}
      onBackdropPress={() => onBackDropPress()}
      // onRequestClose={onBackDropPress}
      // coverScreen
      statusBarTranslucent
      useNativeDriver
      // transparent
      style={{ margin: 0 }}
    >
      <Portal.Host>
        <View
          style={{
            flex: 1,
            // backgroundColor: 'rgba(0,0,0, 0.4)',
            alignSelf: Platform.OS === 'web' ? 'center' : 'flex-end',
            justifyContent: Platform.OS === 'web' ? 'center' : 'center',
          }}
        >
          <SafeAreaView
            style={{
              paddingTop: Platform.OS === 'web' ? 12 : 0,
              width: modalWidth,
              minWidth: 280,
              backgroundColor: '#fff',
              borderRadius: fullScreen ? 0 : 6,
              // alignSelf: 'flex-end',
              flex: Platform.OS === 'web' ? undefined : 1,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                // flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  marginLeft: 24,
                  fontWeight: '500',
                }}
              >
                {selectedReservation?.customer?.fullName}
              </Text>

              <TouchableOpacity
                onPress={onBackDropPress}
                style={{
                  width: 80,
                  height: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Ionicons name="md-close" size={26} color="#ccc" />
              </TouchableOpacity>
            </View>
            <ScrollView
              style={{
                paddingHorizontal: 24,
              }}
              contentContainerStyle={{
                paddingTop: 10,
                paddingBottom: 10,
              }}
              keyboardShouldPersistTaps="always"
            >
              <InfoItem
                label="Reservation Date"
                value={selectedReservation.formattedDate}
              />
              <InfoItem
                label="Start Time"
                value={selectedReservation.timeFrom}
              />
              {/* <InfoItem */}
              {/*  label={'End Time'} */}
              {/*  value={`${moment( */}
              {/*    selectedReservation.reservation_date_end, */}
              {/*  ).format('HH:mm')}`} */}
              {/* /> */}
              <InfoItem
                label="Duration"
                value={`${moment
                  .duration(selectedReservation.duration)
                  .humanize()}`}
              />
              <InfoItem
                label="Number of guests"
                value={`${selectedReservation.persons} guests`}
              />
              <InfoItem
                label={tables?.length > 1 ? 'Tables' : 'Table'}
                value={tables?.map((table, index) => {
                  const vejica = index !== 0 ? ', ' : '';
                  return vejica + table.toString();
                })}
                italic
              />
              <InfoItem
                label="Phone Number"
                value={selectedReservation?.customer?.phone}
              />

              <InfoItem
                label="Created on"
                value={moment(selectedReservation.createdOnDate).format(
                  'DD. MMM YYYY [at] HH:mm',
                )}
              />
              <InfoItem
                label="Created by"
                value={
                  selectedReservation.createdByRestaurant
                    ? 'You'
                    : selectedReservation?.customer?.fullName
                }
              />
              <InfoItem
                label="Status"
                value={status}
                valueColor={statusColor}
              />
              <InfoItem
                label="Messages"
                value={
                  selectedReservation?.messages?.length > 0 &&
                  `${selectedReservation?.messages[0]?.author || 'note'}: ${
                    selectedReservation.messages[0].text
                  }`
                }
              />
              {selectedReservation.pendingConfirmation && (
                <InfoItem label="Confirm or reject reservation">
                  <FormButtonsGroup marginTop={5} marginBottom={15}>
                    <FormButtonWithConfirmation
                      // disabled={!name || !openHoursId}
                      onConfirm={() => rejectReservationRequest()}
                      label="Reject"
                      title="Reject Reservation"
                      confirmText="Reject"
                      cancelText="Cancel"
                      message="Do you really want to reject this reservation?"
                      type={FormButtonsType.CANCEL}
                      size={FormButtonSize.SMALL}
                      icon={FormButtonIconType.CANCEL}
                      secondary
                    />
                    <View style={{ width: 12 }} />
                    <FormButtom
                      // disabled={!name || !openHoursId}
                      onPress={() => acceptReservationRequest()}
                      label="Confirm"
                      type={FormButtonsType.ACCEPT}
                      size={FormButtonSize.SMALL}
                      icon={FormButtonIconType.CHECK}
                    />
                  </FormButtonsGroup>
                </InfoItem>
              )}
              {!selectedReservation.cancelled &&
                !selectedReservation.pendingConfirmation && (
                  <AreYouSureWrapper
                    style={{ marginVertical: 10 }}
                    onConfirm={cancelReservation}
                    title="Cancel reservation"
                    confirmText="Yes"
                    cancelText="No"
                    message="Do you want to cancel this reservation?"
                  >
                    <View style={styles.reservationContainer}>
                      <Text style={styles.reservationButtonText}>
                        Cancel Reservation
                      </Text>
                    </View>
                  </AreYouSureWrapper>
                )}
            </ScrollView>
          </SafeAreaView>
        </View>
      </Portal.Host>
    </Modal>
  );
};

export default ReservationOverviewModal;

const styles = StyleSheet.create({
  info_left: {
    fontSize: 16,
    fontWeight: '700',
    alignItems: 'flex-start',
    flexDirection: 'row',
    color: '#444',
  },
  info_right: {
    marginRight: 12,
    fontSize: 16,
    fontWeight: '400',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  info_line: {
    paddingVertical: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 30,
  },
  rightSide: {
    flexDirection: 'row',
  },
  note: {
    alignItems: 'center',
    height: 55,
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    marginHorizontal: 24,
    paddingHorizontal: 12,
  },
  reservationContainer: {
    // marginHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    backgroundColor: '#f95b54',
    borderRadius: 8,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 15,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  cancelledText: {
    textAlign: 'center',
    color: Colors.dangerRed,
    fontSize: 17,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
    marginBottom: 20,
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});
