import React from 'react';
import { Animated, PanResponder } from 'react-native';

import _ from 'lodash';
import Table from './Table';

const ROTATION_DEG_INTERVAL = 45;

export default class TableDraggable extends React.Component {
  constructor(props) {
    super(props);
    const { table, scaleFactor } = props;
    const { position } = table;

    this.state = {
      pan: new Animated.ValueXY({
        x: position.x * scaleFactor,
        y: position.y * scaleFactor,
      }),
      opacity: new Animated.Value(1),
      rotation: new Animated.Value(position.angle),
      angle: position.angle,
      offsets: null,
    };

    // this.positionXY = {
    //   x: position.x * scaleFactor,
    //   y: position.y * scaleFactor,
    // };
    // this.state.pan.addListener(value => (this.positionXY = value));

    this.panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onStartShouldSetPanResponder: (e, gesture) => true,
      onPanResponderGrant: (e, gesture) => {
        // console.log(this.positionXY);
        const { pan } = this.state;
        const { switchRemoveArea } = this.props;

        pan.setOffset({
          x: this.positionXY.x,
          y: this.positionXY.y,
        });
        pan.setValue({ x: 0, y: 0 });
        switchRemoveArea('show');
      },

      onPanResponderMove: (e, gestureState) => {
        const { pan } = this.state;
        Animated.event(
          [
            null,
            {
              dx: pan.x,
              dy: pan.y,
            },
          ],
          { useNativeDriver: false },
        )(e, gestureState);
        // console.log('pos move: ', this.positionXY.x);
        this.onMove({
          x: this.positionXY.x + gestureState.dx,
          y: this.positionXY.y + gestureState.dy,
        });
      },
      onPanResponderRelease: (e, gesture) => {
        const { switchRemoveArea } = this.props;

        this.positionXY.x += gesture.dx;
        this.positionXY.y += gesture.dy;
        // console.log('pos1 pan1: ', this.positionXY.x, this.state.pan.x._value);
        // console.log('edx1: ', gesture.x0, gesture.dx);
        // const positionXY = this.positionXY;
        this.onRelease(this.positionXY);
        switchRemoveArea('hide');

        this.state.pan.flattenOffset();
        setTimeout(() => {
          switchRemoveArea('hide');
          // console.log(
          //   'pos2 pan 2: ',
          //   this.positionXY.x,
          //   this.state.pan.x._value,
          // );
          // this.onRelease(this.positionXY);
          // this.onRelease(positionXY);
        }, 300);
      },
      onPanResponderTerminationRequest: (e, gestureState) => {
        console.log('termination request');
        return false;
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        console.log('terminated');
      },
    });

    this.debounceRotate = _.throttle(this.rotateTable, 650, {
      trailing: false,
    });
  }

  componentDidMount() {
    const { table, scaleFactor } = this.props;
    const { position } = table;
    this.positionXY = {
      x: position.x * scaleFactor,
      y: position.y * scaleFactor,
    };
    // this.panListener = this.state.pan.addListener(
    //   value => (this.positionXY = value),
    // );
    this.computeOffsets();
  }

  componentWillUnmount() {
    // this.state.pan.removeListener(this.panListener);
  }

  rotateTable = () => {
    const { rotation, angle } = this.state;
    const newAngle = (angle + ROTATION_DEG_INTERVAL) % 360;

    Animated.timing(rotation, {
      toValue: newAngle,
      duration: 200,
      useNativeDriver: true,
    }).start();

    this.setState({ angle: newAngle }, () => this.computeOffsets());
  };

  onMove = position => {
    const barHeight = -54;
    const { roomContainer } = this.props;
    const { opacity, offsets } = this.state;
    const { top, bottom, left, right } = offsets;
    const centerOffset = { x: (left + right) / 2, y: (top + bottom) / 2 };
    const centerPosition = {
      x: position.x + centerOffset.x,
      y: position.y + centerOffset.y,
    };

    // console.log(position.x, position.y);

    const actionControlOffsetX =
      (roomContainer.fullWidth - roomContainer.width) / 2;

    if (
      centerPosition.y < 0 &&
      centerPosition.y > barHeight &&
      centerPosition.x + actionControlOffsetX > roomContainer.fullWidth * 0.7 &&
      centerPosition.x + actionControlOffsetX < roomContainer.fullWidth
    ) {
      Animated.timing(opacity, {
        toValue: 0.2,
        duration: 100,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }).start();
    }
    if (
      centerPosition.y < 0 &&
      centerPosition.y > barHeight &&
      centerPosition.x + actionControlOffsetX >
        roomContainer.fullWidth * 0.36 &&
      centerPosition.x + actionControlOffsetX <
        roomContainer.fullWidth * (2 / 3)
    ) {
      this.debounceRotate();
    }
  };

  onRelease = position => {
    const barHeight = -54;

    const { roomContainer, scaleFactor, table } = this.props;

    const { table_id } = table;
    const { offsets, angle } = this.state;
    const { top, bottom, left, right } = offsets;
    const centerOffset = { x: (left + right) / 2, y: (top + bottom) / 2 };
    const centerPosition = {
      x: position.x + centerOffset.x,
      y: position.y + centerOffset.y,
    };

    const actionControlOffsetX =
      (roomContainer.fullWidth - roomContainer.width) / 2;
    if (
      centerPosition.y < 0 &&
      centerPosition.y > barHeight &&
      centerPosition.x + actionControlOffsetX > roomContainer.fullWidth * 0.7 &&
      centerPosition.x + actionControlOffsetX < roomContainer.fullWidth
    ) {
      this.props.removeTableFromRoom(table_id);
      return;
    }

    let newX = position.x;
    let newY = position.y;

    if (roomContainer.width < position.x + right) {
      newX = roomContainer.width - right;
    }
    if (position.x < -left) {
      newX = -left;
    }
    if (roomContainer.height < position.y + bottom) {
      newY = roomContainer.height - bottom;
    }
    if (position.y < -top) {
      newY = -top;
    }

    const realPosition = {
      x: Math.round(newX / scaleFactor),
      y: Math.round(newY / scaleFactor),
      angle,
    };

    this.props.updateTablePosition(table_id, realPosition);
    // console.log(position.x, position.y, newX, newY);

    Animated.timing(
      this.state.pan, // Auto-multiplexed
      {
        toValue: {
          x: newX,
          y: newY,
        },
        duration: 30,
        useNativeDriver: true,
      },
    ).start();
    this.positionXY = { x: newX, y: newY };
  };

  calculatePointRotation = (cx, cy, x, y, angle) => {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - cx) + sin * (y - cy) + cx;
    const ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  };

  computeOffsets = () => {
    const { table } = this.props;
    let { width, length, round } = table.dimensions;
    const { scaleFactor } = this.props;

    width *= scaleFactor;
    length *= scaleFactor;

    let angle = -this.state.angle;
    if (length === width && round) {
      angle = 0;
    }

    const v1 = this.calculatePointRotation(
      length / 2,
      width / 2,
      length,
      0,
      angle,
    );
    const v2 = this.calculatePointRotation(
      length / 2,
      width / 2,
      0,
      width,
      angle,
    );
    const v3 = this.calculatePointRotation(length / 2, width / 2, 0, 0, angle);
    const v4 = this.calculatePointRotation(
      length / 2,
      width / 2,
      length,
      width,
      angle,
    );
    const left = Math.min(v1[0], v2[0], v3[0], v4[0]);
    const top = Math.min(v1[1], v2[1], v3[1], v4[1]);
    const right = Math.max(v1[0], v2[0], v3[0], v4[0]);
    const bottom = Math.max(v1[1], v2[1], v3[1], v4[1]);

    this.setState({ offsets: { top, bottom, left, right } });
  };

  render() {
    const spin = this.state.rotation.interpolate({
      inputRange: [0, 360],
      outputRange: ['0deg', '360deg'],
    });

    const spinTextBack = this.state.rotation.interpolate({
      inputRange: [0, 360],
      outputRange: ['360deg', '0deg'],
    });

    const { showDraggable, opacity, rotation } = this.state;
    const { scaleFactor, table } = this.props;

    return (
      <Animated.View
        style={{
          position: 'absolute',
          transform: [
            ...this.state.pan.getTranslateTransform(),
            // { rotate: spin },
          ],
        }}
      >
        <Animated.View
          {...this.panResponder.panHandlers}
          style={[
            {
              // backgroundColor: 'red',
              // position: 'absolute',
              transform: [
                // { scale: scaleFactor },
                // { rotate: spin },
                { translateX: -table.dimensions.length / 2 },
                { translateY: -table.dimensions.width / 2 },
                { scale: scaleFactor },
                { translateX: table.dimensions.length / 2 },
                { translateY: table.dimensions.width / 2 },
                { rotate: spin },

                // ...this.state.pan.getTranslateTransform(),
              ],
            },
          ]}
        >
          <Table
            // animatedTextAngle={rotation}
            opacity={opacity}
            tableWidth={table.dimensions.width}
            tableLength={table.dimensions.length}
            isRound={table.dimensions.round}
            chairsTop={table.chairs.top}
            chairsBottom={table.chairs.bottom}
            chairsLeft={table.chairs.left}
            chairsRight={table.chairs.right}
            chairsCount={table.chairs.count}
            // tableNumber={table.table_number}
          />
          <Animated.View
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Animated.Text
              style={{
                fontSize: 24,
                color: 'white',
                transform: [{ rotate: spinTextBack }],
              }}
              selectable={false}
            >
              {table.group_prefix ? `${table.group_prefix}` : ''}
              {table.table_number}
            </Animated.Text>
          </Animated.View>
        </Animated.View>
      </Animated.View>
    );
  }

  // render() {
  //   return <View>{this.renderDraggable()}</View>;
  // }
}
