import { Platform, Text, View } from 'react-native';
import React from 'react';
import Colors from '../../constants/Colors';

const DrawHours = ({ startTime, endTime, rowLength }) => {
  const times = [];
  let endHour = endTime;
  if (endTime < startTime) {
    endHour += 24;
  }

  for (let i = startTime; i < endHour + 1; i += 1) {
    const hour = `${i % 24 < 10 ? '0' : ''}${(i % 24).toString()}`;
    const text =
      i !== endTime + 1 ? `${hour}${rowLength > 40 ? ':00' : ''}` : null;
    times.push(
      <View
        style={{
          width: rowLength,
          paddingHorizontal: 2,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: '#ccc',
          borderRightWidth: 0,
          // zIndex: 1000,
        }}
        key={`${rowLength}+${i}`}
      >
        <Text
          style={{
            fontSize: rowLength > 40 ? 15 : 13,
            fontWeight: Platform.OS === 'ios' ? '500' : '400',
            color: Colors.darker,
            marginLeft: -rowLength,
            opacity: i === startTime ? 0 : 1,
          }}
          selectable={false}
          numberOfLines={1}
          // allowFontScaling
          adjustsFontSizeToFit
        >
          {text}
        </Text>
      </View>,
    );
  }

  return times;
};

export default DrawHours;
