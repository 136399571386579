import React from 'react';
import * as Device from 'expo-device';
import { DeviceType } from 'expo-device';
import deviceStorage from './services/DeviceStorage';

import TableServices from './services/TablesServices';
import LayoutServices from './services/LayoutServices';
import OpenHoursServices from './services/OpenHoursServices';

import NavigationService from './services/NavigationService';
import TimelineServices from './services/TimelineServices';
import SettingsServices from './services/SettingsServices';
import SnackbarNotification from './components/SnackbarNotification';

export const GlobalContext = React.createContext();

export class GlobalContextProvider extends React.Component {
  state = {
    jwtToken: null,
    restaurantId: null,
    restaurants: null,
    tableGroups: [],
    tables: [],
    models: [],
    openHours: [],
    defaultOpenHours: null,
    rooms: [],
    deviceType: null,
    reservationsSettings: null,
    restaurantInfo: null,
    restaurantPhotos: [],
    closedDays: [],
    snackbarVisible: false,
    snackbarMessage: '',
    snackbarType: undefined,
    snackbarBottomMargin: 75,
    dialogVisible: false,
    dialogMessage: '',
  };

  async componentDidMount() {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    this.setState({
      restaurantId: restaurantId ? parseInt(restaurantId) : null,
      jwtToken,
    });

    this.getDeviceType();

    if (jwtToken) {
      if (restaurantId) {
        this.updateModels();
        this.updateTables();
        this.updateTableGroups();
        this.updateOpenHours();
        this.updateRooms();
        this.updateReservationsSettings();
        this.updateRestaurantInfo();
        this.updateClosedDays();
      } else {
        try {
          const restaurants = await TimelineServices.get_restaurants(jwtToken);
          if (restaurants) {
            this.setState({ restaurants });
          } else {
            this.logout();
          }
        } catch (e) {
          this.logout();
        }
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { jwtToken, restaurantId } = this.state;
    if (!prevState.jwtToken && jwtToken) {
      try {
        const restaurants = await TimelineServices.get_restaurants(jwtToken);
        if (restaurants) {
          this.setState({ restaurants });
        } else {
          this.logout();
        }
      } catch (e) {
        this.logout();
      }
    }

    if (!prevState.restaurantId && restaurantId) {
      this.updateModels();
      this.updateTables();
      this.updateTableGroups();
      this.updateOpenHours();
      this.updateRooms();
      this.updateReservationsSettings();
      this.updateRestaurantInfo();
      this.updateClosedDays();
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  logout = () => {
    this.setState({
      jwtToken: null,
      restaurantId: null,
      restaurants: null,
      tableGroups: [],
      tables: [],
      models: [],
      openHours: [],
      defaultOpenHours: null,
      rooms: [],
      deviceType: null,
      reservationsSettings: null,
      restaurantInfo: null,
      restaurantPhotos: [],
      closedDays: [],
      snackbarVisible: false,
      snackbarMessage: '',
      dialogVisible: false,
      dialogMessage: '',
    });
    deviceStorage.deleteConfig().then();
    NavigationService.navigate('login');
  };

  updateJwt = (jwtToken) => {
    this.setState({
      jwtToken,
    });
  };

  changeRestaurantId = (restaurantId) => {
    this.setState({
      restaurantId,
    });
  };

  updateModels = async () => {
    const { jwtToken, restaurantId } = this.state;
    const models = await LayoutServices.getTableModels2(jwtToken, restaurantId);
    this.setState({
      models,
    });
  };

  updateReservationsSettings = async () => {
    const { jwtToken, restaurantId } = this.state;
    const reservationsSettings = await SettingsServices.getReservationsSettings(
      jwtToken,
      restaurantId,
    );
    this.setState({
      reservationsSettings,
    });
  };

  updateRestaurantInfo = async () => {
    const { jwtToken, restaurantId } = this.state;
    const restaurantInfo = await SettingsServices.getInfo(
      jwtToken,
      restaurantId,
    );
    this.setState({
      restaurantInfo,
    });
  };

  updateRestaurantPhotos = async () => {
    const { jwtToken, restaurantId } = this.state;
    const restaurantPhotos = await SettingsServices.getPhotos(
      jwtToken,
      restaurantId,
    );
    this.setState({
      restaurantPhotos,
    });
  };

  updateClosedDays = async () => {
    const { jwtToken, restaurantId } = this.state;
    const closedDays = await OpenHoursServices.getClosedDays(
      jwtToken,
      restaurantId,
    );

    const daysArray = closedDays?.closed_days?.map((a) => a.date);

    this.setState({
      closedDays: daysArray,
    });
  };

  updateRooms = async () => {
    const { jwtToken, restaurantId } = this.state;
    const rooms = await LayoutServices.getRooms2(jwtToken, restaurantId);
    this.setState({
      rooms,
    });
  };

  getDeviceType = async () => {
    const type = await Device.getDeviceTypeAsync();
    let deviceType = 'tablet';
    if (type === DeviceType.PHONE) {
      deviceType = 'phone';
    } else if (type === DeviceType.DESKTOP) {
      deviceType = 'web';
    }

    this.setState({
      deviceType,
    });
  };

  updateOpenHours = async () => {
    const { jwtToken, restaurantId } = this.state;
    const openHours = await OpenHoursServices.getOpenHours(
      jwtToken,
      restaurantId,
    );
    const defaultOpenHours = await OpenHoursServices.getDefaultOpenHours(
      jwtToken,
      restaurantId,
    );
    this.setState({
      openHours,
      defaultOpenHours,
    });
  };

  updateTables = async () => {
    const { jwtToken, restaurantId } = this.state;
    const tables = await TableServices.getTables2(jwtToken, restaurantId);
    this.setState({
      tables,
    });
  };

  updateTableGroups = async () => {
    const { jwtToken, restaurantId } = this.state;
    const tableGroups = await TableServices.getTableGroups2(
      jwtToken,
      restaurantId,
    );
    this.setState({
      tableGroups,
    });
  };

  showSnackbarMessage = (message, bottomMargin = 75) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: message,
      snackbarBottomMargin: bottomMargin,
      snackbarType: undefined,
    });

    setTimeout(() => {
      this.setState({
        snackbarBottomMargin: 75,
      });
    }, 6000);
  };

  showAlert = (text, type) => {
    this.setState({
      snackbarVisible: true,
      snackbarMessage: text,
      snackbarType: type,
    });
  };

  onDismissSnackBar = () => {
    this.setState({
      snackbarVisible: false,
      snackbarMessage: '',
    });
  };

  render() {
    const { children } = this.props;
    const { snackbarVisible, snackbarMessage, snackbarType } = this.state;

    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          updateModels: this.updateModels,
          updateTableGroups: this.updateTableGroups,
          updateTables: this.updateTables,
          updateOpenHours: this.updateOpenHours,
          updateRooms: this.updateRooms,
          updateReservationsSettings: this.updateReservationsSettings,
          updateRestaurantInfo: this.updateRestaurantInfo,
          updateRestaurantPhotos: this.updateRestaurantPhotos,
          updateClosedDays: this.updateClosedDays,
          logout: this.logout,
          updateJwt: this.updateJwt,
          changeRestaurantId: this.changeRestaurantId,
          showSnackbarMessage: this.showSnackbarMessage,
          showAlert: this.showAlert,
          showDialog: this.showDialog,
        }}
      >
        {children}
        <SnackbarNotification
          isVisible={snackbarVisible}
          message={snackbarMessage}
          onDismiss={this.onDismissSnackBar}
          type={snackbarType}
        />
      </GlobalContext.Provider>
    );
  }
}

// create the consumer as higher order component
export const withGlobalContext = (ChildComponent) => (props) =>
  (
    <GlobalContext.Consumer>
      {(context) => <ChildComponent {...props} global={context} />}
    </GlobalContext.Consumer>
  );
