// import { AsyncStorage } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

const deviceStorage = {
  async saveKey(key, valueToSave) {
    try {
      await AsyncStorage.setItem(key, valueToSave);
    } catch (error) {
      console.log('AsyncStorage Error: ' + error.message);
    }
  },

  async getJWT() {
    try {
      const value = await AsyncStorage.getItem('jwtToken');
      if (value !== null) {
        return value;
      } else {
        return null;
      }
    } catch (error) {
      console.log('AsyncStorage Error: ' + error.message);
    }
  },

  async loadConfig() {
    try {
      const jwtToken = await AsyncStorage.getItem('jwtToken');
      const restaurantId = await AsyncStorage.getItem('restaurantId');
      if (jwtToken !== null) {
        this.setState({
          jwtToken,
          restaurantId,
          loadingConfig: false,
        });
      } else {
        this.setState({
          loadingConfig: false,
        });
      }
    } catch (error) {
      console.log('AsyncStorage Error: ' + error.message);
    }
  },

  async loadRestaurantId() {
    try {
      return (await AsyncStorage.getItem('restaurantId')) || false;
    } catch (error) {
      return false;
    }
  },

  async deleteConfig() {
    try {
      await AsyncStorage.multiRemove(['jwtToken', 'restaurantId']);
    } catch (error) {
      console.log('AsyncStorage Error: ' + error.message);
    }
  },
};

export default deviceStorage;
