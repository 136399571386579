import React from 'react';
import {
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';

import ModalStyle from '../../../styles/ModalStyle';
import LayoutServices from '../../../services/LayoutServices';
import { withGlobalContext } from '../../../GlobalContext';
import { SafeAreaView } from 'react-native-safe-area-context';
import Table from '../../Spaces/RoomsComponents/Table';
import {
  DimensionsInput,
  FormButtom,
  FormButtonsGroup,
  FormButtonsType,
  FormRowStaticText,
  FormRowStyledContainer,
  FormRowSwitch,
  RowInput,
} from '../../../components/Form';
import { CountSelectorInt } from '../../../components/CountSelector';

const CHAIR_WIDTH = 50;

class NewModelModal extends React.Component {
  constructor(props) {
    // const { navigation } = props;

    super(props);
    this.state = {
      name: null,
      isRound: false,
      isVisible: true,
      chairsTop: 2,
      chairsBottom: 2,
      chairsLeft: 0,
      chairsRight: 0,
      chairsTotal: 20,
      length: 140,
      width: 80,
      lengthTemp: 140,
      widthTemp: 80,
    };
  }

  closeModal = force => {
    setTimeout(() => {
      // this.props.navigation.goBack(null);
    }, 300);
    // this.setState({ isVisible: false });
  };

  isRoundSwitch = () => {
    const { isRound } = this.state;
    this.setState({
      isRound: !isRound,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this.setState({
        name: null,
        isRound: false,
        isVisible: true,
        chairsTop: 2,
        chairsBottom: 2,
        chairsLeft: 0,
        chairsRight: 0,
        chairsTotal: 20,
        length: 140,
        width: 80,
        lengthTemp: 140,
        widthTemp: 80,
      });
    }
  }

  async createNewModel() {
    const {
      name,
      isRound,
      width,
      length,
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      chairsTotal,
    } = this.state;

    const { global, closeModal } = this.props;
    const { updateModels, showAlert } = global;

    const data = {};
    data.name = name;

    data.dimensions = {};
    data.dimensions.length = length;
    data.dimensions.width = width;
    data.dimensions.round = isRound;

    data.chairs = {};
    data.chairs.top = chairsTop;
    data.chairs.bottom = chairsBottom;
    data.chairs.left = chairsLeft;
    data.chairs.right = chairsRight;

    const isCircle = width === length && isRound;

    data.chairs.count = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;

    const result = await LayoutServices.createTableModel(data);
    if (result.ok) {
      updateModels();
      closeModal();
      showAlert('New model was created.', 'success');
    } else {
      showAlert(`${result.message}`, 'error');
      // Alert.alert('Something went wrong', result.message);
    }
  }

  updateChairsCount = (value, state) => {
    Keyboard.dismiss();
    this.setState({ [state]: value });
  };

  RenderTableConfigView = () => {
    const {
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      isRound,
      width,
      length,
      chairsTotal,
    } = this.state;

    const chairsOptions = [];
    for (let i = 0; i < 50; i += 1) {
      chairsOptions[i] = {
        label: i,
        key: i,
        value: i,
      };
    }

    const isCircle = width === length && isRound;

    const chairsCount = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;
    // const ROW_HEIGHT = 220;
    const MIN_DIM = Math.min(
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    );
    // const ROW_HEIGHT = MIN_DIM < 500 ? 200 : 200;
    const ROW_HEIGHT = Math.min(180, MIN_DIM * 0.4);
    // let scaleFactor = ROW_HEIGHT / Math.max(width, length);
    // scaleFactor *= width === length ? 1 : 1;

    const table = {
      chairs: {
        count: chairsCount,
        top: chairsTop,
        bottom: chairsBottom,
        left: chairsLeft,
        right: chairsRight,
      },
      dimensions: {
        length,
        width,
        round: isRound,
      },
    };

    if (Math.min(length, width) < 35) {
      return null;
    }

    return (
      <View
        style={{
          marginTop: 0,
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: 'red',
        }}
      >
        <View style={{ justifyContent: 'center' }}>
          {!isCircle && (
            <FormRowStyledContainer width={130} height={38}>
              <CountSelectorInt
                min={0}
                max={Math.floor(length / CHAIR_WIDTH)}
                value={chairsTop}
                onUpdateValue={value =>
                  this.updateChairsCount(value, 'chairsTop')
                }
              />
            </FormRowStyledContainer>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={{ justifyContent: 'center' }}>
            {!isCircle && (
              <FormRowStyledContainer width={42} height={120}>
                <CountSelectorInt
                  min={0}
                  max={Math.floor(width / CHAIR_WIDTH)}
                  value={chairsLeft}
                  onUpdateValue={value =>
                    this.updateChairsCount(value, 'chairsLeft')
                  }
                  vertical
                />
              </FormRowStyledContainer>
            )}
          </View>
          <View style={{ padding: 0, overflow: 'hidden' }}>
            <Table
              // scaleFactor={scaleFactor}
              maxWidth={isCircle ? ROW_HEIGHT + 30 : ROW_HEIGHT * 1.3}
              maxHeight={isCircle ? ROW_HEIGHT + 30 : ROW_HEIGHT}
              tableWidth={table.dimensions.width}
              tableLength={table.dimensions.length}
              isRound={table.dimensions.round}
              chairsTop={table.chairs.top}
              chairsBottom={table.chairs.bottom}
              chairsLeft={table.chairs.left}
              chairsRight={table.chairs.right}
              chairsCount={table.chairs.count}
              tableNumber={table.table_number}
            />
          </View>
          <View style={{ justifyContent: 'center' }}>
            {!isCircle && (
              <FormRowStyledContainer width={42} height={120}>
                <CountSelectorInt
                  min={0}
                  max={Math.floor(width / CHAIR_WIDTH)}
                  value={chairsRight}
                  onUpdateValue={value =>
                    this.updateChairsCount(value, 'chairsRight')
                  }
                  vertical
                />
              </FormRowStyledContainer>
            )}
          </View>
        </View>
        <View
          style={{ justifyContent: 'center', marginTop: isCircle ? 20 : 0 }}
        >
          <FormRowStyledContainer width={130} height={38}>
            {!isCircle ? (
              <CountSelectorInt
                min={0}
                max={Math.floor(length / CHAIR_WIDTH)}
                value={chairsBottom}
                onUpdateValue={value =>
                  this.updateChairsCount(value, 'chairsBottom')
                }
              />
            ) : (
              <CountSelectorInt
                min={0}
                max={Math.floor((length * 3.14) / CHAIR_WIDTH)}
                value={chairsTotal}
                onUpdateValue={value =>
                  this.updateChairsCount(value, 'chairsTotal')
                }
              />
            )}
          </FormRowStyledContainer>
        </View>
      </View>
    );
  };

  setNumber = stringNumber => {
    const int = parseInt(stringNumber);

    return isNaN(int) ? 0 : int;
  };

  confirmDimensions = () => {
    const { widthTemp, lengthTemp } = this.state;

    this.setState({
      width: Math.max(this.setNumber(widthTemp), CHAIR_WIDTH),
      length: Math.max(this.setNumber(lengthTemp), CHAIR_WIDTH),
      lengthTemp: Math.max(this.setNumber(lengthTemp), CHAIR_WIDTH),
      widthTemp: Math.max(this.setNumber(widthTemp), CHAIR_WIDTH),
    });
  };

  render() {
    const {
      name,
      isRound,
      // isVisible,
      chairsTop,
      chairsBottom,
      chairsLeft,
      chairsRight,
      chairsTotal,
      width,
      length,
    } = this.state;

    const { isVisible, closeModal, smallDevice } = this.props;

    // const { isRoundSwitch, closeModal } = this;
    const { isRoundSwitch } = this;
    const isCircle = width === length && isRound;
    const chairsCount = isCircle
      ? chairsTotal
      : chairsTop + chairsBottom + chairsLeft + chairsRight;

    // const { deviceType } = this.props.global;
    const isVertical =
      Dimensions.get('window').width < Dimensions.get('window').height;
    const minDim = Math.min(
      Dimensions.get('window').width,
      Dimensions.get('window').height,
    );
    let wh_w;
    let wh_h;
    if (isVertical) {
      wh_w = Math.min(420, Dimensions.get('window').width - 24);
      wh_h = Math.min(420, Dimensions.get('window').height / 2) - 80;
    } else {
      wh_w = Math.min(420, Dimensions.get('window').width / 2) - 30;
      wh_h = Math.min(420, Dimensions.get('window').height) - 80;
    }

    const dismissKeyboard = () => {
      if (Platform.OS !== 'web') {
        Keyboard.dismiss();
      }
    };

    return (
      <Modal
        style={{
          margin: 0,

          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          // padding: 12,
        }}
        animationType={'fade'}
        transparent
        statusBarTranslucent
        visible={isVisible}
        onRequestClose={() => closeModal()}
        supportedOrientations={['portrait', 'landscape']}
      >
        <TouchableWithoutFeedback onPress={dismissKeyboard}>
          <View
            style={{
              flex: 1,
              backgroundColor: 'rgba(0,0,0, 0.4)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS === 'ios' ? 'padding' : 'padding'}
              enabled
            >
              <View
                style={[
                  ModalStyle.modalContainerSplit,
                  {
                    marginHorizontal: 12,
                    backgroundColor: '#f6f7f9',
                  },
                ]}
              >
                <SafeAreaView edges={smallDevice ? ['top'] : []}>
                  <View style={[ModalStyle.headerBar, { height: 60 }]}>
                    <View style={ModalStyle.headerNavigation} />
                    <Text style={ModalStyle.headerTitle}>New Table Model</Text>
                    <TouchableOpacity
                      style={ModalStyle.headerNavigation}
                      onPress={() => closeModal()}
                      hitSlop={{ top: 15, bottom: 15, left: 0, right: 0 }}
                    >
                      <Ionicons name="md-close" size={25} color="#ccc" />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      marginHorizontal: 0,
                      flexDirection: isVertical ? 'column' : 'row',
                      maxHeight: '100%',
                    }}
                  >
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: isVertical ? 'center' : 'flex-start',
                        width: wh_w,
                        marginBottom: isVertical ? 20 : 40,
                      }}
                    >
                      <this.RenderTableConfigView />
                    </View>

                    <View
                      style={{
                        paddingRight: 24,
                        paddingLeft: isVertical ? 24 : 0,
                        maxWidth: '100%',
                        width: isVertical ? 500 : 310,
                        justifyContent: 'center',
                      }}
                    >
                      <RowInput
                        label={'Name'}
                        value={name}
                        onChangeText={text => this.setState({ name: text })}
                        placeholder="Ex.: Table with 4 chairs"
                        small
                      />
                      <DimensionsInput
                        labelFirst={'Length'}
                        labelSecond={'Width'}
                        valueFirst={this.state.length}
                        valueSecond={this.state.width}
                        onChangeValueFirst={number =>
                          this.setState({ length: number })
                        }
                        onChangeValueSecond={number =>
                          this.setState({ width: number })
                        }
                        // onChangeAny={() => this.confirmDimensions()}
                        unit="cm"
                      />
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <FormRowSwitch
                          label={'Round'}
                          onValueChange={isRoundSwitch}
                          value={isRound}
                        />
                        <FormRowStaticText
                          label={'Chairs total'}
                          textAlignRight
                          value={chairsCount}
                          textStyle={{ marginTop: 0, fontSize: 17 }}
                        />
                      </View>

                      <FormButtonsGroup marginTop={12} marginBottom={24}>
                        <FormButtom
                          disabled={!name}
                          onPress={() => this.createNewModel()}
                          label={'Create Model'}
                          type={FormButtonsType.CREATE}
                        />
                      </FormButtonsGroup>
                    </View>
                  </View>
                </SafeAreaView>
              </View>
            </KeyboardAvoidingView>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

export default withGlobalContext(NewModelModal);
