import React, { useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { State, TapGestureHandler } from 'react-native-gesture-handler';
import * as Haptics from 'expo-haptics';
import { ImpactFeedbackStyle } from 'expo-haptics';
import Table from '../../Spaces/RoomsComponents/Table';
import Colors from '../../../constants/Colors';

export interface CircleButtonProps {
  size?: number;
  iconSize?: number;
  onPress?: () => void;
  inverted?: boolean;
  children?: any;
}

const CircleButton = ({
  size = 46,
  onPress = () => null,
  inverted = false,
  iconSize = 24,
  children,
}: CircleButtonProps) => {
  return (
    <TouchableOpacity
      disabled
      onPress={onPress}
      style={{
        width: size,
        height: size,
        borderRadius: 500,
        backgroundColor: !inverted ? Colors.superCoolBlue : 'white',
        justifyContent: 'center',
        alignItems: 'center',
        shadowOpacity: 0.1,
        shadowRadius: 5,
        shadowColor: '#000000',
        shadowOffset: {
          width: -1,
          height: 1,
        },
        zIndex: 1,
        elevation: 6,
      }}
    >
      {children || (
        <Ionicons
          name="add"
          size={iconSize}
          color={inverted ? Colors.superCoolBlue : 'white'}
          style={{ paddingLeft: 2, paddingTop: 1, shadowOpacity: 0.0 }}
        />
      )}
    </TouchableOpacity>
  );
};

export interface FloatButtonOptionProps {
  label?: string;
  primary?: boolean;
  inverted?: boolean;
  children?: any;
  textOpacity?: Animated.SharedValue<number>;
  iconOpacity?: Animated.SharedValue<number>;
  translateMagnitude?: number;
  onIconPress?: () => void;
}

const FloatButtonOption: React.FC<FloatButtonOptionProps> = ({
  label,
  primary = false,
  inverted = false,
  children,
  textOpacity,
  iconOpacity,
  translateMagnitude = 0,
  onIconPress = () => null,
}) => {
  const scale = useSharedValue(1);

  const animatedTextStyle = useAnimatedStyle(() => {
    if (textOpacity === undefined) return {};
    const opacity = interpolate(textOpacity.value, [0, 0.8, 1], [0, 0, 1]);
    return {
      opacity,
    };
  });

  const animatedIconContainerStyle = useAnimatedStyle(() => {
    if (iconOpacity === undefined)
      return { transform: [{ scale: scale.value }] };

    return {
      opacity: iconOpacity.value,
      transform: [{ scale: scale.value }],
    };
  });

  const animatedContainerStyle = useAnimatedStyle(() => {
    if (iconOpacity === undefined) return {};
    return {
      transform: [{ translateY: (1 - iconOpacity.value) * translateMagnitude }],
    };
  });

  const onTapGestureHandler = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      if (Platform.OS !== 'web') {
        Haptics.impactAsync(ImpactFeedbackStyle.Medium).then();
      }
      onIconPress();
    } else if (nativeEvent.state === State.BEGAN) {
      scale.value = withSpring(0.9, { stiffness: 300 });
    } else {
      scale.value = withSpring(1);
    }
  };

  return (
    <Animated.View
      style={[
        { flexDirection: 'row', alignItems: 'center' },
        animatedContainerStyle,
      ]}
      pointerEvents="box-none"
    >
      <Animated.Text
        style={[
          {
            fontSize: 16,
            fontWeight: '700',
            color: Colors.dark,
            marginRight: 10,
          },
          textOpacity && animatedTextStyle,
        ]}
      >
        {label}
      </Animated.Text>
      <TapGestureHandler onHandlerStateChange={onTapGestureHandler}>
        <Animated.View
          style={[
            {
              width: 64,
              height: 64,
              justifyContent: 'center',
              alignItems: 'center',
            },
            animatedIconContainerStyle,
          ]}
        >
          <CircleButton
            size={primary ? 54 : 44}
            iconSize={primary ? 34 : 24}
            inverted={inverted}
          >
            {children}
          </CircleButton>
        </Animated.View>
      </TapGestureHandler>
    </Animated.View>
  );
};

export interface FloatMenuProps {
  primaryItem: FloatMenuItemProps;
  items?: FloatMenuItemProps[];
}

export interface FloatMenuItemProps {
  label?: string;
  iconComponent?: React.ReactChild;
  onPress: () => void;
}

export interface FloatMenuTablesProps {
  // onPressAddTable?: () => void;
  onPressCreateGroup?: () => void;
  onPressCreateTableModel?: () => void;
}

export const FloatMenu = ({ primaryItem, items = [] }: FloatMenuProps) => {
  const isVisible = useSharedValue(0);

  const [isExpanded, setIsExpanded] = useState(false);

  const animateIsVisible = (
    toValue: number,
    delay: number = 0,
    duration: number = 200,
  ) => {
    if (toValue === 0) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }

    isVisible.value = withDelay(delay, withTiming(toValue, { duration }));
  };

  const onBackgroundTapGestureHandler = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      animateIsVisible(0);
    }
  };

  const animatedContainerStyle = useAnimatedStyle(() => {
    const bg = interpolateColor(
      isVisible.value,
      [0, 1],
      ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.98)'],
    );
    return {
      backgroundColor: bg,
    };
  });

  const onItemPress = (callback: () => void = () => null) => {
    if (items.length === 0) {
      callback();
    } else if (isExpanded) {
      callback();
      animateIsVisible(0, 0);
    } else {
      animateIsVisible(1);
    }
  };

  return (
    <TapGestureHandler
      onHandlerStateChange={onBackgroundTapGestureHandler}
      enabled={isExpanded}
    >
      <Animated.View
        style={[
          {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            alignItems: 'flex-end',
            zIndex: 1,
          },
          animatedContainerStyle,
        ]}
        pointerEvents={isExpanded ? 'auto' : 'box-none'}
        // pointerEvents={'none'}
      >
        <View
          style={{
            position: 'absolute',
            bottom: 20,
            right: 12,
            alignItems: 'flex-end',
            zIndex: 1,
          }}
          pointerEvents="box-none"
        >
          {items.map((item, key) => {
            return (
              <FloatButtonOption
                key={`${item.label}`}
                label={item.label}
                inverted
                textOpacity={isVisible}
                iconOpacity={isVisible}
                translateMagnitude={(items.length - key) * 64}
                onIconPress={() => onItemPress(item.onPress)}
              >
                {item.iconComponent}
              </FloatButtonOption>
            );
          })}

          <FloatButtonOption
            label={primaryItem.label}
            primary
            textOpacity={isVisible}
            onIconPress={() => onItemPress(primaryItem.onPress)}
          />
        </View>
      </Animated.View>
    </TapGestureHandler>
  );
};

export const FloatMenuSetupTables = ({
  // onPressAddTable = () => null,
  onPressCreateGroup = () => null,
  onPressCreateTableModel = () => null,
}: FloatMenuTablesProps) => {
  // const primaryItem: FloatMenuItemProps = {
  //   label: 'Add Table',
  //   onPress: onPressAddTable,
  // };

  const primaryItem: FloatMenuItemProps = {
    label: 'Create Group',
    onPress: onPressCreateGroup,
  };

  const createTableModelItem: FloatMenuItemProps = {
    label: 'Create Table Model',
    onPress: onPressCreateTableModel,
    iconComponent: (
      <Table
        selected
        maxWidth={33}
        maxHeight={33}
        tableLength={50}
        tableWidth={60}
        chairsTop={1}
        chairsBottom={1}
        chairsLeft={0}
        chairsRight={0}
        chairsCount={2}
        // tableNumber="+"
        isRound={false}
      >
        <Text
          style={{
            fontSize: 40,
            color: 'white',
            marginBottom: 4,
            marginLeft: 1,
          }}
        >
          +
        </Text>
      </Table>
    ),
  };

  return (
    <FloatMenu
      primaryItem={primaryItem}
      // items={[createTableModelItem, createGroupItem]}
      items={[createTableModelItem]}
    />
  );
};

const FloatButton = ({ onPress }) => {
  const primaryItem: FloatMenuItemProps = {
    onPress,
  };
  return <FloatMenu primaryItem={primaryItem} />;
};

export default FloatButton;
