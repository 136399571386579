import React from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import deviceStorage from '../../../services/DeviceStorage';
import Colors from '../../../constants/Colors';

const SelectRestaurantModal = ({
  isVisible,
  closeModal,
  restaurants,
  saveId,
}) => {
  const saveRestaurantId = id => {
    saveId(id);
    closeModal();
  };

  const availableRestaurants = restaurants?.map?.((data, index) => {
    return (
      <View style={styles.info_line} key={index}>
        <Text style={styles.info_left}>{data.name}</Text>
        <TouchableOpacity onPress={() => saveRestaurantId(data.id)}>
          <Text style={styles.info_right}>Select</Text>
        </TouchableOpacity>
      </View>
    );
  });
  // const availableRestaurants = null;

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={0.76}
      animationIn="fadeIn"
      animationOut="fadeOut"
      coverScreen
    >
      <View
        style={{
          width: '48%',
          height: Platform.OS === 'ios' ? '100%' : '110%',
          backgroundColor: '#fff',
          borderRadius: 8,
          alignSelf: 'center',
          paddingBottom: 30,
        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              alignItems: 'center',
              shadowOpacity: 0.15,
              shadowRadius: 13,
              shadowOffset: {
                height: 0,
                width: 0,
              },
              elevation: 1,
              marginTop: 20,
            }}
          >
            <Text style={{ fontSize: 23, marginBottom: 20 }}>
              Select Restaurant
            </Text>
          </View>
          <ScrollView>{availableRestaurants}</ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default SelectRestaurantModal;

const styles = StyleSheet.create({
  info_left: {
    fontSize: 18,
    // fontWeight: '500',
    marginTop: 16,
    marginLeft: 12,
    flex: 2,
    flexDirection: 'row',
  },
  info_right: {
    marginRight: 12,
    fontSize: 18,
    fontWeight: '400',
    marginTop: 16,
    flex: 4,
    textAlign: 'right',
    color: Colors.lightBlue,
  },
  info_line: {
    flexDirection: 'row',
    borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    paddingBottom: 12,
    marginHorizontal: 24,
  },

  reservationContainer: {
    margin: 40,
    marginHorizontal: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 46,
    backgroundColor: '#f95b54',
    // borderWidth: 1.3,
    borderColor: '#00f',
    // paddingVertical: 12,
    borderRadius: 45,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 18,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});
