import React, { useContext } from 'react';
import { Ionicons } from '@expo/vector-icons';

import Colors from '../constants/Colors';
import { Text, View } from 'react-native';
import { ReservationsContext } from '../contex/ReservationsContext';
import { ActivityContext } from '../contex/ActivityContext';

export default function TabBarIcon({ name, size, focused, color, showBadge }) {
  const { unseenNewReservations } = useContext(ActivityContext);
  const badgeCount = unseenNewReservations;
  return (
    <View style={{ width: size, height: size }}>
      <Ionicons
        name={name}
        size={size}
        style={{ marginBottom: -3 }}
        color={focused ? color : Colors.tabIconDefault}
      />
      {showBadge && badgeCount > 0 && (
        <View
          style={{
            position: 'absolute',
            right: -6,
            top: -3,
            // width: 16,
            height: 15,
            paddingHorizontal: 4,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,45,45,1)',
            shadowColor: 'rgb(154,5,5)',
            shadowOpacity: 0.3,
            shadowRadius: 2,
            shadowOffset: {
              width: -1,
              height: 1,
            },
            // borderWidth: 2,
            // borderColor: 'white',
          }}
        >
          <Text style={{ color: 'white', fontSize: 9, fontWeight: '700' }}>
            {badgeCount < 100 ? badgeCount : '99'}
          </Text>
        </View>
      )}
    </View>
  );
}
