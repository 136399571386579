import React, { useContext, useEffect, useState } from 'react';

import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { format } from 'date-fns';
import ReservationOverviewModal from './Timeline/modals/ReservationOverviewModal';
import ReservationItem from '../components/ReservationItem';
import { Reservation } from '../transofrmations/reservationsTransformer';
import { ActivityContext } from '../contex/ActivityContext';
import Colors from '../constants/Colors';

export default function ActivityScreen({ navigation }: any) {
  const [selectedReservation, setSelectedReservation] = useState(null);

  const {
    upcomingReservations,
    refreshing,
    updatedDate,
    refreshUpcomingReservations,
    markAllLogsAsSeen,
  } = useContext(ActivityContext);

  useEffect(() => {
    markAllLogsAsSeen();
    const listener = navigation.addListener('didFocus', () => {
      markAllLogsAsSeen();
      refreshUpcomingReservations();
    });
    return () => listener.remove();
  }, []);

  interface RenderReservationProps {
    item: Reservation;
  }

  const renderItem = ({ item }: RenderReservationProps) => (
    <ReservationItem
      dateFrom={item.dateTimeFromISOString}
      dateTo={item.dateTimeToISOString}
      dateCreated={item.createdOnDate}
      name={item.customer?.fullName}
      persons={item.persons}
      multiTable={item.multiTable}
      cancelled={item.cancelled}
      createdByRestaurant={item.createdByRestaurant}
      webForm={item.createdByNonRegisteredCustomer}
      walkIn={item.walkIn}
      messages={item?.messages}
      onPress={() => setSelectedReservation(item)}
      showStatus
      showTimeAgo
      pending={item.pendingConfirmation}
      // hideIcons
    />
  );

  return (
    <SafeAreaView style={[styles.container]}>
      <View style={{ width: 600, maxWidth: '100%', flex: 1 }}>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Recent Reservations</Text>

          <TouchableOpacity onPress={() => refreshUpcomingReservations()}>
            <Text style={styles.lastUpdated}>
              Updated at {format(updatedDate, 'HH:mm')}
            </Text>
          </TouchableOpacity>
        </View>
        <FlatList
          data={upcomingReservations}
          renderItem={renderItem}
          keyExtractor={(item) => `${item.id}`}
          refreshing={refreshing}
          onRefresh={refreshUpcomingReservations}
          contentContainerStyle={{ paddingHorizontal: 10, paddingBottom: 10 }}
        />
        <ReservationOverviewModal
          isVisible={!!selectedReservation}
          onBackDropPress={() => setSelectedReservation(null)}
          onSwipeComplete={() => setSelectedReservation(null)}
          selectedReservation={selectedReservation}
        />
      </View>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'white',
    backgroundColor: Colors.lightBlueBackground,
    alignItems: 'center',
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  lastUpdated: {
    color: '#9ca0a4',
    fontSize: 12,
    backgroundColor: '#edeeef',
    padding: 6,
    borderRadius: 6,
    overflow: 'hidden',
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
});
