import React from 'react';
import { Platform } from 'react-native';

import { createStackNavigator } from 'react-navigation-stack';

import TabBarIcon from '../components/TabBarIcon';

import ProfileSettings from '../screens/Settings/ProfileSettings';
import ReservationSettings from '../screens/Settings/ReservationsSettings';
import OpenHoursSettings from '../screens/Settings/OpenHoursSettings';
import HolidaysSettings from '../screens/Settings/HolidaysSettings';

const SettingsStack = createStackNavigator(
  {
    profile: ProfileSettings,
    reservations: ReservationSettings,
    openHours: OpenHoursSettings,
    holidays: HolidaysSettings,
  },
  {
    headerMode: 'none',
    headerShown: false,
    defaultNavigationOptions: {
      animationEnabled: false,
    },
  },
);

SettingsStack.navigationOptions = {
  tabBarLabel: 'Settings',
  tabBarIcon: ({ focused, tintColor }) => (
    <TabBarIcon
      focused={focused}
      name="options"
      size={Platform.OS === 'web' ? 26 : 22}
      color={tintColor}
    />
  ),
};

SettingsStack.path = 'settings';

export default SettingsStack;
