import { Platform, Text, View } from 'react-native';
import React from 'react';

const GridHours = ({ rowLength, rowHeight, columns, rowNumber }) => {
  const row = [];
  for (let i = 0; i < columns + 1; i += 1) {
    row.push(
      <View
        key={i}
        style={{
          width: rowLength,
          height: rowHeight,
          // borderColor: '#dddddd',
          borderColor: '#dadde1',
          // borderTopWidth: rowNumber === 0 ? 0.6 : 0,
          borderBottomWidth: 0.4,
          borderRightWidth: Platform.OS === 'ios' ? 1 : 1,
          flexDirection: 'row',
        }}
      >
        <View
          style={{ borderRightWidth: 0.4, borderColor: '#ebeef1', flex: 1 }}
        />
        <View
          style={{ borderRightWidth: 0.4, borderColor: '#ebeef1', flex: 1 }}
        />
        <View style={{ flex: 1 }} />
        <View
          style={{ borderLeftWidth: 0.4, borderColor: '#ebeef1', flex: 1 }}
        />
      </View>,
    );
  }
  return row;
};

const DrawGrid = ({ rows, columns, rowLength, rowHeight }) => {
  const grid = [];
  for (let i = 0; i < rows; i += 1) {
    grid.push(
      <View key={i} style={{ flexDirection: 'row', marginLeft: 0 }}>
        <GridHours
          rowLength={rowLength}
          rowHeight={rowHeight}
          columns={columns}
          rowNumber={i}
        />
      </View>,
    );
  }
  return grid;
};

export default DrawGrid;
