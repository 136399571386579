import React, { useContext, useEffect, useState } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';

import moment from 'moment';
import { format, parse } from 'date-fns';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Colors from '../../../constants/Colors';
// import Collapsible from 'react-native-collapsible';
import { GlobalContext } from '../../../GlobalContext';
import ReservationServices from '../../../services/ReservationsServices';
import DynamicContainer from './DynamicContainer';
import {
  FormButtom,
  FormButtonsGroup,
  FormButtonsType,
  FormRowStaticText,
  RowHorizontalSelector,
  RowInput,
  RowInputStepper,
} from '../../../components/Form';
import { DATETIME_FORMAT } from '../../../contex/DateTimeContext';
import HeaderButton from '../../../components/HeaderButton';

const NewMultitableReservation = ({
  dateFrom,
  dateTo,
  selectedTables,
  vertical,
  expand,
  onSuccess,
  onBack,
  onSelectTablesClick,
  animatedHeight,
  reanimatedContainerHeight,
}) => {
  const global = useContext(GlobalContext);

  const [selectedPersons, setSelectedPersons] = useState(0);
  const [name, setName] = useState('');
  const [note, setNote] = useState('');
  const [phone, setPhone] = useState('');
  const [chairsMax, setChairsMax] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const chairsTotal = selectedTables.reduce(
      (accumulator, current) => accumulator + current.chairs.count,
      0,
    );
    setChairsMax(chairsTotal);
    setSelectedPersons(chairsTotal);
  }, [selectedTables]);

  const updatePersonsCount = (value) => {
    setSelectedPersons(value);
  };

  const submitReservation = () => {
    const { restaurantId, jwtToken, showSnackbarMessage, showAlert } = global;

    const data = {};
    data.customer = { fullname: name, phone };
    data.restaurant_id = restaurantId;
    if (selectedTables.length === 0) {
      showSnackbarMessage(
        `Error: Please select at least one table for reservation.`,
      );
      return;
    }
    if (name == null || name === '') {
      showSnackbarMessage(`Error: Please enter name`);
      return;
    }
    setLoading(true);

    data.tables = selectedTables.map((table) => {
      return table.id;
    });
    data.date_from = dateFrom;
    data.date_to = dateTo;
    data.persons = selectedPersons;
    data.note = note;

    ReservationServices.submitReservation(jwtToken, data).then((result) => {
      if (result.ok) {
        setTimeout(() => {
          setLoading(false);
          showAlert('Reservation was added successfully', 'success');
          onSuccess();
        }, 1500);
      } else {
        setLoading(false);
        showAlert(`Something went wrong: ${result.message}`, 'warning');
        // showSnackbarMessage();
      }
    });
  };

  const selectedTablesX =
    selectedTables.length > 0
      ? selectedTables
      : [{ label: 'select', none: true }];

  return (
    <DynamicContainer
      vertical={vertical}
      title="New Reservation"
      animatedHeight={animatedHeight}
      reanimatedContainerHeight={reanimatedContainerHeight}
      buttonComponent={
        <HeaderButton
          // label={'New'}
          small
          icon="close"
          backgroundColor={null}
          color={Colors.lighter2}
          style={{
            height: 36,
            width: 36,
            marginRight: 12,
            paddingHorizontal: 0,
            paddingLeft: 1,
            borderRadius: 8,
          }}
          textStyle={{ fontSize: 14, fontWeight: '500' }}
          onPress={() => onBack()}
          iconSize={26}
          // shadow
        />
      }
    >
      <KeyboardAwareScrollView
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          flex: 1,
          // backgroundColor: 'red',
        }}
        // viewIsInsideTabBar
        // viewIsInsideTabBar
        extraHeight={vertical ? 280 : 200}
        enableAutomaticScroll
        keyboardShouldPersistTaps="handled"
        // onKeyboardWillShow={frames => {
        //   expand();
        // }}
      >
        <FormRowStaticText
          label="When"
          disabled
          value={format(
            parse(dateFrom, DATETIME_FORMAT, new Date()),
            'eeee, d. MMMM yyyy',
          )}
        />
        <FormRowStaticText
          label="Time"
          disabled
          value={`${moment(dateFrom).format('HH:mm')} - ${moment(dateTo).format(
            'HH:mm',
          )}`}
        />
        <RowHorizontalSelector label="Tables" height={38}>
          {selectedTablesX.map((table) => {
            return (
              <TouchableWithoutFeedback
                onPress={onSelectTablesClick}
                disabled={!table.none}
                key={table.label}
              >
                <View
                  style={{
                    backgroundColor: Colors.lightererer,
                    borderWidth: 1,
                    borderColor: '#e0e7ee',
                    height: 32,
                    borderRadius: 8,
                    paddingHorizontal: 12,
                    marginHorizontal: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      color: table.none ? Colors.light : Colors.dark,
                      fontWeight: table.none ? '500' : '500',
                      // fontStyle: 'italic',
                      fontSize: 14,
                    }}
                  >
                    {table.label}
                  </Text>
                </View>
              </TouchableWithoutFeedback>
            );
          })}
        </RowHorizontalSelector>

        <RowInput
          small
          label="Full name"
          onChangeText={(text) => setName(text)}
          placeholder="Guest name"
          autoCapitalize="words"
          value={name}
          onFocus={expand}
        />
        <RowInput
          small
          label="Phone"
          onChangeText={(text) => setPhone(text)}
          placeholder="+12 345 578"
          keyboardType="number-pad"
          value={phone}
          onFocus={expand}
        />
        <RowInputStepper
          value={selectedPersons}
          maxValue={chairsMax}
          onValueUpdate={updatePersonsCount}
          label="Guests"
          small
        />

        <RowInput
          small
          multiline
          label="Note"
          onChangeText={(text) => setNote(text)}
          placeholder="Additional information about reservation"
          onFocus={expand}
          value={note}
        />
        <FormButtonsGroup marginTop={0} marginBottom={15}>
          <FormButtom
            disabled={!name || selectedTables.length === 0}
            loading={loading}
            onPress={() => submitReservation()}
            label="Confirm reservation"
            type={FormButtonsType.CREATE}
          />
        </FormButtonsGroup>
        {/* <View style={{ height: vertical ? 400 : 0 }} /> */}
      </KeyboardAwareScrollView>
    </DynamicContainer>
  );
};

export default NewMultitableReservation;
