import { Platform, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

const GLOBAL = require('../constants/Globals');

const Buttons = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'center',
  },
  box: {
    height: 43,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
  },
  big: {
    alignSelf: 'center',
    backgroundColor: Colors.darkBlue,
    height: 43,
    flex: 1,
    // marginHorizontal: 5,
    borderRadius: 12,
    // borderRadius: 4,
    borderColor: '#aaa',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 4,
    marginBottom: 12,
  },
  border: {
    backgroundColor: 'transparent',
    shadowOpacity: 0,
    elevation: 0,
    borderWidth: 1,
    marginBottom: 5,
    borderColor: Colors.someGreen,
  },
  green: {
    backgroundColor: Colors.confirmGreen,
  },
  red: {
    backgroundColor: Colors.dangerRed,
    // flex: 0,
  },
  grey: {
    backgroundColor: Colors.lighter,
  },
  dark: {
    backgroundColor: Colors.darker,
  },
  white: {
    backgroundColor: Colors.white,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  whiteFont: {
    color: Colors.white,
  },
  blueFont: {
    color: Colors.lightBlue,
  },
  greenFont: {
    color: Colors.confirmGreen,
  },
  rounded: {
    borderRadius: 200,
  },
  text: {
    fontSize: 14,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: '500',
    // paddingHorizontal: 10,
    // paddingVertical: 10,
  },
  button: {
    backgroundColor: Colors.someGreen,
    alignItems: 'center',
    height: 34,
    paddingHorizontal: 11,
    // borderRadius: 6,
    borderRadius: 11,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  shadow: {
    shadowOpacity: Platform.OS === 'web' ? 0.1 : 0.16,

    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 0,
    },
    elevation: 4,
  },

  blueBackground: {
    backgroundColor: Colors.lightBlue,
  },

  buttonSmall: {
    height: 35,
    width: 35,
    // paddingTop: 1,
    paddingLeft: 1,
    // borderRadius: 10,
    // borderRadius: 4,
    paddingHorizontal: 0,
  },
  buttonIcon: {
    color: Colors.white,
    marginLeft: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    // height: 22,
    // width: 20,
  },
  buttonText: {
    fontSize: 14,
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.white,
    fontWeight: Platform.OS === 'ios' ? '500' : '600',
  },
});

export default Buttons;
