import moment from 'moment';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import Colors from '../constants/Colors';

const ReservationItem = ({
  dateFrom,
  dateTo,
  dateCreated,
  name,
  persons,
  createdByRestaurant,
  multiTable,
  cancelled,
  onPress,
  messages,
  webForm,
  walkIn,
  style,
  hideDate,
  showStatus,
  showTables,
  tables,
  hideMessages,
  hideIcons,
  showTimeAgo,
  showMessagesTopRight,
  disableOnPress,
  guestsRightIcon,
  pending,
}: any) => {
  const reservationDateFormatted = moment(dateFrom).format('DD. MMMM YYYY');
  const reservationTimeFromFormatted = moment(dateFrom).format('HH:mm');
  const reservationTimeToFormatted = moment(dateTo).format('HH:mm');
  const dateTimeCreatedAgo = moment.utc(dateCreated).fromNow();
  const past = moment(dateTo).isBefore(moment());
  const active = !past && moment(dateFrom).isBefore(moment());
  const reservationDateIn = moment(dateFrom).fromNow();
  return (
    <TouchableOpacity
      style={[
        styles.item,
        createdByRestaurant && styles.itemByRestaurant,
        multiTable && styles.itemMultiTable,
        pending && styles.itemPending,
        walkIn && styles.itemWalkIn,
        cancelled && styles.itemCancelled,
        style,
      ]}
      onPress={onPress}
      disabled={disableOnPress}
    >
      {showStatus && (
        <Text
          style={[
            styles.itemTextStaringIn,
            past && styles.itemTextPast,
            cancelled && styles.itemTextCanceled,
            active && styles.itemTextActive,
          ]}
        >
          {cancelled && 'Canceled'}
          {!cancelled && past && !active && 'Completed'}
          {!cancelled && !past && !active && `starting ${reservationDateIn}`}
          {!cancelled && active && `seated`}
        </Text>
      )}
      <View style={{ flexShrink: 1 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={[styles.itemTextSpecial]}>
            {name && name !== ' ' ? name : ''}
            <Text
              style={{
                fontWeight: Platform.OS === 'ios' ? '400' : '400',
                // fontStyle: 'italic',
                color: Colors.light,
              }}
            >
              {walkIn && (!name || name === ' ') ? 'walk-in' : ''}
              {!walkIn && !name ? 'Guest' : ''}
            </Text>
          </Text>
          {pending && (
            <View
              style={{
                backgroundColor: 'rgb(255,237,219)',
                paddingHorizontal: 5,
                height: 17,
                justifyContent: 'center',
                borderRadius: 4,
                marginLeft: 6,
              }}
            >
              <Text
                style={{
                  color: 'rgb(248,146,78)',
                  fontSize: 10,
                  fontWeight: '700',
                  // fontStyle: 'italic',
                }}
              >
                PENDING
              </Text>
            </View>
          )}
        </View>
        {!hideDate && (
          <Text style={[styles.itemText]}>
            {!hideIcons ? (
              <Ionicons
                name="calendar-outline"
                size={13}
                // color={'#aaa'}
                style={styles.iconStyle}
              />
            ) : (
              ''
            )}
            {!hideIcons ? '  ' : ''}
            {reservationDateFormatted}
          </Text>
        )}
        <Text style={[styles.itemText]}>
          {!hideIcons ? (
            <Ionicons
              name="time-outline"
              size={13}
              // color={'#aaa'}
              style={styles.iconStyle}
            />
          ) : (
            ''
          )}
          {!hideIcons ? '  ' : ''}
          {reservationTimeFromFormatted} - {reservationTimeToFormatted}
        </Text>

        {!guestsRightIcon && (
          <Text style={[styles.itemText]}>
            {!hideIcons ? (
              <Ionicons
                name="person-outline"
                size={13}
                style={styles.iconStyle}
              />
            ) : (
              ''
            )}
            {!hideIcons ? '  ' : ''}
            {persons}
            {!hideIcons ? '' : ' guest'}
            {hideIcons && persons !== 1 ? 's' : ''}
          </Text>
        )}
        {/* {showTables && ( */}
        {/*  <View style={{ flexDirection: 'row', marginTop: 4 }}> */}
        {/*    {tables.map(table => { */}
        {/*      return ( */}
        {/*        <View */}
        {/*          style={{ */}
        {/*            backgroundColor: '#eceff3', */}
        {/*            borderRadius: 6, */}
        {/*            paddingHorizontal: 8, */}
        {/*            paddingVertical: 4, */}
        {/*            marginRight: 3, */}
        {/*          }} */}
        {/*        > */}
        {/*          <Text */}
        {/*            style={[styles.itemTextTables]} */}
        {/*            numberOfLines={1} */}
        {/*            adjustsFontSizeToFit */}
        {/*          >{`${table.label}`}</Text> */}
        {/*        </View> */}
        {/*      ); */}
        {/*    })} */}
        {/*  </View> */}
        {/* )} */}
        {guestsRightIcon && <View style={{ height: 12 }} />}
        {!hideMessages && (
          <Text
            style={[
              styles.itemText,
              messages.length === 0 && { fontStyle: 'italic', color: '#bbb' },
            ]}
          >
            <Ionicons
              name="chatbox-outline"
              size={13}
              style={[
                styles.iconStyle,
                messages.length === 0 && { color: '#bbb' },
              ]}
            />
            {'  '}
            {messages.length === 0 ? 'no messages' : messages.length}
          </Text>
        )}
        {/* <View style={{ height: 12 }} /> */}
      </View>
      <View style={styles.itemRightContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {showTimeAgo && (
            <Text style={styles.itemTextDate}>{dateTimeCreatedAgo}</Text>
          )}

          {guestsRightIcon && (
            <Text
              style={[
                styles.itemTextMessageIcon,
                {
                  color: '#5d6e7c',
                  fontWeight: '700',
                  marginTop: 1,
                },
              ]}
            >
              <Text>{persons}</Text>
            </Text>
          )}
          {guestsRightIcon && (
            <Text
              style={[
                styles.itemTextMessageIcon,
                {
                  color: '#5d6e7c',
                  marginLeft: 1,
                },
              ]}
            >
              <Ionicons name="person" size={12} />{' '}
            </Text>
          )}
          {showMessagesTopRight && (
            <Text
              style={[
                styles.itemTextMessageIcon,
                { marginTop: 2 },
                messages.length === 0
                  ? { color: '#e9ebee' }
                  : { color: '#768896' },
              ]}
            >
              <Ionicons
                name={messages.length === 0 ? 'chatbox' : 'chatbox'}
                size={13}
              />
              {/* {' '}{messages.length} */}
            </Text>
          )}
        </View>

        {showTables && (
          <View style={{ flexDirection: 'row', marginTop: 4 }}>
            {tables.map((table) => {
              return (
                <View
                  key={`${table.id}`}
                  style={{
                    // backgroundColor: '#e4e9ef',
                    backgroundColor: 'rgb(120,155,180)',
                    borderRadius: 4,
                    paddingHorizontal: 6,
                    paddingVertical: 3,
                    marginLeft: 4,
                  }}
                >
                  <Text
                    style={[styles.itemTextTables]}
                    numberOfLines={1}
                    adjustsFontSizeToFit
                  >{`${table.label}`}</Text>
                </View>
              );
            })}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    marginHorizontal: 2,
    overflow: 'visible',
    zIndex: 10,
    marginTop: 5,
    marginBottom: 5,
    paddingHorizontal: 12,
    paddingTop: 10,
    paddingBottom: 10,
    shadowOpacity: Platform.OS === 'web' ? 0.08 : 0.05,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    borderLeftWidth: 4,
    borderRadius: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderColor: '#fc6363',
    shadowRadius: 5,
    elevation: 3,
    // borderTopRightRadius: 0,
    // borderBottomRightRadius: 0,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemByRestaurant: {
    borderColor: '#6dd275',
  },
  itemOnline: {
    // borderColor: '#fc6363',
  },
  itemPending: {
    borderColor: '#ffbc78',
  },
  itemWalkIn: {
    borderColor: 'rgb(248,155,218)',
    // borderColor: 'rgba(109,196,193,1)',
  },
  itemCancelled: {
    borderColor: '#c3c8cb',
  },
  itemMultiTable: {
    borderColor: '#5ca5ef',
  },
  itemText: {
    fontSize: 14,
    paddingTop: 1,
    color: '#3f4e59',
  },
  itemTextSpecial: {
    color: '#2c3441',
    fontWeight: 'bold',
    // textTransform: 'uppercase',
    // paddingBottom: 1,
    fontSize: 14,
  },
  itemTextDate: {
    color: '#bec1c5',
  },
  itemTextMessageIcon: {
    // marginTop: 2,
    fontSize: 14,
  },
  itemTexOnline: {
    color: Colors.dangerRed,
    paddingTop: 5,
    // textTransform: 'uppercase',
  },
  itemTextStaringIn: {
    // color: '#ef9118',
    color: '#29c550',

    // color: '#40732e',
    fontWeight: '600',
    textTransform: 'uppercase',
    textAlign: 'right',
    position: 'absolute',
    bottom: 10,
    right: 10,
    // textTransform: 'capitalize',
    fontSize: 12,
    marginTop: 7,
  },
  itemTextTables: {
    // color: Colors.darkLighter,
    color: Colors.white,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'right',
    fontSize: 11,
    // marginTop: 7,
  },
  itemTextPast: {
    color: '#575a65',
  },
  itemTextActive: {
    // color: '#29c550',
    color: Colors.darkBlue,
  },
  itemTextCanceled: {
    color: '#ccc',
  },
  itemRightContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: 1,
    paddingLeft: 30,
    // maxWidth: '50%',
  },
  iconStyle: {
    color: '#233a4b',
  },
  dotContainer: {
    flexDirection: 'row',
    marginTop: 6,
    height: 6,
    // marginLeft: 1,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: '#f54759',
    marginRight: 4,
  },
  dotNote: {
    backgroundColor: '#fdde41',
  },
});
export default ReservationItem;
