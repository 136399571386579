import React, { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../GlobalContext';
import {
  Reservation,
  transformReservations,
} from '../transofrmations/reservationsTransformer';
import { Room, transformRooms } from '../transofrmations/roomsTransformer';
import { DateTimeContext } from './DateTimeContext';
import { ActivityContext } from './ActivityContext';
import ReservationServices from '../services/ReservationsServices';
import { addHours } from 'date-fns';

export interface ReservationsByDate {
  [date: string]: Reservation[];
}

export type ReservationsContextState = {
  reservations: Reservation[];
  rooms: Room[];
  refreshReservations: () => void;
  refreshReservationsAsync: () => Promise<boolean>;
};

const contextDefaultValues: ReservationsContextState = {
  reservations: [],
  rooms: [],
  refreshReservations: () => {},
  refreshReservationsAsync: () => new Promise(() => true),
};

export const ReservationsContext = createContext<ReservationsContextState>(
  contextDefaultValues,
);

const ReservationsProvider: React.FC = ({ children }) => {
  const [reservations, setReservations] = useState<Reservation[]>(
    contextDefaultValues.reservations,
  );

  const [rooms, setRooms] = useState<Room[]>(contextDefaultValues.rooms);

  const { jwtToken, restaurantId, rooms: roomsRaw } = useContext(GlobalContext);
  const { selectedDateString } = useContext(DateTimeContext);
  const { upcomingReservations, refreshUpcomingReservationsAsync } = useContext(
    ActivityContext,
  );

  useEffect(() => {
    setRooms(transformRooms(roomsRaw));
  }, [roomsRaw]);

  useEffect(() => {
    if (
      jwtToken &&
      restaurantId &&
      upcomingReservations &&
      selectedDateString
    ) {
      setReservationsOnSelectedDate();
    }
  }, [upcomingReservations]);

  useEffect(() => {
    if (
      jwtToken &&
      restaurantId &&
      selectedDateString &&
      upcomingReservations
    ) {
      setReservations([]);
      setReservationsOnSelectedDate();
    }
  }, [selectedDateString]);

  const setReservationsOnSelectedDate = (
    refreshFirst = false,
    callback = () => null,
  ) => {
    if (
      new Date(selectedDateString) < addHours(new Date(), -24) ||
      refreshFirst
    ) {
      getReservationsOnDate(selectedDateString).then(res => {
        setReservations(res);
        callback();
      });
    } else {
      const reservationsOnSelectedDate = upcomingReservations.filter(
        res => res.dateString === selectedDateString,
      );
      setReservations(reservationsOnSelectedDate);
      callback();
    }
  };

  const refreshReservations = (skipEmpty = false) => {
    refreshReservationsAsync()
      .then(() => {
        // console.log('reloaded');
      })
      .catch(error => {
        // console.log(error);
      });
  };

  const refreshReservationsAsync = async () => {
    // console.log(selectedDateString);
    // getReservationsOnDate(selectedDateString)
    //   .then(res => {
    //     console.log('hahaha');
    //     console.log(res);
    //     setReservations(res);
    //     return new Promise<boolean>((resolve, reject) => {
    //       return resolve(true);
    //     });
    //   })
    //   .catch(() => {
    //     return new Promise<boolean>((resolve, reject) => {
    //       return resolve(false);
    //     });
    //   });
    return new Promise<boolean>((resolve, reject) => {
      refreshUpcomingReservationsAsync().then(() => {
        setReservationsOnSelectedDate(true, () => resolve(true));
        // resolve(true);
        // setTimeout(() => {}, 200);
      });
    });
    // return refreshUpcomingReservationsAsync();
  };

  const getReservationsOnDate = async (dateString: string) => {
    const raw = await ReservationServices.getReservations2(
      jwtToken,
      restaurantId,
      dateString,
    );
    if (raw) {
      const reservationsOnDate = transformReservations(raw);
      return new Promise<Reservation[]>((resolve, reject) => {
        return resolve(reservationsOnDate);
      });
    } else {
      return new Promise<Reservation[]>((resolve, reject) => {
        return resolve([]);
      });
    }
  };

  const value = React.useMemo(
    () => ({
      reservations,
      rooms,
      refreshReservations,
      refreshReservationsAsync,
    }),
    [reservations, rooms],
  );

  return (
    <ReservationsContext.Provider value={value}>
      {children}
    </ReservationsContext.Provider>
  );
};

export default ReservationsProvider;
export const withReservationsContext = ChildComponent => props => (
  <ReservationsContext.Consumer>
    {context => <ChildComponent {...props} reservationsContext={context} />}
  </ReservationsContext.Consumer>
);
