import { Text, TouchableOpacity, View } from 'react-native';
import React from 'react';

import { withGlobalContext } from '../GlobalContext';

import { Button, DefaultTheme, Dialog, Portal } from 'react-native-paper';
import Colors from '../constants/Colors';

const AreYouSureWrapper = props => {
  const {
    children,
    style,
    disabled,
    title,
    message,
    onConfirm = () => {},
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onPress = () => {},
    containerStyle,
  } = props;

  const [visible, setVisible] = React.useState(false);
  const showDialog = () => {
    setVisible(true);
    onPress();
  };
  const hideDialog = () => {
    setVisible(false);
  };

  const confirmAndHideDialog = () => {
    setVisible(false);
    onConfirm();
  };

  return (
    <View style={style}>
      <TouchableOpacity
        onPress={showDialog}
        disabled={disabled}
        style={containerStyle}
      >
        {children}
      </TouchableOpacity>
      <Portal>
        <Dialog
          visible={visible}
          theme={{ ...DefaultTheme, dark: false }}
          onDismiss={hideDialog}
          style={{
            width: 340,
            alignSelf: 'center',
            flexShrink: 1,
            marginHorizontal: 20,
          }}
        >
          <Dialog.Title theme={{ ...DefaultTheme, dark: false }}>
            {title}
          </Dialog.Title>
          <Dialog.Content>
            <Text>{message}</Text>
          </Dialog.Content>
          {/*<Divider />*/}
          <Dialog.Actions
            style={{ justifyContent: 'space-between', paddingHorizontal: 16 }}
          >
            <Button onPress={hideDialog} color={Colors.lightBlue}>
              {cancelText}
            </Button>
            <Button onPress={confirmAndHideDialog} color={Colors.dangerRed}>
              {confirmText}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default withGlobalContext(AreYouSureWrapper);
