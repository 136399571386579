export interface Log {
  timestampString: string;
  seen: boolean;
  actionType: 'update' | 'create';
  actionBy: 'restaurant' | 'guest';
}

export const transformLogs = (logs: any): Log[] => {
  if (logs) {
    return logs.map((log: any) => {
      return transformLog(log);
    });
  }
  return [];
};

export const transformLog = (logApiData: any): Log => {
  return {
    timestampString: logApiData.timestamp,
    seen: logApiData.seen,
    actionType: logApiData.action_type,
    actionBy: logApiData.action_by,
  };
};
