import React from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import { Portal } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ModalStyle from '../../../styles/ModalStyle';

const ModalWrapper = ({
  children,
  onClose,
  isVisible,
  title,
  disableScroll,
  width,
  backgroundColor,
  style,
  headerTitleComponent,
}) => {
  // const { children, onClose, isVisible, title, disableScroll, width } = props;

  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const isSmall = dimensions.width < 400;

  return (
    <Modal
      // isVisible={isVisible}
      // backdropOpacity={0.65}
      // animationIn="fadeIn"
      // animationInTiming={200}
      // animationOut="fadeOut"
      // animationOutTiming={500}
      // onBackdropPress={() => onClose()}
      // coverScreen
      // scrollHorizontal
      // backdropTransitionOutTiming={0}
      // hideModalContentWhileAnimating
      // style={{ margin: 0, alignItems: 'center', justifyContent: 'center' }}
      style={{
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      statusBarTranslucent
      animationType="fade"
      transparent
      visible={isVisible}
      onRequestClose={() => onClose()}
      supportedOrientations={['portrait', 'landscape']}
    >
      <Portal.Host>
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0, 0.5)',
            // backgroundColor: 'red',
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 12,
            flexDirection: 'row',
            // paddingBottom: 0,
          }}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'padding'}
          >
            <View
              style={[
                ModalStyle.modalContainer,
                width && { width },
                backgroundColor && { backgroundColor },
                // { maxHeight: '100%' },
                { flex: 0, flexShrink: 1 },
                // isSmall &&
                {
                  // maxWidth: '95%',
                  // flex: 1,
                  // paddingTop: insets.top - 10,
                  // borderRadius: 0,
                },
              ]}
            >
              <View style={ModalStyle.headerBar}>
                {title && <View style={ModalStyle.headerNavigation} />}
                {headerTitleComponent}
                {title && <Text style={ModalStyle.headerTitle}>{title}</Text>}
                <TouchableOpacity
                  style={ModalStyle.headerNavigation}
                  onPress={() => onClose()}
                  hitSlop={{ top: 15, bottom: 15, left: 0, right: 0 }}
                >
                  <Ionicons name="md-close" size={24} color="#ccc" />
                </TouchableOpacity>
              </View>
              <ScrollView
                style={[{ paddingHorizontal: 20, width: '100%' }, style]}
                scrollEnabled={!disableScroll}
                // contentContainerStyle={{ maxHeight: '90%' }}
              >
                {children}
              </ScrollView>
            </View>
          </KeyboardAvoidingView>
        </View>
      </Portal.Host>
    </Modal>
  );
};

export default ModalWrapper;
