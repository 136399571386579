import React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';

import Colors from '../../../constants/Colors';
import Table from './Table';

const RoomStatic = ({
  room,
  tables,
  roomContainer,
  occupiedTables,
  selectedTables,
  registerTableClick = () => null,
  enableSelection,
  backgroundColor,
  reanimatedWidth,
  reanimatedHeight,
  reanimatedScaleFactor,
  liveMode,
}) => {
  const animatedStyleContainer =
    reanimatedWidth && reanimatedHeight
      ? useAnimatedStyle(() => {
          return {
            width: reanimatedWidth.value,
            height: reanimatedHeight.value,
          };
        }, [reanimatedWidth, reanimatedHeight])
      : {};

  const animatedStyleContainer2 =
    room && reanimatedScaleFactor
      ? useAnimatedStyle(() => {
          return {
            transform: [
              { translateX: -room.dimensions.width / 2 },
              { translateY: -room.dimensions.height / 2 },
              { scale: reanimatedScaleFactor.value },
              { translateX: room.dimensions.width / 2 },
              { translateY: room.dimensions.height / 2 },
            ],
          };
        }, [room, reanimatedScaleFactor])
      : {};

  return (
    <Animated.View
      style={[
        {
          // backgroundColor,
          // flex: 1,
          flexDirection: 'row',
        },
        !reanimatedWidth && {
          width: roomContainer?.width,
          height: roomContainer?.height,
        },
        reanimatedWidth && animatedStyleContainer,
      ]}
    >
      {(roomContainer || reanimatedHeight) && room && (
        <Animated.View
          style={[
            {
              position: 'absolute',
              width: room.dimensions.width,
              height: room.dimensions.height,
              borderWidth: 1,
              borderRadius: 10,
              backgroundColor: 'rgb(248,249,250)',
              borderColor: Colors.lighterer,
            },
            !reanimatedScaleFactor && {
              transform: [
                { translateX: -room.dimensions.width / 2 },
                { translateY: -room.dimensions.height / 2 },
                { scale: roomContainer.scaleFactor },
                { translateX: room.dimensions.width / 2 },
                { translateY: room.dimensions.height / 2 },
              ],
            },
            reanimatedScaleFactor && animatedStyleContainer2,
          ]}
        >
          {tables.map((table, key) => {
            const isOccupied = liveMode
              ? table?.tableStatus?.active
              : table?.tableStatus?.occupied;
            const timeLeftPercent =
              table?.tableStatus?.remainingDurationPercent;
            const nextReservationTime = table?.tableStatus?.nextReservationTime;
            return (
              <TouchableOpacity
                onPress={() => registerTableClick(table)}
                disabled={!enableSelection}
                key={`x${table.position.x}y${table.position.y}id${table.id}`}
                style={{
                  position: 'absolute',
                  transform: [
                    { translateX: table.position.x },
                    { translateY: table.position.y },
                    { rotate: `${table.position.angle}deg` },
                  ],
                }}
              >
                <Table
                  occupied={isOccupied}
                  selected={selectedTables?.includes(table.id)}
                  textRotationAngle={table.position.angle}
                  tableWidth={table.dimensions.width}
                  tableLength={table.dimensions.length}
                  isRound={table.dimensions.round}
                  chairsTop={table.chairs.top}
                  chairsBottom={table.chairs.bottom}
                  chairsLeft={table.chairs.left}
                  chairsRight={table.chairs.right}
                  chairsCount={table.chairs.count}
                  tableNumber={table.table_number || table.label}
                  groupPrefix={table.group_prefix}
                  timeLeftPercent={liveMode ? timeLeftPercent : undefined}
                  bottomText={
                    liveMode ? nextReservationTime || undefined : undefined
                  }
                />
              </TouchableOpacity>
            );
          })}
        </Animated.View>
      )}
    </Animated.View>
  );
};

export default RoomStatic;
