import React, { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../GlobalContext';
import OpenHoursServices from '../services/OpenHoursServices';
import {
  OpenHours,
  OpenHoursDay,
  transformOpenHoursSet,
} from '../transofrmations/openHoursTransformer';
import {
  DATETIME_FORMAT,
  DateTimeContext,
  DateTimeRange,
} from './DateTimeContext';
import {
  addMinutes,
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';

export type OpenHoursContextState = {
  openHours: OpenHours;
  openHoursDayOnSelectedDate: OpenHoursDay;
  openHoursRangeOnSelectedDate: DateTimeRange;
  refreshOpenHours: () => void;
};

const contextDefaultValues: OpenHoursContextState = {
  openHours: null,
  openHoursDayOnSelectedDate: {
    closeTime: '23:00',
    closeTimeHolidays: '23:00',
    closed: false,
    closedHolidays: false,
    firstHour: 0,
    lastHour: 23,
    openTime: '00:00',
    openTimeHolidays: '00:00',
  },
  openHoursRangeOnSelectedDate: {
    from: new Date(),
    fromString: format(new Date(), DATETIME_FORMAT),
    to: new Date(),
    toString: format(addMinutes(new Date(), 10), DATETIME_FORMAT),
  },
  refreshOpenHours: () => {},
};

export const OpenHoursContext = createContext<OpenHoursContextState>(
  contextDefaultValues,
);

const OpenHoursProvider: React.FC = ({ children }) => {
  const [openHours, setOpenHours] = useState(contextDefaultValues.openHours);

  const [openHoursDayOnSelectedDate, setOpenHoursDayOnSelectedDate] = useState(
    contextDefaultValues.openHoursDayOnSelectedDate,
  );

  const [
    openHoursRangeOnSelectedDate,
    setOpenHoursRangeOnSelectedDate,
  ] = useState(contextDefaultValues.openHoursRangeOnSelectedDate);

  const { jwtToken, restaurantId } = useContext(GlobalContext);
  const dateTimeContext = useContext(DateTimeContext);
  const {
    selectedDayShortString,
    selectedDateTimeRange,
    selectedDateString,
  } = dateTimeContext;

  useEffect(() => {
    let from = setHours(
      selectedDateTimeRange.from,
      openHoursDayOnSelectedDate.firstHour,
    );

    from = setMinutes(from, 0);
    from = setSeconds(from, 0);
    from = setMilliseconds(from, 0);

    let to = setHours(
      selectedDateTimeRange.from,
      openHoursDayOnSelectedDate.lastHour,
    );
    to = setMinutes(to, 0);
    to = setSeconds(to, 0);
    to = setMilliseconds(to, 0);

    const fromString = format(from, DATETIME_FORMAT);
    const toString = format(to, DATETIME_FORMAT);

    const range: DateTimeRange = {
      from,
      to,
      fromString,
      toString,
    };

    setOpenHoursRangeOnSelectedDate(range);
  }, [openHoursDayOnSelectedDate, selectedDateString]);

  useEffect(() => {
    if (openHours && selectedDayShortString) {
      const selected = openHours.days[selectedDayShortString];
      setOpenHoursDayOnSelectedDate(selected);
    }
  }, [selectedDayShortString, openHours]);

  useEffect(() => {
    refreshOpenHours();
  }, [jwtToken, restaurantId]);

  const refreshOpenHours = () => {
    if (jwtToken && restaurantId) {
      OpenHoursServices.getDefaultOpenHours(jwtToken, restaurantId).then(
        res => {
          if (res) {
            const transformed = transformOpenHoursSet(res);
            setOpenHours(transformed);
          }
        },
      );
    }
  };

  const value = React.useMemo(
    () => ({
      openHours,
      openHoursDayOnSelectedDate,
      openHoursRangeOnSelectedDate,
      refreshOpenHours,
    }),
    [openHours, openHoursDayOnSelectedDate, openHoursRangeOnSelectedDate],
  );

  return (
    <OpenHoursContext.Provider value={value}>
      {children}
    </OpenHoursContext.Provider>
  );
};

export default OpenHoursProvider;

export const withOpenHoursContext = ChildComponent => props => (
  <OpenHoursContext.Consumer>
    {context => <ChildComponent {...props} openHoursContext={context} />}
  </OpenHoursContext.Consumer>
);
