module.exports = {
  // API_URL: 'http://192.168.1.30:5000/api/manage/v1',
  // API_URL: 'http://172.20.10.2:5000/api/manage/v1',
  // API_URL: 'https://dev.reetab.com/api/manage/v1'
  API_URL: process.env.API_URL,
  RESERVATIONS_UPDATE_INTERVAL_SECONDS: 5,
  FORGOT_PASSWORD_URL: process.env.FORGOT_PASSWORD_URL,
  PRIVACY_URL: process.env.PRIVACY_URL,
  TERMS_URL: process.env.TERMS_URL,
  LANDING_PAGE_URL: process.env.LANDING_PAGE_URL,
  PRIMARY_PASTEL_COLOR: '#f8443b',
  PRIMARY_COLOR: '#f44336',
  DEFAULT_LINK_COLOR: '#2079ee',
  LIGHT_BACKGROUND_COLOR: 'rgba(252, 252, 252, 1)',
  WHITE_BACKGROUND_COLOR: '#fff',
  CONFIRM_GREEN: '#23D161',
  DARK: '#333333',
  DAYS: [
    { shortName: 'mon', name: 'Monday' },
    { shortName: 'tue', name: 'Tuesday' },
    { shortName: 'wed', name: 'Wednesday' },
    { shortName: 'thu', name: 'Thursday' },
    { shortName: 'fri', name: 'Friday' },
    { shortName: 'sat', name: 'Saturday' },
    { shortName: 'sun', name: 'Sunday' },
  ],
};
