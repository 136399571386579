import { Text, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import Colors from '../constants/Colors';
import { Ionicons } from '@expo/vector-icons';

const ICON_SIZE = 22;
const CIRCLE_SIZE = 36;
const FONT_SIZE = 16;
const VALUE_WIDTH = 75;

interface CountSelectorOption {
  label: string;
  value: any;
}

interface CountSelectorProps {
  index: number;
  onIndexUpdate: (newIndexValue: number) => void;
  options: CountSelectorOption[];
  minIndex?: number;
  maxIndex?: number;
  vertical?: boolean;
}

const SelectorButtom = ({ onPress, disabled, iconName }) => {
  return (
    <TouchableOpacity
      hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
      style={{
        width: 38,
        height: 38,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={onPress}
    >
      <Ionicons
        name={iconName}
        size={25}
        color={disabled ? Colors.lighterer : Colors.lightBlue}
        style={{
          marginTop: 1,
          marginLeft: 2,
          // height: 25,
          // width: 25,
          // textAlign: 'center',
        }}
      />
    </TouchableOpacity>
  );
};

const CountSelector = ({
  index,
  onIndexUpdate,
  options,
  minIndex,
  maxIndex,
  vertical,
}: CountSelectorProps) => {
  const [indexRange, setIndexRange] = useState({ from: 0, to: 0 });

  useEffect(() => {
    const maxArrayIndex = options.length - 1;
    const range = {
      from: minIndex || 0,
      to: maxIndex ? Math.min(maxArrayIndex, maxIndex) : maxArrayIndex,
    };
    setIndexRange(range);
  }, [options, minIndex, maxIndex]);

  const [label, setLabel] = useState('');
  useEffect(() => {
    if (index >= 0 && index < options.length) {
      const option = options[index];
      setLabel(option.label);
    } else {
    }
  }, [index, options]);

  const addToIndex = value => {
    const newIndexValue = Math.min(
      indexRange.to,
      Math.max(indexRange.from, index + value),
    );
    onIndexUpdate(newIndexValue);
  };

  return (
    <View
      style={[
        {
          flexDirection: vertical ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
          flex: 1,
          alignItems: 'center',
          paddingHorizontal: 5,
          paddingVertical: 2,
        },
      ]}
    >
      <SelectorButtom
        onPress={() => addToIndex(-1)}
        iconName={'remove'}
        disabled={indexRange.from === index}
      />
      <View
        style={{
          // width: vertical ? 38 : 70,
          // height: vertical ? 50 : 38,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: Colors.dark,
            fontSize: 15,
            fontWeight: '500',
          }}
        >
          {label}
        </Text>
      </View>
      <SelectorButtom
        onPress={() => addToIndex(1)}
        iconName={'add'}
        disabled={indexRange.to === index}
      />
    </View>
  );
};

export interface CountSelectorIntProps {
  min: number;
  max: number;
  value: number;
  onUpdateValue: (newValue: number) => void;
  labelArray?: [string];
  vertical?: boolean;
}

export interface CountSelectorDurationProps extends CountSelectorIntProps {
  interval: number;
}

export const CountSelectorInt = ({
  min,
  max,
  value,
  onUpdateValue,
  labelArray,
  vertical,
}: CountSelectorIntProps) => {
  const options = [...Array(max - min + 1).keys()].map((value1, index) => {
    const label =
      labelArray && labelArray.length > index
        ? labelArray[index]
        : `${min + index}`;
    return {
      label: label,
      value: min + index,
    };
  });

  useEffect(() => {
    if (value > max) {
      onUpdateValue(max);
    } else if (value < min) {
      onUpdateValue(min);
    }
  }, [max, min]);

  const onIndexUpdate = (index: number) => {
    onUpdateValue(options[index].value);
  };

  return (
    <CountSelector
      index={value - min}
      onIndexUpdate={onIndexUpdate}
      options={options}
      vertical={vertical}
    />
  );
};

export const CountSelectorDurationMinutes = ({
  min,
  max,
  interval,
  value,
  onUpdateValue,
}: CountSelectorDurationProps) => {
  const onIndexUpdate = (index: number) => {
    onUpdateValue(options[index].value);
  };

  const [index, setIndex] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const durationOptions = [];
    for (let i = min; i <= max; i += interval) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const minutesString = minutes === 0 ? '00' : minutes;
      durationOptions.push({
        label: `${hours}:${minutesString}`,
        value: i,
      });
    }
    setOptions(durationOptions);
  }, [min, max, interval]);

  useEffect(() => {
    setIndex(() => {
      return (value - min) / interval;
    });
  }, [options, value]);

  return (
    <CountSelector
      index={index}
      onIndexUpdate={onIndexUpdate}
      options={options}
    />
  );
};

export default CountSelector;
