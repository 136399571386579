import React, { useContext, useEffect, useState } from 'react';
import { Alert, Text, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import TableServices from '../../../services/TablesServices';
import Table from '../../Spaces/RoomsComponents/Table';
import {
  FormButtom,
  FormButtonWithConfirmation,
  FormButtonIconType,
  FormButtonsGroup,
  FormButtonsType,
  FormSelectorItem,
  RowHorizontalSelector,
  RowInput,
  RowInputStepper,
} from '../../../components/Form';

const AddEditTableModal = (props) => {
  const { visible, close, groupName, groupId, editTable } = props;
  const global = useContext(GlobalContext);
  const { models, tableGroups } = global;

  const [tableNumber, setTableNumber] = useState(
    editTable?.table_number || null,
  );
  const [chairs, setChairs] = useState(editTable ? editTable?.chairs : 4);
  const [selectedModelId, setSelectedModelId] = useState(
    editTable ? editTable?.table_model?.id : null,
  );

  const [
    modelsWithSelectedNumberOfChairs,
    setModelsWithSelectedNumberOfChairs,
  ] = useState([]);

  useEffect(() => {
    const filteredModels = models.filter(
      (model) => model?.chairs?.count === chairs,
    );
    setModelsWithSelectedNumberOfChairs(filteredModels);
  }, [models, chairs]);

  const addTable = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = groupId;
    data.modelId = selectedModelId;
    data.chairs = chairs;

    TableServices.addNewTable(jwtToken, restaurantId, data).then((result) => {
      if (result.ok) {
        showSnackbarMessage(`Table ${tableNumber} was added.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        Alert.alert('Something went wrong', result.message);
      }
    });
  };

  const update = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    const data = {};
    data.tableNumber = tableNumber;
    data.selectedGroupId = groupId;
    data.modelId = selectedModelId;
    data.chairs = chairs;

    TableServices.updateTable(jwtToken, restaurantId, data, editTable.id).then(
      (result) => {
        if (result.ok) {
          showSnackbarMessage(`Table ${tableNumber} was updated.`);
          updateTableGroups();
          updateTables();
          close();
        } else {
          Alert.alert('Something went wrong', result.message);
        }
      },
    );
  };

  const deleteTable = () => {
    const {
      jwtToken,
      updateTableGroups,
      updateTables,
      showSnackbarMessage,
    } = global;

    TableServices.removeTable(jwtToken, editTable.id).then((result) => {
      if (result.ok) {
        showSnackbarMessage(`Table ${tableNumber} was deleted.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        Alert.alert('Something went wrong', result.message);
      }
    });
  };

  const changeModelId = (id) => {
    setSelectedModelId(id);
  };

  const setChair = (count) => {
    setSelectedModelId(null);
    setChairs(count);
  };

  return (
    <ModalWrapper
      onClose={close}
      title={editTable ? 'Edit Table' : 'New Table'}
      isVisible={visible}
      backgroundColor="#f6f7f9"
    >
      <RowInput label="Group" disabled value={`${groupName || ''}`} small />
      <RowInput
        label="Table Number"
        small
        value={`${tableNumber || ''}`}
        keyboardType="number-pad"
        placeholder="ex. 10"
        onChangeText={(value) => {
          const number = parseInt(value, 10);
          setTableNumber(Number.isNaN(number) ? '' : number);
        }}
      />
      <RowInputStepper
        value={chairs}
        onValueUpdate={setChair}
        label="chairs"
        small
      />
      <RowHorizontalSelector label="Table Model" height={120}>
        {modelsWithSelectedNumberOfChairs.map((model, key) => {
          return (
            <FormSelectorItem
              onSelect={() => changeModelId(model.id)}
              key={key}
              width={140}
              height={105}
              selected={model.id === selectedModelId}
            >
              <Table
                isRound={model.dimensions.round}
                maxWidth={120}
                maxHeight={90}
                selected={model.id === selectedModelId}
                // occupied={model.id !== selectedModelId}
                tableWidth={model.dimensions.width}
                tableLength={model.dimensions.length}
                chairsTop={model.chairs.top}
                chairsBottom={model.chairs.bottom}
                chairsLeft={model.chairs.left}
                chairsRight={model.chairs.right}
                chairsCount={model.chairs.count}
                bottomText={model.name}
              />
            </FormSelectorItem>
          );
        })}
        {modelsWithSelectedNumberOfChairs.length === 0 && (
          <FormSelectorItem onSelect={() => null} width={260} height={105}>
            <View
              style={{
                flex: 1,
                padding: 16,
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: '600',
                  color: Colors.dark,
                  textAlign: 'left',
                }}
              >
                No models with {chairs} chairs
              </Text>
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: '500',
                  paddingTop: 8,
                  color: Colors.lighter2,
                }}
              >
                You can create a new model in the models section.
              </Text>
            </View>
          </FormSelectorItem>
        )}
      </RowHorizontalSelector>

      {editTable ? (
        <FormButtonsGroup marginTop={0} marginBottom={15}>
          <FormButtonWithConfirmation
            onConfirm={() => deleteTable()}
            title="Remove this table"
            confirmText="Remove"
            cancelText="Cancel"
            message="Are you sure you want to remove this table?"
            label="Remove Table"
            type={FormButtonsType.DELETE}
            icon={FormButtonIconType.TRASH}
          />
          <View style={{ width: 20 }} />
          <FormButtom
            disabled={!tableNumber}
            onPress={() => update()}
            label="Save"
            type={FormButtonsType.DEFAULT}
          />
        </FormButtonsGroup>
      ) : (
        <FormButtonsGroup marginTop={0} marginBottom={15}>
          <FormButtom
            disabled={!tableNumber}
            onPress={() => addTable()}
            label="Add Table"
            type={FormButtonsType.CREATE}
          />
        </FormButtonsGroup>
      )}
    </ModalWrapper>
  );
};
export default AddEditTableModal;
