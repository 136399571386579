import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { GlobalContext } from '../../../GlobalContext';
import ModalWrapper from '../components/ModalWrapper';
import TableServices from '../../../services/TablesServices';
import {
  FormButtom,
  FormButtonWithConfirmation,
  FormButtonIconType,
  FormButtonsGroup,
  FormButtonsType,
  FormSelectorItem,
  RowHorizontalSelector,
  RowInput,
} from '../../../components/Form';
import Colors from '../../../constants/Colors';

const AddEditGroupModal = ({
  visible,
  close,
  editGroup,
  editGroupId,
  editGroupName,
  editOpenHoursId,
  editOpenHoursName,
  editPrefix,
}) => {
  const global = useContext(GlobalContext);
  const { openHours } = global;

  const [name, setName] = useState(editGroup ? editGroupName : '');
  const [openHoursId, setOpenHoursId] = useState(
    editGroup ? editOpenHoursId : null,
  );
  const [openHoursName, setOpenHoursName] = useState(
    editGroup ? editOpenHoursName : null,
  );

  const [prefix, setPrefix] = useState(editGroup ? editPrefix : null);

  useEffect(() => {
    if (!visible) {
      setName('');
      setOpenHoursId(null);
      setOpenHoursName(null);
      setPrefix(null);
    }
  }, [visible]);

  const addGroup = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
      updateRooms,
    } = global;

    const data = { name, open_hours_set_id: openHoursId, prefix };

    TableServices.createTableGroup(jwtToken, restaurantId, data).then(
      (result) => {
        if (result.ok) {
          showSnackbarMessage(`Table group ${name} was created.`);
          updateTableGroups();
          updateTables();
          updateRooms();
          close();
        } else {
          showSnackbarMessage(`Something went wrong`);
        }
      },
    );
  };

  const updateGroup = () => {
    const {
      jwtToken,
      restaurantId,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
      updateRooms,
    } = global;

    const data = {
      name,
      prefix,
      open_hours_set_id: openHoursId,
    };

    TableServices.editTableGroup(
      jwtToken,
      restaurantId,
      data,
      editGroupId,
    ).then((result) => {
      if (result.ok) {
        showSnackbarMessage(`Table group ${name} was updated.`);
        updateTableGroups();
        updateTables();
        updateRooms();
        close();
      } else {
        showSnackbarMessage(`Something went wrong`);
      }
    });
  };

  const deleteGroup = () => {
    const {
      jwtToken,
      updateTableGroups,
      showSnackbarMessage,
      updateTables,
    } = global;

    TableServices.removeGroup(jwtToken, editGroupId).then((result) => {
      if (result.ok) {
        showSnackbarMessage(`Table group ${name} was removed.`);
        updateTableGroups();
        updateTables();
        close();
      } else {
        showSnackbarMessage(`Something went wrong`);
      }
    });
  };

  const changeSelectedOpenHours = (id, newName) => {
    setOpenHoursId(id);
    setOpenHoursName(newName);
  };

  return (
    <ModalWrapper
      onClose={close}
      title={editGroup ? 'Edit Group' : 'New Group'}
      isVisible={visible}
      backgroundColor="#f6f7f9"
    >
      <RowInput
        label="Group name"
        value={`${name || ''}`}
        placeholder="Ex. fine dining"
        onChangeText={(value) => setName(value)}
        small
      />
      <RowInput
        label="Group prefix"
        onChangeText={(value) => setPrefix(value)}
        placeholder="eg. F"
        value={prefix}
        small
      />

      <RowHorizontalSelector label="Open Hours set" height={60}>
        {openHours?.map((set, index) => {
          return (
            <FormSelectorItem
              onSelect={() => changeSelectedOpenHours(set.id, set.name)}
              key={index}
              width={140}
              height={50}
              selected={openHoursName === set.name}
            >
              <Text style={{ fontSize: 15, color: Colors.dark }}>
                {set.name}
              </Text>
            </FormSelectorItem>
          );
        })}
      </RowHorizontalSelector>
      {editGroup ? (
        <FormButtonsGroup marginTop={0} marginBottom={15}>
          <FormButtonWithConfirmation
            onConfirm={() => deleteGroup()}
            title="Remove this group"
            confirmText="Remove"
            cancelText="Cancel"
            message="Are you sure you want to remove this group?"
            label="Remove Group"
            type={FormButtonsType.DELETE}
            icon={FormButtonIconType.TRASH}
          />
          <View style={{ width: 20 }} />
          <FormButtom
            disabled={!name || !openHoursId}
            onPress={() => updateGroup()}
            label="Save"
            type={FormButtonsType.DEFAULT}
          />
        </FormButtonsGroup>
      ) : (
        <FormButtonsGroup marginTop={0} marginBottom={15}>
          <FormButtom
            disabled={!name || !openHoursId}
            onPress={() => addGroup()}
            label="Add Group"
            type={FormButtonsType.CREATE}
          />
        </FormButtonsGroup>
      )}
    </ModalWrapper>
  );
};
export default AddEditGroupModal;
