import React from 'react';
import {
  Alert,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Linking,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { Input } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import styles from '../../styles/LoginStyle';
import { withGlobalContext } from '../../GlobalContext';
import deviceStorage from '../../services/DeviceStorage';

const GLOBAL = require('../../constants/Globals');

class LoginScreen extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isMounted: false,
    };
  }

  updateValue = (text, field) => {
    const { isMounted } = this.state;
    if (field === 'email' && isMounted) {
      this.setState({
        email: text,
      });
    } else if (field === 'password' && isMounted) {
      this.setState({
        password: text,
      });
    }
  };

  register = () => {
    const { navigation } = this.props;
    // NavigationService.navigate('Register');

    navigation.navigate('Register');
  };

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  login = () => {
    const collection = {};
    const { password, email } = this.state;
    collection.email = email;
    collection.password = password;
    const { global, navigation } = this.props;
    const { showSnackbarMessage } = global;

    if (!this.validateEmail(email)) {
      Alert.alert('Wrong email format');
      if (Platform.OS === 'web') {
        showSnackbarMessage('Wrong email format');
      }
      return;
    }

    const url = `${GLOBAL.API_URL}/auth/login`;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(collection),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        if (responseData.jwt_token) {
          deviceStorage.saveKey('jwtToken', responseData.jwt_token);
          global.updateJwt(responseData.jwt_token);
          navigation.navigate('App');
        } else if (responseData.message) {
          showSnackbarMessage(responseData.message, 0);
        } else {
          showSnackbarMessage('Something went wrong', 0);
        }
      })
      .catch(error => console.error(error));
  };

  componentWillUnmount = () => {
    this.state.isMounted = false;
  };

  componentDidMount = () => {
    this.setState({ isMounted: true });
  };

  render() {
    return (
      <KeyboardAvoidingView
        // behavior="padding"
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
        keyboardVerticalOffset={-100}
      >
        <SafeAreaView style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => (Platform.OS === 'web' ? null : Keyboard.dismiss())}
          >
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                <Image
                  style={styles.logo}
                  source={require('../../assets/images/reetab_business.png')}
                  resizeMode={'contain'}
                />
              </View>

              <View style={styles.infoContainer}>
                <Input
                  autoCapitalize={'none'}
                  placeholder="Email"
                  placeholderTextColor="rgba(0,0,0,0.35)"
                  leftIcon={
                    <Ionicons name="mail" size={20} color="rgba(0,0,0,0.40)" />
                  }
                  clearButtonMode={'while-editing'}
                  leftIconContainerStyle={{ marginHorizontal: 8, width: 30 }}
                  inputContainerStyle={styles.input}
                  containerStyle={{ paddingHorizontal: 0, height: 58 }}
                  inputStyle={{ fontSize: 16 }}
                  keyboardType="email-address"
                  returnKeyType="next"
                  onChangeText={text => this.updateValue(text, 'email')}
                  onSubmitEditing={() => this.refs.txtPassword.focus()}
                />

                <Input
                  autoCapitalize={'none'}
                  leftIcon={
                    <Ionicons
                      name="lock-closed"
                      size={20}
                      color="rgba(0,0,0,0.40)"
                    />
                  }
                  clearButtonMode={'while-editing'}
                  leftIconContainerStyle={{ marginHorizontal: 8, width: 30 }}
                  inputContainerStyle={styles.input}
                  containerStyle={{ paddingHorizontal: 0, height: 58 }}
                  inputStyle={{ fontSize: 16 }}
                  placeholder="Password"
                  placeholderTextColor="rgba(0,0,0,0.35)"
                  returnKeyType="go"
                  secureTextEntry
                  onChangeText={text => this.updateValue(text, 'password')}
                  ref="txtPassword"
                  onSubmitEditing={() => this.login()}
                />
                <View>
                  <TouchableOpacity onPress={() => this.login()}>
                    <View style={styles.loginButtonContainer}>
                      <Text style={styles.loginButtonText}>Login</Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View>
                  <TouchableOpacity onPress={() => this.register()}>
                    <View
                      style={[styles.loginButtonContainer, styles.darkButton]}
                    >
                      <Text style={[styles.loginButtonText, styles.textColor]}>
                        Sign up new restaurant
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    // marginTop: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(GLOBAL.FORGOT_PASSWORD_URL);
                    }}
                  >
                    <Text style={[styles.bottomText]}>Reset Password</Text>
                  </TouchableOpacity>
                  <Text style={styles.bottomText}> · </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Linking.openURL(GLOBAL.LANDING_PAGE_URL);
                    }}
                  >
                    <Text style={styles.bottomText}>Help </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                }}
              />
            </View>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
}

const LoginScreen2 = withGlobalContext(LoginScreen);
LoginScreen2.navigationOptions = {
  headerShown: false,
};
export default LoginScreen2;
