import MovableReservationContainer from './MovableReservationContainer';
import SingleReservation from './SingleReservation';
import Colors from '../../constants/Colors';
import React, { useEffect, useState } from 'react';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {
  addMinutes,
  differenceInMinutes,
  format,
  parseISO,
  setHours,
  setMinutes,
} from 'date-fns';
import { Reservation } from '../../transofrmations/reservationsTransformer';

const BREAKING_ROW_HEIGHT = 36;
const MINUTES_PER_UNIT = 15;

const reservationColor = (reservation: Reservation) => {
  let boxColor = 'rgba(255,0,0,0.58)';
  if (reservation.createdByRestaurant) {
    boxColor = 'rgba(48, 209, 88, 0.70)';
  }

  if (reservation.walkIn) {
    boxColor = 'rgba(255,82,180,0.63)';
    // boxColor = 'rgba(111,213,210,0.68)';
  }

  if (reservation.multiTable) {
    boxColor = 'rgba(23,144,250, 0.50)';
  }
  if (reservation.pendingConfirmation) {
    boxColor = 'rgba(255,166,69,0.77)';
  }
  if (reservation.cancelled) {
    boxColor = 'rgb(216,221,227)';
  }
  return boxColor;
};

const computeReservationDuration = (
  reservationStartDatetime,
  reservationEndDatetime,
) => {
  const from = reservationStartDatetime;
  const to = reservationEndDatetime;
  return differenceInMinutes(to, from);
};

const computeMinutesFromStart = (reservationStartDatetime, firstHour) => {
  const to = reservationStartDatetime;
  const from = setMinutes(setHours(to, firstHour), 0);
  return differenceInMinutes(to, from);
};

interface DrawReservationProps {
  reservation: Reservation;
  tables: any;
  firstHour: any;
  lastHour: any;
  hourLength: any;
  rowHeight: any;
  showReservationDetailsModal: any;
  hideCancelled: any;
  editingReservations: any;
  setEditingReservations: any;
  onReservationEdit: any;
}

const DrawReservation = ({
  reservation,
  tables,
  firstHour,
  lastHour,
  hourLength,
  rowHeight,
  showReservationDetailsModal,
  hideCancelled,
  editingReservations,
  setEditingReservations,
  onReservationEdit,
}: DrawReservationProps) => {
  const [reservationStartDatetime, setReservationStartDatetime] = useState(
    parseISO(reservation.dateTimeFromISOString),
  );
  const [reservationEndDatetime, setReservationEndDatetime] = useState(
    parseISO(reservation.dateTimeToISOString),
  );
  const [durationMinutes, setDurationMinutes] = useState(
    computeReservationDuration(
      reservationStartDatetime,
      reservationEndDatetime,
    ),
  );
  const [minutesFromFirstHour, setMinutesFromFirstHour] = useState(
    computeMinutesFromStart(reservationStartDatetime, firstHour),
  );

  const [updatedTables, setUpdatedTables] = useState([]);

  useEffect(() => {
    setDurationMinutes(
      computeReservationDuration(
        reservationStartDatetime,
        reservationEndDatetime,
      ),
    );
    setMinutesFromFirstHour(
      computeMinutesFromStart(reservationStartDatetime, firstHour),
    );
  }, [
    firstHour,
    lastHour,
    hourLength,
    rowHeight,
    reservationStartDatetime,
    reservationEndDatetime,
  ]);

  useEffect(() => {
    setReservationStartDatetime(parseISO(reservation.dateTimeFromISOString));
    setReservationEndDatetime(parseISO(reservation.dateTimeToISOString));
  }, [reservation.dateTimeFromISOString, reservation.dateTimeToISOString]);

  const x = useSharedValue(0);
  // const opacity = useSharedValue(0);
  const width = useSharedValue(0);
  const numberOfTables = tables?.length;
  // const animatedContainerStyle = useAnimatedStyle(() => {
  //   return {
  //     opacity: opacity.value,
  //   };
  // });

  // useEffect(() => {
  //   const duration = 200 + Math.random() * 2000;
  //   opacity.value = withTiming(1, { duration: duration });
  // }, []);
  const onTimeChange = (minutesDt, durationMinutesDt) => {
    const newDateFrom = addMinutes(reservationStartDatetime, minutesDt);
    const newDateTo = addMinutes(
      reservationEndDatetime,
      minutesDt + durationMinutesDt,
    );
    const newDateFromString = format(newDateFrom, "yyyy-MM-dd'T'HH:mm:ss");
    const newDateToString = format(newDateTo, "yyyy-MM-dd'T'HH:mm:ss");
    onReservationEdit(newDateFromString, newDateToString, updatedTables);
  };

  const onTableChange = (prevTableIndex, tablesDt) => {
    const newTableIndex = prevTableIndex + tablesDt;
    const prevTable = tables[prevTableIndex];
    const updatedTable = tables[newTableIndex];

    const existingIndex = updatedTables.findIndex(tableUpdate => {
      return tableUpdate.prevTable.id === prevTable.id;
    });
    const update = {
      prevTable,
      updatedTable,
    };
    if (existingIndex === -1) {
      setUpdatedTables([...updatedTables, update]);
      setTimeout(
        () => onReservationEdit(null, null, [...updatedTables, update]),
        300,
      );
    } else {
      const updatedTableEdit = Object.assign([...updatedTables], {
        [existingIndex]: update,
      });
      setUpdatedTables(updatedTableEdit);
      setTimeout(() => onReservationEdit(null, null, updatedTableEdit), 300);
    }
  };

  const indexes =
    reservation?.tables?.map(table =>
      tables.findIndex(x => x.id === table.id),
    ) || [];

  return indexes.map(tableIndex => {
    if (!(reservation.cancelled && hideCancelled)) {
      const boxColor = reservationColor(reservation);
      const note = reservation?.messages?.length > 0;

      return (
        <MovableReservationContainer
          onPress={() => showReservationDetailsModal(reservation)}
          key={`key:${tableIndex}${reservation.id}${hourLength}`}
          rowHeight={rowHeight}
          tableIndex={tableIndex}
          numberOfTables={numberOfTables}
          numberOfHours={lastHour - firstHour}
          minuteWidth={hourLength / 60}
          editingReservations={editingReservations}
          setEditingReservations={setEditingReservations}
          durationMinutes={durationMinutes}
          minutesFromFirstHour={minutesFromFirstHour}
          x={x}
          width={width}
          onTimeChange={onTimeChange}
          onTableChange={tablesDt => onTableChange(tableIndex, tablesDt)}
        >
          {/*<Animated.View style={[{ flex: 1 }, animatedContainerStyle]}>*/}
          <SingleReservation
            dateFrom={reservation.dateTimeFromISOString}
            dateTo={reservation.dateTimeToISOString}
            showTime={rowHeight > BREAKING_ROW_HEIGHT}
            fullname={reservation?.customer.fullName}
            walkIn={reservation?.walkIn}
            boxColor={boxColor}
            persons={reservation?.persons}
            note={note}
            textColor={Colors.darkest}
            textColor2={Colors.darker}
            textColor3={'#3f505b'}
            pendingConfirmation={reservation.pendingConfirmation}
          />
          {/*</Animated.View>*/}
        </MovableReservationContainer>
      );
    }
  });
};

export default DrawReservation;
