export interface Table {
  id?: number;
  chairs?: number;
  tableNumber?: number;
  label?: string;
  groupName?: string;
}

export const transformTables = (tables: any): Table[] => {
  if (tables) {
    return tables.map((table: any) => {
      return transformTable(table);
    });
  }
  return [];
};

export const transformTable = (tableApiData: any): Table => {
  const label = `${tableApiData.group_prefix || ''}${
    tableApiData.table_number
  }`;
  return {
    id: tableApiData.id,
    chairs: tableApiData.chairs,
    tableNumber: tableApiData.table_number,
    label: label,
    groupName: tableApiData.group,
  };
};
