import React, { createContext, useContext, useEffect, useState } from 'react';
import { Room, TableInRoom } from '../transofrmations/roomsTransformer';
import { ReservationsContext } from './ReservationsContext';
import { Reservation } from '../transofrmations/reservationsTransformer';
import { areIntervalsOverlapping, differenceInMinutes, parse } from 'date-fns';
import { DATETIME_FORMAT, DateTimeContext } from './DateTimeContext';

export interface DateTimeRange {
  from: string;
  to: string;
}

interface TablesMap {
  [id: number]: TableInRoom;
}

export type RoomContextState = {
  room: Room;
  reservationsOnInterval: Reservation[];
  tablesArray: TableInRoom[];
  tablesInRoom: TablesMap;
  setRoomId: (id: number) => void;
};

const contextDefaultValues: RoomContextState = {
  room: null,
  reservationsOnInterval: [],
  tablesArray: [],
  tablesInRoom: {},
  setRoomId: () => null,
};

export const RoomContext = createContext<RoomContextState>(
  contextDefaultValues,
);

const RoomProvider: React.FC = ({ children }) => {
  const reservationsContext = useContext(ReservationsContext);
  const { rooms, reservations } = reservationsContext;

  const dateTimeContext = useContext(DateTimeContext);
  const { selectedDateTimeRange } = dateTimeContext;

  const [room, setRoom] = useState<Room>(contextDefaultValues.room);
  const [tablesArray, setTablesArray] = useState<TableInRoom[]>(
    contextDefaultValues.tablesArray,
  );
  const [tablesInRoom, setTablesInRoom] = useState<TablesMap>(
    contextDefaultValues.tablesInRoom,
  );
  const [reservationsOnInterval, setReservationsOnInterval] = useState<
    Reservation[]
  >([]);

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      if (!room) {
        setRoomId(rooms[0].id);
      } else {
        const selectedRoomId = room.id;
        setRoomId(selectedRoomId);
      }
    }
  }, [rooms]);

  useEffect(() => {
    setReservationsOnInterval(() => reservationsInRoomDuringInterval());
  }, [selectedDateTimeRange, room, reservations]);

  const setRoomId = (id: number) => {
    const xx = rooms.find(r => r.id === id);
    if (xx) {
      const { tables, ...roomOhneTables } = xx;
      const tablesObj = {};
      tables.forEach(table => {
        tablesObj[table.id] = table;
      });
      setTablesInRoom(tablesObj);
      setRoom(xx);
    }
  };

  useEffect(() => {
    const tablesInRoomTemp = { ...tablesInRoom };
    reservationsOnInterval.forEach(reservation => {
      const isActive = reservation.dateTimeFrom <= selectedDateTimeRange.from;

      const minutesLeft = differenceInMinutes(
        reservation.dateTimeTo,
        selectedDateTimeRange.from,
      );

      const durationMinutes = differenceInMinutes(
        reservation.dateTimeTo,
        reservation.dateTimeFrom,
      );
      const durationPercent =
        durationMinutes !== 0 ? minutesLeft / durationMinutes : undefined;

      reservation.tables.forEach(table => {
        if (table.id in tablesInRoomTemp) {
          const tableStatus = tablesInRoomTemp[table.id].tableStatus || {
            occupied: false,
            activeReservation: null,
            remainingDurationPercent: null,
            nextReservationTime: null,
            nextReservation: null,
          };

          tableStatus.occupied = true;
          if (isActive) {
            tableStatus.active = true;
            tableStatus.activeReservation = reservation;
            tableStatus.remainingDurationPercent = durationPercent;
          } else if (
            !tableStatus.nextReservationTime ||
            parse(
              tableStatus.nextReservationTime,
              DATETIME_FORMAT,
              new Date(),
            ) < reservation.dateTimeFrom
          ) {
            tableStatus.nextReservation = reservation;
            tableStatus.nextReservationTime = reservation.timeFrom;
          }

          tablesInRoomTemp[table.id] = {
            ...tablesInRoomTemp[table.id],
            tableStatus: { ...tableStatus },
          };
        }
      });
    });
    setTablesArray(Object.values(tablesInRoomTemp));
  }, [reservationsOnInterval]);

  const reservationsInRoomDuringInterval = () => {
    const selectedInterval = {
      start: selectedDateTimeRange.from,
      end: selectedDateTimeRange.to,
    };

    if (room && reservations) {
      const reservationsOnDate = reservations.filter(reservation => {
        const reservationInterval = {
          start: parse(
            reservation.dateTimeFromISOString,
            DATETIME_FORMAT,
            new Date(),
          ),
          end: parse(
            reservation.dateTimeToISOString,
            DATETIME_FORMAT,
            new Date(),
          ),
        };
        return (
          areIntervalsOverlapping(selectedInterval, reservationInterval) && // reservations overlaping selected range
          reservation.tables.some(table => table.id in tablesInRoom) && // reservations in selected room
          !reservation.cancelled // reservation isn't cancelled
        );
      });

      return reservationsOnDate;
    }
    return [];
  };

  const value = React.useMemo(
    () => ({
      room,
      tablesArray,
      reservationsOnInterval,
      setRoomId,
    }),
    [room, tablesArray, reservationsOnInterval],
  );

  return <RoomContext.Provider value={value}>{children}</RoomContext.Provider>;
};

export default RoomProvider;
