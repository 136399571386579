import { Dimensions, Platform, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

const GLOBAL = require('../constants/Globals');

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(248, 248, 248, 1)',
    flex: 1,
  },
  logo: {
    width: Platform.OS === 'web' ? 420 : 320,
    maxWidth: '85%',
    height: 80,
  },
  infoContainer: {
    paddingHorizontal: 20,
    backgroundColor: 'rgba(248, 248, 248, 1)',

    width:
      Dimensions.get('window').width > 400
        ? 400
        : Dimensions.get('window').width,
  },

  input: {
    height: 48,
    marginBottom: 0,
    paddingBottom: 0,
    paddingLeft: 6,
    paddingRight: 12,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    // backgroundColor: 'rgba(255, 255, 0, 1)',
    borderBottomWidth: 0,
    borderRadius: 6,
    shadowOpacity: 0.05,
    shadowRadius: 2,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 0.4,
  },

  noIcon: {
    paddingLeft: 20,
  },

  buttonContainer: {
    marginTop: 5,
    height: 50,
    backgroundColor: 'red',
    paddingVertical: 13,
    borderRadius: 6,
  },
  bottomText: {
    color: Colors.lightBlue,
    // color: Colors.pastelRed,
    fontWeight: '500',
    fontSize: 16,
    marginTop: 10,
  },

  loginButtonContainer: {
    // paddingHorizontal: 20,
    marginBottom: 6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    backgroundColor: Colors.pastelRed,
    borderColor: '#00f',
    borderRadius: 6,
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  darkButton: {
    backgroundColor: Colors.darker,
    // backgroundColor: Colors.white,
    // borderWidth: 1.5,
    // borderColor: Colors.pastelRed,
    // shadowOpacity: 0.05,
    marginTop: 2,
  },
  loginButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 15,
    fontWeight: Platform.OS === 'ios' ? '600' : '600',
  },
  textColor: {
    // color: Colors.pastelRed,
  },
});

export default styles;
