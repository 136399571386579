import React, { useEffect, useState } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

// import Modal from 'react-native-modal';
import ReservationServices from '../../../services/ReservationsServices';
import Colors from '../../../constants/Colors';
import Buttons from '../../../styles/Buttons';
import ModalWrapper from '../../Setup/components/ModalWrapper';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import { DATETIME_FORMAT } from '../../../contex/DateTimeContext';
import {
  FormButtom,
  FormButtonsGroup,
  FormButtonsType,
  FormRowStaticText,
  RowInput,
  RowInputStepper,
} from '../../../components/Form';

const QuickAddReservationModal = ({
  isVisible,
  table,
  from,
  hideModal,
  duration,
  jwtToken,
  restaurantId,
  showAlert,
}) => {
  const [durationMinutes, setDurationMinutes] = useState(120);
  const [isNoteCollapsed, setIsNoteCollapsed] = useState(true);
  const [note, setNote] = useState('');
  const [selectedPersons, setSelectedPersons] = useState(0);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setDurationMinutes(120);
      setIsNoteCollapsed(true);
      setNote('');
      setPhone('');
      setName('');
    }
  }, [isVisible]);

  useEffect(() => {
    if (table?.chairs) {
      setSelectedPersons(table.chairs);
    }
  }, [table]);

  const submitReservation = (walkIn = false) => {
    const to = addMinutes(from, durationMinutes);

    const data = {};
    data.customer = { fullname: name, phone };
    data.restaurant_id = restaurantId;
    data.tables = [table.id];
    data.date_from = format(from, DATETIME_FORMAT);
    data.date_to = format(to, DATETIME_FORMAT);
    data.persons = selectedPersons;
    data.note = note;
    setLoading(true);
    ReservationServices.submitReservation(jwtToken, data, walkIn)
      .then(result => {
        if (result.ok) {
          hideModal(true);
          showAlert('Reservation was added successfully', 'success');
        } else {
          showAlert(result.message, 'warning');
          // Alert.alert('Something went wrong', result.message);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const showWalkIn = Math.abs(differenceInMinutes(from, new Date())) < 60;
  return (
    <ModalWrapper
      onClose={() => hideModal()}
      // title={`Reservation on table ${
      //   table?.group_prefix ? table?.group_prefix : ''
      // }${table?.table_number}`}
      headerTitleComponent={
        <Text
          style={{
            marginLeft: 24,
            fontWeight: '500',
            fontSize: 18,
            color: Colors.dark,
          }}
        >{`Reservation on table ${
          table?.group_prefix ? table?.group_prefix : ''
        }${table?.table_number}`}</Text>
      }
      isVisible={isVisible}
      width={420}
      style={{ marginBottom: 10, marginHorizontal: 0 }}
    >
      {/*<FormRowStaticText*/}
      {/*  label={'Table'}*/}
      {/*  disabled*/}
      {/*  value={`${table?.group_prefix ? table?.group_prefix : ''}${*/}
      {/*    table?.table_number*/}
      {/*  }`}*/}
      {/*/>*/}
      <FormRowStaticText
        label={'When'}
        disabled
        value={format(from, "EEEE, d. MMMM 'at' HH:mm")}
      />

      <RowInput
        small
        label={'Full name'}
        onChangeText={text => setName(text)}
        placeholder="Guest name"
        autoCapitalize={'words'}
        value={name}
      />
      <RowInput
        small
        label={'Phone'}
        onChangeText={text => setPhone(text)}
        placeholder="+12 345 578"
        keyboardType={'number-pad'}
        value={phone}
      />
      <RowInputStepper
        value={selectedPersons}
        maxValue={table?.chairs || selectedPersons}
        onValueUpdate={setSelectedPersons}
        label={'Guests'}
        small
      />
      <RowInputStepper
        value={durationMinutes}
        onValueUpdate={setDurationMinutes}
        label={'Duration'}
        small
        duration
      />

      <RowInput
        small
        multiline
        label={'Note'}
        onChangeText={text => setNote(text)}
        placeholder="Add note"
        value={note}
      />
      {/*<View style={{ flex: 1, paddingBottom: 8, paddingHorizontal: 4 }}>*/}

      {/*<View style={styles.info_line}>*/}
      {/*  <Text style={styles.info_left}>When</Text>*/}
      {/*  <Text style={styles.info_right}>*/}
      {/*    {format(from, "EEE, d. MMM 'at' HH:mm")}*/}
      {/*  </Text>*/}
      {/*</View>*/}
      {/*<View style={styles.info_line}>*/}
      {/*  <Text style={styles.info_left}>Table</Text>*/}
      {/*  <Text style={styles.info_right}>*/}
      {/*    {table?.group_prefix || ''}*/}
      {/*    {table?.table_number}*/}
      {/*  </Text>*/}
      {/*</View>*/}
      {/*<View style={styles.info_line}>*/}
      {/*  <Text style={styles.info_left}>Name </Text>*/}
      {/*  <TextInput*/}
      {/*    style={[*/}
      {/*      styles.info_right,*/}
      {/*      styles.info_right_input,*/}
      {/*      { color: '#222' },*/}
      {/*    ]}*/}
      {/*    onChangeText={text => setName(text)}*/}
      {/*    placeholder="customer's name"*/}
      {/*    placeholderTextColor={Colors.placeholder}*/}
      {/*    value={name}*/}
      {/*    autoCapitalize="words"*/}
      {/*    autoCorrect={false}*/}
      {/*  />*/}
      {/*</View>*/}
      {/*<View style={styles.info_line}>*/}
      {/*  <Text style={styles.info_left}>Phone</Text>*/}
      {/*  <TextInputMask*/}
      {/*    type="custom"*/}
      {/*    options={{*/}
      {/*      mask: '999 999 999',*/}
      {/*    }}*/}
      {/*    style={[*/}
      {/*      styles.info_right,*/}
      {/*      styles.info_right_input,*/}
      {/*      { color: '#222' },*/}
      {/*    ]}*/}
      {/*    onChangeText={text => setPhone(text)}*/}
      {/*    placeholder="phone number"*/}
      {/*    placeholderTextColor={Colors.placeholder}*/}
      {/*    value={phone}*/}
      {/*    selectTextOnFocus*/}
      {/*    autoCorrect={false}*/}
      {/*    textContentType="telephoneNumber"*/}
      {/*    keyboardType="phone-pad"*/}
      {/*  />*/}
      {/*</View>*/}
      {/*<View style={[styles.info_line, { borderBottomWidth: 0 }]}>*/}
      {/*  <Text style={styles.info_left}>Duration </Text>*/}
      {/*  <View style={styles.info_right}>*/}
      {/*    <CountSelectorDurationMinutes*/}
      {/*      interval={15}*/}
      {/*      min={45}*/}
      {/*      max={500}*/}
      {/*      value={durationMinutes}*/}
      {/*      onUpdateValue={setDurationMinutes}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*</View>*/}
      {/*<View style={[styles.info_line, { borderBottomWidth: 0 }]}>*/}
      {/*  <Text style={styles.info_left}>Guests </Text>*/}
      {/*  <View style={styles.info_right}>*/}
      {/*    <CountSelectorInt*/}
      {/*      min={0}*/}
      {/*      max={table?.chairs || selectedPersons}*/}
      {/*      value={selectedPersons}*/}
      {/*      onUpdateValue={setSelectedPersons}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*</View>*/}

      {/*<View style={[styles.info_line, { borderBottomWidth: 0 }]}>*/}
      {/*  <Text style={styles.info_left}>Note</Text>*/}
      {/*  <TouchableOpacity*/}
      {/*    onPress={() => setIsNoteCollapsed(prevState => !prevState)}*/}
      {/*    hitSlop={{ top: 6, bottom: 6, left: 10, right: 10 }}*/}
      {/*  >*/}
      {/*    <Text style={[styles.info_right, { color: Colors.lightBlue }]}>*/}
      {/*      {isNoteCollapsed ? 'add a note' : 'hide'}*/}
      {/*    </Text>*/}
      {/*  </TouchableOpacity>*/}
      {/*</View>*/}
      {/*<Collapsible*/}
      {/*  collapsed={isNoteCollapsed}*/}
      {/*  duration={500}*/}
      {/*  collapsedHeight={0}*/}
      {/*>*/}
      {/*  <View*/}
      {/*    style={[*/}
      {/*      styles.info_line,*/}
      {/*      {*/}
      {/*        borderWidth: 0,*/}
      {/*        height: undefined,*/}
      {/*        alignItems: undefined,*/}
      {/*        justifyContent: undefined,*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  >*/}
      {/*    <TextInput*/}
      {/*      multiline*/}
      {/*      placeholder="Enter additional information..."*/}
      {/*      onChangeText={text => setNote(text)}*/}
      {/*      value={note}*/}
      {/*      style={{*/}
      {/*        fontSize: 15,*/}
      {/*        color: Colors.dark,*/}
      {/*        flex: 1,*/}
      {/*        height: 80,*/}
      {/*        paddingVertical: 8,*/}
      {/*        textAlignVertical: 'top',*/}
      {/*        marginVertical: 5,*/}
      {/*        marginHorizontal: Platform.OS === 'web' ? 3 : 0,*/}
      {/*        paddingHorizontal: 6,*/}
      {/*        borderRadius: 4,*/}
      {/*        backgroundColor: Colors.lightererer,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </View>*/}
      {/*</Collapsible>*/}
      <FormButtonsGroup marginTop={6} marginBottom={12}>
        <FormButtom
          disabled={!name}
          onPress={() => submitReservation()}
          loading={loading}
          label={'Confirm reservation'}
          type={FormButtonsType.CREATE}
        />
      </FormButtonsGroup>
      {/*<View style={[Buttons.container, { marginTop: 12, marginBottom: 0 }]}>*/}
      {/*  <TouchableOpacity*/}
      {/*    style={[Buttons.big, Buttons.green, { marginBottom: 10 }]}*/}
      {/*    onPress={() => submitReservation()}*/}
      {/*  >*/}
      {/*    <Text style={Buttons.text}>Confirm reservation</Text>*/}
      {/*  </TouchableOpacity>*/}
      {/*</View>*/}
      {showWalkIn && (
        <View style={[Buttons.container, { marginTop: 0, marginBottom: 12 }]}>
          <TouchableOpacity
            style={[
              Buttons.big,
              Buttons.border,
              { borderColor: Colors.cyan, marginBottom: 0 },
            ]}
            onPress={() => submitReservation(true)}
          >
            <Text style={[Buttons.text, { color: Colors.cyan }]}>
              Seat walk-in
            </Text>
          </TouchableOpacity>
        </View>
      )}
      {/*</View>*/}
    </ModalWrapper>
  );
};

export default QuickAddReservationModal;

const styles = StyleSheet.create({
  info_left: {
    fontSize: 17,
    fontWeight: '500',
    color: Colors.darker,
    // marginLeft: 12,
    // flex: 2,
    flexDirection: 'row',
    // backgroundColor:"blue",
  },
  info_right: {
    // backgroundColor:"yellow",
    fontSize: 17,
    fontWeight: '400',
    // flex: 4,
    textAlign: 'right',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 10,
    paddingLeft: 20,
    color: Colors.light,
  },
  info_right_input: {
    // paddingVertical: 10,
    paddingLeft: 0,
    flex: 1,
  },
  info_line: {
    justifyContent: 'space-between',
    // flex: 1,
    // paddingLeft: 12,
    // paddingRight: 12,

    flexDirection: 'row',
    // borderBottomWidth: 0.75,
    borderBottomColor: '#ddd',
    // paddingBottom: 12,
    alignItems: 'center',
    height: 46,
    // backgroundColor:"green",
  },

  reservationContainer: {
    margin: 40,
    marginHorizontal: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: 46,
    backgroundColor: '#23d160',
    // borderWidth: 1.3,
    borderColor: '#00f',
    // paddingVertical: 12,
    borderRadius: 45,
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowOffset: {
      height: 3,
      width: 3,
    },
    elevation: 2,
  },
  reservationButtonText: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 18,
    fontWeight: Platform.OS === 'ios' ? '500' : '400',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: 12,
    marginRight: 0,
  },
});
