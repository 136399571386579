import Colors from '../../../constants/Colors';
import Style from '../../../constants/Style';
import { Animated, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useRef } from 'react';

const SpacesMenu = ({
  isLive,
  isPortrait,
  isPhone,
  setIsLive,
  isExpanded,
  selectedRoom,
  switchRoomSelectMenu,
  changeSelectedRoom,
  rooms,
  paddingLeft,
}: any) => {
  const isExpandedAnimated = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const toValue = isExpanded ? 1 : 0;
    Animated.timing(isExpandedAnimated, {
      toValue,
      duration: isPortrait ? 300 : 350,
      useNativeDriver: false,
    }).start();
  }, [isExpanded]);

  return (
    <Animated.View
      style={[
        {
          zIndex: 1,
          backgroundColor: Colors.white,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          borderTopWidth: isPortrait ? 0.6 : 0,
          borderTopColor: '#e4e8ee',
          borderTopRightRadius: isPortrait || isPhone ? 0 : 10,
          borderBottomRightRadius: isPortrait || isPhone ? 0 : 10,
          marginVertical: isPortrait || isPhone ? 0 : 12,
          paddingLeft: paddingLeft,
          // paddingBottom: isPortrait ? 0 : 55,
          // borderRadius: 12,
          // marginLeft: 12,
          flex: isPhone && !isPortrait ? 1 : undefined,
          // maxHeight: 50 + (rooms?.length || 0) * 50,
        },
        Style.shadowShort,
      ]}
    >
      <Animated.View
        style={[
          {
            // backgroundColor: Colors.white,
            zIndex: 1,
            height: isPortrait
              ? isExpandedAnimated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [46, 46 + (rooms?.length || 0) * 46],
                })
              : null,
            width: !isPortrait
              ? isExpandedAnimated.interpolate({
                  inputRange: [0, 1],
                  outputRange: [70, 200],
                })
              : '100%',

            overflow: 'hidden',
            // backgroundColor: 'red',
          },
          // Style.shadowShort,
        ]}
      >
        <Animated.View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() => switchRoomSelectMenu()}
            style={{ flex: 1 }}
          >
            <Animated.View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: isPortrait ? 46 : 60,
                // marginTop: ,
              }}
            >
              <Animated.View
                style={{
                  width: isPortrait ? 60 : 70,
                  justifyContent: 'center',
                  paddingLeft: isPortrait ? 18 : 0,
                  alignItems: isPortrait ? 'flex-start' : 'center',
                  height: isPortrait ? 46 : 60,
                }}
              >
                <Ionicons
                  // name="menu"
                  name={isPortrait ? 'menu' : 'menu'}
                  size={isPortrait ? 24 : 24}
                  style={{
                    color: Colors.dark,
                  }}
                />
              </Animated.View>
              <Animated.Text
                selectable={false}
                style={{
                  color: Colors.dark,
                  fontSize: 15,
                  marginLeft: isPortrait ? -5 : -5,
                  opacity: isPortrait ? 1 : isExpandedAnimated,
                  fontWeight: '600',
                }}
                numberOfLines={1}
              >
                {!isExpanded && isPortrait && selectedRoom?.name}
                {/*{isExpanded && isPortrait && 'Spaces'}*/}
                {!isPortrait && 'Spaces'}
              </Animated.Text>
            </Animated.View>
          </TouchableOpacity>

          {/*{isPortrait && (*/}
          {/*  <SegmentedControl*/}
          {/*    values={['Live', 'Interval']}*/}
          {/*    selectedIndex={isLive ? 0 : 1}*/}
          {/*    onChange={value => {*/}
          {/*      if (value.nativeEvent.selectedSegmentIndex === 0) {*/}
          {/*        setIsLive(true);*/}
          {/*      } else {*/}
          {/*        setIsLive(false);*/}
          {/*      }*/}
          {/*    }}*/}
          {/*    appearance="light"*/}
          {/*    style={{*/}
          {/*      width: isPortrait ? 160 : '100%',*/}
          {/*      height: isPortrait ? 30 : 34,*/}
          {/*      marginHorizontal: 10,*/}
          {/*    }}*/}
          {/*    tintColor={Colors.dark}*/}
          {/*    activeFontStyle={{ color: Colors.white, fontSize: 12 }}*/}
          {/*    fontStyle={{ color: '#0b4373', fontSize: 12 }}*/}
          {/*  />*/}
          {/*)}*/}
        </Animated.View>
        <Animated.ScrollView showsVerticalScrollIndicator={false}>
          {rooms?.map?.((room, key) => {
            const isSelected = room.id === selectedRoom?.id;
            const isLast = key === rooms.length - 1;
            return (
              <Animated.View
                key={key}
                style={{
                  // flex: 1,
                  backgroundColor: isSelected ? Colors.lightBlue : null,
                  borderColor: !isSelected
                    ? Colors.lightererer
                    : 'rgba(0,0,0,0)',
                  borderTopWidth: isExpandedAnimated.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0.5, 0],
                  }),
                  borderBottomWidth: isExpandedAnimated.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0.5, 0],
                  }),
                  // borderTopRightRadius: 10,
                  // borderBottomRightRadius: 10,
                  // marginRight: isExpandedAnimated.interpolate({
                  //   inputRange: [0, 1],
                  //   outputRange: [0, 12],
                  // }),
                  // maxWidth: 180,
                  // marginVertical: 3,
                  // marginRight: 6,
                  // marginRight: 16,
                  // paddingLeft: 10,
                  // borderTopRightRadius: 10,
                  borderBottomRightRadius: isLast ? 10 : 0,
                }}
              >
                <TouchableOpacity
                  onPress={() => changeSelectedRoom(room.id)}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // paddingVertical: 10,
                    height: isPortrait ? 46 : 55,
                    // paddingLeft: 0,
                  }}
                >
                  <Animated.Text
                    style={{
                      fontSize: 15,
                      fontWeight: isSelected ? '600' : '500',
                      color: isSelected ? Colors.white : Colors.dark,
                      textTransform: 'capitalize',
                      opacity: isExpandedAnimated,
                      maxWidth: isExpandedAnimated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 300],
                      }),
                      paddingRight: isExpandedAnimated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 20],
                      }),
                      paddingLeft: isExpandedAnimated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 20],
                      }),
                    }}
                    numberOfLines={1}

                    // ellipsizeMode={'head'}
                  >
                    {room.name}
                  </Animated.Text>
                  {!isPortrait && (
                    <Animated.Text
                      style={{
                        fontSize: 11,
                        paddingHorizontal: 6,
                        width: 70,
                        opacity: isExpandedAnimated.interpolate({
                          inputRange: [0, 0.1, 1],
                          outputRange: [1, 0, 0],
                        }),
                        textAlign: 'center',
                        // backgroundColor: 'red',
                        fontWeight: isSelected ? '700' : '600',
                        // textTransform: "uppercase",
                        color: isSelected ? Colors.white : Colors.light,
                      }}
                    >
                      {room.name}
                    </Animated.Text>
                  )}
                  {/*<Ionicons*/}
                  {/*  name={*/}
                  {/*    room.id === selectedRoom?.id ? 'chevron-forward' : null*/}
                  {/*  }*/}
                  {/*  size={17}*/}
                  {/*  style={{*/}
                  {/*    color:*/}
                  {/*      room.id === selectedRoom?.id*/}
                  {/*        ? Colors.white*/}
                  {/*        : Colors.light,*/}
                  {/*    width: 17,*/}
                  {/*    // marginHorizontal: 6,*/}
                  {/*    textAlign: 'right',*/}
                  {/*  }}*/}
                  {/*/>*/}
                </TouchableOpacity>
              </Animated.View>
            );
          })}
        </Animated.ScrollView>
        {/*{!isPortrait && (*/}
        {/*  <Animated.View*/}
        {/*    style={{*/}
        {/*      width: '100%',*/}
        {/*      paddingHorizontal: 15,*/}
        {/*      paddingTop: 15,*/}
        {/*      paddingBottom: 15,*/}
        {/*      borderTopWidth: 1,*/}
        {/*      borderTopColor: Colors.lighterer,*/}
        {/*      opacity: isExpandedAnimated.interpolate({*/}
        {/*        inputRange: [0, 0.5, 0.9, 1],*/}
        {/*        outputRange: [0, 0, 0.9, 1],*/}
        {/*        // outputRange: [46, 140],*/}
        {/*      }),*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <SegmentedControl*/}
        {/*      values={['Live', 'Interval']}*/}
        {/*      selectedIndex={isLive ? 0 : 1}*/}
        {/*      onChange={value => {*/}
        {/*        if (value.nativeEvent.selectedSegmentIndex === 0) {*/}
        {/*          setIsLive(true);*/}
        {/*        } else {*/}
        {/*          setIsLive(false);*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      appearance="light"*/}
        {/*      style={{*/}
        {/*        width: isPortrait ? 180 : '100%',*/}
        {/*        height: isPortrait ? 32 : 36,*/}
        {/*      }}*/}
        {/*      tintColor={Colors.dark}*/}
        {/*      activeFontStyle={{ color: Colors.white, fontSize: 12 }}*/}
        {/*      fontStyle={{ color: '#0b4373', fontSize: 12 }}*/}
        {/*    />*/}
        {/*  </Animated.View>*/}
        {/*)}*/}
      </Animated.View>
    </Animated.View>
  );
};

export default SpacesMenu;
