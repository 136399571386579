import React, { useContext, useEffect, useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import SideMenuWrapper from './SideMenuWrapper';
import SettingsStyle from '../../styles/SettingsStyle';
import { GlobalContext } from '../../GlobalContext';
import SettingsServices from '../../services/SettingsServices';
import {
  FormButtom,
  FormButtonsGroup,
  FormButtonsType,
  FormRowSwitch,
  RowInput,
} from '../../components/Form';

const SettingsItem = (props) => {
  const { title, textInput, setTextInput, inputSubText } = props;
  return (
    <View style={SettingsStyle.SettingsItem}>
      <Text style={SettingsStyle.SettingsItemTitle}>{title}</Text>
      <View style={SettingsStyle.settingsItemInputRow}>
        <TextInput
          style={SettingsStyle.settingsItemTextInput}
          // placeholder=""
          onChangeText={(text) => setTextInput(text)}
          defaultValue={textInput}
        />
        <Text style={SettingsStyle.settingsItemInputSubText}>
          {inputSubText}
        </Text>
      </View>
    </View>
  );
};

const updateSettings = async (props) => {
  const { global } = props;
  const { showSnackbarMessage } = global;

  const result = await SettingsServices.updateReservationsSettings(props);
  if (result.ok) {
    // Alert.alert('Saved');
    showSnackbarMessage(`Settings were saved`);
  } else {
    showSnackbarMessage(`Something went wrong`);
    // Alert.alert('Something went wrong', result.message);
  }
};

const ReservationSettings = () => {
  const global = useContext(GlobalContext);

  const { reservationsSettings } = global;

  const [acceptReservations, setAcceptReservations] = useState(false);

  const [requireConfirmation, setRequireConfirmation] = useState(false);

  const [reservationDuration, setReservationDuration] = useState('');
  const [daysInAdvance, setDaysInAdvance] = useState('');
  const [gap, setGap] = useState('');
  const [reservationPolicy, setReservationPolicy] = useState('');
  const [availabilityCheckPartySizeLimit, setAvailabilityCheckPartySizeLimit] =
    useState('');
  const [largerPartySizeMessage, setLargerPartySizeMessage] = useState('');
  const [minMinutesBefore, setMinMinutesBefore] = useState('');

  useEffect(() => {
    global.updateReservationsSettings();
  }, []);

  useEffect(() => {
    if (reservationsSettings) {
      const {
        accept_reservations,
        require_confirmation,
        default_duration_minutes,
        max_days_in_advance,
        gap_minutes,
        min_minutes_before,
        reservation_policy,
        availability_check_party_size_limit,
        larger_party_size_message,
      } = reservationsSettings;

      setAcceptReservations(accept_reservations);
      setRequireConfirmation(require_confirmation);
      setReservationDuration(default_duration_minutes);
      setDaysInAdvance(max_days_in_advance);
      setGap(gap_minutes);
      setMinMinutesBefore(min_minutes_before);

      setReservationPolicy(reservation_policy);
      setAvailabilityCheckPartySizeLimit(availability_check_party_size_limit);
      setLargerPartySizeMessage(larger_party_size_message);
    }
  }, [reservationsSettings]);

  const toggleAcceptReservationsSwitch = () =>
    setAcceptReservations((previousState) => !previousState);

  const toggleRequireConfirmationSwitch = () =>
    setRequireConfirmation((previousState) => !previousState);

  const save = () => {
    updateSettings({
      acceptReservations,
      reservationDuration: parseInt(reservationDuration, 10),
      daysInAdvance: parseInt(daysInAdvance, 10),
      gap: parseInt(gap, 10),
      minMinutesBefore: parseInt(minMinutesBefore, 10),
      reservationPolicy,
      largerPartySizeMessage,
      availabilityCheckPartySizeLimit: parseInt(
        availabilityCheckPartySizeLimit,
        10,
      ),
      requireConfirmation,
      global,
    }).then(() => global.updateReservationsSettings());
  };

  return (
    <SideMenuWrapper route="reservations" title="Reservations">
      <View style={SettingsStyle.container}>
        {/* <Text style={SettingsStyle.SettingsItemTitle}> */}
        {/*  Accept reservations from app */}
        {/* </Text> */}
        {/* <View style={SettingsStyle.SettingsBooleanField}> */}
        {/*  <Switch */}
        {/*    onValueChange={toggleAcceptReservationsSwitch} */}
        {/*    value={acceptReservations} */}
        {/*  /> */}
        {/*  <Text style={SettingsStyle.settingsItemInputSubText}> */}
        {/*    {acceptReservations */}
        {/*      ? 'Users can book a table in your restaurant' */}
        {/*      : "Users can't book a table in your restaurant"} */}
        {/*  </Text> */}
        {/* </View> */}

        {/* <Text style={SettingsStyle.SettingsItemTitle}> */}
        {/*  Require Confirmation */}
        {/* </Text> */}
        {/* <View style={SettingsStyle.SettingsBooleanField}> */}
        {/*  <Switch */}
        {/*    onValueChange={toggleRequireConfirmationSwitch} */}
        {/*    value={requireConfirmation} */}
        {/*  /> */}
        {/*  <Text style={SettingsStyle.settingsItemInputSubText}> */}
        {/*    {requireConfirmation */}
        {/*      ? 'You will need to confirm reservations made by users' */}
        {/*      : 'Reservations from Reetab app are approved automatically'} */}
        {/*  </Text> */}
        {/* </View> */}
        <FormRowSwitch
          label="Enable online reservations"
          onValueChange={toggleAcceptReservationsSwitch}
          value={acceptReservations}
          inlineText={
            acceptReservations
              ? 'Users can book a table in your restaurant online'
              : "Users can't book a table in your restaurant online"
          }
        />
        <FormRowSwitch
          label="Require Confirmation"
          onValueChange={toggleRequireConfirmationSwitch}
          value={requireConfirmation}
          inlineText={
            requireConfirmation
              ? 'Confirmation is required for online reservations'
              : 'Online reservations are confirmed automatically'
          }
        />
        <RowInput
          label="Accept reservations for"
          value={daysInAdvance?.toString()}
          onChangeText={setDaysInAdvance}
          inlineLabel="days in advance"
          small
          inputWidth={38}
        />
        <RowInput
          label="Default duration of reservation"
          value={reservationDuration?.toString()}
          onChangeText={setReservationDuration}
          inlineLabel="minutes"
          small
          inputWidth={46}
        />

        <RowInput
          label="Minimum time gap between reservations assigned to the same table"
          value={gap?.toString()}
          onChangeText={setGap}
          inlineLabel="minutes"
          small
          inputWidth={46}
        />
        <RowInput
          label={"Don't accept reservations starting sooner than in"}
          value={minMinutesBefore?.toString()}
          onChangeText={setMinMinutesBefore}
          inlineLabel="minutes"
          small
          inputWidth={46}
        />

        <RowInput
          label="Reservation Policy"
          value={reservationPolicy}
          onChangeText={setReservationPolicy}
          small
          multiline
        />

        <RowInput
          label="Party size limit for auto availability check"
          value={availabilityCheckPartySizeLimit}
          onChangeText={setAvailabilityCheckPartySizeLimit}
          inlineLabel="guests"
          small
          inputWidth={40}
        />

        <RowInput
          label="Larger party size booking message"
          value={largerPartySizeMessage}
          onChangeText={setLargerPartySizeMessage}
          small
          multiline
        />

        {/* <SettingsItem */}
        {/*  title={'Default duration of reservation'} */}
        {/*  textInput={reservationDuration?.toString()} */}
        {/*  setTextInput={setReservationDuration} */}
        {/*  inputSubText={'minutes'} */}
        {/* /> */}
        {/* <SettingsItem */}
        {/*  title={'Accept reservations in advance for'} */}
        {/*  textInput={daysInAdvance?.toString()} */}
        {/*  setTextInput={setDaysInAdvance} */}
        {/*  inputSubText={'days'} */}
        {/* /> */}

        {/* <SettingsItem */}
        {/*  title={'Miminum gap between two consecutive reservations'} */}
        {/*  textInput={gap?.toString()} */}
        {/*  setTextInput={setGap} */}
        {/*  inputSubText={'minutes'} */}
        {/* /> */}
        {/* <SettingsItem */}
        {/*  title={"Don't accept reservations starting sooner than in"} */}
        {/*  textInput={minMinutesBefore?.toString()} */}
        {/*  setTextInput={setMinMinutesBefore} */}
        {/*  inputSubText={'minutes'} */}
        {/* /> */}
        <FormButtonsGroup marginTop={6} marginBottom={24}>
          <FormButtom
            onPress={save}
            label="Save Settings"
            type={FormButtonsType.DEFAULT}
          />
        </FormButtonsGroup>
        {/* <Button */}
        {/*  onPress={save} */}
        {/*  text={'Save Settings'} */}
        {/*  style={{ marginTop: 10 }} */}
        {/*  color={Colors.confirmGreen} */}
        {/*  active */}
        {/* /> */}
      </View>
    </SideMenuWrapper>
  );
};
export default ReservationSettings;
