import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import SegmentedControl from '@react-native-community/segmented-control';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../constants/Colors';

const options = [
  { label: 'Tables', value: 'tables' },
  { label: 'Models', value: 'models' },
  { label: 'Spaces', value: 'spaces' },
  { label: 'Hours', value: 'OpenHours' },
];

const FloatNavigator = props => {
  const changeViewSegmented = index => {
    props.navigation.navigate(options[index].value);
  };

  const navIndex = props.navigation.state.index;
  const insets = useSafeAreaInsets();

  return (
    <View
      style={[
        floatNavigatorStyle.container,
        // Platform.OS === 'web' && floatNavigatorStyle.web,
        { paddingTop: Math.max(insets.top, 10) },
      ]}
      pointerEvents="box-none"
    >
      <SegmentedControl
        // values={['Tables', 'Models', 'Spaces', 'Hours']}
        values={['Tables', 'Models', 'Spaces']}
        selectedIndex={navIndex}
        onChange={value =>
          changeViewSegmented(value.nativeEvent.selectedSegmentIndex)
        }
        appearance={'light'}
        backgroundColor={false}
        style={{ width: 300 }}
        tintColor={Colors.lightBlue}
        fontStyle={{ color: Colors.darker }}
        activeFontStyle={{ color: 'white' }}
      />
    </View>
  );
};

export default FloatNavigator;

// class FloatNavigator extends React.Component {
//   changeView = view => {
//     this.props.navigation.navigate(view);
//   };
//
//   changeViewSegmented = index => {
//     this.props.navigation.navigate(options[index].value);
//   };
//
//   render() {
//     const { changeView, changeViewSegmented } = this;
//     const navIndex = this.props.navigation.state.index;
//     return (
//       <SafeAreaView
//         style={[floatNavigatorStyle.container]}
//         pointerEvents="box-none"
//         mode="padding"
//       >
//         <SegmentedControl
//           // values={['Tables', 'Models', 'Spaces', 'Hours']}
//           values={['Tables', 'Models', 'Spaces']}
//           selectedIndex={navIndex}
//           onChange={value =>
//             changeViewSegmented(value.nativeEvent.selectedSegmentIndex)
//           }
//           appearance={'light'}
//           backgroundColor={Platform.OS === 'web' ? false : false}
//           style={{ width: 300 }}
//           tintColor={Colors.lightBlue}
//           fontStyle={{ color: Colors.darker }}
//           activeFontStyle={{ color: 'white' }}
//         />
//       </SafeAreaView>
//     );
//   }
// }
//
// export default withNavigation(FloatNavigator);

const floatNavigatorStyle = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
  },
  web: {
    marginTop: 30,
  },
});
