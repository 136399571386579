import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { addDays, format, parse } from 'date-fns';
import CalendarModal from '../../screens/Timeline/modals/CalendarModal';

const Arrow = ({ direction, size }) => {
  return (
    <Ionicons
      name={`chevron-${direction}`}
      size={size}
      color={Colors.darkBlue}
      style={{ marginTop: Platform.OS === 'ios' ? 1 : 0 }}
    />
  );
};

const styles = StyleSheet.create({
  topBarItem: {
    alignSelf: 'center',
    backgroundColor: 'white',
    // borderRadius: 10,
    paddingHorizontal: 3,
    borderRadius: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    shadowOpacity: Platform.OS === 'web' ? 0.1 : 0.18,
    shadowColor: 'rgba(17,56,77,0.5)',
    borderWidth: 0.4,
    borderColor: '#e2e8ea',
    shadowRadius: 6,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 4,
  },
});

const DateSelectItem = ({ selectedDate, onDateChangeCallback, size, live }) => {
  const [time, setTime] = useState('');
  const [date, setDate] = useState(new Date());
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  useEffect(() => {
    setDate(parse(selectedDate, 'yyyy-MM-dd', new Date()));
  }, [selectedDate]);

  const addDaysToDate = days => {
    const newDate = addDays(date, days);
    onDateChangeCallback(format(newDate, 'yyyy-MM-dd'));
    setDate(newDate);
  };

  const changeDate = d => {
    setDate(new Date(d));
    onDateChangeCallback(format(new Date(d), 'yyyy-MM-dd'));
    setTimeout(() => {
      setIsCalendarVisible(false);
    }, 100);
  };

  const setToToday = () => {
    setDate(new Date());
    onDateChangeCallback(format(new Date(), 'yyyy-MM-dd'));
  };

  const updateTime = () => {
    const newDate = new Date();
    const newTime = format(newDate, 'HH:mm');
    if (newTime !== time) {
      setTime(newTime);
    }
  };

  const showCalendarModal = () => {
    setIsCalendarVisible(true);
  };

  useEffect(() => {
    updateTime();
    const timer = setInterval(() => {
      updateTime();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <View
      style={[
        styles.topBarItem,
        {
          width: size === 'small' ? 220 : 260,
          height: size === 'small' ? 38 : 44,
        },
        live && {
          // width: size === 'small' ? 130 : 130,
          height: size === 'small' ? 38 : 38,
          borderWidth: 1,
          width: null,
          paddingHorizontal: 16,
          justifyContent: 'center',
          elevation: 0,
          // backgroundColor: '#2d3742',
          backgroundColor: 'rgba(250,251,252,0.99)',
          // borderWidth: 0,
          // borderRadius: 300,
          shadowOpacity: 0,
        },
      ]}
    >
      {live ? null : (
        <TouchableOpacity
          onPress={() => addDaysToDate(-1)}
          style={{
            paddingHorizontal: 8,
            borderRightWidth: 0,
            borderColor: '#aaa',
            height: size === 'small' ? 35 : 44,
            justifyContent: 'center',
          }}
          hitSlop={{ top: 10, bottom: 10, left: 5, right: 0 }}
        >
          <Arrow direction={'back'} size={size === 'small' ? 20 : 22} />
        </TouchableOpacity>
      )}
      {live ? (
        <TouchableOpacity
          onPress={() => showCalendarModal()}
          onLongPress={() => setToToday()}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'center',
          }}
        >
          <View
            style={{
              width: 8,
              height: 8,
              backgroundColor: 'rgba(109,196,193,0.75)',
              borderRadius: 30,
              marginRight: 8,
            }}
          />
          <Text
            style={{
              fontSize: 15,
              fontWeight: '500',
              // color: 'rgba(255, 255, 255, 1)',
              color: Colors.light,
              // marginRight: 8,
            }}
          >
            <Text style={{ color: Colors.darkest }}>Today</Text> {time}
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          // onPress={size !== 'small' ? () => showCalendarModal() : null}
          onPress={() => showCalendarModal()}
          onLongPress={() => setToToday()}
          delayLongPress={300}
          style={{
            // flex: 1,
            height: '100%',
            alignContent: 'center',
            justifyContent: 'center',
          }}
          hitSlop={{ top: 10, bottom: 10, left: 0, right: 0 }}

          // disabled={size === 'small'}
        >
          {size !== 'small' && (
            <Text
              style={{
                fontSize: 15,
                fontWeight: '500',
                color: Colors.dark,
                alignSelf: 'center',
              }}
            >
              {format(date, 'EEEE')}
              {/*{selectedDate.calendar(null, {*/}
              {/*  sameDay: '[Today]',*/}
              {/*  nextDay: 'dddd',*/}
              {/*  nextWeek: 'dddd',*/}
              {/*  lastDay: 'dddd',*/}
              {/*  lastWeek: 'dddd',*/}
              {/*  sameElse: 'dddd',*/}
              {/*})}*/}
            </Text>
          )}

          <Text
            style={{
              fontSize: size === 'small' ? 15 : 15,
              fontWeight: size === 'small' ? '500' : '400',
              color: size === 'small' ? Colors.dark : Colors.light,
              alignSelf: 'center',
            }}
          >
            {size !== 'small' &&
              // selectedDate.format('D. MMMM YYYY')
              format(date, 'd. MMMM yyyy')}
            {size === 'small' && (
              <Text>
                {/*{selectedDate.format('dddd')}*/}
                {format(date, 'EEEE')}
                <Text style={{ color: Colors.lighter2, fontWeight: '500' }}>
                  {'  '}
                  {format(date, 'd. MMM')}
                  {/*{selectedDate.format('D. MMM')}*/}
                </Text>
              </Text>
            )}
          </Text>
        </TouchableOpacity>
      )}
      {live ? null : (
        <TouchableOpacity
          onPress={() => addDaysToDate(1)}
          style={{
            paddingHorizontal: 8,
            // borderRightWidth: 0,
            borderColor: '#aaa',
            // width: 50,
            height: size === 'small' ? 35 : 42,
            // backgroundColor:'red',
            justifyContent: 'center',
          }}
          hitSlop={{ top: 10, bottom: 10, left: 0, right: 5 }}
        >
          <Arrow direction={'forward'} size={size === 'small' ? 20 : 22} />
        </TouchableOpacity>
      )}
      <CalendarModal
        size={'big'}
        isVisible={isCalendarVisible}
        onBackDropPress={() => setIsCalendarVisible(false)}
        selectedDate={format(date, 'yyyy-MM-dd')}
        changeSelectedDate={dt => changeDate(dt)}
      />
    </View>
  );
};

export default DateSelectItem;
