import React from 'react';
import { Platform } from 'react-native';

import { createMaterialTopTabNavigator } from 'react-navigation-tabs';

import {
  createStackNavigator,
  TransitionPresets,
} from 'react-navigation-stack';

import TabBarIcon from '../components/TabBarIcon';
import RoomEdit from '../screens/Spaces/RoomsComponents/RoomEdit';
import RoomStatic from '../screens/Spaces/RoomsComponents/RoomStatic';

import FloatNavigator from '../screens/Setup/components/FloatNavigator';
import GroupsView from '../screens/Setup/views/TableGroupsView';
import SpacesView from '../screens/Setup/views/SpacesView';
import ModelsView from '../screens/Setup/views/ModelsView';

import NewTableModal from '../screens/Setup/modals/deprecated/NewTableModal';
import NewGroupModal from '../screens/Setup/modals/deprecated/NewGroupModal';
import NewOpenHoursModal from '../screens/Setup/modals/deprecated/NewOpenHoursModal';
import NewModelModal from '../screens/Setup/modals/NewModelModal';
import NewRoomModal from '../screens/Setup/modals/deprecated/NewRoomModal';

import UpdateModelModal from '../screens/Setup/modals/deprecated/ModelAssociateTableModal';
import UpdateTableModal from '../screens/Setup/modals/deprecated/UpdateTableModal';
import UpdateGroupModal from '../screens/Setup/modals/deprecated/UpdateGroupModal';
import UpdateOpenHoursModal from '../screens/Setup/modals/deprecated/UpdateOpenHoursModal';

import DevicePairingModal from '../screens/Setup/modals/deprecated/DevicePairingModal';

const SetupStack = createMaterialTopTabNavigator(
  {
    tables: GroupsView,
    models: ModelsView,
    spaces: SpacesView,
    // OpenHours: OpenHoursView,
  },
  {
    tabBarComponent: FloatNavigator,
    lazy: false,
    swipeEnabled: true,
    // transitionConfig: () => fadeOut(),
    tabBarOptions: {},
    defaultNavigationOptions: {
      ...TransitionPresets.ModalTransition,
    },
  },
);
//
SetupStack.navigationOptions = {
  // header: null,
  headerShown: false,
};

SetupStack.path = '';

const SetupModalStack = createStackNavigator(
  {
    setup: SetupStack,
    newTable: NewTableModal,
    newGroup: NewGroupModal,
    newOpenHours: NewOpenHoursModal,
    updateTable: UpdateTableModal,
    updateGroup: UpdateGroupModal,
    updateOpenHours: UpdateOpenHoursModal,
    devicePairing: DevicePairingModal,
    add_model: NewModelModal,
    newRoom: NewRoomModal,
    updateModel: UpdateModelModal,
  },
  {
    mode: 'modal',
    defaultNavigationOptions: {
      // ...TransitionPresets.ModalTransition,
      transitionConfig: () => ({
        containerStyle: {
          backgroundColor: 'transparent',
        },
      }),
      cardStyle: {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    headerShown: false,
    headerMode: 'none',

    // transparentCard: true,
  },
);

SetupModalStack.navigationOptions = {
  tabBarLabel: 'Tables',
  tabBarIcon: ({ focused, tintColor }) => (
    <TabBarIcon
      focused={focused}
      name="ios-keypad"
      size={Platform.OS === 'web' ? 26 : 17}
      color={tintColor}
    />
  ),
  cardStyle: {
    backgroundColor: 'transparent',
  },
  headerShown: false,
};

SetupModalStack.path = '';

const ConfigurationStack = createStackNavigator(
  {
    SetupModalStack,
    editSpace: RoomEdit,
    RoomStatic,
  },
  {
    headerMode: 'none',
    headerShown: false,

    // cardStyle: {
    //   backgroundColor: 'transparent'
    // }
    // transparentCard: true,
  },
);

ConfigurationStack.navigationOptions = ({ navigation }) => {
  let tabBarVisible = true;
  if (navigation.state?.routes.length > 1) {
    if (navigation.state.routes[1]?.routeName === 'editSpace') {
      tabBarVisible = false;
    }
  }
  return {
    cardStyle: {
      backgroundColor: 'transparent',
    },
    tabBarLabel: 'Setup',
    tabBarIcon: ({ focused, tintColor }) => (
      <TabBarIcon
        focused={focused}
        name="layers"
        size={Platform.OS === 'web' ? 26 : 21}
        color={tintColor}
      />
    ),
    tabBarVisible,
  };
};

ConfigurationStack.path = 'setup';

export default ConfigurationStack;
