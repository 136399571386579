import { Text, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import Buttons from '../styles/Buttons';

const HeaderButton = ({
  small,
  label,
  icon,
  backgroundColor,
  color,
  onPress,
  shadow,
  iconSize,
  style,
  textStyle,
  border,
  borderColor,
  flipIcon,
}) => {
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      style={[
        Buttons.button,
        small && Buttons.buttonSmall,
        backgroundColor !== undefined && { backgroundColor },
        shadow && Buttons.shadow,
        { borderWidth: border ? 1 : 0, borderColor },
        // { borderWidth: 0.4, borderColor: 'rgba(0,0,0,0.07)' },
        style,
      ]}
    >
      <Text
        selectable={false}
        style={[
          Buttons.buttonIcon,
          color && { color },
          flipIcon && {
            transform: [{ rotate: '180deg' }],
          },
        ]}
      >
        <Ionicons name={icon} size={iconSize || 22} />
      </Text>
      <Text
        style={[Buttons.buttonText, color && { color }, textStyle]}
        selectable={false}
        numberOfLines={1}
      >
        {small || !label ? '' : ` ${label} `}
      </Text>
    </TouchableOpacity>
  );
};

export default HeaderButton;
