import { Platform, StyleSheet } from 'react-native';
import Colors from './Colors';

const Style = StyleSheet.create({
  container: {
    flex: 1,
  },
  shadow: {
    shadowOpacity: Platform.OS === 'web' ? 0.15 : 0.08,
    shadowRadius: 5,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 1,
  },
  shadowX: {
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 1,
  },
  shadowMin: {
    shadowOpacity: 0.12,
    shadowRadius: 2,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    elevation: 4,
  },
  shadowShort: {
    shadowOpacity: Platform.OS === 'web' ? 0.16 : 0.08,
    shadowRadius: Platform.OS === 'web' ? 4 : 3,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 4,
  },
  shadowSpacesMenu: {
    shadowOpacity: Platform.OS === 'web' ? 0.16 : 0.1,
    // borderLeftWidth: 1,

    shadowRadius: 4,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 4,
  },

  shadowDown: {
    shadowOpacity: 0.04,
    shadowRadius: 3,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    borderBottomWidth: Platform.OS === 'android' ? 1 : 0,
    borderBottomColor: Colors.lighterer,
    // elevation: 4,
  },
});

export default Style;
