import { useEffect, useState } from 'react';

export default function useSet(defaultValue) {
  const [set, setSet] = useState(defaultValue);
  const [array, setArray] = useState([defaultValue]);

  useEffect(() => {
    setArray(Array.from(set));
  }, [set]);

  function add(element) {
    setSet(prev => {
      prev.add(element);
      return new Set(prev);
    });
  }

  function remove(element) {
    setSet(prev => {
      prev.delete(element);
      return new Set(prev);
    });
  }

  function toggle(element) {
    setSet(prev => {
      if (prev.has(element)) {
        prev.delete(element);
      } else {
        prev.add(element);
      }
      return new Set(prev);
    });
  }

  function clear() {
    setSet(new Set());
  }

  // return [set, array, setSet, add, remove, toggle, clear];
  return [set, setSet, add, remove, toggle, clear, array];
}
