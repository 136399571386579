import { StyleSheet, Dimensions, Platform } from 'react-native';
import Colors from '../constants/Colors';

const OpenHoursStyle = StyleSheet.create({
  time: {
    color: Colors.darker,
    paddingHorizontal: 8,
    // textAlign: 'center',
    fontSize: 16,
  },
  timeLine: {
    paddingHorizontal: 0,
  },
  fromToTimeContainer: {
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    // flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 210,
    justifyContent: 'flex-end',
  },
  closedText: { color: Colors.light, fontSize: 16, textAlign: 'right' },
  webTimePicker: { width: 70 },
  dayText: { fontSize: 15, fontWeight: '500' },
  fullRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
    // maxWidth: 360,
  },
});

export default OpenHoursStyle;
