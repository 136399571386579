import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import Style from '../constants/Style';
import { maximumDepthError } from 'react-native/Libraries/Utilities/ReactNativeTestTools';

const ICON_SIZE = 22;
const CIRCLE_SIZE = 36;
const FONT_SIZE = 16;
const VALUE_WIDTH = 75;

export default class CountIncreaser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexValue: props.initialIndex,
      numberColor: props.textColor || '#333',
      buttonColor: props.buttonColor || Colors.lightBlue,
      buttonColorDisabled: props.buttonColorDisabled || Colors.disabled,
      iconSize: props.iconSize || ICON_SIZE,
      addIcon: props.addIcon || 'md-add',
      removeIcon: props.removeIcon || 'md-remove',
      valueWidth: props.valueWidth || VALUE_WIDTH,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { maxIndex, updateValueFunc, optionsArray } = props;
    const { indexValue } = state;

    const maxArrayIndex = optionsArray.length - 1;
    const maxIndexProp = maxIndex || maxArrayIndex;

    const newIndex = Math.min(maxArrayIndex, maxIndexProp, indexValue);

    if (indexValue !== newIndex && optionsArray.length > 0) {
      updateValueFunc(optionsArray[newIndex].value);
      return {
        indexValue: newIndex,
      };
    }
    return null;
  }

  increaseCounter = () => {
    const maxIndex = this.props.optionsArray.length - 1;
    const options = this.props.optionsArray;
    const { indexValue } = this.state;
    const newIndexValue = indexValue < maxIndex ? indexValue + 1 : indexValue;
    const { updateValueFunc } = this.props;

    this.setState({
      indexValue: newIndexValue,
    });

    updateValueFunc(options[newIndexValue].value);
  };

  decreaseCounter = () => {
    const options = this.props.optionsArray;
    const { indexValue } = this.state;
    const newIndexValue = indexValue > 0 ? indexValue - 1 : indexValue;
    const { updateValueFunc } = this.props;

    this.setState({
      indexValue: newIndexValue,
    });
    updateValueFunc(options[newIndexValue].value);
  };

  render() {
    const options = this.props.optionsArray;
    const {
      numberColor,
      buttonColor,
      buttonColorDisabled,
      indexValue,
      iconSize,
      addIcon,
      removeIcon,
      valueWidth,
    } = this.state;

    const maxIndex = this.props.optionsArray.length - 1;

    const { circleBorder, vertical } = this.props;
    const circleBorderWidth = circleBorder >= 0 ? circleBorder : 0;
    const circleSize = circleBorder === 0 ? iconSize + 20 : CIRCLE_SIZE;
    const iconPadding = circleBorder === 0 && !vertical ? 0 : 0;
    const iconPaddingVertical = vertical ? 40 : 0;
    return (
      <View
        style={[
          {
            // flexDirection: 'row',
            flexDirection: vertical ? 'column-reverse' : 'row',
            borderRadius: 100,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <TouchableOpacity
          hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
          onPress={() => this.decreaseCounter()}
          style={{
            borderWidth: circleBorderWidth,
            borderRadius: 100,
            borderColor: indexValue > 0 ? buttonColor : buttonColorDisabled,
            width: circleSize,
            height: CIRCLE_SIZE,
            alignItems: 'center',
            // paddingHorizontal: 30,
            paddingRight: iconPadding,
            paddingLeft: 0,
            paddingVertical: iconPaddingVertical,
            justifyContent: 'center',
            // backgroundColor: 'red',
          }}
        >
          <Ionicons
            name={removeIcon}
            size={iconSize}
            color={indexValue > 0 ? buttonColor : buttonColorDisabled}
            style={{
              height: iconSize,
              width: iconSize,
              textAlign: 'center',
            }}
          />
        </TouchableOpacity>
        <View style={{ width: valueWidth, alignItems: 'center' }}>
          <Text style={{ fontSize: FONT_SIZE, color: numberColor }}>
            {options[indexValue] ? options[indexValue].label : 'err'}
          </Text>
        </View>

        <TouchableOpacity
          hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
          onPress={() => this.increaseCounter()}
          style={{
            borderWidth: circleBorderWidth,
            borderRadius: 100,
            borderColor:
              maxIndex > indexValue ? buttonColor : buttonColorDisabled,
            width: circleSize,
            height: CIRCLE_SIZE,
            paddingLeft: iconPadding,
            paddingRight: 0,
            // paddingHorizontal: 30,
            paddingVertical: iconPaddingVertical,
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor: 'red',
          }}
        >
          <Ionicons
            name={addIcon}
            size={iconSize}
            color={maxIndex > indexValue ? buttonColor : buttonColorDisabled}
            style={{
              height: iconSize,
              width: iconSize,
              textAlign: 'center',
              // backgroundColor:"red"
            }}
          />
        </TouchableOpacity>
      </View>
    );
  }
}
