import React from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';

import Colors from '../../../../constants/Colors';
import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import OpenHoursServices from '../../../../services/OpenHoursServices';
import { withGlobalContext } from '../../../../GlobalContext';
import { Picker } from '@react-native-community/picker';
import Collapsible from '../../../../components/Collapsible';

const DAYS_MAP = [
  { name: 'Mon', short: 'mon' },
  { name: 'Tue', short: 'tue' },
  { name: 'Wed', short: 'wed' },
  { name: 'Thu', short: 'thu' },
  { name: 'Fri', short: 'fri' },
  { name: 'Sat', short: 'sat' },
  { name: 'Sun', short: 'sun' },
];

class UpdateOpenHoursModal extends React.Component {
  constructor(props) {
    const { navigation } = props;
    const set = navigation.getParam('set', 'NO-ID');

    super(props);
    this.state = {
      set,
      OpenHoursName: set.name,
      days: set.days,
      openHoursListCollapsed: false,
      isDateTimePickerVisible: false,
      timeToEdit: { currentTime: '20:00' },
      isVisible: true,
    };
  }

  closeModal = force => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  _showTimePicker = (day, fromTo) => {
    const { days } = this.state;
    const thisDay = days[day] || {};

    const currentTime =
      fromTo === 'from' ? thisDay.open_time : thisDay.close_time;

    const timeToEdit = { day, fromTo, currentTime };
    this.setState({ timeToEdit }, () => {
      this.setState({ isDateTimePickerVisible: true });
    });
  };

  updateTime = (time, day, fromTo) => {
    const newState = Object.assign({}, this.state);
    const { days } = newState;
    days[day] = days[day] || {};
    if (fromTo === 'from') {
      days[day].open_time = time;
    } else {
      days[day].close_time = time;
    }
    this.setState({ days });
  };

  switchIsClosed = day => {
    const newState = Object.assign({}, this.state);
    const { days } = newState;
    days[day] = days[day] || {};
    days[day].closed = !days[day].closed;
    this.setState({ days });
  };

  hideTimePicker = () => this.setState({ isDateTimePickerVisible: false });

  _handleTimePicked = date => {
    const { timeToEdit } = this.state;
    const time = moment(date).format('HH:mm');
    this.updateTime(time, timeToEdit.day, timeToEdit.fromTo);
    this.hideTimePicker();
  };

  Arrow = props => {
    const name = `chevron-${props.direction}`;
    return (
      <Ionicons
        name={name}
        size={20}
        color={Colors.lightBlue}
        style={{
          marginTop: Platform.OS === 'ios' ? 5 : 0,
          marginLeft: 12,
          // marginRight: 10,
        }}
      />
    );
  };

  renderDays = () => {
    const { days } = this.state;

    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hours = i < 10 ? '0' + i : i;
        const minutes = j === 0 ? '00' : j;
        timeOptions.push(hours + ':' + minutes);
      }
    }

    const pickerOptions = timeOptions.map(time => {
      return <Picker.Item key={time} label={time} value={time} />;
    });

    const sets = DAYS_MAP.map((day, index) => {
      let thisDay = { open_time: null, close_time: null, closed: false };
      if (days[day.short]) {
        thisDay = days[day.short];
      }

      const openClose = (
        <View style={{ flexDirection: 'row' }}>
          {Platform.OS === 'web' ? (
            <Picker
              mode="dropdown"
              style={{ width: 66 }}
              selectedValue={thisDay.open_time}
              onValueChange={time => this.updateTime(time, day.short, 'from')}
            >
              {pickerOptions}
            </Picker>
          ) : (
            <TouchableOpacity
              onPress={() => this._showTimePicker(day.short, 'from')}
            >
              <Text
                style={[
                  ModalStyle.inputTime,
                  !thisDay.open_time ? { color: Colors.light } : null,
                ]}
              >
                {thisDay.open_time || '00:00'}
              </Text>
            </TouchableOpacity>
          )}
          <Text
            style={[ModalStyle.inputTime, { width: 20, color: Colors.dark }]}
          >
            -
          </Text>
          {Platform.OS === 'web' ? (
            <Picker
              mode="dropdown"
              style={{ width: 66 }}
              selectedValue={thisDay.close_time}
              onValueChange={time => this.updateTime(time, day.short, 'to')}
            >
              {pickerOptions}
            </Picker>
          ) : (
            <TouchableOpacity
              onPress={() => this._showTimePicker(day.short, 'to')}
            >
              <Text
                style={[
                  ModalStyle.inputTime,
                  !thisDay.close_time ? { color: Colors.light } : null,
                ]}
              >
                {thisDay.close_time || '00:00'}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      );

      const isClosedText = (
        <Text style={[ModalStyle.inputRight, { color: Colors.light }]}>
          Closed
        </Text>
      );
      return (
        <View key={index} style={ModalStyle.openHoursRow}>
          <Text style={{ fontSize: 16, fontWeight: '500' }}>{day.name}</Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            {thisDay.closed ? isClosedText : openClose}
            <Switch
              style={{ marginLeft: 12 }}
              onValueChange={() => this.switchIsClosed(day.short)}
              value={!thisDay.closed}
            />
          </View>
        </View>
      );
    });
    return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
  };

  switchCollapse = () => {
    this.setState({
      openHoursListCollapsed: !this.state.openHoursListCollapsed,
    });
  };

  updateName = value => {
    this.setState({ OpenHoursName: value });
  };

  removeDialog = () => {
    const { name } = this.state.set;
    Alert.alert('Confirm Delete', `Delete ${name}?`, [
      {
        text: 'Cancel',
        // style: 'cancel',
      },
      {
        text: 'Delete',
        style: 'destructive',
        onPress: () => this.removeOpenHours(),
      },
    ]);
  };

  async removeOpenHours() {
    const { jwtToken } = this.props.global;
    const { set } = this.state;
    const setId = set.id;

    const result = await OpenHoursServices.removeOpenHoursSet(jwtToken, setId);
    if (result.ok) {
      this.props.global.updateOpenHours();
      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  async updateOpenHours() {
    const { days, OpenHoursName, set } = this.state;
    const { jwtToken, restaurantId } = this.props.global;

    const data = {};
    data.days = days;
    data.OpenHoursName = OpenHoursName;
    const { id } = set;

    const result = await OpenHoursServices.updateOpenHoursSet(
      jwtToken,
      restaurantId,
      data,
      id,
    );
    if (result.ok) {
      this.props.global.updateOpenHours();
      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  render() {
    const { switchCollapse, closeModal, updateName } = this;
    const {
      openHoursListCollapsed,
      OpenHoursName,
      timeToEdit,
      isVisible,
    } = this.state;
    const selectedTime = timeToEdit.currentTime
      ? timeToEdit.currentTime
      : '12:00';
    const timePickerDate = `2000-01-01T${selectedTime}:00`;
    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <View
          style={[
            ModalStyle.modalContainer,
            // ModalStyle.modalContainerEdit,
          ]}
        >
          <View style={ModalStyle.headerBar}>
            <View style={ModalStyle.headerNavigation} />
            <Text style={ModalStyle.headerTitle}>Edit Open Hours</Text>
            <TouchableOpacity
              style={ModalStyle.headerNavigation}
              onPress={() => closeModal()}
            >
              <Ionicons name="md-close" size={29} color="#ccc" />
            </TouchableOpacity>
          </View>
          <ScrollView style={{ marginHorizontal: 36 }}>
            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Name</Text>
                <View style={ModalStyle.touchableSelect}>
                  <TextInput
                    style={[
                      ModalStyle.fontRight,
                      ModalStyle.inputRight,
                      { color: '#222' },
                    ]}
                    onChangeText={value => updateName(value)}
                    placeholder="Enter set name"
                    value={OpenHoursName}
                    autoCorrect={false}
                  />
                </View>
              </View>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Days</Text>
                <TouchableOpacity
                  style={ModalStyle.touchableSelect}
                  onPress={() => switchCollapse()}
                >
                  <Text style={ModalStyle.fontRight}>
                    {openHoursListCollapsed ? 'Show' : 'Hide'}
                  </Text>
                  <this.Arrow
                    direction={openHoursListCollapsed ? 'down' : 'up'}
                  />
                </TouchableOpacity>
              </View>
              <Collapsible
                collapsed={openHoursListCollapsed}
                duration={500}
                collapsedHeight={0}
              >
                <this.renderDays />
              </Collapsible>
              <View style={Buttons.container}>
                <TouchableOpacity
                  style={[Buttons.big]}
                  onPress={() => this.updateOpenHours()}
                >
                  <Text style={Buttons.text}>Save Changes</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <DateTimePicker
            isVisible={this.state.isDateTimePickerVisible}
            onConfirm={this._handleTimePicked}
            onCancel={this.hideTimePicker}
            mode="time"
            date={moment(timePickerDate).toDate()}
            minuteInterval={15}
            width={320}
          />
          <Collapsible
            collapsed={!openHoursListCollapsed}
            duration={500}
            collapsedHeight={0}
          >
            <TouchableOpacity
              style={ModalStyle.bottomButton}
              onPress={() => this.removeDialog()}
            >
              <Ionicons
                name="close"
                size={28}
                color={Colors.dangerRed}
                style={{
                  height: 28,
                  width: 28,
                  // backgroundColor: "blue"
                }}
              />
              <Text style={ModalStyle.deleteText}>Delete this set</Text>
            </TouchableOpacity>
          </Collapsible>
        </View>
      </Modal>
    );
  }
}

export default withGlobalContext(UpdateOpenHoursModal);
