import GLOBAL from '../constants/Globals';
import deviceStorage from './DeviceStorage';

const TableServices = {
  async getTables2(jwtToken, restaurantId) {
    const url = GLOBAL.API_URL + '/tables?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });

      if (response.ok) {
        const responseJson = await response.json();
        // responseJson.ok = response.ok;
        return responseJson;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getTableGroups2(jwtToken, restaurantId) {
    const url =
      GLOBAL.API_URL +
      '/tables/groups?restaurant_id=' +
      restaurantId +
      '&list_tables=1';
    const auth = `Bearer ${jwtToken}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        // responseJson.ok = response.ok;
        return responseJson;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getTableGroups(
    jwtToken_deprecated,
    restaurantId_deprecated,
    list_tables = 0,
  ) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url =
      GLOBAL.API_URL +
      '/tables/groups?restaurant_id=' +
      restaurantId +
      '&list_tables=' +
      list_tables;
    const auth = 'Bearer ' + jwtToken;

    try {
      let response = await fetch(url, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let responseJson = await response.json();
      this.setState({
        tableGroups: responseJson,
        loadingGroups: false,
      });
    } catch (error) {
      throw Error(error);
    }
  },

  async getTables(
    jwtToken_deprecated,
    restaurantId_deprecated,
    redirect = 'follow',
  ) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/tables?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;
    try {
      let response = await fetch(url, {
        method: 'GET',

        redirect: 'follow',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      if (!response.ok) {
        this.setState({
          loadingTables: false,
        });
        throw Error(response.statusText);
      }
      let responseJson = await response.json();
      this.setState({
        tables: responseJson,
        loadingTables: false,
      });
    } catch (error) {
      this.setState({
        loadingTables: false,
      });
      throw Error(error);
    }
  },

  async createTableGroup(jwtToken_deprecated, restaurantId_deprecated, data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/tables/groups';
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.name = data.name;
    collection.prefix = data.prefix;
    collection.outside = data.outside;
    collection.open_hours_set_id = data.open_hours_set_id;

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async editTableGroup(
    jwtToken_deprecated,
    restaurantId_deprecated,
    data,
    groupId,
  ) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/tables/groups/' + groupId;
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.name = data.name;
    collection.prefix = data.prefix;
    collection.outside = data.outside || false;
    collection.open_hours_set_id = data.open_hours_set_id;

    try {
      let response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async removeGroup(jwtToken_deprecated, groupId) {
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/tables/groups/' + groupId;
    const auth = 'Bearer ' + jwtToken;
    try {
      let response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async addNewTable(jwtToken_deprecated, restaurantId_deprecated, data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = GLOBAL.API_URL + '/tables';
    const auth = 'Bearer ' + jwtToken;

    const collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.table_number = data.tableNumber;
    collection.chairs = data.chairs;
    collection.table_group_id = data.selectedGroupId;
    if (data.modelId) {
      collection.table_model_id = data.modelId;
    }
    console.log(collection);

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      const responseJson = await response.json();
      responseCollection.message = responseJson.message;
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async verifyDevice(
    jwtToken_deprecated,
    restaurantId_deprecated,
    verificationCode,
  ) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/devices/verify';
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.verification_code = verificationCode;

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      let responseJson = await response.json();

      responseCollection.ok = response.ok;
      responseCollection.message = responseJson.message;
      responseCollection.device_id = responseJson.device_id;

      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async linkDeviceToTable(jwtToken_deprecated, deviceId, tableId) {
    const jwtToken = await deviceStorage.getJWT();

    let url = GLOBAL.API_URL + '/devices/link';
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.device_id = deviceId;
    collection.table_id = tableId;

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async updateTable(
    jwtToken_deprecated,
    restaurantId_deprecated,
    data,
    tableId,
  ) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();

    const url = GLOBAL.API_URL + '/tables/' + tableId;
    const auth = 'Bearer ' + jwtToken;

    const collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.table_number = data.tableNumber;
    collection.chairs = data.chairs;
    collection.table_group_id = data.selectedGroupId;

    if (data.modelId) {
      collection.table_model_id = data.modelId;
    }

    try {
      let response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async removeTable(deprecated_jwtToken, tableId) {
    const jwtToken = await deviceStorage.getJWT();
    let url = GLOBAL.API_URL + '/tables/' + tableId;
    const auth = 'Bearer ' + jwtToken;
    try {
      let response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
};

export default TableServices;
