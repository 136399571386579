import { Platform, Text, View } from 'react-native';
import React from 'react';
import { DATE_FORMAT } from '../../contex/DateTimeContext';
import { differenceInMinutes, format, set } from 'date-fns';

const CurrentTimeIndicator = ({
  selectedDate,
  firstHour,
  hourLength,
  position,
  style,
  color,
  lineColor,
}) => {
  const currentTime = new Date();

  const hourStart = set(currentTime, {
    hours: firstHour,
    minutes: 0,
    seconds: 0,
  });
  const offsetTime = differenceInMinutes(currentTime, hourStart);

  // const hourStart = moment(firstHour.toString(), 'HH');
  // const offsetTime = currentTime.diff(hourStart, 'minutes');

  const offset = (offsetTime * hourLength) / 60;
  if (format(selectedDate, DATE_FORMAT) !== format(currentTime, DATE_FORMAT)) {
    return null;
  }

  if (position === 'top') {
    return (
      <View
        style={[
          {
            position: 'absolute',
            left: offset - 35,
            // bottom: -0,
            // marginBottom: -10,
            // height: 40,
            width: 70,
            paddingTop: 4,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible',
            // height: 400,
            // top: 0,
          },
          { ...style },
        ]}
      >
        <View
          style={{
            backgroundColor: color,
            height: 22,
            width: 50,
            paddingTop: Platform.OS === 'ios' ? 1 : 0,
            borderRadius: 3,
            justifyContent: 'center',
            alignItems: 'center',
            shadowOpacity: 0.2,
            shadowRadius: 3,
            overflow: 'visible',
            shadowOffset: {
              height: 2,
              width: 2,
            },
            elevation: 1,
          }}
        >
          <Text
            style={{
              fontSize: 13,
              fontWeight: Platform.OS === 'ios' ? '500' : '400',
              color: 'white',
            }}
            selectable={false}
          >
            {format(currentTime, 'HH:mm')}
          </Text>
        </View>
        <View
          style={{
            height: Platform.OS === 'android' ? 0 : 2000,
            // zIndex: 50,
            // height: 9,
            backgroundColor: lineColor,
            width: 2,
            shadowOpacity: 0.1,
            shadowRadius: 2,
            overflow: 'visible',
            shadowOffset: {
              // height: 2,
              // width: 2,
            },
            elevation: 10,
          }}
        />
      </View>
    );
  }
  return (
    <View
      style={{
        marginTop: -20,
        // paddingTop: -20,
        position: 'absolute',
        left: offset - 5,
        // top: -20,
        height: '100%',
        width: 10,
        alignItems: 'center',
        zIndex: 20,
      }}
    >
      <View
        style={{
          backgroundColor: 'rgba(48,209,88,1)',
          // height: '100%',
          width: 2,
        }}
      />
    </View>
  );
};

export default CurrentTimeIndicator;
