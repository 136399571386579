import React from 'react';
import {
  ActivityIndicator,
  Animated,
  Image,
  StyleSheet,
  View,
} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

export default class AuthLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.animatedValue = new Animated.Value(0.5);
    this.animatedValue2 = new Animated.Value(0);
    this._bootstrapAsync();
  }

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    const userToken = await AsyncStorage.getItem('jwtToken');
    // console.log("token:", userToken);
    setTimeout(() => {
      this.props.navigation.navigate(userToken ? 'timeline' : 'login');
    }, 1000);
  };

  // Render any loading content that you like here
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            fadeDuration={500}
            resizeMode={'contain'}
            source={require('../../assets/images/reetab_business.png')}
          />
          <View style={{ padding: 20 }}>
            <ActivityIndicator size="small" />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    width: 420,
    maxWidth: '80%',
  },
});
