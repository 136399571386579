import GLOBAL from '../constants/Globals';

import deviceStorage from './DeviceStorage';

const OpenHoursServices = {
  async getOpenHours(jwtToken, restaurantId) {
    const url = GLOBAL.API_URL + '/open_hours?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getDefaultOpenHours(jwtToken, restaurantId) {
    const url =
      GLOBAL.API_URL + '/open_hours/default?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getClosedDays(jwtToken, restaurantId) {
    const url =
      GLOBAL.API_URL + '/open_hours/closed_days?restaurant_id=' + restaurantId;
    const auth = 'Bearer ' + jwtToken;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
        timeout: 5,
      });
      const responseJson = await response.json();
      responseJson.ok = response.ok;
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async updateClosedDays(days) {
    // console.log(data, setId);
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    let url = GLOBAL.API_URL + '/open_hours/closed_days';
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);

    const formatedDays = days.map(day => {
      return { date: day };
    });
    collection.closed_days = formatedDays;
    console.log(collection);

    try {
      let response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      const responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        const responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async createOpenHoursSet(jwtToken_deprecated, restaurantId_deprecated, data) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    let url = GLOBAL.API_URL + '/open_hours';
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.name = data.OpenHoursName;
    collection.days = data.days;

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async updateOpenHoursSet(
    jwtToken_deprecated,
    restaurantId_deprecated,
    data,
    setId,
  ) {
    // console.log(data, setId);
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    let url = GLOBAL.API_URL + '/open_hours/' + setId;
    const auth = 'Bearer ' + jwtToken;

    let collection = {};
    collection.restaurant_id = parseInt(restaurantId);
    collection.name = data.OpenHoursName;
    collection.days = data.days;
    // console.log(collection);
    try {
      let response = await fetch(url, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collection),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },

  async removeOpenHoursSet(jwtToken_deprecated, setId) {
    const restaurantId = await deviceStorage.loadRestaurantId();
    const jwtToken = await deviceStorage.getJWT();
    let url = GLOBAL.API_URL + '/open_hours/' + setId;
    const auth = 'Bearer ' + jwtToken;
    try {
      let response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: auth,
        }),
      });
      let responseCollection = {};
      responseCollection.ok = response.ok;
      if (!response.ok) {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      } else {
        let responseJson = await response.json();
        responseCollection.message = responseJson.message;
      }
      return responseCollection;
    } catch (error) {
      throw Error(error);
    }
  },
};

export default OpenHoursServices;
