import React, { useContext, useState } from 'react';
import {
  FlatList,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import TablesViewStyle from '../../../styles/TablesViewStyle';

import { GlobalContext } from '../../../GlobalContext';
import Style from '../../../constants/Style';
import Colors from '../../../constants/Colors';
import RenderTables from '../components/RenderTables';
import { FloatMenuSetupTables } from '../components/FloatButton';
import AddEditGroupModal from '../modals/AddEditGroupModal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import NewModelModal from '../modals/NewModelModal';

const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const DAYS_MAP = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

const extractGroupKey = item => `group + ${item.id}`;

const RenderOpenHours = ({ days }) => {
  const weekDayToday = (new Date().getDay() + 6) % 7;
  let OpenHoursDays;
  if (days) {
    OpenHoursDays = DAYS.map((value, key) => {
      const day = days[value];
      const dayName = DAYS_MAP[value];
      let openInterval = 'undefined';
      if (day && day.open_time && day.close_time) {
        const openTime = day.open_time;
        const closeTime = day.close_time;
        openInterval = `${openTime} - ${closeTime}`;
      }
      if (day && day.closed) {
        openInterval = 'Closed';
      }
      return (
        <View
          style={
            key === weekDayToday
              ? TablesViewStyle.openHoursDayContainerSelected
              : TablesViewStyle.openHoursDayContainer
          }
          key={key}
        >
          <Text
            style={{ fontSize: 15, fontWeight: '500', color: Colors.darker }}
          >
            {dayName}
          </Text>
          <Text
            style={{
              fontSize: day?.closed ? 15 : 15,
              color: day?.closed ? Colors.light : Colors.dark,
            }}
          >
            {openInterval}
          </Text>
        </View>
      );
    });
  }
  return <View style={{ flex: 1 }}>{OpenHoursDays}</View>;
};

const RenderGroup = ({ item, onEdit }) => {
  return (
    <View style={[TablesViewStyle.groupRowContainer, Style.shadowShort]}>
      <View
        style={{
          marginTop: 6,
          marginHorizontal: 10,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <View style={TablesViewStyle.subHeader}>
          <Text style={TablesViewStyle.subHeaderTitleFont}>
            {item.name || null}
            <Text style={{ color: Colors.lighter, fontSize: 18 }}>
              {' '}
              {item.prefix ? item.prefix : ''}
              {/*{group}*/}
            </Text>
          </Text>
        </View>
        <View style={TablesViewStyle.subHeader}>
          <TouchableOpacity
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            onPress={() => onEdit(item)}
            style={TablesViewStyle.editButton}
          >
            <Text style={TablesViewStyle.editButtonFont}>Edit Group</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          // flex: 1,
          flexDirection: 'row',
          marginVertical: 0,
          flexWrap: 'wrap',
        }}
      >
        <View style={{ marginHorizontal: 10, minWidth: 280, flexGrow: 1 }}>
          {/*<View style={TablesViewStyle.moduleContainer}>*/}
          {/*  <View style={TablesViewStyle.moduleRow}>*/}
          {/*    <Text style={TablesViewStyle.moduleTitleBold}>RESERVATIONS</Text>*/}
          {/*    <Text style={TablesViewStyle.moduleSubTitle}>Status</Text>*/}
          {/*  </View>*/}
          {/*  <Text*/}
          {/*    style={[*/}
          {/*      TablesViewStyle.moduleMainInfoFont,*/}
          {/*      { color: Colors.confirmGreen },*/}
          {/*    ]}*/}
          {/*  >*/}
          {/*    enabled*/}
          {/*  </Text>*/}
          {/*</View>*/}
          <View style={TablesViewStyle.moduleContainer}>
            <View style={TablesViewStyle.moduleRow}>
              <Text style={TablesViewStyle.moduleTitleBold}>TABLES</Text>
              <Text style={TablesViewStyle.moduleSubTitle}>Total</Text>
            </View>
            <Text style={TablesViewStyle.moduleMainInfoFont}>
              {item.number_of_tables}
            </Text>
          </View>
          <View style={TablesViewStyle.moduleContainer}>
            <View style={TablesViewStyle.moduleRow}>
              <Text style={TablesViewStyle.moduleTitleBold}>OPEN HOURS</Text>
              {/*<Text style={TablesViewStyle.moduleSubTitle}>Title</Text>*/}
            </View>
            <Text style={TablesViewStyle.moduleMainInfoFont}>
              {item.open_hours_name}
            </Text>
            <Text style={TablesViewStyle.moduleSubTitle}>Days</Text>
            <RenderOpenHours days={item.open_hours.days} />
          </View>
        </View>
        <View style={{ paddingHorizontal: 10, width: 330, flexGrow: 1 }}>
          <RenderTables
            tables={item.tables}
            groupId={item.id}
            groupName={item.name}
          />
        </View>
      </View>
    </View>
  );
};

const TableGroupsView = () => {
  const [newGroupModalVisible, setNewGroupModalVisible] = useState(false);

  const [editGroup, setEditGroup] = useState(null);
  const [isNewModelModalVisible, setIsNewModelModalVisible] = useState(false);

  const hideEditModal = () => {
    setEditGroup(null);
  };

  const showEditModal = group => {
    setEditGroup(group);
  };

  // const showDevicePairingModal = () => {
  //   this.props.navigation.navigate('devicePairing');
  // };

  const hideNewGroupModal = () => {
    setNewGroupModalVisible(false);
  };

  const showNewGroupModal = () => {
    setNewGroupModalVisible(true);
  };

  // const { global } = this.props;
  const global = useContext(GlobalContext);

  const { tableGroups, deviceType } = global;

  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const isNarrow = dimensions.width < 650;

  return (
    <View style={[TablesViewStyle.container]}>
      <FloatMenuSetupTables
        onPressCreateGroup={() => showNewGroupModal()}
        onPressCreateTableModel={() => setIsNewModelModalVisible(true)}
      />
      <View style={TablesViewStyle.topBarContainer}>
        <View
          style={[
            TablesViewStyle.menuBarContainer,
            { marginTop: Math.max(insets.top, 10) + 6 },
          ]}
        >
          <View />
        </View>
      </View>
      <FlatList
        contentContainerStyle={{
          paddingTop: 60 + Math.max(insets.top, 10),
          paddingBottom: 20,
        }}
        data={tableGroups}
        renderItem={({ item }) => (
          <RenderGroup item={item} onEdit={showEditModal} />
        )}
        keyExtractor={extractGroupKey}
        showsVerticalScrollIndicator={false}
      />
      <AddEditGroupModal
        visible={newGroupModalVisible}
        close={hideNewGroupModal}
      />
      <NewModelModal
        isVisible={isNewModelModalVisible}
        closeModal={() => setIsNewModelModalVisible(false)}
        smallDevice={Math.min(dimensions.width, dimensions.height) < 600}
      />
      {editGroup && (
        <AddEditGroupModal
          visible={!!editGroup}
          close={hideEditModal}
          editGroup={editGroup}
          editPrefix={editGroup?.prefix}
          editGroupId={editGroup?.id}
          editGroupName={editGroup?.name}
          editOpenHoursId={editGroup?.open_hours_set_id}
          editOpenHoursName={editGroup?.open_hours_name}
        />
      )}
    </View>
  );
};

export default TableGroupsView;
