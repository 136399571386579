import React, { useContext, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Portal } from 'react-native-paper';
import Modal from 'react-native-modal';
import {
  differenceInMinutes,
  format,
  formatDuration,
  intervalToDuration,
  parseISO,
} from 'date-fns';
import Colors from '../../../constants/Colors';
import { InfoItem } from './ReservationOverviewModal';
import Button from '../../../components/Button';
import ReservationServices from '../../../services/ReservationsServices';
import { GlobalContext } from '../../../GlobalContext';
import { ReservationsContext } from '../../../contex/ReservationsContext';
import { ActivityContext } from '../../../contex/ActivityContext';

const EditOverview = ({
  reservationId,
  prevDateFrom,
  prevDateTo,
  newDateFrom,
  newDateTo,
  isStartTimeUpdated,
  isEndTimeUpdated,
  removedTables,
  addedOrNotRemovedTables,
  customerFullName,
}) => {
  let addedOrNotRemovedTablesString = '';
  let removedTablesString = '';

  addedOrNotRemovedTables.forEach((table, index) => {
    addedOrNotRemovedTablesString += `${index === 0 ? '' : ', '}${
      table.tableNumber
    } (${table.chairs} chairs)`;
  });

  removedTables.forEach((table, index) => {
    removedTablesString += `${index === 0 ? '' : ', '}${table.tableNumber}${
      table.tableNumber
    } (${table.chairs} chairs)`;
  });

  const prevDuration = formatDuration(
    intervalToDuration({ start: prevDateFrom, end: prevDateTo }),
  );
  const newDuration = formatDuration(
    intervalToDuration({ start: newDateFrom, end: newDateTo }),
  );
  const isDurationUpdated = prevDuration !== newDuration;

  return (
    <View
      style={{
        marginBottom: 16,
        backgroundColor: Colors.lightBackground,
        borderRadius: 5,
        paddingHorizontal: 13,
        paddingTop: 12,
      }}
    >
      <Text
        style={{
          fontWeight: '400',
          fontSize: 16,
          marginBottom: 10,
          color: Colors.darkest,
        }}
      >
        <Text style={{ fontWeight: '600' }}>
          #{reservationId}{' '}
          <Text style={{ fontWeight: '600', color: Colors.light }}>
            {customerFullName}
          </Text>
          {}
        </Text>
      </Text>
      {/* <InfoItem label={'Name'} value={customerFullName} /> */}
      <InfoItem
        label="Start Time"
        value={`${format(newDateFrom, 'HH:mm')}`}
        crossedValue={`${
          isStartTimeUpdated ? format(prevDateFrom, 'HH:mm') : ''
        }`}
      />

      <InfoItem
        label="Duration"
        value={`${newDuration}`}
        crossedValue={`${isDurationUpdated ? prevDuration : ''}`}
      />

      <InfoItem
        label={addedOrNotRemovedTables.length > 1 ? 'Tables' : 'Table'}
        value={addedOrNotRemovedTablesString}
        crossedValue={removedTablesString}
      >
        {addedOrNotRemovedTables.map((table) => {
          return (
            <View
              key={`${table.id} ${reservationId} ${
                table.prevTable ? table.prevTable.tableNumber : 'x'
              }`}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 2,
              }}
            >
              {/* <View */}
              {/*  style={{ */}
              {/*    height: 4, */}
              {/*    width: 4, */}
              {/*    marginLeft: 6, */}
              {/*    marginRight: 8, */}
              {/*    backgroundColor: '#555', */}
              {/*    borderRadius: 20, */}
              {/*  }} */}
              {/* /> */}
              <Text
                style={{ fontSize: 15, color: '#161721', fontWeight: '400' }}
              >
                <Text
                  style={{
                    color: '#afb2bb',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>
                    {table.prevTable ? `${table.prevTable.tableNumber}` : ''}
                  </Text>
                  <Text style={{ fontStyle: 'italic' }}>
                    {table.prevTable
                      ? ` (${table.prevTable.chairs} chairs)`
                      : ''}
                  </Text>
                </Text>
                {table.prevTable ? '  ' : ''}

                {table.prevTable ? (
                  <Ionicons name="arrow-forward" size={16} color="#555" />
                ) : (
                  ''
                )}
                {table.prevTable ? '  ' : ''}
                <Text style={{ fontWeight: 'bold' }}>{table.tableNumber}</Text>
                <Text style={{ fontStyle: 'italic' }}>
                  {' '}
                  ({table.chairs} chairs)
                </Text>
              </Text>
            </View>
          );
        })}
      </InfoItem>
    </View>
  );
};

const transformTable = (table, prevTable = null) => {
  return {
    tableNumber: table.table_number || table.tableNumber,
    chairs: table.chairs,
    id: table.id,
    prevTable,
  };
};

const ConfirmEditsModal = ({ isVisible, hideModal, onCancel, edits }) => {
  const windowWidth = useWindowDimensions().width;
  const fullScreen = windowWidth < 500;
  let modalWidth = Platform.OS === 'web' ? 500 : 400;
  if (fullScreen) {
    modalWidth = windowWidth;
  }

  const global = useContext(GlobalContext);
  const { refreshReservations, refreshReservationsAsync } = useContext(
    ReservationsContext,
  );

  const { refreshUpcomingReservationsAsync } = useContext(ActivityContext);
  const {
    showSnackbarMessage,
    updateReservationsOnSelectedDate,
    showAlert,
  } = global;

  const [reservationsEdits, setReservationsEdits] = useState([]);
  const [buttonActive, setButtonActive] = useState(true);

  useEffect(() => {
    if (isVisible) {
      const result = [];
      edits.forEach((edit) => {
        const { reservation } = edit;
        const prevDateFrom = parseISO(reservation.dateTimeFromISOString);
        const prevDateTo = parseISO(reservation.dateTimeToISOString);

        const newDateFrom = edit.updatedDateFrom
          ? parseISO(edit.updatedDateFrom)
          : prevDateFrom;
        const newDateTo = edit.updatedDateTo
          ? parseISO(edit.updatedDateTo)
          : prevDateTo;

        const prevTables = edit.reservation.tables.map((table) => table);
        const newTables = edit.updatedTables.map((update) => update);

        const isStartTimeUpdated =
          Math.abs(differenceInMinutes(prevDateFrom, newDateFrom)) > 1;

        const isEndTimeUpdated =
          Math.abs(differenceInMinutes(prevDateTo, newDateTo)) > 1;

        const removedTables = [];
        const addedOrNotRemovedTables = [];

        prevTables.forEach((table) => {
          const replacement = newTables.find(
            (x) => x.prevTable.id === table.id,
          );
          if (replacement && replacement.updatedTable.id !== table.id) {
            const prevTable = transformTable(replacement.prevTable);
            removedTables.push(prevTable);
            addedOrNotRemovedTables.push(
              transformTable(replacement.updatedTable, prevTable),
            );
          } else {
            addedOrNotRemovedTables.push(transformTable(table));
          }
        });
        if (
          removedTables.length !== 0 ||
          isStartTimeUpdated ||
          isEndTimeUpdated
        ) {
          result.push({
            reservationId: edit.reservationId,
            customerFullName: reservation.customer.fullName,
            prevDateFrom,
            prevDateTo,
            newDateFrom,
            newDateTo,
            isStartTimeUpdated,
            isEndTimeUpdated,
            removedTables,
            addedOrNotRemovedTables,
          });
        }
      });
      setReservationsEdits(result);
    }
  }, [isVisible]);

  useEffect(() => {
    if (reservationsEdits.length < 1) {
      onCancel();
    }
  }, [reservationsEdits]);

  const onConfirm = () => {
    setButtonActive(false);
    const editReservationsApiObject = [];
    reservationsEdits.forEach((reservation) => {
      const obj = { reservation_id: reservation.reservationId, tables: [] };
      if (reservation.isStartTimeUpdated) {
        obj.reservation_date = format(
          reservation.newDateFrom,
          "yyyy-MM-dd'T'HH:mm:ss",
        );
      }
      if (reservation.isEndTimeUpdated) {
        obj.reservation_date_end = format(
          reservation.newDateTo,
          "yyyy-MM-dd'T'HH:mm:ss",
        );
      }
      reservation.addedOrNotRemovedTables.forEach((table) => {
        obj.tables.push(table.id);
      });
      editReservationsApiObject.push(obj);
    });

    ReservationServices.patchReservations(editReservationsApiObject)
      .then((res) => {
        refreshReservationsAsync().then(() => {
          onCancel();
          setButtonActive(true);
          showAlert('Reservations were updated.', 'info');
        });
      })
      .catch((error) => {
        showAlert('Reservations overlap.', 'error');
        hideModal();
        setButtonActive(true);
      });
  };

  return (
    <Modal
      hideModalContentWhileAnimating
      onSwipeComplete={hideModal}
      isVisible={isVisible}
      backdropOpacity={0.4}
      // animationIn={Platform.OS === 'web' ? 'fadeIn' : 'fadeInRight'}
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInTiming={200}
      // animationOut={Platform.OS === 'web' ? 'fadeOut' : 'fadeOutRightBig'}
      onBackButtonPress={() => hideModal()}
      onBackdropPress={() => hideModal()}
      // onRequestClose={hideModal}
      // coverScreen
      useNativeDriver
      // transparent
      style={{ margin: 0 }}
    >
      <Portal.Host>
        <View
          style={{
            flex: 1,
            // backgroundColor: 'rgba(0,0,0, 0.4)',
            // alignSelf: Platform.OS === 'web' ? 'center' : 'flex-end',
            alignSelf: Platform.OS === 'web' ? 'center' : 'center',
            justifyContent: Platform.OS === 'web' ? 'center' : 'center',
          }}
        >
          <SafeAreaView
            style={{
              paddingTop: !fullScreen ? 10 : 0,
              width: modalWidth,
              backgroundColor: '#fff',
              borderRadius: fullScreen ? 0 : 8,
              // alignSelf: 'flex-end',
              flex: !fullScreen ? undefined : 1,
              maxHeight: !fullScreen ? '90%' : undefined,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                // flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  marginLeft: 30,
                  fontWeight: '500',
                }}
              >
                Review changes
              </Text>

              <TouchableOpacity
                onPress={hideModal}
                style={{
                  width: 80,
                  height: 46,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Ionicons name="md-close" size={26} color="#ccc" />
              </TouchableOpacity>
            </View>
            <ScrollView
              style={{
                paddingHorizontal: 25,
              }}
              contentContainerStyle={{
                paddingTop: 10,
              }}
              keyboardShouldPersistTaps="always"
            >
              {reservationsEdits.map((edit) => {
                return (
                  <EditOverview
                    prevDateFrom={edit.prevDateFrom}
                    prevDateTo={edit.prevDateTo}
                    newDateFrom={edit.newDateFrom}
                    newDateTo={edit.newDateTo}
                    isStartTimeUpdated={edit.isStartTimeUpdated}
                    isEndTimeUpdated={edit.isEndTimeUpdated}
                    removedTables={edit.removedTables}
                    addedOrNotRemovedTables={edit.addedOrNotRemovedTables}
                    reservationId={edit.reservationId}
                    customerFullName={edit.customerFullName}
                    key={`${edit.reservationId}`}
                  />
                );
              })}
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 25,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                paddingTop: 18,
                paddingBottom: 18,
                elevation: 6,
                shadowOpacity: fullScreen ? 0.04 : 0.05,
                shadowOffset: {
                  width: 0,
                  height: -8,
                },
                shadowRadius: 8,
                backgroundColor: 'white',
              }}
            >
              <Button
                text="Discard changes"
                active
                textColor="#767a85"
                style={{
                  marginRight: 8,
                  flex: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                }}
                onPress={onCancel}
              />
              <Button
                color={Colors.darkBlue}
                text="Confirm"
                active={buttonActive}
                loading={!buttonActive}
                loadingComponent={
                  <ActivityIndicator
                    animating
                    size="small"
                    style={{ opacity: 1, marginRight: 10 }}
                    color="#ffffff"
                  />
                }
                style={{
                  marginLeft: 8,
                  flex: 1,
                  paddingHorizontal: 10,
                  height: 40,
                  // paddingVertical: 10,
                }}
                onPress={onConfirm}
              />
            </View>
          </SafeAreaView>
        </View>
      </Portal.Host>
    </Modal>
  );
};

export default ConfirmEditsModal;
