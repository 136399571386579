import React from 'react';
import {
  Alert,
  FlatList,
  KeyboardAvoidingView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import TableVanilla from '../../../Spaces/RoomsComponents/TableVanilla';
import LayoutServices from '../../../../services/LayoutServices';
import { withGlobalContext } from '../../../../GlobalContext';
import Style from '../../../../constants/Style';

const CHAIR_WIDTH = 50;

class ModelAssociateTableModal extends React.Component {
  constructor(props) {
    const { navigation } = props;
    const model = navigation.getParam('model', 'no-model');
    super(props);
    this.state = {
      model: model,
      isVisible: true,
      tables: [],
    };
  }

  componentDidMount() {
    this.setState({
      tables: this.props.global.tables,
    });
  }

  closeModal = force => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  Table = ({ item, assigned }) => {
    const { model } = this.state;
    const number = item.table_number;
    const tableId = item.id;
    const modelId = item.table_model.id;

    if (!assigned && null !== modelId) {
      return null;
    }
    if (assigned && model.id !== modelId) {
      return null;
    }

    if (!assigned && model.chairs.count < item.chairs) {
      return null;
    }

    return (
      <TouchableOpacity
        style={[
          {
            backgroundColor: Colors.lightest,
            borderRadius: 6,
            marginHorizontal: 20,
            marginBottom: 0,
            marginTop: 8,
            flex: 1,
            padding: 12,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          Style.shadowShort,
        ]}
        onPress={() => this.AssignTableModel(tableId, assigned)}
      >
        <Text style={{ fontSize: 17, color: Colors.dark, fontWeight: '500' }}>
          {number}
          <Text style={{ color: Colors.lighter, fontSize: 14 }}>
            {'  |  '}
            {item.table_group_name}
            {'  |  '}
            {item.chairs} Chairs
          </Text>
        </Text>
        <Text
          style={{
            fontSize: 13,
            fontWeight: '600',
            color: assigned ? Colors.dangerRed : Colors.confirmGreen,
          }}
        >
          {assigned ? 'REMOVE' : 'ADD'}
        </Text>
      </TouchableOpacity>
    );
  };

  async AssignTableModel(tableId, assigned) {
    const { model } = this.state;
    const { jwtToken } = this.props.global;
    const data = {
      table_model_id: assigned ? -1 : model.id,
      table_id: tableId,
    };

    const result = await LayoutServices.AssignTableToModel(data, jwtToken);
    if (result.ok) {
      this.props.global.updateTables();
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  }

  render() {
    const { isVisible, model } = this.state;
    const ROW_HEIGHT = 220;
    const table = model;

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => this.closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
        style={{ margin: 0 }}
      >
        <KeyboardAvoidingView behavior="padding" enabled>
          <View
            style={[
              {
                // maxWidth: '100%',
                backgroundColor: Colors.darkBlueBackground,
                paddingBottom: 20,
                borderRadius: 8,
                alignSelf: 'center',
              },
            ]}
          >
            <View style={ModalStyle.headerBar}>
              <View style={ModalStyle.headerNavigation} />
              <Text style={ModalStyle.headerTitle}>Tables to model</Text>
              <TouchableOpacity
                style={ModalStyle.headerNavigation}
                onPress={() => this.closeModal()}
              >
                <Ionicons name="md-close" size={29} color="#ccc" />
              </TouchableOpacity>
            </View>
            <View
              style={{
                paddingHorizontal: 10,
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <View
                style={[
                  {
                    width: 400,
                    maxWidth: '95%',
                    borderRadius: 5,
                    margin: 5,
                    backgroundColor: Colors.lightBlueBackground,
                  },
                  Style.shadow,
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    marginBottom: 12,
                  }}
                >
                  <Text
                    style={{
                      color: Colors.darker,
                      fontSize: 20,
                      fontWeight: '500',
                      marginBottom: 25,
                      marginTop: 20,
                      margin: 10,
                      justifyContent: 'center',
                      alignSelf: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    {model.name}
                  </Text>
                  <Table tableProps={table} maxWidth={ROW_HEIGHT} />
                  <Text
                    style={{
                      marginTop: 16,
                      color: Colors.light,
                      fontSize: 15,
                      fontWeight: '500',
                    }}
                  >
                    {model.dimensions.length} x {model.dimensions.width} cm |{' '}
                    {model.chairs.count} chairs
                  </Text>
                </View>
                <View
                  style={[
                    {
                      // flex: 3,
                      borderRadius: 17,
                      borderBottomRightRadius: 12,
                      borderBottomLeftRadius: 12,
                    },
                    // Style.shadow,
                  ]}
                >
                  <Text
                    style={{
                      color: Colors.darker,
                      fontSize: 17,
                      fontWeight: '500',
                      marginBottom: 10,
                      margin: 10,
                      justifyContent: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    Tables associated with this model
                  </Text>
                  <FlatList
                    data={this.props.global.tables}
                    style={{
                      alignSelf: 'stretch',
                      marginBottom: 10,
                      paddingBottom: 5,
                      height: 160,
                    }}
                    renderItem={({ item }) => (
                      <this.Table item={item} assigned />
                    )}
                    keyExtractor={item => ' ' + item.id}
                  />
                </View>
              </View>
              <View
                style={[
                  {
                    width: 400,
                    maxWidth: '95%',
                    margin: 5,
                    borderRadius: 5,
                    // marginLeft: 20,
                    // marginRight: 20,
                    // marginTop: 0,
                    backgroundColor: Colors.lightBlueBackground,
                  },
                  Style.shadow,
                ]}
              >
                <View
                  style={[
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 9,
                      marginTop: 8,
                      marginHorizontal: 20,
                      borderRadius: 8,
                    },
                  ]}
                >
                  <Text
                    style={{
                      color: Colors.darker,
                      fontSize: 17,
                      fontWeight: '500',
                      justifyContent: 'center',
                    }}
                  >
                    Undefined Tables
                  </Text>
                </View>
                <FlatList
                  data={this.props.global.tables}
                  style={{
                    alignSelf: 'stretch',
                    marginBottom: 10,
                    paddingBottom: 5,
                    height: 160,
                  }}
                  renderItem={({ item }) => (
                    <this.Table item={item} assigned={false} />
                  )}
                  keyExtractor={item => ' ' + item.id}
                />
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}

export default withGlobalContext(ModelAssociateTableModal);
