import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  Platform,
  Switch,
  useWindowDimensions,
  View,
} from 'react-native';

import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { NavigationEvents } from 'react-navigation';
import Animated, {
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';
import TimelineHeadStyle from '../../styles/TimelineHeadStyle';
import DateSelectItem from '../../components/timelineComponents/DateSelectItem';
import Style from '../../constants/Style';
import RoomStatic from './RoomsComponents/RoomStatic';
import ListReservations from './RoomsComponents/ListReservations';
import NewMultitableReservation from './RoomsComponents/NewMultitableReservation';
import { GlobalContext } from '../../GlobalContext';
import useSet from '../../hooks/useSet';
import { RoomContext } from '../../contex/RoomContext';
import { ReservationsContext } from '../../contex/ReservationsContext';
import SpacesMenu from './RoomsComponents/SpacesMenu';
import { DateTimeContext } from '../../contex/DateTimeContext';
import { OpenHoursContext } from '../../contex/OpenHoursContext';
import TimeRangeSelectorV2 from '../../components/TimeRangeSelectorV2';
import Colors from '../../constants/Colors';
import LogoNotification from '../../components/LogoNotification';
// const moment = extendMoment(Moment);

const Spaces: React.FC = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isNewReservationVisible, setNewReservationVisible] = useState(false);
  const [
    selectedTablesSet,
    setSelectedTables,
    addToSelectedTables,
    removeFromSelectedTables,
    toggleFromSelectedTables,
    clearSelectedTables,
    selectedTablesArray,
  ] = useSet(new Set());

  // const reservationsHeight = useRef(new Animated.Value(300)).current;

  const global = useContext(GlobalContext);
  const roomContext = useContext(RoomContext);
  const reservationsContext = useContext(ReservationsContext);
  const dateTimeContext = useContext(DateTimeContext);
  const openHoursContext = useContext(OpenHoursContext);

  const { openHoursRangeOnSelectedDate } = openHoursContext;

  const {
    selectedDateString,
    selectedDateTimeRange,
    setTimeRange,
    isLive,
    setDate,
    switchLiveMode,
  } = dateTimeContext;

  const { rooms } = reservationsContext;
  const {
    room: selectedRoom,
    setRoomId,
    reservationsOnInterval,
    tablesArray,
  } = roomContext;

  const dimensions = useWindowDimensions();
  const [isPhone, setIsPhone] = useState(undefined);
  const [isPortrait, setIsPortrait] = useState(undefined);
  const [isPhoneAndPortrait, setIsPhoneAndPortrait] = useState(undefined);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    const { width, height } = dimensions;
    setIsPhone(height < 600 || width < 600);
    setIsPortrait(height > width);
    setIsPhoneAndPortrait(width < 800 && height > width);
  }, [dimensions]);

  const reanimatedWidth = useSharedValue(0);
  const reanimatedHeight = useSharedValue(0);
  const reanimatedScaleFactor = useSharedValue(0);
  const reanimatedContainerHeight = useSharedValue(0);

  const xHeight = useSharedValue(200);

  const expandNewReservation = (spring = false) => {
    const expandedValue = reanimatedContainerHeight.value;
    xHeight.value = withDelay(
      150,
      withSpring(expandedValue, {
        damping: 30,
        stiffness: 100,
      }),
    );
  };
  const contractNewReservation = (spring = false, medium = false) => {
    const contractedValue = medium ? 204 : 60;
    xHeight.value = withSpring(contractedValue, {
      damping: 30,
      stiffness: 160,
    });
  };

  const changeSelectedDate = (date) => {
    setDate(date);
  };

  const changeSelectedRoom = (roomId) => {
    clearSelectedTables();
    setRoomId(roomId);
    if (isPortrait) {
      switchRoomSelectMenu(true);
    }
  };

  const timeIntervalSelectionCallback = (from, to) => {
    setTimeRange(from, to);
  };

  const switchReservations = () => {
    if (!isNewReservationVisible) {
      expandNewReservation();
    }
    setNewReservationVisible((prevState) => !prevState);
    clearSelectedTables();
  };

  const onTablePressCallback = (table) => {
    if (isNewReservationVisible && !table?.tableStatus?.occupied) {
      toggleFromSelectedTables(table);
    }
  };

  const switchRoomSelectMenu = (hide) => {
    setIsMenuVisible(!isMenuVisible && !hide);
  };

  const onScreenFocus = () => {
    const { refreshOpenHours } = openHoursContext;
    const { updateRooms } = global;
    refreshOpenHours();
    updateRooms();
  };

  const roomContainerRef = useRef(null);

  useEffect(() => {
    if (roomContainerRef.current) {
      roomContainerRef.current.measure((x, y, width, height, pageX, pageY) => {
        setRoomContainerDimensions(width, height);
      });
    }
  }, [selectedRoom]);

  const onLayoutUpdateCallback = ({ nativeEvent }) => {
    setRoomContainerDimensions(
      nativeEvent.layout.width,
      nativeEvent.layout.height,
    );
  };

  const setRoomContainerDimensions = (width, height) => {
    if (selectedRoom && width > 0 && height > 0) {
      let k = (width - 24) / selectedRoom.dimensions.width;
      if (k * selectedRoom.dimensions.height >= height) {
        k = (height - 24) / selectedRoom.dimensions.height;
      }

      reanimatedWidth.value = k * selectedRoom.dimensions.width;
      reanimatedHeight.value = k * selectedRoom.dimensions.height;
      reanimatedContainerHeight.value = height - 5;
      reanimatedScaleFactor.value = k;
    }
  };

  if (isPortrait === undefined) {
    return <Animated.View />;
  }

  return (
    <SafeAreaView style={[TimelineHeadStyle.mainContainer]} edges={['top']}>
      <NavigationEvents onDidFocus={onScreenFocus} />
      <View style={[TimelineHeadStyle.container]}>
        <View
          style={[
            TimelineHeadStyle.topBarContainer,
            isPhone && TimelineHeadStyle.topBarContainerSmall,
            isPhoneAndPortrait && TimelineHeadStyle.topBarContainerNotch,
            !isPortrait && Style.shadowDown,
          ]}
        >
          <View
            style={[
              TimelineHeadStyle.topBarSpace,
              {
                justifyContent: 'flex-start',
              },
            ]}
          >
            <LogoNotification
              showIconOnly={isPhoneAndPortrait}
              small={isPhone && !isPhoneAndPortrait}
            />
          </View>
          <DateSelectItem
            live={isLive}
            selectedDate={selectedDateString}
            onDateChangeCallback={changeSelectedDate}
            size={isPhone ? 'small' : 'big'}
          />
          <View
            style={[
              TimelineHeadStyle.topBarSpace,
              {
                justifyContent: 'flex-end',
                flexDirection: 'row',
              },
            ]}
          >
            <Switch
              value={isLive}
              onValueChange={() => switchLiveMode(!isLive)}
              trackColor={{ false: '#767577', true: Colors.cyan }}
              thumbColor="#ffffff"
            />
          </View>
        </View>

        <View
          style={[
            Style.shadowDown,
            {
              zIndex: 3,
              backgroundColor: 'white',
            },
          ]}
          pointerEvents="box-none"
        >
          <TimeRangeSelectorV2
            rangeFrom={openHoursRangeOnSelectedDate.from}
            rangeTo={openHoursRangeOnSelectedDate.to}
            hourWidth={isPhone ? 64 : 100}
            height={isPhone ? 36 : 40}
            minDurationInMinutes={45}
            durationIntervalInMinutes={15}
            onIntervalUpdateCallBack={timeIntervalSelectionCallback}
            showControls={Platform.OS === 'web'}
            disableScroll={Platform.OS === 'web'}
            isCollapsed={isLive}
          />
        </View>
        <View
          style={{
            flex: 1,
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <View
            style={[
              {
                zIndex: 1,
                flexDirection: isPortrait ? 'column' : 'row',
                flex: 1,
                marginTop: 0,
                backgroundColor: 'rgba(240, 240, 241, 0.999)',
              },
              Style.shadowShort,
            ]}
          >
            <View style={{}}>
              <SpacesMenu
                paddingLeft={Platform.OS === 'ios' ? insets.left - 16 : 0}
                isLive={isLive}
                isPortrait={isPortrait}
                isPhone={isPhone}
                setIsLive={switchLiveMode}
                isExpanded={isMenuVisible}
                selectedRoom={selectedRoom}
                switchRoomSelectMenu={switchRoomSelectMenu}
                changeSelectedRoom={changeSelectedRoom}
                rooms={rooms}
              />
            </View>
            <View
              style={{
                flex: 1,
                // flexShrink: 1,
                // height: '100%',
                // justifyContent: isPortrait ? 'flex-start' : 'center',
                paddingVertical: 12,
                alignItems: 'center',
              }}
              ref={roomContainerRef}
              onLayout={onLayoutUpdateCallback}
            >
              {selectedRoom && tablesArray && selectedTablesArray && (
                <RoomStatic
                  key={`${selectedRoom.id}`}
                  room={selectedRoom}
                  tables={tablesArray}
                  reanimatedWidth={reanimatedWidth}
                  reanimatedHeight={reanimatedHeight}
                  reanimatedScaleFactor={reanimatedScaleFactor}
                  // occupiedTables={Array.from(occupiedTablesSet)}
                  selectedTables={selectedTablesArray.map((table) => {
                    return table.id;
                  })}
                  registerTableClick={onTablePressCallback}
                  enableSelection
                  backgroundColor="rgba(255,255,255, 0.7)"
                  liveMode={isLive}
                />
              )}
            </View>

            <Animated.View
              style={[
                {
                  width: 350,
                  zIndex: 20,
                },
                isPortrait && {
                  width: Dimensions.get('window').width,
                  position: 'absolute',
                  elevation: 20,
                  bottom: 0,
                  left: 0,
                },
              ]}
            >
              {isNewReservationVisible ? (
                <NewMultitableReservation
                  animatedHeight={xHeight}
                  reanimatedContainerHeight={reanimatedContainerHeight}
                  dateFrom={selectedDateTimeRange.fromString}
                  dateTo={selectedDateTimeRange.toString}
                  selectedTables={selectedTablesArray}
                  onSuccess={() => switchReservations()}
                  onBack={() => switchReservations()}
                  onSelectTablesClick={() =>
                    contractNewReservation(false, true)
                  }
                  vertical={isPortrait}
                  expand={expandNewReservation}
                />
              ) : (
                <ListReservations
                  animatedHeight={xHeight}
                  reanimatedContainerHeight={reanimatedContainerHeight}
                  reservationsOnInterval={reservationsOnInterval}
                  newReservation={() => switchReservations()}
                  vertical={isPortrait}
                />
              )}
            </Animated.View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};
// Spaces.navigationOptions = {
//   headerShown: false,
// };
export default Spaces;
