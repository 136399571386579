import React from 'react';
import {
  Platform,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Alert,
  ScrollView,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import TableServices from '../../../../services/TablesServices';
import { withGlobalContext } from '../../../../GlobalContext';
import Collapsible from '../../../../components/Collapsible';

class DevicePairingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: null,
      verified: false,
      tableSelectCollapsed: true,
      groupSelectCollapsed: true,
      selectedGroup: null,
      selectedTable: null,
      deviceId: null,
      isVisible: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { tableGroups, selectedGroup } = this.state;

    if (!selectedGroup && tableGroups && tableGroups.length > 0) {
      this.changeSelectedGroup(tableGroups[0]);
    }
  }

  changeSelectedGroup = group => {
    this.setState({
      selectedGroup: group,
      groupSelectCollapsed: true,
      selectedTable: null,
    });
  };

  changeSelectedTable = table => {
    this.setState({
      selectedTable: table,
      tableSelectCollapsed: true,
    });
  };

  closeModal = () => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  downArrow = color => {
    const name = 'ios-arrow-down';
    return (
      <Ionicons
        name={name}
        size={20}
        color={color}
        style={{
          marginTop: Platform.OS === 'ios' ? 5 : 0,
          marginLeft: 12,
          // marginRight: 10,
        }}
      />
    );
  };

  updateVerificationCode = value => {
    this.setState({ verificationCode: value });
  };

  verify = async () => {
    const { jwtToken, restaurantId } = this.props.global;
    const { verificationCode } = this.state;

    const result = await TableServices.verifyDevice(
      jwtToken,
      restaurantId,
      verificationCode,
    );
    if (result.ok) {
      this.setState({ verified: true, deviceId: result.device_id });
      Alert.alert(
        'Device is now verified',
        'You can now link this device with a table',
        [
          {
            text: 'Select table',
          },
        ],
      );
    } else {
      this.setState({ verificationCode: '' });
      Alert.alert('Error', result.message, [
        {
          text: 'Ok',
        },
      ]);
    }
  };

  linkToTable = async () => {
    const { deviceId, selectedTable } = this.state;
    const { jwtToken } = this.props.global;

    const result = await TableServices.linkDeviceToTable(
      jwtToken,
      deviceId,
      selectedTable.id,
    );
    if (result.ok) {
      this.props.global.updateTableGroups();

      Alert.alert('Success', 'Device is now configured', [
        {
          text: 'Ok',
          onPress: () => this.closeModal(),
        },
      ]);
    } else {
      Alert.alert('Error', result.message, [
        {
          text: 'Ok',
        },
      ]);
    }
  };

  switchCollapse = item => {
    const { verified, groupSelectCollapsed, tableSelectCollapsed } = this.state;

    Keyboard.dismiss();

    if (item === 'table') {
      this.setState({
        tableSelectCollapsed: verified
          ? !tableSelectCollapsed
          : tableSelectCollapsed,
        groupSelectCollapsed: true,
      });
    } else if (item === 'group') {
      this.setState({
        groupSelectCollapsed: verified
          ? !groupSelectCollapsed
          : groupSelectCollapsed,
        tableSelectCollapsed: true,
      });
    }
  };

  renderGroupOptions = () => {
    const { tableGroups } = this.props.global;

    if (!tableGroups) {
      return <View />;
    }
    const sets = tableGroups.map((set, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.changeSelectedGroup(set)}
          key={index}
          style={ModalStyle.selectOptionRow}
        >
          <Text style={{ fontSize: 17 }}>{set.name}</Text>
          <Text style={ModalStyle.selectText}>Select</Text>
        </TouchableOpacity>
      );
    });
    return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
  };

  renderTableOptions = () => {
    const { selectedGroup } = this.state;

    if (!selectedGroup || !selectedGroup.tables) {
      return <View />;
    }
    const sets = selectedGroup.tables.map((set, index) => {
      if (set.device_linked) {
        return null;
      }
      return (
        <TouchableOpacity
          onPress={() => this.changeSelectedTable(set)}
          key={index}
          style={ModalStyle.selectOptionRow}
        >
          <Text style={{ fontSize: 17 }}>{set.table_number}</Text>
          <Text style={ModalStyle.selectText}>Select</Text>
        </TouchableOpacity>
      );
    });
    return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
  };

  render() {
    const {
      verificationCode,
      groupSelectCollapsed,
      tableSelectCollapsed,
      verified,
      selectedGroup,
      selectedTable,
      isVisible,
    } = this.state;
    const { closeModal, updateVerificationCode, switchCollapse } = this;

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <KeyboardAvoidingView behavior="padding" enabled>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <View style={ModalStyle.modalContainer}>
              <View style={ModalStyle.headerBar}>
                <View style={ModalStyle.headerNavigation} />
                <Text style={ModalStyle.headerTitle}>Pair a device</Text>
                <TouchableOpacity
                  style={ModalStyle.headerNavigation}
                  onPress={() => closeModal()}
                >
                  <Ionicons name="md-close" size={29} color="#ccc" />
                </TouchableOpacity>
              </View>
              <View style={{ marginHorizontal: 36 }}>
                <View style={ModalStyle.itemSelect}>
                  <View style={ModalStyle.itemSelectText}>
                    <Text style={ModalStyle.fontLeft}>Verification Code</Text>
                    <View style={ModalStyle.touchableSelect}>
                      {verified ? (
                        <Text
                          style={[
                            ModalStyle.fontRight,
                            ModalStyle.inputRight,
                            {
                              color: Colors.confirmGreen,
                              fontWeight: '500',
                              minWidth: 100,
                            },
                          ]}
                        >
                          Verified
                        </Text>
                      ) : (
                        <TextInput
                          style={[
                            ModalStyle.fontRight,
                            ModalStyle.inputRight,
                            { color: '#222', minWidth: 100 },
                          ]}
                          placeholderTextColor={Colors.placeholder}
                          onChangeText={value => updateVerificationCode(value)}
                          placeholder="ex. G41E3B"
                          value={verificationCode}
                          autoCorrect={false}
                          editable={!verified}
                          autoCapitalize="characters"
                          onSubmitEditing={() => this.verify()}
                        />
                      )}
                    </View>
                  </View>
                </View>

                <Collapsible
                  collapsed={!verified}
                  duration={500}
                  collapsedHeight={0}
                >
                  <View style={ModalStyle.itemSelect}>
                    <View style={ModalStyle.itemSelectText}>
                      <Text style={ModalStyle.fontLeft}>Group</Text>
                      <TouchableOpacity
                        style={ModalStyle.touchableSelect}
                        onPress={() => switchCollapse('group')}
                      >
                        <Text
                          style={[
                            ModalStyle.fontRight,
                            verified ? null : { color: Colors.disabled },
                          ]}
                        >
                          {selectedGroup ? selectedGroup.name : 'Select group'}
                        </Text>
                        {this.downArrow(
                          verified ? Colors.lightBlue : Colors.disabled,
                        )}
                      </TouchableOpacity>
                    </View>
                    <Collapsible
                      collapsed={groupSelectCollapsed}
                      duration={500}
                      collapsedHeight={0}
                    >
                      <ScrollView style={{ maxHeight: 250 }}>
                        <this.renderGroupOptions />
                      </ScrollView>
                    </Collapsible>
                  </View>

                  <View style={ModalStyle.itemSelect}>
                    <View style={ModalStyle.itemSelectText}>
                      <Text style={ModalStyle.fontLeft}>Table</Text>
                      <TouchableOpacity
                        style={ModalStyle.touchableSelect}
                        onPress={() => switchCollapse('table')}
                      >
                        <Text
                          style={[
                            ModalStyle.fontRight,
                            verified ? null : { color: Colors.disabled },
                          ]}
                        >
                          {selectedTable
                            ? selectedTable.table_number
                            : 'Select table'}
                        </Text>
                        {this.downArrow(
                          verified ? Colors.lightBlue : Colors.disabled,
                        )}
                      </TouchableOpacity>
                    </View>
                    <Collapsible
                      collapsed={tableSelectCollapsed}
                      duration={500}
                      collapsedHeight={0}
                    >
                      <ScrollView style={{ maxHeight: 250 }}>
                        <this.renderTableOptions />
                      </ScrollView>
                    </Collapsible>
                  </View>
                </Collapsible>
                <View style={Buttons.container}>
                  <TouchableOpacity
                    style={[Buttons.big, verified ? null : Buttons.green]}
                    onPress={() => {
                      !verified ? this.verify() : this.linkToTable();
                    }}
                  >
                    <Text style={Buttons.text}>
                      {verified ? 'Link device with table' : 'Verify'}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </Modal>
    );
  }
}

export default withGlobalContext(DevicePairingModal);
