import GLOBAL from '../constants/Globals';

const TimelineServices = {
  async get_restaurants(jwt_token) {
    const url = `${GLOBAL.API_URL}/auth/restaurants`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt_token}`,
        }),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      // console.log(responseJson);
      return await response.json();
    } catch (error) {
      throw error;
      // console.error(error);
    }
  },
};

export default TimelineServices;
