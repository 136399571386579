import { createBrowserApp } from '@react-navigation/web';
import { createSwitchNavigator } from 'react-navigation';

import MainTabNavigator from './MainTabNavigator';
import { createStackNavigator } from 'react-navigation-stack';
import LoginScreen from '../screens/Login/LoginScreen';
import AuthLoadingScreen from '../screens/Login/AuthLoadingScreen';
import RegisterScreen from '../screens/Login/RegisterScreen';
import { fadeIn } from 'react-navigation-transitions';

const LoginStack = createStackNavigator({
  login: LoginScreen,
});
LoginStack.path = 'app';

const RegisterStack = createStackNavigator({
  register: RegisterScreen,
});
RegisterStack.path = 'app';

const AuthLoadingStack = createStackNavigator(
  {
    AuthLoading: AuthLoadingScreen,
  },
  {
    headerMode: 'none',
  },
);
AuthLoadingStack.path = 'app';

const switchNavigator = createSwitchNavigator(
  {
    // You could add another route here for authentication.
    // Read more at https://reactnavigation.org/docs/en/auth-flow.html

    AuthLoading: AuthLoadingStack,
    App: MainTabNavigator,
    Auth: LoginStack,
    Register: RegisterStack,
  },
  {
    initialRouteName: 'AuthLoading',
    transitionConfig: () => fadeIn(1000),
  },
);

export default createBrowserApp(switchNavigator, {
  history: 'browser',
});
