import React from 'react';
import {
  Animated,
  Switch,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Slider from '@react-native-community/slider';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

import ModalStyle from '../../../styles/ModalStyle';

export default class OptionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backdropOpacity: new Animated.Value(1),
      modalOpacity: new Animated.Value(1),
    };
  }

  closeModal = force => {
    const { hideModal } = this.props;
    hideModal(force);
  };

  changeBackdrop = (value, value2) => {
    console.log('wht', value);
    Animated.timing(this.state.backdropOpacity, {
      toValue: value,
      duration: 300,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.state.modalOpacity, {
      toValue: value2,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  updateLength = value => {
    this.changeBackdrop(0.2, 0.99);
    this.props.updateHourLength(value);
  };
  updateHeight = value => {
    this.changeBackdrop(0.2, 0.99);

    this.props.updateRowHeight(value);
  };

  render() {
    const { isVisible, portrait } = this.props;
    const { closeModal } = this;

    return (
      <Modal
        isVisible={isVisible}
        // backdropOpacity={this.state.backdropOpacity}
        customBackdrop={
          <TouchableWithoutFeedback
            style={{ flex: 1 }}
            onPress={this.closeModal}
          >
            <Animated.View
              style={{
                flex: 1,
                backgroundColor: 'black',
                opacity: this.state.backdropOpacity,
              }}
            />
          </TouchableWithoutFeedback>
        }
        animationIn="fadeIn"
        animationInTiming={100}
        animationOut="fadeOut"
        animationOutTiming={300}
        // onBackdropPress={this.closeModal}
        coverScreen
        // scrollHorizontal
        backdropTransitionOutTiming={0}
        // hideModalContentWhileAnimating
      >
        <Animated.View
          style={[
            ModalStyle.modalContainer,
            {
              // margin: 20,
              // marginLeft: 0,
              width: portrait ? '100%' : 380,
              paddingBottom: 24,
              // height: 225,
              // flex: 1,
              opacity: this.state.modalOpacity,
            },
          ]}
        >
          <View style={ModalStyle.headerBar}>
            <View style={ModalStyle.headerNavigation} />
            <Text style={ModalStyle.headerTitle}>Options</Text>
            <TouchableOpacity
              style={ModalStyle.headerNavigation}
              onPress={() => closeModal()}
            >
              <Ionicons name="md-close" size={26} color="#ccc" />
            </TouchableOpacity>
          </View>
          <View style={{ marginHorizontal: 20 }}>
            <View style={ModalStyle.itemSelect}>
              <View style={{ marginBottom: 8 }}>
                <Text
                  style={[
                    ModalStyle.fontLeft,
                    { fontSize: 11, fontWeight: '600' },
                  ]}
                >
                  WIDTH
                </Text>
                <View style={ModalStyle.touchableSelect}>
                  {/*<View style={ModalStyle.optionsIcon}>*/}
                  {/*  <View*/}
                  {/*    style={{*/}
                  {/*      width: 12,*/}
                  {/*      // marginHorizontal: 10,*/}
                  {/*      height: 4,*/}
                  {/*      borderRadius: 1,*/}
                  {/*      backgroundColor: Colors.light,*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</View>*/}

                  <Slider
                    style={{ flex: 1, height: 32, marginHorizontal: 0 }}
                    minimumValue={32}
                    maximumValue={248}
                    step={24}
                    value={this.props.hourLength}
                    onValueChange={value => this.updateLength(value)}
                    onSlidingComplete={value => this.changeBackdrop(1, 1)}
                  />
                  {/*<View style={ModalStyle.optionsIcon}>*/}
                  {/*  <View*/}
                  {/*    style={{*/}
                  {/*      width: 26,*/}
                  {/*      marginHorizontal: 2,*/}
                  {/*      height: 4,*/}
                  {/*      borderRadius: 1,*/}
                  {/*      backgroundColor: Colors.light,*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</View>*/}
                </View>
              </View>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={{ marginBottom: 8 }}>
                <Text
                  style={[
                    ModalStyle.fontLeft,
                    { fontSize: 11, fontWeight: '600' },
                  ]}
                >
                  HEIGHT
                </Text>
                <View style={ModalStyle.touchableSelect}>
                  {/*<View style={ModalStyle.optionsIcon}>*/}
                  {/*  <Ionicons*/}
                  {/*    name="reorder-four-outline"*/}
                  {/*    size={24}*/}
                  {/*    color={Colors.light}*/}
                  {/*  />*/}
                  {/*</View>*/}

                  <Slider
                    style={{ flex: 1, height: 32, marginHorizontal: 0 }}
                    value={this.props.rowHeight}
                    minimumValue={30}
                    maximumValue={82}
                    step={6}
                    onValueChange={value => this.updateHeight(value)}
                    onSlidingComplete={value => this.changeBackdrop(1, 1)}
                  />
                  {/*<View style={ModalStyle.optionsIcon}>*/}
                  {/*  <Ionicons*/}
                  {/*    name="reorder-two"*/}
                  {/*    size={26}*/}
                  {/*    color={Colors.light}*/}
                  {/*  />*/}
                  {/*</View>*/}
                </View>
              </View>
            </View>
            <View style={ModalStyle.itemSelect}>
              <View
                style={[
                  ModalStyle.itemSelectText,
                  {
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: 0,
                  },
                ]}
              >
                <Text
                  style={[
                    ModalStyle.fontLeft,
                    { fontSize: 11, marginBottom: 7, fontWeight: '600' },
                  ]}
                >
                  HIDE CANCELED RESERVATIONS
                </Text>
                {/*<View style={ModalStyle.touchableSelect}>*/}
                <Switch
                  onValueChange={this.props.hideCancelledSwitch}
                  value={this.props.hideCancelled}
                  style={{ marginLeft: 2 }}
                  // trackColor={{ true: Colors.lightBlue }}
                />
                {/*</View>*/}
              </View>
            </View>
          </View>
        </Animated.View>
      </Modal>
    );
  }
}
