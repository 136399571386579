import React from 'react';

import { StyleSheet, View } from 'react-native';

const TimeRangeIndicator = ({}) => {
  return (
    <View
      style={styles.base}
      renderToHardwareTextureAndroid={true}
      // shouldRasterizeIOS
    >
      <View style={styles.baseTop} />
      {/*<View style={{ width: 22, height: 5.5 }}>*/}
      {/*  /!*<View style={styles.baseBottom} />*!/*/}
      {/*  <View style={styles.baseBottom2} />*/}
      {/*</View>*/}
    </View>
  );
};

export default TimeRangeIndicator;

const styles = StyleSheet.create({
  base: {
    flex: 1,
    // width: 20,
    opacity: 0.65,
    paddingLeft: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  baseBottom: {
    borderTopWidth: 7,
    borderTopColor: 'rgba(9,125,255,0.65)',
    borderLeftWidth: 8,
    borderLeftColor: 'transparent',
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    // height: 20,
    // width: 20,
    // borderRadius: 10,
    left: 0,
    bottom: 0,
    position: 'absolute',
  },
  baseBottom2: {
    height: 16,
    width: 16,
    borderRadius: 8,
    borderBottomRightRadius: 0,
    // backgroundColor: 'red',
    backgroundColor: 'rgba(9,125,255,1)',
    // transform: [{ rotate: '0deg' }, { rotateY: '140deg' }],
    transform: [
      { scaleY: 0.6 },
      { rotateZ: '45deg' },
      // { rotateX: '0deg' },
    ],
    // borderRadius: 10,
    left: 3,
    bottom: -1,
    position: 'absolute',
  },
  baseTop: {
    backgroundColor: 'rgba(9,125,255,1)',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    // marginLeft: 1,
    // height: 20,
    flex: 1,
    width: 17,
    // transform: [{ translateX: 0.2 }],
  },
});
