import React from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import Modal from 'react-native-modal';
import { Ionicons } from '@expo/vector-icons';
// import Collapsible from 'react-native-collapsible';
import Colors from '../../../../constants/Colors';

import ModalStyle from '../../../../styles/ModalStyle';
import Buttons from '../../../../styles/Buttons';
import OpenHoursServices from '../../../../services/OpenHoursServices';
import TableServices from '../../../../services/TablesServices';
import { withGlobalContext } from '../../../../GlobalContext';
import Collapsible from '../../../../components/Collapsible';

class UpdateGroupModal extends React.Component {
  constructor(props) {
    const { navigation } = props;

    const group = navigation.getParam('group', 'NO-ID');

    super(props);
    this.state = {
      groupName: group.name,
      isOutside: group.outside,
      openHoursSelectCollapsed: true,
      selectedOpenHoursName: group.open_hours_name,
      selectedOpenHoursId: group.open_hours_set_id,
      group,
      isVisible: true,
    };
  }

  downArrow = () => {
    // const name = 'ios-arrow-down';
    return (
      <Ionicons
        name={'chevron-down'}
        size={20}
        color={Colors.lightBlue}
        style={{ marginTop: Platform.OS === 'ios' ? 5 : 0, marginLeft: 12 }}
      />
    );
  };

  closeModal = force => {
    setTimeout(() => {
      this.props.navigation.goBack(null);
    }, 300);
    this.setState({ isVisible: false });
  };

  changeSelectedOpenHours = (id, name) => {
    this.setState({
      selectedOpenHoursId: id,
      selectedOpenHoursName: name,
      openHoursSelectCollapsed: true,
    });
  };

  renderOpenHoursOptions = () => {
    const { openHours } = this.props.global;

    if (!openHours) {
      return <View />;
    }
    const sets = openHours.map((set, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.changeSelectedOpenHours(set.id, set.name)}
          key={index}
          style={ModalStyle.selectOptionRow}
        >
          <Text style={{ fontSize: 17 }}>{set.name}</Text>
          <Text style={ModalStyle.selectText}>Select</Text>
        </TouchableOpacity>
      );
    });
    return <View style={{ paddingBottom: 20, paddingRight: 10 }}>{sets}</View>;
  };

  isOutsideSwitch = () => {
    const { isOutside } = this.state;
    this.setState({
      isOutside: !isOutside,
    });
  };

  switchCollapse = () => {
    const { openHoursSelectCollapsed } = this.state;
    this.setState({
      openHoursSelectCollapsed: !openHoursSelectCollapsed,
    });
  };

  updateGroup = async () => {
    const { groupName, isOutside, selectedOpenHoursId, group } = this.state;

    const { jwtToken, restaurantId } = this.props.global;

    const data = {};
    data.name = groupName;
    data.outside = isOutside;
    data.open_hours_set_id = selectedOpenHoursId;

    const result = await TableServices.editTableGroup(
      jwtToken,
      restaurantId,
      data,
      group.id,
    );
    if (result.ok) {
      this.props.global.updateTableGroups();

      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  };

  removeGroup = async () => {
    const { group } = this.state;
    const { jwtToken } = this.props.global;

    const groupId = group.id;
    const result = await TableServices.removeGroup(jwtToken, groupId);
    if (result.ok) {
      this.props.global.updateTableGroups();
      this.closeModal(true);
    } else {
      Alert.alert('Something went wrong', result.message);
    }
  };

  removeDialog = () => {
    const { group } = this.state;
    Alert.alert('Confirm Delete', `Delete ${group.name}?`, [
      {
        text: 'Cancel',
        // style: 'cancel',
      },
      {
        text: 'Delete',
        style: 'destructive',
        onPress: () => this.removeGroup(),
      },
    ]);
  };

  render() {
    const {
      groupName,
      isOutside,
      openHoursSelectCollapsed,
      isVisible,
      selectedOpenHoursName,
    } = this.state;
    const {
      isOutsideSwitch,
      switchCollapse,
      closeModal,
      createNewGroup,
    } = this;

    return (
      <Modal
        isVisible={isVisible}
        backdropOpacity={0.5}
        animationIn="fadeIn"
        animationInTiming={200}
        animationOut="fadeOut"
        animationOutTiming={500}
        onBackdropPress={() => closeModal()}
        coverScreen
        scrollHorizontal
        backdropTransitionOutTiming={0}
        hideModalContentWhileAnimating
      >
        <View style={ModalStyle.modalContainer}>
          <View style={ModalStyle.headerBar}>
            <View style={ModalStyle.headerNavigation} />
            <Text style={ModalStyle.headerTitle}>Edit Table Group</Text>
            <TouchableOpacity
              style={ModalStyle.headerNavigation}
              onPress={() => closeModal()}
            >
              <Ionicons name="md-close" size={29} color="#ccc" />
            </TouchableOpacity>
          </View>
          <View style={{ marginHorizontal: 36 }}>
            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Name</Text>
                <View style={ModalStyle.touchableSelect}>
                  <TextInput
                    style={[
                      ModalStyle.fontRight,
                      ModalStyle.inputRight,
                      { color: '#222' },
                    ]}
                    placeholderTextColor={Colors.placeholder}
                    onChangeText={groupName => this.setState({ groupName })}
                    placeholder="Enter name"
                    value={groupName}
                    autoCorrect={false}
                  />
                </View>
              </View>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Open Hours</Text>
                <TouchableOpacity
                  style={ModalStyle.touchableSelect}
                  onPress={() => switchCollapse()}
                >
                  <Text style={ModalStyle.fontRight}>
                    {selectedOpenHoursName}
                  </Text>
                  <this.downArrow />
                </TouchableOpacity>
              </View>
              <Collapsible
                collapsed={openHoursSelectCollapsed}
                duration={500}
                collapsedHeight={0}
              >
                <ScrollView style={{ maxHeight: 250 }}>
                  <this.renderOpenHoursOptions />
                </ScrollView>
              </Collapsible>
            </View>

            <View style={ModalStyle.itemSelect}>
              <View style={ModalStyle.itemSelectText}>
                <Text style={ModalStyle.fontLeft}>Outside</Text>
                <View style={ModalStyle.touchableSelect}>
                  <Switch
                    onValueChange={isOutsideSwitch}
                    value={isOutside}
                    // trackColor={{ true: Colors.lightBlue }}
                  />
                </View>
              </View>
            </View>

            <View style={Buttons.container}>
              <TouchableOpacity
                style={[Buttons.big]}
                onPress={() => this.updateGroup()}
              >
                <Text style={Buttons.text}>Save Changes</Text>
              </TouchableOpacity>
            </View>
          </View>
          <TouchableOpacity
            style={ModalStyle.bottomButton}
            onPress={() => this.removeDialog()}
          >
            <Ionicons
              name="close"
              size={28}
              color={Colors.dangerRed}
              style={{
                height: 28,
                width: 28,
                // backgroundColor: "blue"
              }}
            />
            <Text style={ModalStyle.deleteText}>Delete this group</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}

export default withGlobalContext(UpdateGroupModal);
