import { Platform, View } from 'react-native';
import React, { useContext, useState } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import ReservationOverviewModal from '../../Timeline/modals/ReservationOverviewModal';
import ReservationItem from '../../../components/ReservationItem';
import SwipeItemMenu from '../../../components/SwipeItemMenu';
import ReservationServices from '../../../services/ReservationsServices';
import { GlobalContext } from '../../../GlobalContext';
import DynamicContainer from './DynamicContainer';
import { Reservation } from '../../../transofrmations/reservationsTransformer';
import Colors from '../../../constants/Colors';
import HeaderButton from '../../../components/HeaderButton';
import 'react-native-reanimated';

export interface ListReservationsProps {
  reservationsOnInterval: Reservation[];
  panHandlers?: any;
  vertical: boolean;
  newReservation?: () => void;
  onGestureEvent: any;
  animatedHeight?: any;
  reanimatedContainerHeight?: any;
}

const ListReservations = ({
  animatedHeight,
  reanimatedContainerHeight,
  reservationsOnInterval,
  vertical,
  newReservation = () => null,
}: ListReservationsProps) => {
  const global = useContext(GlobalContext);
  const { getReservations, selectedDate, showAlert } = global;

  const [selectedReservation, setSelectedReservation] =
    useState<Reservation | null>(null);

  const cancelReservation = (reservationId) => {
    ReservationServices.cancelReservation(reservationId).then((res) => {
      if (res.ok) {
        getReservations(selectedDate.format('YYYY-MM-DD'), true);
        showAlert('Reservation was cancelled.', 'info');
      } else {
        showAlert('Something went wrong.', 'error');
      }
    });
  };

  const [activeItemId, setActiveItemId] = useState('');

  return (
    <DynamicContainer
      animatedHeight={animatedHeight}
      reanimatedContainerHeight={reanimatedContainerHeight}
      vertical={vertical}
      title="Reservations"
      buttonComponent={
        <HeaderButton
          // label={'New'}
          icon="add"
          backgroundColor="#27d767"
          style={{
            height: Platform.OS === 'web' ? 36 : 40,
            width: Platform.OS === 'web' ? 36 : 40,
            marginRight: 12,
            marginTop: 2,
            paddingHorizontal: 0,
            paddingLeft: 2,
            borderRadius: 10,
          }}
          textStyle={{ fontSize: 14, fontWeight: '500' }}
          onPress={() => newReservation()}
          iconSize={26}
          shadow
          small={false}
          label={undefined}
          color={undefined}
          border={undefined}
          borderColor={undefined}
          flipIcon={undefined}
        />
      }
    >
      {reservationsOnInterval.length === 0 ? (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 200,
          }}
        >
          <Ionicons
            name="md-calendar"
            size={130}
            style={{ color: '#eee' }}
            selectable={false}
          />
        </View>
      ) : null}
      <ScrollView style={{ paddingHorizontal: 0 }}>
        <View
          style={{
            height: 0,
            backgroundColor: 'rgba(236,237,238,1)',
          }}
        />
        {reservationsOnInterval.map((reservation: Reservation) => {
          return (
            <View key={`${reservation.id}`}>
              <SwipeItemMenu
                type={reservation.reservationType}
                itemId={`${reservation.id}`}
                activeItemId={`${activeItemId}`}
                onActiveCallback={() => setActiveItemId(`${reservation.id}`)}
                onPressDetails={() => setSelectedReservation(reservation)}
                onPressPrimary={() => setSelectedReservation(reservation)}
                onPressCancel={() => cancelReservation(reservation.id)}
              >
                <ReservationItem
                  key={`${reservation.id}`}
                  dateFrom={reservation.dateTimeFromISOString}
                  dateTo={reservation.dateTimeToISOString}
                  dateCreated={reservation.createdOnDate}
                  name={reservation.customer?.fullName}
                  persons={reservation.persons}
                  multiTable={reservation.multiTable}
                  cancelled={reservation.cancelled}
                  createdByRestaurant={reservation.createdByRestaurant}
                  webForm={reservation.createdByNonRegisteredCustomer}
                  walkIn={reservation.walkIn}
                  messages={reservation?.messages}
                  tables={reservation.tables}
                  pending={reservation.pendingConfirmation}
                  hideIcons
                  hideMessages
                  showMessagesTopRight
                  guestsRightIcon
                  // onPress={() => setSelectedReservation(reservation)}
                  disableOnPress
                  style={{
                    borderRadius: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    shadowOpacity: 0.0,
                    borderLeftWidth: 0,
                    paddingLeft: 10,
                    paddingRight: 18,
                    paddingTop: 6,
                    paddingBottom: 10,
                    elevation: 0,
                    marginHorizontal: 0,
                    marginLeft: 0,
                    marginBottom: 0,
                    marginTop: 0,
                    backgroundColor: Colors.lightBlueBackground,
                  }}
                  hideDate
                  showTables
                />
              </SwipeItemMenu>

              <View
                style={{
                  height: 1,
                  backgroundColor: 'rgba(236,237,238,1)',
                }}
              />
            </View>
          );
        })}
        {/* <View style={{ height: vertical ? 400 : 0 }} /> */}
      </ScrollView>
      <ReservationOverviewModal
        isVisible={!!selectedReservation}
        onBackDropPress={() => setSelectedReservation(null)}
        selectedReservation={selectedReservation}
      />
    </DynamicContainer>
  );
};

export default ListReservations;
